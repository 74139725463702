<!--<div class="container-fluid">
   <div class="navbar">
    <img class="logo-image inner-logo" [src]="logoImg" [uiSref]="'main.home'" alt="logo">   
  </div>
</div>-->

<div class="resetform-body">
 
  <div class="container" style="text-align: center;">
    <img class="logo-image inner-logo" src="../../../assets/videogif/MSME-Logo-Gif.gif" [uiSref]="'main.home'" alt="logo"> 
    <div class="row rowTop">
      <div class="resetform-header">
        <h3 class="text-center text-center-m" style="color:white; font-size:30px;"><b>RESET PASSWORD</b></h3>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">

       
        
        <div class="resetform-main resetform-main-m">

          <form novalidate (ngSubmit)="onResetPasswordSubmit()" [formGroup]="resetPasswordForm">

              <div class="row form-group no-bottom-border">

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                  <label>Enter New Password</label>

                  <input type="password " class="form-control text-style password-m" maxlength="20" [formControl]="pwd" (focus)="onPasswordFocus()"/>
                  <p *ngIf="isShowMessage && pwd.invalid" style="color: red;font-size: small">Password must contain one upper case letter,special character and alphanumeric,length must be between 8 and 20.</p>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
              </div>
              
              <div class="row form-group no-botton-border">

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label>Confirm New Password</label>
                  <input type="password " class="form-control text-style password-m" [formControl]="cpwd" maxlength="20" (focus)="onConfirmedPasswordFocus()"/>
                  <p *ngIf="isShowConfirmedPassword && cpwd.invalid" style="color: red;font-size: small">Confirmed password must be the same as the password.</p>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
              </div>


            <button type="submit" class="btn text-center clickButton button-m" [disabled]="resetPasswordForm.invalid">RESET MY PASSWORD</button>

          </form>

        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>
    </div>
  </div>
</div>











          <!--<form novalidate (ngSubmit)="onResetPasswordSubmit()"-->
                    <!--[formGroup]="resetPasswordForm">-->
                <!--<div class="row">-->
                  <!--<div class="col-md-2 col-sm-12" style="margin-bottom: 3%">-->
                    <!--New password :-->
                  <!--</div>-->
                  <!--<div class="col-md-8 col-sm-12">-->
                    <!--<input type="password" class="form-control full-width bottomborder" maxlength="20" [formControl]="pwd" (focus)="onPasswordFocus()"/>-->
                    <!--<p *ngIf="isShowMessage && pwd.invalid" style="color: red;font-size: small">Password must contain one upper case letter,special character and alphanumeric,length must be between 8 and 20.</p>-->
                  <!--</div>-->
                <!--</div>-->
                <!--<div class="row form-group" style="margin-bottom: 3%">-->
                  <!--<div class="col-md-2 col-sm-12">-->
                    <!--Confirmed new password :-->
                  <!--</div>-->
                  <!--<div class="col-md-8 col-sm-12">-->
                    <!--<input type="password" class="form-control full-width bottomborder" [formControl]="cpwd" maxlength="20" (focus)="onConfirmedPasswordFocus()"/>-->
                    <!--<p *ngIf="isShowConfirmedPassword && cpwd.invalid" style="color: red;font-size: small">Confirmed password must be the same as the password.</p>-->
                  <!--</div>-->
                <!--</div>-->
                <!--<button type="submit" class="btn btn-success width50" [disabled]="resetPasswordForm.invalid">RESET MY PASSWORD</button>-->
              <!--</form>-->
        <!--</div>-->

      <!--</div>-->
      <!--<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>-->

    <!--</div>-->
  <!--</div>-->


<!--</div>-->



<!--<div clas="row">-->

  <!--<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>-->
  <!--<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">-->

    <!--<div class="card detail-card">-->
      <!--<p>Welcome</p>-->
    <!--</div>-->

  <!--</div>-->
  <!--<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>-->


<!--</div>-->










