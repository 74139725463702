import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../../src/services/auth.service';
import { isNullOrUndefined } from 'util';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'app-password-reset',
    templateUrl: 'password-reset.component.html',
    styleUrls: ['password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

    @Input() pRUserInfo:any;
    @Input() active:boolean;
    @Input() headerName:string;
    @Input() msg:string;
    @Input() btnName:string;
    @Output() setActive = new EventEmitter();
    @Output() resetUserInfo = new EventEmitter();
    public pwdResetForm:any;
    public showPwdInText:any;
    public resendOtpTimer:number = 60;
    public timerDisplay:string = "1:00";
    public timerInterval:any;
    public otp:number;
    public isForm:boolean = false;

    constructor(private authService:AuthService,private toastr: ToastrService) { }

    ngOnInit() {
        this.initValues();
    }

    initValues(){
        this.pwdResetForm = new FormGroup({
            currentPwd: new FormControl('',[]),
            pwd: new FormControl('', [
                Validators.required,
                Validators.minLength(12),
                Validators.maxLength(20),
                Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\\$%]).{12,20})')
            ]),
            confirmPwd: new FormControl('', [
                Validators.required,
                Validators.minLength(12),
                Validators.maxLength(20),
                Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\\$%]).{12,20})')
            ]),
        },{validators: this.validateAreEqual});
        $('#passwordExpiry').modal('show');
        this.sendOtpFn('forget');
    }

    public validateAreEqual(c: AbstractControl): { notSame: boolean } | null {
        return c.value.pwd === c.value.confirmPwd ? null : { notSame: true };
    }

    get pRF(){
        return this.pwdResetForm.controls;
    }

    onClose(){
        this.pwdResetForm.reset();
        this.resetUserInfo.emit({});
        this.setActive.emit(false);
        $('#passwordExpiry').modal('hide');
    }

    showInText(value){
        if(this.showPwdInText === value){
            this.showPwdInText = "";
        } else {
            this.showPwdInText = value;
        }
    }

    resetPassword(){
        if(this.pwdResetForm.valid){
            let body = {
                currentPwd:this.pwdResetForm.value.currentPwd,
                pwd: this.pwdResetForm.value.pwd,
                _id: this.pRUserInfo._id
            }
            this.authService.resetPassword(body).subscribe((resp:any)=>{
                if(resp.status){
                    this.toastr.success(resp.info,"Success");
                    this.pwdResetForm.reset();
                    this.resetUserInfo.emit({});
                    this.setActive.emit(false);
                    $('#passwordExpiry').modal('hide');
                }else {
                    this.toastr.error(resp.info,"Error");
                }
            })
        } else {
            this.toastr.error("Fill all mandatory fields","Error");
        }
    }

    sendOtpFn(forget?:any){
        this.resendOtpTimer = 60;
        this.timerDisplay = "1:00";
        const self = this;
        let bodyMobile = {
            "mobileNo" : this.pRUserInfo.personalInfo.mobile,
            "action" : "sendOtp",
        }
        if(!isNullOrUndefined(forget)){
            bodyMobile ["forgotPassword"] = true            
        }
        let message = `OTP sent`;
        self.authService.sendOtp(bodyMobile).subscribe(resp => {
            if(resp['type'] == 'success'){
                this.toastr.success(message, 'Message');
                this.timerInterval = setInterval(()=>this.updateTimer(), 1000);
            }else{
                this.toastr.error('User not exist');
              }
        },
        err => {
            this.toastr.error("Please try again", 'Message');
        });
    }

    updateTimer() {
        const minutes = Math.floor(this.resendOtpTimer / 60);
        let seconds: number | String = this.resendOtpTimer % 60;
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        this.timerDisplay = `${minutes}:${seconds}`;
        this.resendOtpTimer--;
        if (this.resendOtpTimer < 0) {
            clearInterval(this.timerInterval);
        }
    }

    verifyOTPFn(){
        const currentUrl = window.location.href;
        const urlArray = currentUrl.split('/');
        let websiteUrl = urlArray[2];
        const self = this
        let body = {
            "email" : this.pRUserInfo.personalInfo.email,
            "mobileNo" : this.pRUserInfo.personalInfo.mobile,
            "action" : "verifyOtp",
            "otp" : this.otp,
            "websiteUrl": websiteUrl
        }
        if(this.otp.toString().length == 7){
                self.authService.sendOtp(body).subscribe(resp => {
                    if(resp['type'] == 'success'){
                        this.toastr.success('Otp Verified', 'Message');
                        this.isForm = true;
                    }
                    else{
                        this.toastr.error('Incorrect OTP', 'Error');
                    }
                  },
                  err => {
                      this.toastr.error("Please try again", 'Message');
                  });
        } else {
            this.toastr.error('Incorrect OTP', 'Error');
        }
    }
}

