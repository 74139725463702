import { Injectable } from '@angular/core';
import { NGX_MAT_DATE_FORMATS, NgxMatDateFormats } from '@angular-material-components/datetime-picker';

@Injectable({
  providedIn: 'root'
})
export class DateTimeFormatsService {
  private _myDateFormats = {
    parse: {
      dateInput: 'DD-MM-YYYY, HH:mm',
    },
    display: {
      dateInput: 'DD-MM-YYYY, HH:mm',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
  }; 

  constructor() {
    if(JSON.parse(sessionStorage.getItem("updateDateFormat"))){
      console.log(JSON.parse(sessionStorage.getItem("updateDateFormat")));
      this._myDateFormats = JSON.parse(sessionStorage.getItem("updateDateFormat"));
    }
  }

  get myDateFormats() {
    return this._myDateFormats;
  }

  updateDateFormats(newFormat) {
    this._myDateFormats = {
      parse: {
        dateInput: newFormat,
      },
      display: {
        dateInput: newFormat,
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
      },
    };
    sessionStorage.setItem("updateDateFormat",JSON.stringify(this._myDateFormats)); 
  }
}