import {NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
// import {NgbModule, NgbTooltipModule, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
// import { DropzoneModule, DropzoneConfigInterface, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
// import { ColorPickerModule } from 'ngx-color-picker';
// import { IconPickerModule } from 'ngx-icon-picker/dist/index';
// import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SidebarModule } from 'ng-sidebar';
// import {SuiAccordionModule, SuiPopupModule} from 'ng2-semantic-ui';
// import {DndModule} from 'ng2-dnd';
// import {PopoverModule} from "ngx-popover";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { ServicesComponent } from './home/component/servicesComponent/services.component';
import { AboutComponent } from './home/component/aboutComponent/about.component';
// import { GalleryComponent } from './home/component/galleryComponent/gallery.component';
import { ContactComponent } from './home/component/contactComponent/contact.component';

import { SectionComponent } from './home/sectionComponent/section.component';

import {HomeComponent} from './home/home.component';
import {HomeHeaderComponent} from './layouts/header/header.component';
import {HomeFooterComponent} from './layouts/footer/footer.component';
import {HomeSidebarComponent} from './layouts/sidemenu/sidemenu.component';
import {DynamicRoute} from './dynamic.routes';
import {MainComponent} from './layouts/main/main.component';
import {Broadcaster} from '../../services/sharable.service';
// import {BlogComponent} from './blog/blog.component';
// import {BlogViewComponent} from './blog/blogview/blogview.component';
import {ImageChangeComponent} from './home/component/image-change.component';
import {WebsiteService} from './service/website.services';
// import {ValidationOnBlurDirective} from '../../directives/FormControlEvents.directive';
import {ShareModule} from '../share/share.module';
import { ModalModule } from 'ngx-bootstrap/modal';





/*const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 1,
    centeredSlides: true
};*/

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  imports:
    [
      // NgbModule,
      DynamicRoute,
      FormsModule,
      ReactiveFormsModule,
      CommonModule,
     //  DropzoneModule,
      ScrollToModule.forRoot(),
       SidebarModule.forRoot(),
       ModalModule.forRoot(),
     // ColorPickerModule,
     //  SwiperModule,
     // IconPickerModule,
     //  SuiAccordionModule,
     //  SuiPopupModule,
     //  DndModule.forRoot(),
     //  PopoverModule,
     //  NgbTooltipModule.forRoot(),
        ShareModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
  declarations:
    [
      MainComponent,
      HomeComponent,
      HomeHeaderComponent,
      HomeFooterComponent,
      HomeSidebarComponent,
      // BlogComponent,
      // BlogViewComponent,
      ImageChangeComponent,
      ServicesComponent,
        AboutComponent,
      //  GalleryComponent,
        ContactComponent,
        SectionComponent
    ],

    exports : [
        HomeComponent,
        ServicesComponent,
        HomeHeaderComponent,
        HomeSidebarComponent,
    ],
    providers: [
    Broadcaster,
    WebsiteService,
     /* {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG
      }*/
  ],
    schemas: [ NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: []
})
export class DynamicModule {
  constructor() {}
}
