<div class="container-fluid">
  <div class="navbar">
    <img class="logo-image inner-logo" src="assets/img/logo.png" [uiSref]="'main.home'" alt="logo">
  </div>
</div>


<div class="thank-you-body">

    <div class="container" style="text-align: center;">
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <h1 style="font-size:60px;">Thank You</h1>
            <h4 align="center">for Subscribing</h4>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>

    </div>
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <button type="button" (click)="openRegistration()" class="btn clickButton btn-info"  >GET STARTED</button>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
    </div>
</div>

</div>
<div class="about-app-body">
  <div class="row">

    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

      <h1 align="center">About Application</h1>

      <h4 align="center"> Welcome to <b> ManageMyVAT</b>. You have selected the right application to manage your VAT. </h4>

      <h4 align="center">Here’s a list of key features that helps taxpayers in VAT compliance and filing,</h4>

      <div class="container">
        <ul>
          <li><h4>Simplified way of VAT compliant invoicing</h4></li>
          <li><h4>Comprehensive approach to VAT compliance</h4></li>
          <li><h4>Hassle free returns filing</h4></li>
          <li><h4>100% accuracy with tax validation rules</h4></li>
          <li><h4>Easy integration to any ERP</h4></li>
          <li><h4>Access from anywhere through any device</h4></li>
          <li><h4>Sensitive data management</h4></li>
        </ul>
      </div>

      <h4 align="center">For any assistance, please drop a mail on <b>info@managemyvat.com</b></h4>

      <h4 align="center">Regards,
        Team ManageMyVAT</h4>

    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

      <div class="about-app">
        <img class="about-laptop" src="assets/img/Laptop.png">
         </div>

    </div>


  </div>


</div>


