import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
// import { DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
declare var jquery: any;
declare var $: any;

import {Broadcaster} from '../../../../services/sharable.service';

@Component({
  selector: 'app-image',
  template: `
    <div (mouseover)="overEvent($event)" (mouseout)="outEvent($event)" class="{{dropClass}}">
      <div *ngIf="!showImageUpload" [style.text-align]="alignStyle" >
        <button  class="transparent-btn" (click)="showImageUpload = true" >
          <i class="fa fa-picture-o" aria-hidden="true"></i></button>
      </div>
        
        <dropzone *ngIf="showImageUpload"  class="upload-container" [config]="dropConfig" class="{{innerDropClass}}" 
                  [disabled]="disableControl" fxLayout="row" fxLayoutAlign="start strech" fxFlex="auto" [message]="message" 
                (error)="onUploadError($event)" (success)="onUploadSuccess($event)"></dropzone>
    </div>
  `,
  styleUrls: ['../home.component.css']
})
export class ImageChangeComponent implements OnInit {

  showImageUpload: boolean;
  alignStyle: string;

  // @Input() dropConfig: DropzoneConfigInterface;
  @Input() dropClass: string;
  @Input() innerDropClass: string;
  @Input() section: string;
  @Input() disableControl: boolean;
  @Input() showImageIcon: boolean;
  @Input() message: string;
  @Input() iconPos: string;
  @Output() Close= new EventEmitter();
  // @ViewChild(DropzoneComponent) componentRef: DropzoneComponent;
  constructor( private service: Broadcaster) {
  }
  ngOnInit() {
    this.initMethod();
      $('[data-toggle="tooltip"]').tooltip()
  }

  private initMethod() {
      this.getBroadcastMessage();
    if (this.iconPos === null || this.iconPos === undefined) {
      this.alignStyle = 'right';
    }else {
        this.alignStyle = this.iconPos;
    }

    if (this.showImageIcon === false) {
        this.showImageUpload = true;
      /*  if (this.componentRef) {
            this.resetDropzoneUploads();
        }*/
    }

  }
  resetDropzoneUploads() {
    // this.componentRef.directiveRef.reset();
    this.showImageUpload = false;
  }
  overEvent(event) {
    // this.showImageUpload = true;
  }
  outEvent(event) {
    // this.showImageUpload = false;
  }

  onUploadError(args: any) {
    this.resetDropzoneUploads();
  }

  onUploadSuccess(args: any) {
    args['section'] = this.section;
    this.Close.emit(args);
      if (this.showImageIcon !== false) {
          this.resetDropzoneUploads();
      }
  }

    getBroadcastMessage() {
        const self = this;
        this.service.on<string>('clearGalleryImage')
            .subscribe(message => {
               /* if (self.componentRef) {
                    self.componentRef.directiveRef.reset();
                }*/
            });

    }
}
