import {Component, Inject, Injectable, OnInit, ViewContainerRef} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {Transition} from '@uirouter/angular';
import {Response} from "@angular/http";
import {StateService} from '@uirouter/core';
import { ToastrService } from 'ngx-toastr';
import {IConfig, APP_CONFIG} from '../../../appConfig/Iconfig';
import {AuthService} from "../../../services/auth.service";
import {AppConstants} from "../../../services/constant";
import {isNullOrUndefined} from 'util';
@Component({
    selector: 'app-resetpassword-page',
    templateUrl: 'resetPasswordPage.component.html',
    styleUrls: ['resetPasswordPage.component.css']
})

export class ResetPasswordPageComponent implements OnInit{

    params: any;
    resetPasswordForm: FormGroup;
    pwd: FormControl;
    cpwd: FormControl;
    isShowMessage:  boolean;
    public isShowConfirmedPassword: boolean;
    logoImg: string;

    constructor(@Inject(APP_CONFIG) private iconfig: IConfig,private transition: Transition, private stateService: StateService, private authService: AuthService, public toastr: ToastrService, vcr: ViewContainerRef,
                private constant:AppConstants) {
        //this.toastr.setRootViewContainerRef(vcr);
        this.params = transition.params();
    }

    ngOnInit(){
        this.initValues();
        this.initMethods();
        $("#myDiv").css({
            display: "none",
            visibility: "hidden"
          });
    }

    private openRegistration(){
        this.stateService.go('page.login');
    }

    private initValues() {
        this.isShowMessage=false;
        this.isShowConfirmedPassword=false;
        this.logoImg = '';
    }

    private initMethods() {
    let logoUrl;
    this.authService.logoPath.subscribe(item => {
        logoUrl = item;
    });
      if (!isNullOrUndefined(logoUrl) && logoUrl !== '') {
        this.logoImg = logoUrl;
      }
      else {
        this.logoImg = this.iconfig.Application_URL + 'img/logo.png';
      }
        this.initPasswordFormControl();
        this.initPasswordForm();
    }

    initPasswordFormControl(){
        let self=this;
        this.pwd = new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\\$%]).{8,20})')
        ]);

        this.cpwd = new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\\$%]).{8,20})'),
            this.passwordConfirming
        ]);
    }

    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if(c.root.get('pwd')) {
            if (c.value !== c.root.get('pwd').value) {
                return {invalid: true};
            }
        }
    }

    initPasswordForm(){
        this.resetPasswordForm = new FormGroup({
            pwd: this.pwd,
            cpwd: this.cpwd
        });
    }

    onResetPasswordSubmit(){
        const self=this;
        const formModel = this.resetPasswordForm.value;

        this.authService.changeUserPassword(this.params.token,formModel.pwd as string)
            .subscribe(
                res => {
                    let response: any;
                    response = res;
                    if(response.status)
                    {
                        self.toastr.success(self.constant.getToastrMessageByKey('passwordResetSuccessfullyMessage').message,
                            self.constant.getToastrMessageByKey('passwordResetSuccessfullyMessage').title);

                        setTimeout(function () {
                            self.stateService.go('page.login');
                        },2000);
                    }
                    else
                    {
                        self.toastr.error(response.info,'Message');
                    }
                },
                err => {
                    self.toastr.error(self.constant.getToastrMessageByKey('tryAgainLaterMessage').message,
                        self.constant.getToastrMessageByKey('tryAgainLaterMessage').title);
                }
            );
    }

    onPasswordFocus(){
        this.isShowMessage=true;
    }

    onPasswordFocusOut(){
        this.isShowMessage=false;
    }

    onConfirmedPasswordFocus(){
        this.isShowConfirmedPassword=true;
    }
}