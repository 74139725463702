
import {Injectable, Inject} from '@angular/core';
import {Http, Headers, Response} from '@angular/http';
import {APP_CONFIG, IConfig} from '../../../appConfig/Iconfig';
import {CustomAuthHttp} from '../../../services/local-authhttp/local-authhttp';
import { map } from 'rxjs/operators';
@Injectable()
export class WebsiteService {
    private MongoURI: string;
    constructor(private http: CustomAuthHttp,
                @Inject(APP_CONFIG) private iconfig: IConfig, private _customAuthHttp: CustomAuthHttp) {
        this.MongoURI = iconfig.mongoUrl;
    }

    public getDynamicData(webData) {
        let body = JSON.stringify(webData);
        return this._customAuthHttp.post(this.MongoURI + 'dynamic/getData', body)
            .pipe(map((resp: any) => resp));
    }

    public launchWebsite(userData, pageContent) {
        let body = JSON.stringify({userData: userData, pageContent: pageContent } );
        return this._customAuthHttp.post(this.MongoURI + 'dynamic/launchWeb', body)
            .pipe(map((resp: any) => resp));
    }

    public saveWebsiteContent(userData, pageContent) {
        let body = JSON.stringify({userData: userData, pageContent: pageContent } );
        return this._customAuthHttp.post(this.MongoURI + 'dynamic/autoSave', body)
            .pipe(map((resp: any) => resp));
    }

    public sendEmailRequest(webData) {
        let body = JSON.stringify(webData);
        return this._customAuthHttp.post(this.MongoURI + 'website/sendrequest', body)
            .pipe(map((resp: any) => resp));
    }

    public authenticate(username:string, password:string)
    {
        let body = JSON.stringify({"mobile": username, "password": password, "ttl": 1209600000});
        return this._customAuthHttp.post(this.MongoURI+"website/user/login", body);
    }

    getDynamicDataByUser(pageData: any) {
        let body = JSON.stringify({"userId":pageData});
        return this._customAuthHttp.post(this.MongoURI + 'dynamic/getDataByUser', body)
            .pipe(map((resp: any) => resp));
    }

    updateDynamicData(userData: any, pageContent: any) {
        let body = JSON.stringify({userData: userData, userId: userData._id, pageContent: pageContent } );
        return this._customAuthHttp.post(this.MongoURI + 'dynamic/updateWeb', body)
            .pipe(map((resp: any) => resp));
    }
    onLogout() {
        let body = JSON.stringify({
            'data': {
                'status': 'false'
            }
        });

        return this._customAuthHttp.post(this.MongoURI + 'log/status', body).pipe(map(res => res));
    }

    getAllUserRequest() {
        return this._customAuthHttp.get(this.MongoURI + 'legalfaq/getRequestData')
            .pipe(map((resp: any) => resp));
    }

    updateRequestData(data) {
        const body = JSON.stringify(data);
        return this._customAuthHttp.post(this.MongoURI + 'legalfaq/updateRequestData', body)
            .pipe(map((resp: any) => resp));
    }

    saveRequest(data) {
        const body = JSON.stringify(data);
        return this._customAuthHttp.post(this.MongoURI + 'legalfaq/saveRequestData', body)
            .pipe(map((resp: any) => resp));
    }

    changeStatusList(data) {
        const body = JSON.stringify(data);
        return this._customAuthHttp.post(this.MongoURI + 'dynamic/getDataByUser', body)
            .pipe(map((resp: any) => resp));
    }
}
