import { Component, Inject, OnInit, ElementRef, ViewContainerRef, ViewChild, TemplateRef, OnChanges, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { Response } from '@angular/http';
import { any, StateService } from '@uirouter/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TranslateService } from '@ngx-translate/core';
import {forwardRef, Output, EventEmitter, Input} from '@angular/core'
import {SelectItem} from 'primeng/api';
import * as _ from 'underscore';
//import {AppComponent} from "../../app.component";
import { WebAppComponent } from '../../../../src/app/web/web.component';
//import {Router,ActivatedRoute} from "@angular/router";
import { ProductModel } from '../../../../src/app/web/package/package.model';
import {Message} from 'primeng/api';
import {msgctime} from "../../../appConfig/const";
import { PackageService } from '../../../../src/app/web/package/package.service';
import {isNullOrUndefined} from "util";
import { BsModalRef, BsModalService, heLocale } from 'ngx-bootstrap';

//import {RegisterService} from "../../web/registration/services/register.service";
import { AppConstants } from '../../../services/constant';
import { ConfirmModalComponent } from '../../../../src/app/share/components/confirm-modal/confirm.modal.component'

import { id } from '@swimlane/ngx-datatable';
import { async } from 'q';
//import {paymentService} from "../../product/user-management/product/model/product.model";
var moment = require('moment');
import * as momentts from 'moment-timezone';



declare var jquery: any;
declare var $: any;
declare var OneSignal: any;
declare var Razorpay: any;

import { AuthService } from '../../../services/auth.service';
import { AclService } from '../../../services/acl.service';
import { UtilsService } from '../../../utils/common.util';
//import { map } from "rxjs/operator/map";
import { map } from 'rxjs/operators';
import { UtilityService } from '../../../services/utility/utility.service';
import { APP_CONFIG, IConfig } from '../../../appConfig/Iconfig';
import { PackageModel } from "../../web/user-management/package/model/package.model";
import { truncate } from 'fs';
import { User } from '../../web/inventory/product-stock/stock-product.model';
import { UsersService } from '../../../app/web/user-management/user.register/service/user.service';
import { String } from 'aws-sdk/clients/appstream';
import { FormBuilder} from '@angular/forms';
import { ValidationService } from '../../../../src/services/ValidationService';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css'],
    providers: [UtilityService, BsModalService, PackageService, WebAppComponent, AppConstants, ConfirmModalComponent,UsersService]
    // animations: [
    //     trigger('flipState', [
    //         state('active', style({
    //             transform: 'rotateY(179.9deg)'
    //         })),
    //         state('inactive', style({
    //             transform: 'rotateY(0)'
    //         })),
    //         transition('active => inactive', animate('100ms ease-out')),
    //         transition('inactive => active', animate('100ms ease-in'))
    //     ]),
    //     trigger('move', [
    //         state('topPos', style({left: 0, top: 0, opacity: 0})),
    //         state('bottomPos', style({left: 0, top: '50%', opacity: 1})),
    //         transition('topPos => bottomPos', animate( '500ms ease-out' )),
    //         transition('bottomPos => topPos', animate( '5   00ms ease-in' )),
    //     ])
    // ]
})
export class PageLoginComponent implements OnInit {
    params: any;
    private flip: string;
    private userData: any = {};
    private userReg: any = {};
    public genderList: any;
    public residentialList: any;
    public maritalList: any;
    outputParams: FormControl;
    residential: FormControl;
    marital: FormControl;
    state: FormControl;
    public myColors: any;
    public imageOfUi: any;
    public myImage: any;
    public loginWoText: any;
    public logoNewImage:any;
    public eyeImage:any;
    private isStateErrorMessage: boolean;
    private formSubmit: boolean;
    data: any;
    private JwtHelperService: JwtHelperService;
    private formRegSubmit: boolean;
    private isShowMessage: boolean;
    private passwordReset: boolean;
    public isSignUp: boolean;
    public showPw: boolean;
    public showConfirmPw: boolean;
    public loading = false;
    public selectedUserType: string;
    public userList: User[];
    //public utilityType: string;
    regform: FormGroup;
    name: FormControl;
    mobile: FormControl;
    cemail: FormControl;
    pwd: FormControl;
    cpwd: FormControl;
    trmcond: FormControl;
    registrationType:FormControl;
    // selectpkg: FormControl;
    offline: FormControl;
    machineId: FormControl;
    businessType: FormControl;
    public showSignUpForm: boolean;
    public selectPkgBtn: boolean;
    public formLabel: string;
    private formState: string;
    private showUserTypeMsg: boolean;
    private isNameErrorMessage: boolean;
    public isMobileErrorMessage: boolean;
    public isEmailErrorMessage: boolean;
    private isCPasswordMessage: boolean;
    public digits: boolean;
    aclService: AclService;
    public mobileNumber: string;
    public visible: boolean;
    invalidMobile: boolean;
    signupNumber: string = "";
    invalidSignupMobile: boolean;
    public isMatch : boolean;
  public validPassword : boolean;
    license: string = '';
   resetUser:any;
   msg: any;
   bsModalRef: BsModalRef;
  public user: User;
  toggle:any;
  isActive1:boolean;
  isActive2:boolean;
  display:boolean=false;
  public months:SelectItem[];
  public packageListSelectPkg:Array<ProductModel>;
  public cartList:Array<ProductModel>;
  public userId:string;
  public totalAmount:number;
  public addToCartMsg:string;
  public paymentStatus:any;
  private sub:any;
  private pkgcode:any;
  private upgradePkgConfirm:boolean;
  private packageMsg:string = "";
  private pageSource: string = '';
  private showRegister: boolean = false;
  private logoURL:string;
  public myCartList:any;
  public finalAmout:number;
  public logoUrlDynamic:any;
  public extRegisterAllow:any;
  private subscribersPackageList=[];
  public allProcessListId=[];
  modalRef: BsModalRef;
  public logInAttempt:any;
  public registeredUserEmail:any;
  public registeredUser:any;
  public changePwdForm:any;
  public pRUserInfo:any;
  public showPwdInText:any;
  public active:boolean = false;
  msgs:Message[] = [];

  public showIndAndEnt:boolean = true;
  public isMatchPass :boolean = false;
  public resendOtpTimer:number = 60;
  public timerDisplay:string = "1:00";
  public timerInterval:any;
  mobileForm: FormGroup;
  showPopup: boolean = false;
  countryCodes: string[] = ['+91', '+92', '+93', '+94', '+95', '+1']; // Add more as needed
  selectedCountryCode: string = '+91'; // Default selection

    @ViewChild('template',{static:false}) tempModal: ElementRef;


    public filterRec = {'personalInfo': {'name': ''}};
    //modalRef: BsModalRef;
    //public modalRef: BsModalRef;
    public cardHeaderContent: any;   //"Ethics Line Backend Login";
    public userName: any;                     //"Username";
    public passWord:any;                  //"Password";
    public footerContent:any;             //"Copyright © 2021 - Tawazun Ethics Line | ";
    public privacy:any;                        //"Privacy";
    public privacyhref:any;
    public cardFooterarray:any = []; 


    public ListOfFeatures: any;
    public PackageType: any;
    public totalRecords: any;
    public packageList: PackageModel[];
    public loadingPkg: boolean;
    public pkgName: string;
    public extRegAllow: boolean;
    public logoImage: string;
    public isRpa: boolean;
    public businessTypeList: any;
    public isUrlLoad: boolean;
    public showSideImage: boolean
    public loadingBusiness: boolean;
    public show:boolean = false;
    public clickMobile:boolean;
    public clickEmail:boolean;
    public otpMobile: string;
    public checkOTP:boolean;
    public pass:boolean;
    public passing:boolean;
    public validOTP:string;
    public password:string;
    public cpassword: string;
    isSelected: boolean;
    invalidEmail: boolean;
    public loginImageUrl: string;
  public logoNewImagewhite:any;
    public captchaStatus:any;
    public captchaConfig:any;
    public sentOTP: any
    public showOtpElement:boolean;
    public verifySentOtp: string = '';
    public invalidCred:boolean = false;xLink
    public registerUrl:boolean;
    public myNewTaskUrl: string = '';
    public myNewTaskUrlSplit  = [];
    public myNewTaskPath: string = '';
    public isMobile:boolean = false;
    public noSpacesRegex = /.*\S.*/;


     constructor(private transition: Transition, private stateService: StateService, JwtHelperService: JwtHelperService,
        private authService: AuthService, private userService: UsersService,
        public toastr: ToastrService, vcr: ViewContainerRef, private constant: AppConstants,
        public translate: TranslateService, public utilsService: UtilsService, private utilityService: UtilityService,
        private modalService: BsModalService, @Inject(APP_CONFIG) private iconfig: IConfig, private packageService:PackageService, 
        @Inject(forwardRef(() => WebAppComponent)) public app: WebAppComponent,
        public constants:AppConstants,private fb: FormBuilder) {
        //this.toastr.setRootViewContainerRef(vcr);
        this.params = transition.params();
        this.myNewTaskUrl = sessionStorage.getItem('myNewTaskUrl');
        if(this.myNewTaskUrl){
            this.myNewTaskUrlSplit = this.myNewTaskUrl.split('?');
        }
        this.myNewTaskPath = sessionStorage.getItem('myNewTaskPath');
        this.JwtHelperService = JwtHelperService;
        translate.addLangs(['en']);
        translate.setDefaultLang('en');
        this.isUrlLoad = false;

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|hi/) ? browserLang : 'en');
        this.aclService = AclService.getInstance();
        this.msg = [];

        this.months = [];
        this.packageList=[];
        this.cartList=[];
        this.totalAmount=0;
        this.toggle=1;
        this.isActive1 = true;
        this.upgradePkgConfirm=false;
        this.addToCartMsg="";
        this.logoURL="../../../../../src/assets/img/logo.png"
        this.registerUrl = window.location.href.includes('signup')? true : false,
        this.mobileForm = this.fb.group({
            mobileNo: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
            ipAddress:['']
          });        
    }

    ngOnInit() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.isMobile=true;
            this.addToHomeScreen();
            document.getElementById('addToHomeScreen').style.cssText = `
            
            position: absolute;
            top: 88%;
            rder-radius: 4px;
            border: none;
            background-color: rgb(4, 129, 230);
            color: white;
            font-weight: bold;
            width: 200px;
            height: 31px;
            align-items: center;
            text-align: center;
            flex-direction: column;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 15px;
            padding: 4px;
            font-weight: bold;
        `;
        }else{
            this.isMobile=true;
            let addBtn = document.getElementById('addToHomeScreen');
            addBtn.style.display = 'none';
            addBtn.style.visibility = 'hidden';
        }
        let addBtn = document.getElementById('addToHomeScreen');
        let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        // Hide the button if on iOS
        if (isIOS) {
            addBtn.style.display = 'none';
        }
      

        const self = this;
        window.scrollTo(0,0);
        let showLoginPage = true;
        localStorage.setItem('eventID', self.myNewTaskUrlSplit[1]);
        localStorage.setItem('xLink', self.myNewTaskUrlSplit[0]);
        this.authService.checkLoggedIn().subscribe((data)=>{
            console.log(data)
            if(data['loggedIn'] && data['loggedIn']==true){
                showLoginPage = false;
            }
        })
        if(showLoginPage)
        {
            this.initValues();
            this.initMethods();
    
            document.body.scrollTop = 0;
            this.finalAmout=0;
            this.myCartList=[];
            this.subscribersPackageList=[];
            //this.getCartData();
            this.getSubscriberPackageList();
            $("#myDiv").css({
                display: "none",
                visibility: "hidden"
              });

            if(this.registerUrl)(
                this.changeType('taxpayer')
            )
        }
        if(this.params.emailVerification){
            this.emailVerification(this.params.emailVerification);
        }
        this.openMobilePopup();
    }
    ngOnChanges() {
        this.filterRec = {'personalInfo': {'name': ''}};
      }

      ngOnDestory()
      {
          console.log("Destroying the component....")
      }

    private initValues() {
     
       
        this.flip = 'active';
        this.pRUserInfo = {};
        this.formState = 'topPos';
        this.isStateErrorMessage = false;
        // this.isSignUp = false;
        this.showPw = false;
        this.showConfirmPw = false;
        this.formSubmit = false;
        this.formRegSubmit = false;
        this.isShowMessage = false;
        this.showSignUpForm = false;
        this.formLabel = 'Sign Up';
        this.selectPkgBtn = false;
        this.isNameErrorMessage = false;
        this.isMobileErrorMessage = false;
        this.isEmailErrorMessage = false;
        this.isCPasswordMessage = false;
        this.showUserTypeMsg = true;
        this.digits = false;
        this.visible = true;
        this.clickMobile=false;
        this.clickEmail=false;
        this.checkOTP=false;
        this.pass=false;
        this.passing=false;
        this.selectedUserType = '';
        //this.utilityType = '';
        this.userData = {
            mobile: '',
            pwd: ''
        };
        this.mobileNumber = "";
        this.invalidMobile = false;
        this.invalidSignupMobile = false;
        this.ListOfFeatures = ['Invoices', 'Compliance', 'Role & Permission', 'Contact', 'Mulit Organisation'];
        this.pkgName = "Select Package";
        this.ListOfFeatures = [];
        this.PackageType = [];
        this.businessTypeList = [];
        // this.extRegAllow = JSON.parse(sessionStorage.getItem('extRegAllow'));
        // this.isRpa = JSON.parse(sessionStorage.getItem('isRpaAllow'));
        // let logoUrl = sessionStorage.getItem('logoPath');

        //  if(isNullOrUndefined(this.loginImageUrl)) {
        //     this.loginImageUrl = "https://s3.ap-south-1.amazonaws.com/rpa-demo/Images/login.jpg";
        // } else{
        //     this.loginImageUrl = sessionStorage.getItem('loginImageUrl');
        // }

        //  const conditionalTermsUrl = sessionStorage.getItem('conditionalTermsUrl');
        //  if(!isNullOrUndefined(conditionalTermsUrl)){
        this.extRegAllow = isNullOrUndefined(sessionStorage.getItem('extRegAllow')) || sessionStorage.getItem('extRegAllow') == 'undefined' ? false : sessionStorage.getItem('extRegAllow').toLowerCase() == 'true' ? true : false;
        //this.isRpa = isNullOrUndefined(sessionStorage.getItem('isRpaAllow')) || sessionStorage.getItem('isRpaAllow') == 'undefined' ? false : sessionStorage.getItem('isRpaAllow').toLowerCase() == 'true' ? true : false;
        this.isRpa = true;
        let logoUrl = sessionStorage.getItem('logoPath');
        let showImage = sessionStorage.getItem('showSideImage');
        this.showSideImage = true;
        console.log("show image", showImage);
        if(showImage == "true"){
            this.showSideImage = true; //false;
        }else{
            this.showSideImage = false;
        }
        // this.genderList = [
        //     {label: 'Male', value: 'Male'},
        //     {label: 'Female', value: 'Female'}
        // ];
        // this.maritalList = [
        //     {label: 'Married', value: 'Married'},
        //     {label: 'Unmarried', value: 'Unmarried'}
        // ]
        // this.residentialList = [
        //     {label: 'abc', value: 'abc'},
        //     {label: 'xyz', value: 'xyz'}
        // ]
        console.log("showSideImage", this.showSideImage);
        this.cardHeaderContent = sessionStorage.getItem('cardHeaderContent');    //"Ethics Line Backend Login";
        this.userName = sessionStorage.getItem('userName');                     //"Username";
        this.passWord = sessionStorage.getItem('passWord');                     //"Password";
        // console.log("cardfooterarray",sessionStorage.getItem('cardFooterArray'));
        // console.log("cardfooterarrayparse",JSON.parse(JSON.stringify(sessionStorage.getItem('cardFooterArray'))));
        console.log("cardfooterarrayparse",(sessionStorage.getItem('cardFooterArray')));
        if(!isNullOrUndefined(sessionStorage.getItem('cardFooterArray')) && sessionStorage.getItem('cardFooterArray') !== "undefined")
            this.cardFooterarray = JSON.parse(sessionStorage.getItem('cardFooterArray'));

        this.footerContent = sessionStorage.getItem('footerContent');             //"Copyright © 2021 - Tawazun Ethics Line | ";
        this.privacy = sessionStorage.getItem('privacy');                         //"Privacy";
        this.privacyhref = sessionStorage.getItem('privacyhref');                 //"#"
        // let loginBtnColor = sessionStorage.getItem('loginBtnColor');
        let loginBtnColor = "#221b48";
        let loginBtnTextColor = "#ffffff";
        // let loginBtnTextColor = sessionStorage.getItem('loginBtnText');
        // this.loginImageUrl = sessionStorage.getItem('loginImageUrl');
        if(this.isRpa){
            this.logoNewImage = '../../assets/img/logo.png';
            this.eyeImage = '../../../assets/img/Eye-symbol.png';
            this.myColors = {
                'background-color': loginBtnColor,
                'color': loginBtnTextColor,
                'border-radius': '45px',
                'border': '1px solid blue',
                'font': '20px Montserrat',
                'font-weight': '900',
                'width': '40%',
                // 'height': '49.118px',
                // 'padding-top': '12px',
                // 'margin-left': '40px',
                'padding': '2% 0%',
                'text-align': 'center',
                'cursor': 'pointer'
            };
            // this.myImage = {
            //     'background-image':this.imageOfUi,
            //     'background-color': '#cccccc',
            //     'height': '100vh',
            //     'background-position': 'center',
            //     'background-repeat': 'no-repeat',
            //     'background-size': 'cover',
            //     'position': 'relative',
            // }
        }else{
            this.myColors = {
            'color': '#FFFFFF',
            'border-radius': '45px',
            'border': '1px solid blue',
            'background-image': 'linear-gradient(to right, #3300D4,#679BFF)',
            'box-shadow': '3px 14px 26px #ccc',
            'font': '20px Montserrat',
            'font-weight': '900',
            'width': '40%',
            // 'height': '49.118px',
            'padding-top': '12px',
            // 'margin-left': '40px',
            'padding': '2% 0%',
            'text-align': 'center',
            'cursor': 'pointer'
            };
            // this.myImage = {
            //     'background-image':this.imageOfUi,
            //     'background-color': '#cccccc',
            //     'height': '100vh',
            //     'background-position': 'center',
            //     'background-repeat': 'no-repeat',
            //     'background-size': 'cover',
            //     'position': 'relative',
            // }
        }
        if(this.isRpa){
            this.loginImageUrl = '../../../assets/img/login-page-1.png';

            // this.loginWoText = '../../../assets/img/LogIn-Page-image1.png';
            this.loginWoText = '../../assets/img/login-page-1.png'

            this.logoNewImage = '../../assets/img/logo.png';
            this.logoNewImagewhite = '../../assets/img/logo2.png';
            this.eyeImage = '../../../assets/img/Eye-symbol.png';
            }else{
        if (isNullOrUndefined(this.loginImageUrl)) {
            // this.loginImageUrl = '../../../assets/img/login-page.png';
            this.loginImageUrl = this.iconfig.Application_URL + 'img/login.jpg';
        }
    }
        const conditionalTermsUrl = sessionStorage.getItem('conditionalTermsUrl');
        if (!isNullOrUndefined(conditionalTermsUrl)) {
            this.isUrlLoad = true;
         } else {
             this.isUrlLoad = false;
         }
      if (!isNullOrUndefined(logoUrl) && logoUrl !== '') {
        this.logoImage = logoUrl;
      } else {
        this.logoImage = '../../../assets/img/MMV.png';
      }
      this.otpMobile = '';
      this.validOTP = '';
     this.password='';



        this.authService.extRegAllow.subscribe(item => {
            this.extRegAllow = item;
        });
       // let logoUrl;
        this.authService.logoPath.subscribe(item => {
            logoUrl = item;
        });
        if (!isNullOrUndefined(logoUrl) && logoUrl !== '') {
            this.logoImage = logoUrl;
        } else {
            this.logoImage = '../../../assets/img/MMV.png';
        }
        this.isSelected = false;
        this.showOtpElement = false;
        this.invalidCred = false;
    }

    private initMethods() {

        this.getLogo();
        this.getPackagesSelectPkg();
        this.clearPreviousLogin();
        if(this.params.verification){
           this.mobileVerification(this.params.verification);
        }
        if(this.params.registrationId){
            this.changeType('taxpayer');
            this.getRegistrationUser(this.params.registrationId);
        }
        this.intiSignUpFormControl();
        this.intiSignUpForm();
        if (this.iconfig.offlineMode) {
            this.collectLicenseInfo();
        }
        this.getBussinessType();

        
    }

    getRegistrationUser(id){
        this.userService.getRegUser(id).subscribe((resp)=>{
            if(resp.status === 0){
                this.registeredUserEmail = resp.data.personalInfo.email;
                if(!isNullOrUndefined(this.registeredUserEmail) && this.registeredUserEmail){
                    this.showIndAndEnt = false
                }else{
                    this.showIndAndEnt = true
                }
                this.registeredUser = resp.data;
                this.regform.removeControl('cemail');
                this.regform.addControl('cemail',new FormControl(this.registeredUserEmail,[
                    Validators.required,
                    // Validators.pattern('^[a-z|0-9|]*([_][a-z|0-9]+)*([.][a-z|0-9]+([_][a-z|0-9]+)*)?@[a-z][a-z|0-9|]*\.([a-z][a-z|0-9]*(\.[a-z][a-z|0-9]*)?)$')]
                    ValidationService.emailValidator]
                    )) 
            }
        })
    }

    emailVerification(id){
        this.authService.lpEmailVerification(id)
            .pipe(map(res => res.valueOf()))
            .subscribe(
                data=>{
                    let res: any;
                    res = data;
                    if (res.status === true) {
                        if(res.data.toLowerCase().includes('services')){
                            this.toastr.success(res.data,"Success");
                        } else {
                            this.toastr.info(res.data,"Info");
                        }
                    } else  {
                        this.toastr.error('Failed to verify email', 'Message');
                    }   
                },
                err => {
                    this.toastr.error('Please try again later', 'Message');
                }
                )
    }

    toggleFlip() {
        this.flip = (this.flip === 'inactive') ? 'active' : 'inactive';
    }

    // private loginUser() {
    //     this.formSubmit = true;
    //     localStorage.clear(); // to clear all localstorage before login
    //     if(this.invalidMobile)
    //     {
    //         this.invalidCred = true;
    //         return;
    //     }
    //     let digitPattern=/^[0-9]*$/;
    //     if (this.userData.mobile && this.userData.pwd) {
    //         if (this.userData.mobile.trim() !== '' && this.userData.pwd.trim() !== '') {
    //             if(digitPattern.test(this.userData.mobile)){
    //                 this.onLogin(this.userData);
    //             }else{
    //                 this.toastr.error('Please enter valid mobile', 'Message');
    //             }
    //         } else {
    //             this.toastr.error('Please fill all details', 'Message');
    //         }
    //     } else {
    //         this.toastr.error('Please fill all details', 'Message');
    //     }
    // }

    private loginUser() {
        this.formSubmit = true;
        // localStorage.clear(); // to clear all localstorage before login
        if(this.invalidMobile)
        {
            this.invalidCred = true;
            return;
        }
        if (this.userData.mobile && this.userData.pwd) {
            if (this.userData.mobile.trim() !== '' && this.userData.pwd.trim() !== '') {
                 this.A2HS();
                this.onLogin(this.userData);
            } else {
                this.toastr.error('Please fill all details', 'Message');
            }
        } else {
            this.toastr.error('Please fill all details', 'Message');
        }
    }

    onLogin(loginData) {
      const currentUrl = window.location.href;
      const urlArray = currentUrl.split('/');
      const url = urlArray[2];
        const self = this;
        this.loading = true;
        if (loginData.mobile && loginData.mobile.trim() !== '' && loginData.pwd && loginData.pwd.trim() !== '') {
            self.isDirectLogin(loginData, function (status) {
                if (!status) {
                    self.toastr.error('License Expired! Please Renew your subscription!.', 'Message');
                    self.modalRef = self.modalService.show(self.tempModal, { backdrop: 'static', keyboard: false, });
                    //self.stateService.go('web.dashboardta');
                }
                else
                    self.authService.authenticate(loginData.mobile, loginData.pwd,loginData.kliselected, url)
                        .pipe(map(res => res.valueOf()))
                        .subscribe(
                            data => {
                                self.invalidCred = false
                                let res: any;
                                res = data;
                                self.loading = false;
                                self.data = res;
                                if (self.data.status === true) {
                                    let userLocation = JSON.parse(sessionStorage.getItem("userLocation"));
                                    let loc = JSON.parse(sessionStorage.getItem("loc"));
                                    sessionStorage.clear();
                                    sessionStorage.setItem("userLocation",JSON.stringify(userLocation));
                                    sessionStorage.setItem("loc",JSON.stringify(loc));
                                    const token = res.token;
                                    if (token) {
                                        sessionStorage.setItem('jwt', token);
                                        // self.cookieService.put('jwt', token,{ secure : true , httpOnly : true});
                                        const parseddata = self.JwtHelperService.decodeToken(token);
                                        self.authService.generateType(parseddata.type);
                                        const expiry = parseddata.exp;
                                        const userid = parseddata.userId;
                                        const userType = parseddata.userType;
                                        let permissions = [];
                                        if(!isNullOrUndefined(parseddata.permission)) {
                                            parseddata.permission.forEach(item => {
                                                permissions.push(item.toUpperCase());
                                            });
                                        }
                                        permissions = Array.from(new Set(permissions));
                                const userData = res.userData;
                              let orgLabelFile;
                              if (isNullOrUndefined(self.data.userData.organisation)
                                || isNullOrUndefined(self.data.userData.organisation.orgLabelFile)
                                || self.data.userData.organisation.orgLabelFile === '') {
                                orgLabelFile = '../src/assets/i18n/en.json';
                              } else {
                                orgLabelFile = self.data.userData.organisation.orgLabelFile;
                              }
                              sessionStorage.setItem('orgLabelFile', orgLabelFile);  self.utilityService.getTourCondition();
                                if (userid) {
                                    self.authService.setLoggedUserData(userData);
                                    self.authService.setLoggedUserId(userid);
                                    self.authService.setUserType(userType);
                                    sessionStorage.setItem('userType', userType);
                                    self.registerTag(userid);
                                }
                                self.aclService.addPermissions(permissions);
                                sessionStorage.setItem('userData', JSON.stringify(userData));
                                if(userData.locationPreference){
                                    self.authService.setDateTimeFormat(userData.locationPreference.timezone,userData.locationPreference.locale);
                                }
                                if(userData.language){
                                    sessionStorage.setItem("language",userData.language);
                                }
                                if(loginData.kliselected==true)
                                {
                                    self.authService.startRefreshTokenTimer(expiry);
                                    localStorage.setItem('jwt', token);
                                    localStorage.setItem('orgLabelFile', orgLabelFile);
                                    localStorage.setItem('userData', JSON.stringify(userData));
                                    localStorage.setItem('loc',JSON.stringify(loc));
                                    }
                                    self.userData = {};
                                    if (parseddata.type === 'taxpayer' || parseddata.type === 'employee' || parseddata.type === 'msmeRegister'|| parseddata.type === 'msmeImplementer') {
                                        if(self.myNewTaskPath == '/web/task/new-my-task'){
                                            sessionStorage.setItem('eventID', self.myNewTaskUrlSplit[1]);
                                            sessionStorage.setItem('xLink', self.myNewTaskUrlSplit[0]);
                                            self.stateService.go('web.dashboard', {eventID: self.myNewTaskUrlSplit[1], xLink: self.myNewTaskUrlSplit[0]});
                                        }
                                        else{
                                            self.stateService.go('web.dashboard');
                                        }
                                        
                                    } else {
                                        self.stateService.go('web.dashboardta');
                                    }

                                }
                                } else {
                                    self.loading = false;
                                    if(res.info.includes("high registration")){
                                        self.toastr.error(res.info, 'Message');
                                    }
                                    else if (res.info.includes('disable')) {
                                        self.toastr.error('Please contact Administrator to activate account.', 'Message');
                                    } 
                                    else if(res.info.includes('incorrect')) {
                                        self.invalidCred = true;
                                        setTimeout(() => {
                                            self.invalidCred = false;
                                        }, 7000);
                                        
                                        // if(isNullOrUndefined(self.logInAttempt) || self.logInAttempt['mobile'] != loginData.mobile)
                                        // {
                                        //     self.logInAttempt = {};
                                        //     self.logInAttempt['mobile'] = loginData.mobile;
                                        //     self.logInAttempt['count'] = 1;
                                        //      self.logInAttempt['date'] = new Date();
                                        // }
                                        // else
                                        // {
                                        //     self.logInAttempt.count++ ;
                                        // }
                                        // self.toastr.error('Password Attempt - '+self.logInAttempt.count, 'Message');
                                        // self.toastr.error(Invalid , 'Message');
                                    }
                                    else if(res.info.includes('onanother machine')) {
                                        self.toastr.error(res.info,'Message');
                                    } else if(res.info.includes("Your password has expired")){
                                        self.active = true;
                                        self.pRUserInfo = res.userData;
                                        // $('#passwordExpiry').modal('show');
                                        self.toastr.error(res.info,"Error");
                                    } else if(res.info.includes("Your account is locked")){
                                        self.toastr.error(res.info,"Error");
                                    }
                                    else
                                    {
                                        self.toastr.error('Internal Server Error', 'Error');
                                    }
                                }
                            },
                            err => {
                                self.loading = false;
                                self.toastr.error('Please try again later', 'Message');
                            }
                        );
            }
            )
        }

    }
    isDirectLogin(loginData, cb) {
        let self = this;
        if (!this.iconfig.offlineMode) {
            cb(true);
        }
         else {
            this.authService.checkLicenseValidity(loginData.mobile)
                .pipe(map(res => res.valueOf()))
                .subscribe(
                    data => {
                        let res: any;
                        res = data;
                        self.loading = false;
                        self.data = res;
                        if (this.data.status === true) {
                            if (this.data.data == 1)
                                cb(true);
                            else
                                cb(false);
                        } else {
                            self.loading = false;
                            cb(false)
                        }
                    },
                    err => {
                        self.loading = false;
                        self.toastr.error('Please try again later', 'Message');
                        cb(false)
                    }
                );
        }
    }
    registerTag(userid) {
       // let self = this;
       // OneSignal.push(['sendTag', 'userId', userid, function (tagsSent) {
      //  }]);
    }


    private fetchWebsiteData(pageData) {
        const self = this;
        if (pageData.userData) {
            // this.stateService.go('main.home', { userData: pageData.userData});
            // this.stateService.go('web.dashboard', { userData: pageData.userData});
        }
    }


    private clearPreviousLogin() {
        sessionStorage.removeItem('jwt');
    }

    private signUpUser() {
        this.formRegSubmit = true;
        if(this.registeredUserEmail){
            this.userReg._id = this.registeredUser._id;
            this.userReg.subscriberId = this.registeredUser.subscriberId;
            this.userReg.organisation = this.registeredUser.organisation;
        }
        if (this.userReg.name && this.userReg.mobile.length === 10 && this.userReg.email) {
            if (this.userReg.name.trim() !== '' && this.userReg.mobile.trim() !== '' && this.userReg.email.trim() !== '' && this.userReg.trmcond == true) {
                this.userReg.type = this.selectedUserType;
                //this.userReg.utilityType = this.utilityType;
                //this.sendOtpFn()
                this.onRegister(this.userReg);
            } else {
                this.toastr.error('Please fill all details', 'Message');
            }
        } else {
            this.toastr.error('Please fill all details', 'Message');
        }
    }

    sendOtp() {      
        // Show the OTP input field and related options
        this.showOtpElement = true;
    }

    
  

    updateTimer() {
        const minutes = Math.floor(this.resendOtpTimer / 60);
        let seconds: number | String = this.resendOtpTimer % 60;

        if (seconds < 10) {
            seconds = '0' + seconds;
        }

        this.timerDisplay = `${minutes}:${seconds}`;

        this.resendOtpTimer--;

        if (this.resendOtpTimer < 0) {
            clearInterval(this.timerInterval);
        }
    }

    sendOtpFn(forget?:any){
        this.resendOtpTimer = 60;
        this.timerDisplay = "1:00";
        const self = this;
        const mobile= isNullOrUndefined(this.userReg.mobile) ? this.mobileNumber : this.userReg.mobile;
        const email = isNullOrUndefined(this.userReg.email) ? this.registeredUserEmail : this.userReg.email;
// mine    const mobile = this.mobileNumber;
// previous       let  mobile = this.userReg.mobile
        let bodyMobile = {
            "mobileNo" : mobile,
            "action" : "sendOtp",
        }
        // let bodyEmail = {
        //     "email" : email,
        //     "action" : "sendOtp"
        // }
        if(!isNullOrUndefined(forget)){
            bodyMobile ["forgotPassword"] = true            
        }
//        let message = `OTP sent on ${mobile} and Email id ${email}`
        let message = `OTP sent`
        self.authService.sendOtp(bodyMobile).subscribe(resp => {
            if(resp['type'] == 'success'){
                this.toastr.success(message, 'Message');
                this.timerInterval = setInterval(()=>this.updateTimer(), 1000);
            }else{
                this.toastr.error('User not exist');
              }
        },
        err => {
            this.toastr.error("Please try again", 'Message');
        });
        // self.authService.sendOtpEmail(bodyEmail).subscribe(resp => {
        //     if(resp['type'] == 'success'){
        //     //    this.toastr.success(message, 'Message')
        //     }
        // },
        // err => {
        //     this.toastr.error("Please try again", 'Message');
        // });
        this.showOtpElement = true
    }

    resendOTPFn() {
        this.resendOtpTimer = 60;
        this.timerDisplay = "1:00";
        this.timerInterval = setInterval(()=>this.updateTimer(), 1000);
        const mobile = isNullOrUndefined(this.userReg.mobile) ? this.mobileNumber : this.userReg.mobile;
        const body = {
            "mobileNo": mobile,
            "action": "retryOtp"
        };
        const message = `OTP sent again on ${mobile}`;
    return new Promise<void>((resolve, reject) => {
            this.authService.sendOtp(body).subscribe(
                (resp) => {
                    if (resp['type'] === 'success') {
                       (<HTMLInputElement> document.querySelector('#verifySentOtp')).value = '';
                       (<HTMLInputElement>document.querySelector('#verifySentOtp')).focus();
                        this.toastr.success(message, 'Message');
                        resolve();
                    } else {
                        this.toastr.error("Please try again later", 'Error sending OTP');
                        reject("Error sending OTP");
                    }
                },
                (err) => {
                    this.toastr.error("Please try again later", 'Error sending OTP');
                    reject(err);
                }
            );
        });
    }

    // resendOTPEm(){
    //     const self = this;
    //     const email = isNullOrUndefined(this.userReg.email) ? this.registeredUserEmail : this.userReg.email;
    //     let bodyEmail = {
    //         "email" : email,
    //         "action" : "retryOtp"
    //     }
    //     let message = `OTP sent again on ${email}`
    //     self.authService.sendOtpEmail(bodyEmail).subscribe(resp => {
    //     //   console.log("this is response form RESEND OTP CALL",resp)
    //       if(resp['type'] == 'success'){
    //           this.toastr.success(message, 'Message');
    //       }else{
    //         this.toastr.error("Please try again later", 'Error sending OTP');
    //       }
    //     },
    //     err => {
    //         this.toastr.error("Please try again later", 'Error sending OTP');
    //     });
    // }

    verifyOTPFn(event,type?:any){
        const self = this
        const mobile= isNullOrUndefined(this.userReg.mobile) ? this.mobileNumber : this.userReg.mobile;
        const email = isNullOrUndefined(this.userReg.email) ? this.registeredUserEmail : this.userReg.email;
//        let  mobile = this.userReg.mobile
        this.verifySentOtp = event
        let body:any = {
            "email" : email,
            "mobileNo" : mobile,
            "action" : "verifyOtp",
            "otp" : this.verifySentOtp,
            "websiteUrl": this.userReg.websiteUrl
        }
        if(type === 'forgot'){
            body.forgotPassword = true;
        }
        if(this.verifySentOtp.length == 7){
                self.authService.sendOtp(body).subscribe(resp => {
                    // if(resp['message'] == 'otp_verified'){
                        if(resp['type'] == 'success'){
                        this.toastr.success('Otp Verified', 'Message');
                        if(type === 'mobileVerification'){
                            $('#mobileVerification').modal('hide');
                            this.mobileNumber = '';
                        } else {
                            this.passing = true;
                            //    if(resp['verification'] == 'verified'){
                               self.backToUserType();
                                // self.toastr.success('Your details have been successfully submitted.', 'Thanks for registering');
                                
                                if(!isNullOrUndefined(email) && !resp['limitExceeded']){
                                self.toastr.success('You have successfully registered, please login to get started.', 'Registration Successful !',{
                                    closeButton: true, timeOut: 10000,tapToDismiss: true
                                    //  progressBar: true, progressAnimation: 'decreasing'    
                                });
                            } else if(resp['limitExceeded']) {
                                self.toastr.warning('We experiencing high registration with the platform we already registered you but you will not able to login we will notify once you will able to login', 'Registration Successful !',{
                                    closeButton: true, timeOut: 10000,tapToDismiss: true
                                    //  progressBar: true, progressAnimation: 'decreasing'    
                                });
                            } 
                                            self.resetFormData();
                                // this.onRegister(this.userReg);
                            //    }
                        }
                     
                    }else{
                        this.toastr.error('Incorrect OTP', 'Error');
                    }
                  },
                  err => {
                      this.toastr.error("Please try again", 'Message');
                  });
        }
        // else{
        //     this.toastr.error("Please add 7 digit OTP",'Message');
        // }
    }


//     verifyOTPEm(event){
//         const self = this
//         const mobile= isNullOrUndefined(this.userReg.mobile) ? this.mobileNumber : this.userReg.mobile;
//         const email = isNullOrUndefined(this.userReg.email) ? this.registeredUserEmail : this.userReg.email;
// //        let  mobile = this.userReg.mobile
//         this.verifySentOtp = event
//         let body = {
//             "email" : email,
//             "mobileNo" : mobile,
//             "action" : "verifyOtp",
//             "eOtp" : this.verifySentOtp
//         }
//         if(this.verifySentOtp.length == 7){
//                 self.authService.sendOtpEmail(body).subscribe(resp => {
//                     // if(resp['message'] == 'otp_verified'){
//                         if(resp['type'] == 'success' && !isNullOrUndefined(resp['data'])){
//                         this.toastr.success('Otp Verified', 'Message');
//                         this.passing = true;
//                         if(resp['verification'] == 'verified'){
//                         self.backToUserType();
//                         // self.toastr.success('Your details have been successfully submitted.', 'Thanks for registering');
//             self.toastr.success('You have successfully registered to CollabPro, please login to get started.', 'Registration Successful !',{
//                 closeButton: true, timeOut: 10000,tapToDismiss: true
//                 //  progressBar: true, progressAnimation: 'decreasing'    
//             });
//                         self.resetFormData();
//                         // this.onRegister(this.userReg);
//                         }
//                     }else{
//                         this.toastr.error('Incorrect OTP', 'Error');
//                     }
//                   },
//                   err => {
//                       this.toastr.error("Please try again", 'Message');
//                   });
//         }
//         // else{
//         //     this.toastr.error("Please add 7 digit OTP",'Message');
//         // }
//     }

    resetPasswordWithOtp() {
        if(this.changePwdForm.valid){
            const mobile = isNullOrUndefined(this.userReg.mobile) ? this.mobileNumber : this.userReg.mobile;
            const newPassword = this.changePwdForm.value.pwd;
            const body = {
                mobile: mobile,
                password: newPassword
            };
        this.authService.resetPasswordWithOtp(body).subscribe(
            (response) => {
            // Handle successful password reset
            if(response['message'] == "Password updated successfully"){
                this.toastr.success('Password reset successful!', 'Success');
            $('#forgotPasswordModal').modal('hide');
            this.passing=false;            
        }
            // Handle navigation or other actions after reset
          }, error => {
            // Handle password reset errors
            this.toastr.error(error.error['message'] ? error.error['message'] : 'Password reset failed!', 'Error');
          });
        } else {
            this.toastr.error("Fill all mandatory fields","Error");
        }
        
      }

    async changeMobileNo(){
        this.showOtpElement = false
        await this.sleep(800)
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    // showToaster(){
    //     const self = this
    //     self.toastr.success('Please check registered email id and mobile for instructions to Connect with  Tally.', 'Registration Successful !',{
    //         closeButton: true, timeOut: 5000,tapToDismiss: true  
    //     });

    // }

    setLocationPreference(){
        let userLocation = JSON.parse(sessionStorage.getItem("userLocation"));
        userLocation = userLocation.split(", ");
        let country = userLocation[0];
        if(country === "India"){
            country = "INDIA"; // Previous data stored in org collection stored country India in capital letters.
        }
        let timezone = userLocation[1];
        let locale = moment.locale();
        this.userReg.locationPreference = {
            country,
            timezone,
            locale
        };
    }


    private onRegister(userReg: any) {
        const self = this;
        self.loading = true;
        this.setLocationPreference();
        if (this.userReg.name.trim() !== '' && this.userReg.mobile.trim() !== '' &&
            this.userReg.email.trim() !== '' && this.userReg.pwd.trim() !== '') {
            // this.authService.registerUser(userReg)
            //     .pipe(map(res => res.valueOf()))
            //     .subscribe(
                this.showOtpElement = false
                this.authService.signup(userReg)
                .pipe(map(res => res.valueOf()))
                .subscribe(
                    response => {
                        let rec: any;
                        rec = response;
                        if (rec.status) {
                            this.sendOtpFn();
                            self.loading = false;
                            //self.userReg = {};
                            // self.toggleFlip();
                //             self.backToUserType();
                //             // self.toastr.success('Your details have been successfully submitted.', 'Thanks for registering');
                // self.toastr.success('Please check registered email id and mobile for instructions to Connect with  Tally.', 'Registration Successful !',{
                //     closeButton: true, timeOut: 5000,tapToDismiss: true
                //     //  progressBar: true, progressAnimation: 'decreasing'    
                // });
                //             self.resetFormData();
                        } else {
                            self.loading = false;
                            self.toastr.error(rec.data, 'Message');
                        }
                    },
                    err => {
                        self.loading = false;
                        self.toastr.error('Please try again later', 'Message');
                    }
                );
        }
    }

    private testPwd(text) {
        const pmatch = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\$%]).{8,20})/;
        return (text.match(pmatch));
    }

    public intiSignUpForm() {
        this.changePwdForm = new FormGroup({
            pwd: new FormControl('', [
                Validators.required,
                Validators.minLength(12),
                Validators.maxLength(20),
                Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\\$%]).{12,20})')
            ]),
            confirmPwd: new FormControl('', [
                Validators.required,
                Validators.minLength(12),
                Validators.maxLength(20),
                Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\\$%]).{12,20})')
            ]),
        },{validators: this.validateAreEqual})
        this.regform = new FormGroup({
            name: this.name,
            cemail: this.cemail,
            mobile: this.mobile,
            pwd: this.pwd,
            cpwd: this.cpwd,
            trmcond: this.trmcond,
            // outputParams: this.outputParams,
            // residential: this.residential,
            // marital: this.marital,
            // state: this.state,
            // selectpkg: this.selectpkg,
            offline: this.offline,
            machineId: this.machineId,
            businessType: this.businessType,
            registrationType: this.registrationType
        });
    }

    public validateAreEqual(c: AbstractControl): { notSame: boolean } | null {
        return c.value.pwd === c.value.confirmPwd ? null : { notSame: true };
    }

    get pCF(){
        return this.changePwdForm.controls;
    }

    showInText(value){
        if(this.showPwdInText === value){
            this.showPwdInText = "";
        } else {
            this.showPwdInText = value;
        }
    }

    onSignUpSubmit() {
        const formModel = this.regform.value;
        this.userReg.name = formModel.name as string;
        this.userReg.mobile = formModel.mobile as string;
        this.userReg.countryCode = this.selectedCountryCode;
        this.userReg.email = formModel.cemail as string;
        this.userReg.pwd = formModel.pwd as string;
        this.userReg.cpwd = formModel.cpwd as string;
        // this.userReg.selectpkg = formModel.selectpkg as string;
        this.userReg.trmcond = formModel.trmcond as string;
        this.userReg.offline = formModel.offline as string;
        this.userReg.machineId = formModel.machineId as string;
        this.userReg.businessType = formModel.businessType as string;
        const currentUrl = window.location.href;
        const urlArray = currentUrl.split('/');
        this.userReg.websiteUrl = urlArray[2];
        this.userReg.parentUrl = urlArray[2];
        this.userReg.regType = formModel.registrationType as string
        this.signUpUser();
    }

    private intiSignUpFormControl() {
        const self = this;
        this.name = new FormControl('', [Validators.required, this.checkField]);
        this.registrationType = new FormControl('Enterprise', [Validators.required])
        this.mobile = new FormControl('', [
            Validators.required,
            Validators.maxLength(10),
            Validators.minLength(10),
        ]);

        this.cemail = new FormControl('', [
            Validators.required,ValidationService.emailValidator
            // Validators.pattern('^[a-zA-Z|0-9|]*([_][a-zA-Z|0-9]+)*([.][a-zA-Z|0-9]+([_][a-zA-Z|0-9]+)*)?@[a-zA-Z][a-zA-Z|0-9|]*\.([a-zA-Z][a-zA-Z|0-9]*(\.[a-zA-Z][a-zA-Z|0-9]*)?)$')
            // Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')
        ]);

        console.log("EMAIL",this.cemail);

        this.pwd = new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\\$%]).{8,20})')
        ]);

        this.cpwd = new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\\$%]).{8,20})')
        ]);
        this.trmcond = new FormControl('', [Validators.required]);
        // this.selectpkg = new FormControl('', [Validators.required]);
        this.offline = new FormControl('');
        this.machineId = new FormControl('');
        this.businessType = new FormControl('');
        // this.outputParams = new FormControl(this.genderList[0]);
        // this.residential = new FormControl(this.residentialList[0]);
        // this.marital = new FormControl(this.maritalList[0]);
        // this.state = new FormControl('')
    }

    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if (c.root.get('pwd')) {
            if (c.value !== c.root.get('pwd').value) {
                return { invalid: true };
            }
        }
    }
    checkField(c: AbstractControl): { invalid: boolean } {
        if (c.value) {
            if (c.value.trim() === '') {
                return { invalid: true };
            }
        }

    }
  


    //  onKycSignUpSubmit() {
    //     const formModel = this.regform.value;
    //     console.log(this.regform.value);
    //     this.userReg.name = formModel.name as string;
    //     this.userReg.mobile = formModel.mobile as string;
    //     this.userReg.email = formModel.cemail as string;
    //     this.userReg.pwd = formModel.pwd as string;
    //     this.userReg.gender = formModel.outputParams.value;
    //     this.userReg.residentialStatus = formModel.residential.value;
    //     this.userReg.maritalStatus = formModel.marital.value;
    //     this.userReg.state = formModel.state as string;
    //     // this.userReg.cpwd = formModel.cpwd as string;
    //     // this.userReg.trmcond = formModel.trmcond as string;
    //     // this.userReg.offline = formModel.offline as string;
    //     // this.userReg.machineId = formModel.machineId as string;
    //     // this.userReg.businessType = formModel.businessType as string;
    //     const currentUrl = window.location.href;
    //     const urlArray = currentUrl.split('/');
    //     this.userReg.websiteUrl = urlArray[2];

    //     this.signUpUser();
    // }


  checkConfirmPass() {
    if (this.regform.value.cpwd !== '') {
      if (this.regform.value.cpwd === this.regform.value.pwd) {
        this.isCPasswordMessage = false;
      } else {
        this.isCPasswordMessage = true;

      }
    }
  }
    onPasswordFocus() {
        this.isShowMessage = true;
    }
    onCPasswordFocus() {
        this.isCPasswordMessage = true;
    }
    resetFormData() {
        this.regform.reset();
    }
    onNameFocus() {
        this.isNameErrorMessage = true;
    }
    onStateFocus() {
        this.isStateErrorMessage = true;
    }
    onMobileFocus() {
        // if(this.regform.value.mobile == ''){
    if (this.regform.value.mobile.length == 10 && this.regform.value.mobile.length != 0) {
            this.invalidSignupMobile = false;
            // this.isMobileErrorMessage = true;
        }
    }
    onEmailFocus() {
      this.isEmailErrorMessage = true;

    }

    formTypeSelect() {
        if(this.formLabel == "Login"){
            this.showSignUpForm = false;
            this.formLabel = "Sign Up";
        } 
        else if(this.formLabel == "Sign Up"){
            this.showSignUpForm = true;
            this.formLabel = "Login";
        }
        
    }

    changeType(type: any) {
        this.showOtpElement = false
        this.invalidCred = false
      const currentUrl = window.location.href;
      const urlArray = currentUrl.split('/');
        const self = this;
        this.showSignUpForm = true;
        this.selectPkgBtn = true;
        this.selectedUserType = type;
        //this.utilityType = utilityType;
        this.showUserTypeMsg = false;
        this.getPackages(this.selectedUserType);
        setTimeout(function () {
            self.moveForm();
        }, 200);
    }

    pkgAddCart() {
        //this.selectPkgBtn = true;
        // this.bsModalRef = this.modalService.show(this.tempModal, {backdrop: 'static', keyboard: false});
      }

    backToLogin() {
        const self = this;
        setTimeout(function () {
            self.showSignUpForm = false;
            self.formState = 'topPos';
        }, 1000);
        this.selectedUserType = '';
        this.showUserTypeMsg = true;
        this.toggleFlip();
    }
    moveForm() {
        this.formState = 'bottomPos';
        // this.formState = this.formState === 'topPos' ? 'bottomPos' : 'topPos';
    }
    backToUserType() {
        this.showOtpElement = false
        this.formState = 'topPos';
        const self = this;
        setTimeout(function () {
            self.selectedUserType = '';
            self.showSignUpForm = false;
            self.showUserTypeMsg = true;
        }, 0);
        if(this.isMobile){
            this.scrolldown()
        }
    }
    onForgotPasswordClick() {
        this.passing = false;
        this.changePwdForm.reset();
        this.visible = true;
        $('#forgotPasswordModal').modal('show');
        this.clickMobile = true;
        this.otpMobile='';
        this.checkOTP=false;
            this.validOTP='';
            this.pass=false;
    }
    // onSubmit(event){
    //     if(event == 'clickMobile')
    //     {
    //     this.clickMobile=true;
    //     this.clickEmail=false;
    //     this.checkOTP=false;
    //     this.pass=false;
    //     }
    //     else{
    //         this.clickEmail=true;
    //         this.clickMobile=false;
    //         this.checkOTP=false;
    //         this.pass=false;
    //         this.otpMobile='';
    //         this.validOTP='';
    //     }

    // }
    getOTP(event) {

        let self = this;
        self.authService.getOTP(self.otpMobile).subscribe(resp => {
            let response: any;
            response = resp;
            if (response.status == true) {
                this.checkOTP=true;
                this.clickMobile=false;

            } else {
                this.checkOTP=false;
                this.clickMobile=true;
                this.toastr.error(this.constant.INVALID_MOBILE_ERROR, 'Message');
            }
        },
        err => {
            this.toastr.error(this.constant.PROCESS_TRY_AGAIN, 'Message');

            }
    );

    }

    showOTP(event){
        this.authService.showOTP(this.otpMobile,this.validOTP).subscribe(resp => {
        let response: any;
            response = resp;
        if(response.status == true){
            this.pass=true;
            this.checkOTP=false;
            this.clickMobile=false;


        }else if(event == 'checkOTP'){
            this.checkOTP=true;
            this.pass=false;
            this.clickMobile=false;
            this.toastr.error(this.constant.INVALID_OTP_ERROR, 'Message');

        }
        else{
            this.checkOTP=false;
            this.pass=false;
            this.clickMobile=true;
        }
    },
    err => {
        this.toastr.error(this.constant.PROCESS_TRY_AGAIN, 'Message');

        }
        );
    }

    clear() {
        this.password = '';
        this.cpassword = '';
      }
    changePassword(users){
        this.openModal(users);
        this.resetUser.id = users._id;
        this.resetUser.name = users.personalInfo.name;
      }
      savePassword(){

        if(this.cpassword!==this.password){
            this.toastr.error(this.constant.ERROR_MESSAGE, 'Message');
        }
        else {

          this.authService.savePassword(this.otpMobile,'mobile',this.password).subscribe(
            respData => {
                let response: any;
            response =  respData ;
              if (response) {

                this.toastr.success(response.info,'Message');
                $('#forgotPasswordModal').modal('hide');
                this.clear();
              }
              else {
               this.toastr.error(response.info,'Message');
               $('#forgotPasswordModal').modal('hide');
              }
            },
            err => {
              this.msg="network error during reset password";
              setTimeout(() => this.passwordReset = false , 1000);

              console.log("Error");
            });
        }

      }

    mobileVerification(id){
        this.userService.getRegUser(id).subscribe((respData:any)=>{
            if(respData.status === 0){
                this.mobileNumber = respData.data.personalInfo.mobile;
                $('#mobileVerification').modal('show');
                this.sendOtpFn();
                this.sendOtp();
            }
        })
    }

    validatePassword(model){
        if(!isNullOrUndefined(model.validPassword)){
          this.validPassword = !model.validPassword;
        }
      }
      comparePassword(){
          if(this.password != this.cpassword){
              this.isMatch=false;
              this.isMatchPass = true;
            //   this.toastr.error('password does not match', 'Required');

          }else if(this.validPassword) {
            this.isMatch = true;
            this.isMatchPass = false;
          }
      }

    openModal(users) {
        this.bsModalRef = this.modalService.show(this.tempModal,{backdrop: 'static',keyboard:false,});
      }

    onModalForgotPasswordModalClose() {
        this.mobileNumber = '';
        $('#forgotPasswordModal').modal('hide');
    }
    onSearchMobileNumberClick() {
        const self = this;
        if (self.mobileNumber && self.mobileNumber != '') {
            self.mobileNumber = self.mobileNumber.trim();
            const type = 'mail'
            const hostName = !isNullOrUndefined(window.location.hostname) ? window.location.hostname:'';
            self.authService.resetPasswordOld(self.mobileNumber, hostName,type)
                .subscribe(
                    res => {
                        let response: any;
                        response = res;
                        if (response.status) {
                            self.toastr.success(self.constant.getToastrMessageByKey('resetPasswordLinkSendMessage').message,
                                self.constant.getToastrMessageByKey('resetPasswordLinkSendMessage').title);
                            self.mobileNumber = "";
                            $('#forgotPasswordModal').modal('hide');
                        } else {
                            self.toastr.error(response.data, 'Message');
                            self.mobileNumber = "";
                            $('#forgotPasswordModal').modal('hide');
                        }
                    },
                    err => {
                        self.toastr.error(self.constant.getToastrMessageByKey('tryAgainLaterMessage').message,
                            self.constant.getToastrMessageByKey('tryAgainLaterMessage').title);
                        self.mobileNumber = "";
                        $('#forgotPasswordModal').modal('hide');
                    }
                );
        }
    }
    checkLenght() {
        let mobileNumber = this.mobileNumber;
        if (mobileNumber.length == 10) {
            this.digits = false;
            this.visible = false;
        }
        else {
            this.visible = true;
            this.digits = true;
        }

    }
    onMobileNumberFocus() {
        this.digits = true;
    }
    customMobilePattern(e): any {
       let BreakException = {};
        if (e !== '') {
        let patternMob;
        this.authService.mobPattern.subscribe(item => {
            patternMob = item;
        });
          const pattern: any = (!isNullOrUndefined(patternMob) && patternMob !== '') ? JSON.parse(patternMob) : this.constant.mobilePattern;
          try {
            pattern.forEach(mobPattern => {
              const mobileNoPattern: any = new RegExp(mobPattern);
              if (mobileNoPattern.test(e)) {
                this.invalidMobile = false;
                throw BreakException;
              } else {
                this.invalidMobile = true;
              }
            });
          } catch (err) {
            console.log('error',err);
          }
        } else if (e === '') {
            this.invalidMobile = false;
        }
    }
    customSignupMobilePattern() {
        let BreakException = {};
        this.isMobileErrorMessage = false;
        // if(/^0?(?:50|52|55|56|54|58)\d{7}$/.test(this.regform.value.mobile) && this.regform.value.mobile.length == 10 ){
        if (this.regform.value.mobile !== '') {
          const pattern = this.constant.mobilePattern;
          pattern.forEach(mobPattern => {
            if ((mobPattern.test(this.regform.value.mobile))) {
              this.invalidSignupMobile = false;
              throw BreakException;
            } else {
              this.invalidSignupMobile = true;
            }
          });
        } else if (this.regform.value.mobile.length !== 0) {
            this.isMobileErrorMessage = true;
            this.invalidSignupMobile = false;
        } else if (this.regform.value.mobile === '') {
            this.invalidSignupMobile = false;
        }
    }

    validMobileLength(mobile) {
        // if (mobile.length >= 7) {

        // } else
          if (mobile.length === 0) {
            this.invalidMobile = false;
        } else {
            this.customMobilePattern(mobile);
          }
    }
    collectLicenseInfo() {
        let self = this;
        this.authService.checkSubscriberExists()
            .pipe(map(res => res.valueOf()))
            .subscribe(
                data => {
                    let res: any;
                    res = data;
                    self.loading = false;
                    self.data = res;
                    if (!this.data.status) {
                        self.loading = false;
                        this.modalRef = this.modalService.show(this.tempModal, { backdrop: 'static', keyboard: false, });
                    }
                },
                err => {
                    self.loading = false;
                    self.toastr.error('Please try again later', 'Message');
                }
            );

    }
    validateLicense() {
        let self = this;
        this.authService.validateLicense(self.license)
            .pipe(map(res => res.valueOf()))
            .subscribe(
                data => {
                    let res: any;
                    res = data;
                    self.loading = false;
                    self.data = res;
                    if (this.data.status === true) {
                        let response = this.data.data;
                        this.authService.registerUserOnLocalDB(response)
                            .pipe(map(res => res.valueOf()))
                            .subscribe(
                                data => {
                                    let res: any;
                                    res = data;
                                    self.loading = false;
                                    self.data = res;
                                    if (this.data.status === true) {
                                        // let licenseData = JSON.parse(this.data.data);
                                        self.toastr.success('License Successfully Validated You can continue using the utility till ' + moment().add(+response.validityNumber, 'd').format("DD-MM-YYYY"), 'Message');
                                        self.modalRef.hide()
                                    } else {
                                        self.loading = false;
                                        self.toastr.error('InValid License.Please enter a valid license to continue login on utility', 'Message');
                                    }
                                },
                                err => {
                                    self.loading = false;
                                    self.toastr.error('Please try again later', 'Message');
                                }
                            );
                    } else {
                        self.loading = false;
                        self.toastr.error('InValid License.Please enter a valid license to continue login on utility', 'Message');
                    }
                },
                err => {
                    self.loading = false;
                    self.toastr.error('Please try again later', 'Message');
                }
            );
    }


    public getPackages(userType) {
        this.PackageType = [];
        this.utilityService.getPackages(userType/*, webUrl*/).subscribe(respData => {
            let self = this;
            if (respData.status == 0) {
                let pkgdata = respData.data;
                if (pkgdata.length > 0) {
                    self.totalRecords = respData.data.total;
                    self.packageList = [];
                    _.each(pkgdata, function (data) {
                        let Obj = {
                            label: data.productName,
                            value: data._id
                        };
                        self.PackageType.push(Obj);
                        self.loadingPkg = true;

                    });
                    self.packageList = pkgdata;
                    // this.app.stopLoader();
                }
                else {
                    console.log("Error:-", respData.message);
                }
            }

        },
            err => {
                console.log("SomeThing Went Wrong Please Try Again later");
            })
    }

    openPackages(packages: TemplateRef<any>) {
        this.modalRef = this.modalService.show(packages, { backdrop: 'static', class: 'modal-dialog modal-lg' });
    }

    selectedPkg(packages) {
        this.pkgName = "(" +  packages.productName + ")";
        this.regform.controls['selectpkg'].setValue( packages.productName);
    }

    getBussinessType() {
      const self = this;
      self.businessTypeList = [];
      self.loadingBusiness = false;
      self.authService.getBusinessTypes().subscribe(resp => {
        let respData: any;
        respData = resp;
        if (respData.status) {
            respData.data.forEach(item => {
              self.businessTypeList.push({label: item.businessTypeName, value: item._id});
              self.loadingBusiness = true;
            });
          } else {
          self.loadingBusiness = false;
        }
      });
    }
  selectTerms(event) {
      if (event.target.checked) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
  }
  disableSave() {
      if (this.regform.invalid || this.isCPasswordMessage || !this.isSelected) {
        return true;
      } else {
        return false;
      }
  }
  isEmailValid(event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.toLowerCase();
    const email = this.regform.value.cemail as string;
    const emailArray = email.split('@');
    const emptyString = '';
    const subEmail = emailArray[0];
    if (subEmail === emptyString) {
      this.invalidEmail = true;
    } else {
      this.invalidEmail = false;
    }
  }
  // From package.ts

  goBack(){
    this.app.stateService.go('web.dashboard');  
}

getCartData(){
   
    this.packageService.getCartData().subscribe(
        respdata => {
            if(!respdata.status) {
                if (respdata.data.data[0]._doc.cartList.length > 0) {
                    this.myCartList=[];
                     respdata.data.data[0]._doc.cartList.forEach(pkg=>{
                         this.myCartList.push({id:pkg._id,name:pkg.name,amount:pkg.amount})
                         this.finalAmout=this.finalAmout+pkg.amount;
                     })
               
                }
            }
          
        },
        err => {
           
            this.app.showError(err);
        });
}
//This function is responsible for get packages.
getPackagesSelectPkg(){
    
    this.packageService.getPackages().subscribe(
        respdata => {
            if(!respdata.status) {
                if (respdata['data'] && respdata['data'].length > 0) {
                    this.packageListSelectPkg = respdata.data;
                }
            }
            else{
                this.app.showMessage(respdata.info);
            }
           
        },
        err => {
         
            this.app.showError(err);
        });   
}

isAddedToCart(pkg){
    var index= this.myCartList.map(function(item) { return item.id; }).indexOf(pkg._id);
    if(index!=-1)
    return true
    else 
    return false 
}
isPurchaedPackage(pkg){
    var index= this.subscribersPackageList[0].includes(pkg._id);
    if(index)
    return true
    else
    return false 
}


public addToCartPackage(pkg) {
    this.app._authservice.loggedIn = true;
    if (this.app._authservice.loggedIn) {
        this.app.startLoader();
        let data={
            _id:pkg._id,
            name:pkg.productName,
            amount:pkg.price.salesPrice,
            desc:pkg.productName,
            isCart:pkg.isCart,
            isPermission:pkg.isPermission,
            active:true
        }
        this.packageService.addToCart(data).subscribe(data=>{
            if(!data.status){
                this.myCartList.push({id:pkg._id,name:pkg.productName,amount:pkg.price.salesPrice})
                this.finalAmout = this.finalAmout+pkg.price.salesPrice;
                this.totalAmount=this.finalAmout;
                this.app.showSuccess(data.info);
            }
            else{
                this.app.showMessage(data.info);
            }
            this.app.stopLoader();
        },err=>{
            this.app.stopLoader();
            this.app.showError(err);
        });
    }
     else {
        this.myCartList.push({id:pkg._id,name:pkg.productName,amount:pkg.price.salesPrice})   
          }
}
public removeFromCart(pkg){
    this.app._authservice.loggedIn = true;
    if(this.app._authservice.loggedIn) {
        this.app.startLoader();
        let data={
            _id:pkg._id,
            name:pkg.productName,
            amount:pkg.price.salesPrice,
            desc:pkg.productName,
            isCart:pkg.isCart,
            isPermission:pkg.isPermission,
            active:true
        }
        this.packageService.removeFromCart(data).subscribe(data=>{
                if(!data.status){
                    var index= this.myCartList.map(function(item) { return item.id; }).indexOf(pkg._id);
                   this.myCartList.splice(index,1)
                   this.finalAmout=this.finalAmout-pkg.price.salesPrice;
                   this.totalAmount=this.finalAmout
                    this.app.showSuccess(data.info);
                }
                else{
                    this.app.showMessage(data.info);
                }
                this.app.stopLoader();
            },
            err=>{
                this.app.showError(err);
                this.app.stopLoader();
            })
    }
    else{
        var index= this.myCartList.map(function(item) { return item.id; }).indexOf(pkg._id);
        this.myCartList.splice(index,1)
    }

}

public removeFromCartAfterBuy(){
    
         this.packageService.removeFromCartAfterBuy(this.myCartList).subscribe(data=>{
                if(!data.status){
                  // this.myCartList=[];
                   this.getProcessList();
                   this.app.showSuccess(data.info);
                }
                else{
                    this.app.showMessage(data.info);
                }
             
            },
            err=>{
                this.app.showError(err);
                this.app.stopLoader();
            })
    }

public addPackageAfterBuy() { //call this function after payment successfull and remove cart function 
    this.app._authservice.loggedIn = true;
    if(this.app._authservice.loggedIn) {
        this.packageService.doPayment(this.myCartList).subscribe(data=>{
                if(!data.status){
                    this.myCartList.forEach(ele=>{
                        this.subscribersPackageList.push(ele.id)
                    })
                    this.removeFromCartAfterBuy();
                    this.app.showSuccess(data.info);
                }
                else{
                    this.app.showMessage(data.info);
                }
            },
            err=>{
                this.app.showError(err);
            })
    }
    else{
        // this.myCartList=[];         
    }
}
public getSubscriberPackageList() {
    this.app._authservice.loggedIn = true;
    if(this.app._authservice.loggedIn) {
    
        
        this.packageService.getSubscriberPackageList().subscribe(data=>{
                if(!data.status){
                   if(!isNullOrUndefined(data.data)){
                    this.subscribersPackageList.push(data.data[0].packages);
                    this.app.showSuccess(data.info);
                   }
                }
                else{
                    this.app.showMessage(data.info);
                }
            
            },
            err=>{
                this.app.showError(err);
              
            })
    }
    else{
        // this.myCartList=[];         
    }
}
public getProcessList(){
    let packageIds=[];
    this.myCartList.forEach(list=>{
        packageIds.push(list.id);
    })
    this.allProcessListId=[];
    this.packageService.getprocessListId(packageIds).subscribe(data=>{
        if(!data.status){
          
           data.data.forEach(packages=>{
            packages.processList.forEach(ides=>{
                this.allProcessListId.push(ides);
            })
           })
           this.allProcessListId=this.allProcessListId.filter((a, b) => this.allProcessListId.indexOf(a) === b)
           this.processAccess(this.allProcessListId)
            this.app.showSuccess(data.info);
        }
        else{
            this.app.showMessage(data.info);
        }
    
    },
    err=>{
        this.app.showError(err);
      
    })
}

async processAccess(allProcessListId){
await this.packageService.processAccess(allProcessListId).subscribe(data=>{
    if(!data.status){
               this.app.showSuccess(data.info);
               this.myCartList=[];
               this.finalAmout=0;
    }
    else{
        this.app.showMessage(data.info);
    }

},
err=>{
    this.app.showError(err);
  
})
}
async savePayment(data){
    await this.packageService.savepayment(data).subscribe(data=>{
        if(!data.status){
        }
        else{
            this.app.showMessage(data.info);
        }
    
    },
    err=>{
        this.app.showError(err);
      
    }) 
}
async handleSuccess(data){
   await this.addPackageAfterBuy()
   console.log("addPackageAfterBuy");
   
   await this.savePayment(data);
   console.log("savePayment");
   
   await this.app.showMsg('success', 'Message', 'Payment Successfull');
//    console.log("showMsg");
    this.bsModalRef = this.modalService.show(ConfirmModalComponent, { class: 'modal-sm' });
    this.bsModalRef.content.confirmText = "Do You Want To Log Out to use this functionality?";
   await this.bsModalRef.content.onClose.subscribe(result => {
     if (result) {
         this.app._authservice.logout();
     }else{
         console.log("logout");
     }
    })
   
}
async doPayment() {
    //let cuPackage = p;
    let options;
    let self = this;
    let userPersonalInfo=self.app._authservice.loginUser.personalInfo;
    this.finalAmout=0;
    this.myCartList.forEach(list=>{
        this.finalAmout=this.finalAmout+list.amount;
    })
    let obj={
        "amount":(this.finalAmout*100) ,
        "currency": "INR",
        "receipt":"test1",
        "payment_capture":1
    }
   await this.packageService.createOrder(obj).subscribe(result => {

        if (result.status) {
            if (this.myCartList.length>0)
                {
                options = {
                   // "order_id": result.data.id,
                   // "key": "rzp_live_3lX4sJiPvZPWHe",
                    "key":"rzp_test_FEojxhS2wZ7EV8",
                    "amount": (this.finalAmout*100),//this.selectedPackage.total_price *100,//self.price, // 2000 paise = INR 20
                    "name": "GIBOTS",
                    //"name": "Gibots Packages",
                    "description": "Subscription for Gibots",
                    //"description": "All features of the robotice",
                    "image": this.logoURL,  
                    "handler": function async (response) {
                    
                       let  data={
                            paymentId: response.razorpay_payment_id,
                            status: true,
                            packages:this.myCartList,
                            orderId:result.data.id,
                            amount:this.finalAmout
                        };
                   
                        this.handleSuccess(data);
                    }.bind(this),
                    "prefill": {
                        "name": isNullOrUndefined(userPersonalInfo)?"":userPersonalInfo.name,//self.newUser.email,
                        "email": isNullOrUndefined(userPersonalInfo)?"":userPersonalInfo.email,//self.newUser.email
                    },
                    "notes": {
                        "address": "Hello World"
                    },
                    "theme": {
                        "color": "#436FCC"
                    }
                };
                 $.getScript('https://checkout.razorpay.com/v1/checkout.js', function() {
                    let rzp1 = new Razorpay(options);
                    rzp1.open();
                });
              
                this.app.showMessage("Payment Successfull");
            }
            else{
                        this.app.showMsg('success', 'Message', 'Please add package into the cart');
            }
           

        }
    })


   


}


showPassword() {
    this.showPw = !this.showPw;
}

showConfirmPassword() {
    this.showConfirmPw = !this.showConfirmPw;
}
getLogo(){
    let int=window.setInterval(()=>{
        if(JSON.parse(sessionStorage.getItem("orgData"))){
            window.clearInterval(int);
            this.logoUrlDynamic = (JSON.parse(sessionStorage.getItem("orgData")))?JSON.parse(sessionStorage.getItem("orgData")).data.logoURL:'../../assets/img/aiqod-logo.png';
            if(this.logoUrlDynamic && this.logoUrlDynamic.includes('tec_logo305x100')){
                // this.logoUrlDynamic = '../../assets/img/aiqod-logo.png'
                this.logoUrlDynamic = '../../assets/img/CollabPro.png'
            }
            this.extRegisterAllow = (JSON.parse(sessionStorage.getItem("orgData")))?JSON.parse(sessionStorage.getItem("orgData")).data["extRegAllow"]:false;
            if(isNullOrUndefined(this.logoUrlDynamic) || this.logoUrlDynamic=='' || this.logoUrlDynamic=='undefined' || this.logoUrlDynamic==undefined){
                // this.logoUrlDynamic = '../../assets/img/aiqod-logo.png';
                this.logoUrlDynamic = '../../assets/img/CollabPro.png'
            }
            this.extRegisterAllow = (isNullOrUndefined(this.extRegisterAllow) || this.extRegisterAllow == 'undefined')? false : this.extRegisterAllow.toString().toLowerCase() == 'true' ? true : false;
            console.log(this.extRegisterAllow);
        }
    })
    let tme=window.setTimeout(()=>{
        if(JSON.parse(sessionStorage.getItem("orgData"))){
            window.clearTimeout(tme);
        }else{
            this.extRegisterAllow = false;
            this.logoUrlDynamic = '../../assets/img/aiqod-logo.png';
            window.clearInterval(int);
        }
    },10000);
}
changeOption(option) {
    this.regform.patchValue({ registrationType: option });
  }
  scrolldown()
  {
      let scroll=document.getElementById("forScroll")
      scroll.scrollIntoView();
  }
  A2HS(){
    let deferredPrompt;            
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      console.log("before prevent")
      e.preventDefault();
      console.log("After prevent")
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      console.log("before promt")
      window['deferredPrompt'] = deferredPrompt;
        deferredPrompt.prompt();
        console.log("after promt")
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt = null;
        });
      });
    
}

addToHomeScreen(){
    let self =this;
    const addBtn = document.getElementById('addToHomeScreen');
// Check if the application has been installed previously
const isAppInstalled = sessionStorage.getItem('appInstalled') === 'true';
// If the app is already installed, hide the button
if (isAppInstalled) {
    addBtn.style.display = 'none';
}
    addBtn.style.display = 'none';
    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    //   {  
    //   isMobile=true;
    //  }else{
    //   isMobile=false;
    //  }
let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  console.log("BeforeInstallPrompt Fired!");
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  if (!isAppInstalled) {
        addBtn.style.display = 'block';
    }
  if(self.isMobile){
        addBtn.style.display = 'block';
      }else{
        addBtn.style.display = 'none';
      }
if (isAppInstalled) {
        addBtn.style.display = 'none';
    }
  // Update UI to notify the user they can add to home screen
  window['deferredPrompt'] = deferredPrompt;
  window.addEventListener('load', () => {
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        sessionStorage.setItem('appInstalled', 'true');
        addBtn.style.display = 'none';
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  });
  addBtn.addEventListener('click', () => {
        // hide our user interface that shows our A2HS button
        console.log("HI LogIn")
        sessionStorage.setItem('appInstalled', 'true');
        addBtn.style.display = 'none';
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
            sessionStorage.setItem('appInstalled', 'true');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt = null;
        });
      });
});
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) ;

      // Hide the button if on iOS
      if (isIOS) {
          addBtn.style.display = 'none';
      }
}
openMobilePopup(): void {
    let fetchInterval = setInterval(() => {
        let orgData = localStorage.getItem("orgData");
        if (orgData) {
            let fetchUser = (JSON.parse(localStorage.getItem("orgData")))?JSON.parse(localStorage.getItem("orgData")).data["fetchUser"]:false;
            if (fetchUser) {
                this.showPopup = true;
                clearInterval(fetchInterval);
            }
        }
      }, 1000);
  }

  closeMobilePopup(): void {
    this.showPopup = false;
  }

  submitMobileNo(): void {
    if (this.mobileForm.valid) {
      let org = localStorage.getItem('orgData');
      let respData = JSON.parse(org);
      let orgId="";
      if (respData.status) {
        orgId=respData.data.orgId
      } 
      const mobileNo = this.mobileForm.value.mobileNo;
      let ipAddress=""
      if(this.mobileForm.value.ipAddress){
        ipAddress=this.mobileForm.value.ipAddress;
      }
      console.log(`Mobile number entered: ${mobileNo}`);
      console.log("getting user details-")
      let self=this;
      this.loading = true;
      self.authService.getUserData(mobileNo,ipAddress)
      .pipe(map(res => res.valueOf()))
      .subscribe(
          data => {
            let res: any;
            res = data;
            self.loading = false;
            self.data = res;
            if (self.data.status === true) {
                this.closeMobilePopup();
                let orgDataString = localStorage.getItem("orgData");
                if (orgDataString) {
                  let orgData = JSON.parse(orgDataString);
                  if (orgData?.data) {
                    orgData.data.fetchUser = false;
                    localStorage.setItem("orgData", JSON.stringify(orgData));
                  }
                }
                this.app.showMsg('success', 'Message', 'User details fetched succefully!!');

            }else{
                this.app.showMsg('error', 'User Details', 'Please try again');
            }

          },
          err=>{
            self.loading = false;
            this.app.showError(err);
          
        }
      )
    }
  }

}
