import {Component, Inject , OnInit ,  ElementRef , ViewContainerRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

import { Broadcaster } from '../../../../../services/sharable.service';
import { WebsiteService } from '../../../service/website.services';
import { HomeComponent } from '../../home.component';
import { APP_CONFIG, IConfig } from '../../../../../appConfig/Iconfig';
import { AuthService } from '../../../../../services/auth.service';

declare var jquery: any;
declare var $: any;
declare var goog_report_conversion: any;
@Component({
    selector: 'app-contact-section',
    templateUrl: 'contact.component.html',
    styleUrls: ['contact.component.css']
})
export class ContactComponent implements OnInit {

    public pageContents: any;
    private contentEdit: any;
    currentSectionId: string;
    title: string;
    requestData: any;
    public hostName:string;
    applicationMode:string;
    private  loading:boolean=true;
    private langSetKey : string = '';

    constructor(private service: Broadcaster, private homeCompoent: HomeComponent, private elem: ElementRef ,
                private webService: WebsiteService,public toastr: ToastrService,vcr: ViewContainerRef, private authService: AuthService,
                @Inject(APP_CONFIG) private iconfig: IConfig, public translate: TranslateService) {
        //this.toastr.setRootViewContainerRef(vcr);
        this.applicationMode = iconfig.applicationMode;
    }

    ngOnInit() {
        this.initValues();
        this.initMethod();
    }

    initValues() {
        this.pageContents = {};
        this.contentEdit = {
            editContact: false,
        };
        this.currentSectionId  = 'section7';
        this.changeColor(this.pageContents.appTheme);
        this.title="";
        this.requestData = {};
        this.hostName = location.hostname;
    }
    initMethod() {
        $('[data-toggle="tooltip"]').tooltip();
        this.getBroadcastMessage();
        this.setSelectorName();
    }

    getBroadcastMessage() {
        const self = this;
        this.service.on<string>('changeSectionData')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents = rec;
                self.loading=false;
                self.setCurrentTitle();
            });
        this.service.on<string>('setApplicationMode')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.applicationMode = rec;
            });
        this.service.on<string>('changeLanguages')
            .subscribe(message => {
                let rec: any;
                rec = message;
                let language;
                this.authService.langKey.subscribe(item => {
                    language = item;
                });
                if(language == 'arb' || language == 'en'){
                    self.langSetKey = language;
                }else {
                    self.langSetKey = 'en';
                }
                self.translate.use(self.langSetKey);

            });
    }

    setCurrentTitle(){
        const self = this;
        this.pageContents.headerMenu.forEach(function (menu) {
            if(menu.sectionId === self.currentSectionId){
                self.title=menu.title;
            }
        });
    }

    setSelectorName() {
        const self = this;
        const tagName = this.elem.nativeElement.tagName.toLowerCase();
        const contactMenuSelector: any = {
            'selector': tagName,
            'sectionId': this.currentSectionId,
        }

        setTimeout(function(){
            self.service.broadcast('contactSelector', contactMenuSelector);
        }, 1200 );
    }

    editContent(type: string) {
        $('#summernoteContact').summernote({
            height: 120,
            minHeight: null,
            maxHeight: null,
            focus: true
        });
        this.contentEdit.editContact = true;
    }

    saveContent(type: string) {
        this.pageContents.contactContent = $('#summernoteContact').summernote('code');
        $('#summernoteContact').summernote('destroy');
        this.contentEdit.editContact = false;
    }

    cancelContent(type: string) {
        $('#summernoteContact').summernote('destroy');
        this.contentEdit.editContact = false;
    }

    // editService(rec: any, idx: number) {
    //     this.modalHeader = 'Edit Service';
    //     this.serviceData = Object.assign(this.serviceData, rec);
    //     this.editServiceIndex = idx;
    // }

    contactData(type: any) {
        this.homeCompoent.contactData(type);
    }
    private changeColor(appTheme: string) {
        const self = this;
        setTimeout(function () {
            self.service.broadcast('appThemeSetting', self.pageContents.appTheme);
            self.service.broadcast('footerPageColor', self.pageContents.appTheme);
        }, 200);
    }

    sendEmailRequest( record) {
        let self = this;
        const reqData = {
            fromEmail: this.requestData.email,
            toEmail:  this.pageContents.contactData.contactEmail,
            message: this.requestData.message,
            domain: this.hostName,
            mobile: this.requestData.phone,
            name: this.requestData.name
        }
        this.webService.sendEmailRequest(reqData).subscribe(respData => {
                if (respData.status) {
                    let subscriberId;
                    this.authService.generateWebRequest('Y');
                     goog_report_conversion(self.iconfig.Application_URL + 'main/home');
                    this.requestData = {};
                }else {
                    this.toastr.error('Please try again later', 'Oops!');
                }
            },
            err => {
                this.toastr.error('Please try again later', 'Oops!');
            });

    }

    checkFormFields() {
        let count = 0;
        if (this.requestData.name.trim() === '' || this.requestData.name === undefined) {
            if (count === 0) { this.sendErrorMsg('Please enter name', '', 'error');}
            count++;
        }
        if (this.requestData.email === '' || this.requestData.email === undefined) {
            if (count === 0) { this.sendErrorMsg('Please enter email', '', 'error'); }
            count++;
        }else {
            const emailRgx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
            if (!this.requestData.email.match(emailRgx)) {
                if (count === 0) { this.sendErrorMsg('Please enter valid email', '', 'error'); }
                count++;
            }
        }

        if (this.requestData.phone === '' || this.requestData.phone === undefined) {
            if (count === 0) { this.sendErrorMsg('Please enter phone', '', 'error'); }
            count++;
        }else {

            const phoneno = "^[0-9]{10}$";

            if (!this.requestData.phone.match(phoneno)) {
                if (count === 0) { this.sendErrorMsg('Please enter valid phone', '', 'error'); }
                count++;
            }
        }

        if (this.requestData.message.trim() === '' || this.requestData.message === undefined) {
            if (count === 0) { this.sendErrorMsg('Please enter message', '', 'error'); }
            count++;
        }
        if (count > 0) {
            return false;
        }else {
            return true;
        }
    }

    sendErrorMsg(msg, title, type) {
        if (type === 'error') {
            this.toastr.error(msg,title);
        } else if (type === 'success') {
            this.toastr.error(msg,title);
        }else if (type === 'warning') {
            this.toastr.error(msg,title);
        }
    }

    createRequest( data) {
        if(this.applicationMode==='L') {
            if (this.checkFormFields()) {
                this.sendEmailRequest(data);
            }
        }
    }

    validateDataAndMode(data){
        if(data && this.applicationMode == 'L'){
            return true;
        }else{
            return false;
        }
    }
    restrictNumeric(e): any {
      let input;
      if (e.metaKey || e.ctrlKey) {
        return true;
      }
      if (e.which === 32) {
        return false;
      }
      if (e.which === 0) {
        return true;
      }
      if (e.which < 33) {
        return true;
      }
      input = String.fromCharCode(e.which);
      return !!/[\d\s]/.test(input);
    }
}
