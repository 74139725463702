
import { InjectionToken } from '@angular/core';
const appConf = require('./app-config.json');
export let APP_CONFIG = new InjectionToken<string>('app.config');

export class IConfig {
  
  public mongoUrl: string;
  public smartAIUri:string;
  public smartDocChatUrl:string;
  public newBackendUrl: string;
  public applicationMode: string;
  public appType: string;
  public onesignalAppId: string;
  public oneSignalDomain: string;
  public APP_ID: string = '094f4274-a8cd-4f6f-9a23-3841076e2cfe';
  public SUB_DOMAIN: string = 'uae-mig-dev';
  public APP_URL = 'https://localhost:8000/api';
  public DATA_URL: string = 'httpss://s3.ap-south-1.amazonaws.com/adhigamtesting/';
  public Chat_Server_URL: string = "httpss://chat.gibots.com:90";
  public publicPath:any;
  public publicURL: string;
  public blueOceanURL: string;
  public blueOceanAPIURL: string;
  public videoWindow:string;
  public repoartLoadCount:any=2;
  // public APP_ID: string = '3016194c-a843-44fb-a4a3-0b506dbf522c';
  // public SUB_DOMAIN: string = '';
  public PROFILE_PICURL: string = "https://app.genieuslabs.com/profilePics/";
  // public Chat_Server_URL:string ="https://localhost:8081";
  public NODE_URL: string = "https://localhost:8080/gst/";
  public gibotsUrl: string;
  public pdfUrl: string;

  public windowsAppUrl: string = "httpss://s3.ap-south-1.amazonaws.com/desktop-utility/Adhigam.exe";
  public macAppUrl: string = "https://adhigamtesting.s3.amazonaws.com/Adhigam.app-20170805T151706Z-001.zip";
  public linuxAppUrl: string = "";
  public desktopAppName: string = "Adhigam-Desktop";
  /*************************************/
  /*****************Demo Mode Configuration*********************/
  public demoMode: boolean = false;
  public offlineMode: boolean = false;
  Application_URL: string;
  fileServer_URl: string;
  desktopUtilitySocket: string;
  public mmvAppUrl: string = '';
  chatWindow: string;
  chatWindowUT: string;
  public loadDashboardReports = false;
  public backendReportUri: any;
  public orchURI:any;
  public scheduleUri: any;
  public imageUrl: string;
  public requestCancelTime = 10000;
  public loadTilesWhileAnimating = true;
  public loadTilesWhileInteracting= true;
  currentUrl: string;
  domainName: string;
  razorpayAPIKey: string;
  public dwnUrl:any;
  public videoURL :string;
  packageBuyUrl:string
  public chatgenielink:string;
  constructor(tenantKey) {
    this.setAppUrl(tenantKey);
  }

  public setAppUrl(hostname: string) {
  this.publicPath='https://product.aiqod.com/newFolder/gibot/';
//     this.mongoUrl = 'https://localhost:300/adhigam-api/';
  //   this.newBackendUrl = 'https://localhost:3008/gibots-api/';
    // this.orchURI = 'https://localhost:3009/gibots-orch/';
    // this.backendReportUri = 'https://localhost:3010/gibots-reports-api/';
     //this.scheduleUri = 'https://localhost:3011/gibots-api-schedule/';

 this.razorpayAPIKey = "rzp_live_sM7bysA8ZdOowT";

    this.currentUrl = window.location.href;
    // this.currentUrl = "https://dev.aiqod.com:843/";

    const url = new URL(this.currentUrl);
    this.domainName = url.hostname + (url.port ? `:${url.port}` : '');

      this.mongoUrl = 'https://product.aiqod.com/adhigam-api/';
      this.newBackendUrl = 'https://product.aiqod.com/gibots-api/';
      this.orchURI = 'https://product.aiqod.com/gibots-orch/';
      this.backendReportUri = 'https://product.aiqod.com/gibots-reports-api/';
      this.scheduleUri = 'https://product.aiqod.com/gibots-api-schedule/';
      this.smartAIUri = "https://product.aiqod.com/";
      this.smartDocChatUrl = "https://product.aiqod.com/genai_chatbot/"
    // this.mongoUrl = 'httpss://rpa-preprod.gibots.com/adhigam-api/';
    // this.newBackendUrl = 'httpss://rpa-preprod.gibots.com/gibots-api/';
    // this.backendReportUri = 'httpss://rpa.gibots.com/gibots-reports-api/'
    // this.backendReportUri = 'https://localhost:3008/gibots-api/';

    // this.mongoUrl = 'httpss://staging.gibots.com/adhigam-api/';
    // this.newBackendUrl = 'httpss://staging.gibots.com/gibots-api/';
    // this.backendReportUri = 'https://localhost:3010/gibots-reports-api/';
    // this.orchURI = 'httpss://staging.gibots.com/gibots-api/';
    //  this.scheduleUri = 'httpss://staging.gibots.com/gibots-api/';

    // this.mongoUrl = 'httpss://cuda-prod.gibots.com/adhigam-api/';
    // this.newBackendUrl = 'httpss://cuda-prod.gibots.com/gibots-api/';
    //  this.backendReportUri = 'httpss://rpa.gibots.com/gibots-reports-api/'

    // this.mongoUrl = 'httpss://staging.gibots.com/adhigam-api/';
    // this.newBackendUrl = 'httpss://staging.gibots.com/gibots-api/';
    //  this.backendReportUri = 'httpss://rpa.gibots.com/gibots-reports-api/'


    //  this.mongoUrl = 'httpss://rpa.gibots.com/adhigam-api/';
    //  this.newBackendUrl = 'httpss://rpa.gibots.com/gibots-api/';
    //  this.backendReportUri = 'httpss://rpa.gibots.com/gibots-reports-api/'

    //  this.mongoUrl = 'https://localhost:3000/adhigam-api/';
    // this.newBackendUrl = 'https://localhost:3008/gibots-api/';

    this.Application_URL = 'https://product.aiqod.com/';
    this.fileServer_URl = 'https://product.aiqod.com/';
    this.APP_URL = 'https://localhost:8000/api';

    // this.mongoUrl = 'https://192.168.137.12/test/adhigam-api/';
    // this.newBackendUrl = 'https://192.168.137.12/test/gibots-api/';



    // this.mongoUrl = 'https://192.168.1.135:3000/adhigam-api/';
    // this.newBackendUrl = "https://192.168.1.135:3008/gibots-api/";


    this.mmvAppUrl = 'https://localhost:3001/adhigam-api/';
    // this.Application_URL = 'https://staging.managemyvat.com/';
    //this.Application_URL = 'https://new.gibots.com/';
    this.Application_URL = 'https://product.aiqod.com/';


    this.APP_URL = 'https://localhost:8001/api';
    this.applicationMode = appConf.appMode;
    this.appType = 'UAE';
    this.desktopUtilitySocket = 'http://localhost:5000';
    // this.chatWindow = 'https://gibots-pp-chat.gibots.com/';
    // this.chatWindow = 'https://rpa-chat.gibots.com/';
    this.chatWindow = 'https://product.aiqod.com/';

    this.chatWindowUT = 'https://product.aiqod.com/';
    //  this.chatWindow = 'https://localhost:5008';
   //this.videoWindow = 'https://192.168.43.243:5080';
   this.videoWindow = 'https://video-chat.gibots.com/';
   this.imageUrl='http:///';

    this.demoMode = true;
    this.offlineMode = false;
    // this.publicURL = 'https://gibots-fs.gibots.com/cub/';
    // this.blueOceanURL = 'https://blueocean-dev.gibots.com:70/';
    // this.blueOceanAPIURL = 'https://blueocean-dev.gibots.com:70/adhigam-api/';

    this.publicURL = 'https://product.aiqod.com/';
    this.blueOceanURL = 'https://product-fs.aiqod.com/';
    this.dwnUrl='https://product-fs.aiqod.com/'
    this.blueOceanAPIURL = 'https://product.aiqod.com/adhigam-api/';
    this.packageBuyUrl="https://product.aiqod.com"
    this.chatgenielink="https://pwa-prod.aiqod.com:2443/"
    this.videoURL ="https://product-fs.aiqod.com/video/"
  }
}
