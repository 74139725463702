
import {throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import {JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../auth.service';
// AuthHttp, tokenNotExpired,
import { HttpClient, HttpHeaders, HttpResponse ,HttpClientModule } from '@angular/common/http';
// import { Headers, Response } from '@angular/http';
import {Observable } from 'rxjs';
import 'rxjs/Rx';
import { AppConstants } from "../constant";
import { isNullOrUndefined } from 'util';
import { catchError } from 'rxjs/operators/catchError';
import { map } from 'rxjs/operators/map';

@Injectable({
  providedIn:"root"
})
export class CustomAuthHttp {

  constructor(private authHttp: HttpClient, private authService: AuthService, private constant: AppConstants,
    private JwtHelperService:JwtHelperService) {
      
  }
  private getHeader(url: string, flag: boolean) {
  //  let headers = new HttpHeaders();
    const token=sessionStorage.getItem('jwt');
    // headers.append('Content-Type', 'application/json');
    // headers.append('selectedgstin', this.authService.getGSTIN());
    // headers.append('selectedorgid', this.authService.getOrgId());
    // headers.append('Authorization',token);
    // headers.append('selectedCustomerSubscriberId', this.authService.getCustomerSubscriberId());
    // if (flag) {
    //   headers.append('isNotify', this.constant.getNotifyByUrl(url));
    // }
   let gstin= this.authService.getGSTIN();
    let  headers= new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ` + token ,
        'selectedgstin': this.authService.getGSTIN(),
        // 'selectedgstin':  '123456789876543',
        'selectedorgid': this.authService.getOrgId(),
        // 'selectedorgid':  '5beaabd82ac6767c86dc311d',
        'selectedCustomerSubscriberId' : this.authService.getCustomerSubscriberId(),
        // 'isNotify': this.constant.getNotifyByUrl(url)
      })
  
    return headers;
  }
  
  // request(url: string): Observable<Object> {
  //   return this.intercept(this.authHttp.request(url,''));
  // }

  // get(url): Observable<any> {
  //   return this.intercept(this.authHttp.get(url, { headers: this.getHeader(url, false) }));
  // }
  get(url) {
      return this.authHttp.get(url,{ headers: this.getHeader(url, false)});
  }
  post(url: string, body: string){
    return this.authHttp.post(url, body, { headers: this.getHeader(url, true) });
  }
  // post(url: string, body: string): Observable<any> {
  //   return this.intercept(this.authHttp.post(url, body, { headers: this.getHeader(url, true) }));
  // }

  put(url: string, body: string): Observable<any> {
    return this.intercept(this.authHttp.put(url, body, { headers: this.getHeader(url, true) }));
  }

  delete(url: string): Observable<any> {
    return this.intercept(this.authHttp.delete(url, { headers: this.getHeader(url, true) }));
  }

  // intercept(observable: Observable<any>): Observable<any> {
  //   return observable.catch((err, source) => {
  //     if(!isNullOrUndefined(err._body)){
  //       let body = JSON.parse(err._body);
      
    
  //     if (err.status === 500 && (body.message === 'The token could not be parsed from the request' || body.message === 'Token has expired')) {
  //       return Observable.empty();
  //     } else {
  //       return observableThrowError(err);
  //     }
  //   }
  //   else{
  //     return observableThrowError(err);
  //   }
  //   });

  // }
  intercept(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      map(() => //do stuff),
      catchError((err: Error) => {
        if(!isNullOrUndefined(err)){
        if ((err.message === 'The token could not be parsed from the request' || err.message === 'Token has expired')) {
          return Observable.empty();
        } else {
          return observableThrowError(err);
        }
      }
      else{
        return observableThrowError(err);
      }
      })
    ));

  }

}
