
import { throwError as observableThrowError, Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable, NgZone, Inject, forwardRef } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { isNullOrUndefined } from 'util';
// import { AuthHttp } from '@auth0/angular-jwt';
//tokenNotExpired, AUTH_PROVIDERS
import 'rxjs/Rx';

import { HttpClient, HttpHeaders, HttpResponse, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { StateService } from '@uirouter/core';
import { APP_CONFIG, IConfig } from '../appConfig/Iconfig';
import { User } from '../app/share/models/user-model';
import { CustomAuthHttp } from './local-authhttp/local-authhttp';
import * as io from 'socket.io-client';
const s3Bucket = require('../app/appConfig/s3Config.json');
import * as CryptoJS from 'crypto-js';
import { map } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { DateTimeFormatsService } from './datetimeformat.service';

// import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable({
    providedIn:"root"
})
export class AuthService {
    selectedOrgList: string;
    user: Object;
    selectedGSTIN: string;
    taxAgency: string;
    selectedOrgId: string;
    selectedOrgName: string;
    setupWizardStatus: boolean = false;
    zoneImpl: NgZone;
    loggedIn = false;
    public data: any;
    public user_id: number;
    private URI: string;
    private MongoURI: string;
    private BackendURI: string;
    public notifications: any[];
    public currentChatUser: any;
    public ChatConversations: any[];
    public orgConfig: any[];
    public currentOrgConfig: string;
    public chatwindow: boolean;
    public invoicedata: any;
    public loginUser: any;
    public tokenData: any;
    public videoURL:any;
    public passdata: any;
    public videoSocket: any;
    private JwtHelperService: JwtHelperService;
    private selectedPackage: any = null;
    selectedCustomerSubscriberId: string;
    private userType: string;
    private bussinessType: string;
    private userProfilePic: string;
    private tourCondition: any;
    public selectedTaxGroup: boolean = false;
    private MmvAppURI: string;
    public chatSocket;
    public chatSocketUT;
    public utilitySocket;
    private chatURL;
    private chatURLUT;
    public logOutTime: number;
    public userData: any;
    public token: any;
    public logoUrlPath: any;
    public dateTimeFormat:any;
    public currency:string;
    public reportRefreshExceeded:boolean = false;
    private selectedData = new BehaviorSubject<boolean>(false);
    public curentflgStatus = new BehaviorSubject<string>('');
    curenteventstatusFlg = this.curentflgStatus.asObservable();
    public selectedDataorg = new BehaviorSubject<string>('');
    orgCountry = this.selectedDataorg.asObservable();
    public currecyData = new BehaviorSubject<string>('');
    orgCurrency = this.currecyData.asObservable();
    public logoData = new BehaviorSubject<string>('');
    logoPath = this.logoData.asObservable();
    public extReg = new BehaviorSubject<boolean>(false);
    extRegAllow = this.extReg.asObservable();
    public mobilePatternData = new BehaviorSubject<string>('');
    mobPattern = this.mobilePatternData.asObservable();
    public orgsSubIdData = new BehaviorSubject<string>('');
    orgsSubId = this.orgsSubIdData.asObservable();
    public webReqData = new BehaviorSubject<string>('');
    sentWebReqData = this.webReqData.asObservable();
    public langKeyData = new BehaviorSubject<string>('');
    langKey = this.langKeyData.asObservable();
    public typeData = new BehaviorSubject<string>('');
    type = this.typeData.asObservable();
    public gstinData = new BehaviorSubject<string>('');
    gstin = this.gstinData.asObservable();
    public orgNameData = new BehaviorSubject<string>('');
    orgName = this.orgNameData.asObservable();
    public orgLogoData = new BehaviorSubject<string>('');
    OrgList = this.orgLogoData.asObservable();
    public orgIdData = new BehaviorSubject<string>('');
    orgId = this.orgIdData.asObservable();
    public consolidateInvData = new BehaviorSubject<string>('');
    consolidateInv = this.consolidateInvData.asObservable();
    public billPeriodData = new BehaviorSubject<string>('');
    billPeriods = this.billPeriodData.asObservable();
    public orgListData = new BehaviorSubject<string>('');
    orgList = this.orgListData.asObservable();
    public orgConfigData = new BehaviorSubject<string>('');
    orgconfig = this.orgConfigData.asObservable();
    public taxGrpData = new BehaviorSubject<string>('');
    isTaxGrp = this.taxGrpData.asObservable();
    public custSubData = new BehaviorSubject<string>('');
    customerSubscriber = this.custSubData.asObservable();
    public logOutClick = new BehaviorSubject<string>('')
    checkLogout = this.logOutClick.asObservable()
    refreshTokenTimeout: NodeJS.Timer;
    currentMessage = new BehaviorSubject(null);
    ipAddress: any;
    public logoBase64String:string;

    constructor(zone: NgZone,
        JwtHelperService: JwtHelperService,
        private stateService: StateService,
        private cookieService: CookieService,
        private datetimeFormat: DateTimeFormatsService,
        @Inject(APP_CONFIG) private _Iconfig: IConfig,
        private http: HttpClient) {  //private angularFireMessaging: AngularFireMessaging

        //  this.angularFireMessaging.messaging.subscribe(
        //     (_messaging) => {
        //         _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        //         _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        //     }
        // )
        this.URI = _Iconfig.APP_URL;
        this.MongoURI = _Iconfig.mongoUrl;
        this.MmvAppURI = _Iconfig.mmvAppUrl;
        this.BackendURI = _Iconfig.newBackendUrl;
        this.zoneImpl = zone;
        this.notifications = [];
        this.ChatConversations = [];
        this.invoicedata = [];
        this.chatwindow = false;
        this.currentChatUser = {};
        this.JwtHelperService = JwtHelperService;
        this.selectedGSTIN = null;
        this.selectedOrgId = null;
        this.currentChatUser = {};
        this.selectedCustomerSubscriberId = null;
        this.bussinessType = null;
        this.loginUser = new User();
        this.currency = "";
        this.chatURL = _Iconfig.chatWindow;
        this.chatURLUT = _Iconfig.chatWindowUT;
        this.videoURL = _Iconfig.videoWindow;
        this.chatSocket = io.connect(this.chatURL, { path: "/socket-orch" ,secure: true, 'reconnection': true, 'reconnectionDelay': 1000,
        'reconnectionDelayMax' :60000 ,
        'pingInterval': 30000,
        'pingTimeout': 1000000000,
        'reconnectionAttempts': 'Infinity',transports: ['websocket', 'polling']})
        // this.videoSocket = io.connect(this.videoURL);
        this.utilitySocket = io.connect(this._Iconfig.desktopUtilitySocket , {transports: ['websocket','polling'], secure: true, 'reconnection': true, 
        'reconnectionDelay': 30000,
        'reconnectionDelayMax' : 50000,
        'reconnectionAttempts': 'Infinity',
        'pingInterval':10000,
        'pingTimeout': 60000,
        'upgradeTimeout':30000});
        if(sessionStorage.getItem("logoBase64")){
            this.setLogoBase64(sessionStorage.getItem("logoBase64"));
        }
        this.utilityConnection()
        this.getIP();
        this.getUserLocation();  


    }
    getIP()  
    {  
        this.getIPAddress().subscribe((res:any)=>{  
        this.ipAddress=res.ip;  
        console.log("IP--->",this.ipAddress);
        });  
    }
    public getIPAddress()  
    {  
        return this.http.get("https://api.ipify.org/?format=json");  
    }

    public getUserLocation(){
        if(!JSON.parse(sessionStorage.getItem("loc"))&&!JSON.parse(localStorage.getItem("loc"))){
            this.http.get("https://ipapi.co/json").subscribe(res=>{
                this.currency = res['currency'];
                if(res['country_name'] === 'India'){
                    res['country_name'] = "INDIA"; // Previous data stored in org collection stored country India in capital letters.
                }
                let userLocation =  res['country_name']+", "+res['timezone'];
                this.setDateTimeFormat(userLocation.split(", ")[1],"");
                sessionStorage.setItem("loc",JSON.stringify(res));
                sessionStorage.setItem("userLocation",JSON.stringify(userLocation));
             },err=>{
                 console.log(err);
             }
             )
        } else {
            if(JSON.parse(localStorage.getItem("loc"))){
                sessionStorage.setItem("loc",JSON.stringify(JSON.parse(localStorage.getItem("loc"))));
            }
            this.dateTimeFormat =  sessionStorage.getItem("dateTimeFormat");
            if(!this.currency){
                this.currency = JSON.parse(sessionStorage.getItem("loc")).currency;
            }
        }
    }

    setDateTimeFormat(timezone,locale){
        sessionStorage.setItem("timezone",timezone);
        sessionStorage.setItem("locale",locale);
        const dateFormat = moment.utc(new Date()).tz(timezone).locale(locale).localeData().longDateFormat("L");
        const timeFormat = moment.utc(new Date()).tz(timezone).locale(locale).localeData().longDateFormat("LT");
        this.dateTimeFormat = dateFormat + ", " + timeFormat;
        sessionStorage.setItem("dateTimeFormat",this.dateTimeFormat);
        moment.locale(locale);
        this.datetimeFormat.updateDateFormats(this.dateTimeFormat);
    }

    getDateTimeFormat(){
        return this.dateTimeFormat;
    }
    

    returnSocket() {
        return this.chatSocket;
    }



    sendClickEvent() {
        this.logOutClick.next('');
      }

      getClickEvent(): Observable<any>{ 
        return this.logOutClick.asObservable();
      }

    utilityConnection(){
        //console.log("DU LOG CON --->>>>>",localStorage.getItem('isDuCon'));
        
        //if(isNullOrUndefined(localStorage.getItem('isDuCon'))){
        this.chatSocketUT = io.connect(this.chatURLUT, {  path: "/socket-api" , secure: true, 'reconnection': true, 'reconnectionDelay': 20000,
        'reconnectionDelayMax' : 30000,
        'pingInterval': 30000,
        'pingTimeout': 1000000000,
        'reconnectionAttempts': 'Infinity',transports: ['websocket', 'polling']}) ;
        localStorage.setItem('isDuCon','Yes'); 
    //}else{
    //    console.log("Du Socket connection already done");
        
    //}
    }
    returnSocketUT() {
        // console.log("returnSocketUT --->>>> ");
        
        return this.chatSocketUT;
    }

    returnUtilitySocket() {
        return this.utilitySocket;
    }

    returnVideoSocket(){
      this.videoSocket = io.connect(this.videoURL);
      return this.videoSocket;
    }

    getLogoImage(){
        let orgList = !isNullOrUndefined(sessionStorage.getItem("OrgList")) ? sessionStorage.getItem("OrgList").toString() : "";
        if (orgList == "") {
            orgList = this.getOrgList();
        }
            this.logoUrlPath = orgList;
    
        return this.logoUrlPath;
    }

    setLogoBase64(base64String){
        this.logoBase64String = base64String;
    }

    getOrgList() {
        this.OrgList.subscribe(item => {
            this.selectedOrgList = item;
        });
        return this.selectedOrgList;
    }

    setOrgList(OrgList) {
        this.selectedOrgList = OrgList;
    }
    
    checkLoginDataPresent() {
        // if (sessionStorage.getItem('jwt') != null || sessionStorage.getItem('jwt') != undefined)
        let token;
        if(isNullOrUndefined(sessionStorage.getItem('jwt'))){
            token = localStorage.getItem('jwt');
        } else {
        token = sessionStorage.getItem('jwt');
    
        }
        if (token != null || token != undefined)
            return true;
        else
            return false;
    }

    userdata() {
        if(isNullOrUndefined(sessionStorage.getItem('jwt'))){
            this.token = localStorage.getItem('jwt');
        } else {
            this.token = sessionStorage.getItem('jwt');
        }
        this.userData = this.JwtHelperService.decodeToken(this.token);
        if (this.token != null) {
            return this.userData.userType;
        } else {
            return null;
        }
    }



    userDetail() {
        this.token = sessionStorage.getItem('jwt');
        this.userData = this.JwtHelperService.decodeToken(this.token);
        return this.userData;
    }
    checkPermission(data) {
        // this.token = sessionStorage.getItem('jwt');
        console.log("checkPermission");
        if(isNullOrUndefined(sessionStorage.getItem('jwt'))){
            this.token = localStorage.getItem('jwt');
        } else {   
            this.token = sessionStorage.getItem('jwt');
        }
        this.userdata();

        //this.userDetail();
        if (this.token != null) {
            this.userData = this.JwtHelperService.decodeToken(this.token);
            let permissionArr = this.userData.permission;
            let retVal: boolean = false;
            // for (let i = 0; i < permissionArr.length; i++) {
            permissionArr.forEach(element => {
                if (element.toLowerCase() == data.permission.toLowerCase()) {
                    retVal = true;
                }

            });
            return retVal;
        }

    }
    getevent(flg) {
        this.curentflgStatus.next(flg);
    }
    /* for (let entry of permissionArr) {
         if (entry == data.permission) {
             return true;
         }
         else {
             return false;
         }

     }*/




    private getHeader() {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json','X-Frame-Options' : 'deny' });
        return headers;
    }

    public getUser(userId) {
        return this.http.get(this.MongoURI + 'user/list/' + userId, { headers: this.getHeader() })
            .pipe(map(res => res.valueOf()));
    }

    getToken(cb) {
        const self = this;
        let token: any;
        // token = this.cookieService.get('jwt');
        // sessionStorage.setItem('jwt' , token);

        token = sessionStorage.getItem('jwt');
        if (isNullOrUndefined(token)) {
            self.loginUser = null;
            cb(false, self.loginUser);
            //  this.router.navigate(['/login']);
        } else {
            if (this.JwtHelperService.isTokenExpired(token)) {
                sessionStorage.removeItem('jwt');
                self.loginUser = null;
                cb(false, self.loginUser);
            } else {
                let user = this.JwtHelperService.decodeToken(token);
                this.setTokenData(user);
                this.getGSTIN();
                this.getUser(user.userId).subscribe(respData => {
                    let rec: any;
                    rec = respData;
                    if (rec.status) {
                        if (rec.data !== null) {
                            self.loginUser = rec.data;
                            self.userType = rec.data.userType;
                            self.loggedIn = true;
                            cb(true, self.loginUser);
                        } else {
                            self.loginUser = null;
                            cb(false, self.loginUser);
                        }
                    }
                },
                    err => {
                        self.loginUser = null;
                        cb(false, self.loginUser);
                        console.log(err);
                    });
            }
        }
    }

    checkLogin(cb) {
        // let token = this.cookieService.get('jwt');
        // sessionStorage.setItem('jwt' , token);
        let token = sessionStorage.getItem('jwt');
        if (isNullOrUndefined(token)) {
            cb(false, null);
        } else {
            console.log(this.JwtHelperService.getTokenExpirationDate(token));
            if (this.JwtHelperService.isTokenExpired(token)) {
                cb(false, null);
            } else {
                const user = this.JwtHelperService.decodeToken(token);
                cb(true, user);
            }
        }
    }

    fetchUserData() {
        const token = sessionStorage.getItem('jwt');
        if (isNullOrUndefined(token)) {
            this.stateService.go('page.login');
            return false;
        } else {
            if (this.JwtHelperService.isTokenExpired(token)) {
                this.stateService.go('page.login');
                return false;
            } else {
                return this.JwtHelperService.decodeToken(token);
            }
        }
    }

    //     public getAllUser() {
    //         return this.http.get(this.MongoURI+"user/allusers" , { headers: this.getHeader() })
    //             .map(res => res.valueOf());
    //     }

    //     setAlluser(){

    //         this.getAllUser().subscribe(resData =>{
    //             console.log("Users ---->=========>--->",JSON.parse(JSON.stringify(resData)));
    //             sessionStorage.setItem('usersInfo', JSON.parse(JSON.stringify(resData)));
    //         },err=>{
    //             console.log("error -->==>--> ",err)
    //         });
    // };

    //     getUserInfo(){
    //         const Users = sessionStorage.getItem('usersInfo');
    //         return Users;
    //     }

    setBussinessType(btype) {
        this.bussinessType = btype;
    }

    getBussinessType() {

        if (isNullOrUndefined(this.bussinessType) || this.bussinessType === '') {
            this.bussinessType = sessionStorage.getItem('bType');
        }
        return this.bussinessType;
    }


    setUserType(type) {
        this.userType = type;
    }

    getUserType() {
        // return this.userType;
        if (isNullOrUndefined(this.userType) || this.userType === '') {
            this.userType = sessionStorage.getItem('userType');
        }
        return this.userType;
    }

    get(url): Observable<any> {
        return this.intercept(this.http.get(url));

    }

    intercept(observable: Observable<any>): Observable<any> {
        return observable.catch((err, source) => {
            let body = JSON.parse(err._body);
            if (err.status === 500 &&
                (body.message === 'The token could not be parsed from the request' || body.message === 'Token has expired')) {
                this.logout();
                return Observable.empty();
            } else {
                return observableThrowError(err);
            }
        });

    }

    logoutUser()
    {
        const jwt = sessionStorage.getItem('jwt');
        const parseddata = this.JwtHelperService.decodeToken(jwt);
        let body = JSON.stringify({
            'uId':parseddata.userId, ip:this.ipAddress
        });
        return this.http.post(this.MongoURI + 'website/user/logout', body, { headers: this.getHeader() ,withCredentials: true , });
    }
    public authenticate(username: string, password: string,kli, url) {
        // url = 'mmv-mig-dev.gibots.com';
         // url ='localhost:4200';
       //  url = "cuda-prod.gibots.com";
        // url = "rpa.gibots.com";
         //url = 'ocri.gibots.com:1443';
        // url = "msme-dev.gibots.com:453";
    // url="msme.gibots.com:843"
        url = this._Iconfig.domainName;
    //    url= "idp-dev.aiqod.com:453";
        
        // let algorithm = s3Bucket.c.a;
        // let key = s3Bucket.c.k;
        // let IV = s3Bucket.c.i;
        // let cipher = crypto.createCipheriv(algorithm, key, IV);
        // let encrypted = cipher.update(password, 'utf8', 'base64');
        // encrypted += cipher.final('base64');

        let encrypt = false, pass = password;
        try {
            pass = CryptoJS.AES.encrypt(JSON.stringify(password), s3Bucket.c.k).toString();
            encrypt = true;
          } catch (e) {
            pass = password;
            console.log(e);
          }

        let body = JSON.stringify({
            'mobile': username, 'password': pass, 'ttl': 1209600000, 'websiteUrl': url, 'encrypt' : encrypt,
            parentUrl: url , kli : kli , ip:this.ipAddress
        });
        return this.http.post(this.MongoURI + 'website/user/login', body, { headers: this.getHeader() ,withCredentials: true , });
    }
    public getUserData(mobile: string,ipAddress: string) {
   
        let body = JSON.stringify({
            'mobile': mobile, 
            'ipAddress':ipAddress
        });
        return this.http.post(this.MongoURI + 'website/user/getUserData', body, { headers: this.getHeader() ,withCredentials: true , });
    }
    public getUserPackages(mobile: string) {
   
        let body = JSON.stringify({
            'mobile': mobile, 
        });
        return this.http.post(this.MongoURI + 'website/user/getUserPackages', body, { headers: this.getHeader() ,withCredentials: true , });
    }
    public deactivate(userId) {
        return this.http.get(this.MongoURI + 'website/deactivateUser/'+userId);
    }
    checkLoggedIn()
    {
        try{
            let localjwt = localStorage.getItem('jwt');
            let sessionjwt = sessionStorage.getItem('jwt');
            let jwt;
            if(sessionjwt){
                jwt = sessionjwt;
            } else {
                jwt = localjwt;
            }
            const parseddata = this.JwtHelperService.decodeToken(jwt);
            const currentUrl = window.location.href;
            if(parseddata && currentUrl.includes("?emailVerification") && currentUrl.includes("/web/dashboard")){
                parseddata.kli = true;   
            }

            if(!isNullOrUndefined(parseddata) && (isNullOrUndefined(sessionStorage.getItem('loggedIn'))))
            {
                let kli = parseddata.kli;
                let expiry =  parseddata.exp;
                let dtype = parseddata.type;
                let userData = JSON.parse(localStorage.getItem('userData'));
                let userType = parseddata.userType;
                let user_id = parseddata.userId;
                let gstin = localStorage.getItem('gstin');
                let orgId = localStorage.getItem('orgId');
                let resObj = {};
                if(kli==true && (Number(new Date().getTime().toString())/1000) < Number(expiry) && !isNullOrUndefined(dtype) && dtype!=='' && userType && userData && jwt)
                {
                    // sessionStorage.clear();
                    // console.log("Keep Signed In...");
                    this.generateType(dtype);
                    this.setLoggedUserData(userData);
                    this.setLoggedUserId(user_id);
                    this.setUserType(userType);
                    this.setGSTIN(gstin);
                    this.setOrgId(orgId);
                    sessionStorage.setItem('jwt', jwt);
                    sessionStorage.setItem('orgLabelFile', localStorage.getItem("orgLabelFile"));
                    sessionStorage.setItem('userType', userType);
                    sessionStorage.setItem('gstin', gstin);
                    sessionStorage.setItem('orgId', orgId);
                    sessionStorage.setItem('loggedIn', 'true');
                    if(userData && userData.locationPreference){
                        this.setDateTimeFormat(userData.locationPreference.timezone,userData.locationPreference.locale);
                    }
                    if(userData && userData.language){
                        sessionStorage.setItem("language",userData.language);
                    }
                    this.startRefreshTokenTimer(expiry);
                    if(/new-my-task/.test(window.location.href)){

                    }
                    else if (dtype === 'taxpayer' || dtype === 'employee' || dtype === 'msmeRegister'|| dtype === 'msmeImplementer') {
                        const currentUrl = window.location.href;
                        if(currentUrl.includes("?emailVerification") && currentUrl.includes("/web/dashboard")){
                            // let profileData = JSON.parse(localStorage.getItem("profileData"));
                            // let data = profileData.data;
                            // this.setDateTimeFormat(data.locationPreference.timezone,data.locationPreference.locale);
                            // sessionStorage.setItem("language",data.language);
                            let queryParam = currentUrl.split("?")[1];
                            this.stateService.go('web.dashboard',{emailVerification:queryParam.split("=")[1]});
                        } else {
                            this.stateService.go('web.dashboard');
                        }
                      
                    } else {
                        this.stateService.go('web.dashboardta');
                    }
                    resObj['loggedIn'] = true;
                    return of(resObj);
                }
            }
            if(isNullOrUndefined(sessionStorage.getItem('jwt')))
            {
                const currentUrl = window.location.href;
                let queryParam = currentUrl.split("?")[1];
                if(isNullOrUndefined(queryParam)){
                    queryParam = "";
                }
                if(!/resetpassword/i.test(currentUrl)) {
                        if(queryParam.startsWith("registrationId")){
                            this.stateService.go('page.login',{registrationId:queryParam.split("=")[1]});
                        }else if(queryParam.startsWith('verification')){
                            this.stateService.go('page.login',{verification:queryParam.split("=")[1]});
                        }
                        // else if(currentUrl.includes("/web/dashboard" )&& currentUrl.includes("?emailVerification")) {
                        //     this.stateService.go('page.login',{emailVerification:queryParam.split("=")[1]});
                        // }
                         else {
                            this.stateService.go('page.login');
                        }
                }
            }
        }
        catch(e){
            // console.log("Kli error -",e);
        }
        return of({'loggedIn':false})
    }
    public startRefreshTokenTimer(expiry) {

        const expires = new Date(expiry * 1000);
        const timeout = expires.getTime() - Date.now() - (600 * 1000);
        // Regenerate the token before 600 seconds to expiring the token 
        const self = this;
        this.refreshTokenTimeout = setTimeout(() => self.refreshToken().subscribe(respData=>{
            let res;
            res = respData;
            // console.log("Get token response:", respData);
            const token = res.token;
            if (token) {
                sessionStorage.setItem('jwt', token);

                localStorage.setItem('jwt', token);

                self.stateService.go('web.dashboard');                     }
        }), timeout);
    }

    public refreshToken()
    {
        let userData = JSON.parse(localStorage.getItem('userData'));

        let body = JSON.stringify({
            'mobile': userData.personalInfo.mobile ,'ttl': 1209600000 , kli : true
            });
        let data = this.http.post(this.MongoURI + 'website/user/token', body, { headers: this.getHeader() }).pipe(map(res => res.valueOf()));
        return data;
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    public getUpdatedToken(username: string, password: string, url) {
        let body = JSON.stringify({
            'mobile': username, 'password': password, 'ttl': 1209600000, 'websiteUrl': url,
            parentUrl: url
        });
        return this.http.post(this.MongoURI + 'website/user/token', body, { headers: this.getHeader() });
    }
    public getOTP(data: any) {
        let body = JSON.stringify({ 'mobileNo': data });
        return this.http.post(this.MongoURI + 'website/send/sms', body, { headers: this.getHeader() });
    }

    public showOTP(data: any, id: any) {
        let body = JSON.stringify({ 'mobileNo': data, 'otp': id })
        return this.http.post(this.MongoURI + 'website/verify', body, { headers: this.getHeader() });

    }
    public savePassword(number: any, data: any, userData: any) {
        let body = JSON.stringify({ 'id': number, 'verifyParameter': data, 'password': userData })
        return this.http.post(this.MongoURI + 'website/password', body, { headers: this.getHeader() });
    }

    public lpEmailVerification(id){ // lp -login page
        let body = JSON.stringify({ 'id': id});
        return this.http.post(this.MongoURI + 'website/user/emailVerification',body,{ headers: this.getHeader() });
    }

    public signup(userData: any) {
    //   userData['parentUrl'] = "dev.aiqod.com:843";
    //   userData['websiteUrl'] = "dev.aiqod.com:843";
        let body = JSON.stringify(userData);
        return this.http.post(this.MongoURI + 'website/registeruser', body, { headers: this.getHeader() });
    }

    public sendOtp(userData: any) {
        let body = JSON.stringify(userData);
        return this.http.post(this.MongoURI + 'website/send-verify/otp', body, { headers: this.getHeader() });
    }

    // public sendOtpEmail(userData: any) {
    //     let body = JSON.stringify(userData);
    //     return this.http.post(this.MongoURI + 'website/send-verify/eOtp', body, { headers: this.getHeader() });
    // }

    public resetPasswordWithOtp(userData: any) {
        let body = JSON.stringify(userData);
        return this.http.post(this.MongoURI + 'website/changepasswordWithNo', body, { headers: this.getHeader() });
    }

    public registerUser(userData: any) {
        let body = JSON.stringify({ 'userData': userData });
        return this.http.post(this.MongoURI + 'website/register', body, { headers: this.getHeader() });
    }

    public validateUserToken(token: any) {

        let body = JSON.stringify({ 'token': token });
        return this.http.post(this.MongoURI + 'website/activateuser', body, { headers: this.getHeader() });
    }

    public updateUser(id: number, userData: any) {
        let body = JSON.stringify(userData);
        return this.http.post(this.URI + '/poc/updateuser/' + id, body, { headers: this.getHeader() });
    }

    onLogout() {
        let body = JSON.stringify({
            'data': {
                'status': 'false'
            }
        });
        return this.http.post(this.MongoURI + 'log/status', body, { headers: this.getHeader() }).pipe(map(res => res.valueOf()));
    }


    getTimeoutTime() {
        return 7200;
    }

    
    logout() {
        this.loggedIn = false;
        // this.data = new UserDetails();
        this.setSetUpWizardStatus(false);
        this.setCustomerSubscriberId(null);
        this.setGSTIN(null);
        this.setOrgName(null);
        this.setOrgId(null);
        this.stopRefreshTokenTimer();

        localStorage.removeItem('jwt');
        window.localStorage.removeItem('CustomInContext');
        sessionStorage.removeItem('gstin');
        sessionStorage.removeItem('orgName');
        sessionStorage.removeItem('jwt');
        sessionStorage.removeItem('customerSubscriber');
        sessionStorage.removeItem('orgId');
        sessionStorage.removeItem('bType');
        sessionStorage.removeItem('orgConfig');
        sessionStorage.removeItem('orgLabelFile');
        sessionStorage.removeItem('OrgList');
        sessionStorage.removeItem('isTaxGroup');
        sessionStorage.removeItem('taxAgency');
        sessionStorage.removeItem('type');
        sessionStorage.removeItem('userType');
        sessionStorage.removeItem('isRefresh');
        sessionStorage.removeItem('userGroupList');
        sessionStorage.removeItem('UsersInfo');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('processlist');
        sessionStorage.removeItem('reports');
        sessionStorage.removeItem('counterReports');
        sessionStorage.removeItem('subscriberId');
        sessionStorage.removeItem('orgCurrency');
        sessionStorage.removeItem('orgsSubId');
        sessionStorage.removeItem('orgCountry');
        //sessionStorage.removeItem('logoPath');
        //sessionStorage.removeItem('loginImageUrl');
        sessionStorage.removeItem('profileData');
        window.localStorage.removeItem('headerId');
        this.orgNameData = new BehaviorSubject<string>('');
        this.orgName = this.orgNameData.asObservable();
        this.orgLogoData = new BehaviorSubject<string>('');
        this.orgName = this.orgLogoData.asObservable();
        this.orgIdData = new BehaviorSubject<string>('');
        this.orgId = this.orgIdData.asObservable();
        this.gstinData = new BehaviorSubject<string>('');
        this.gstin = this.gstinData.asObservable();
        this.selectedData = new BehaviorSubject<boolean>(false);
        this.selectedDataorg = new BehaviorSubject<string>('');
        this.orgCountry = this.selectedDataorg.asObservable();
        this.logoData = new BehaviorSubject<string>('');
        this.logoPath = this.logoData.asObservable();
        this.currecyData = new BehaviorSubject<string>('');
        this.orgCurrency = this.currecyData.asObservable();
        this.extReg = new BehaviorSubject<boolean>(false);
        // this.extRegAllow = this.extReg.asObservable();
        // this.mobilePatternData = new BehaviorSubject<string>('');
        // this.mobPattern = this.mobilePatternData.asObservable();
        this.orgsSubIdData = new BehaviorSubject<string>('');
        this.orgsSubId = this.orgsSubIdData.asObservable();
        this.webReqData = new BehaviorSubject<string>('');
        this.sentWebReqData = this.webReqData.asObservable();
        this.langKeyData = new BehaviorSubject<string>('');
        this.langKey = this.langKeyData.asObservable();
        this.typeData = new BehaviorSubject<string>('');
        this.type = this.typeData.asObservable();
        this.consolidateInvData = new BehaviorSubject<string>('');
        this.consolidateInv = this.consolidateInvData.asObservable();
        this.billPeriodData = new BehaviorSubject<string>('');
        this.billPeriods = this.billPeriodData.asObservable();
        this.orgListData = new BehaviorSubject<string>('');
        this.orgList = this.orgListData.asObservable();
        this.orgConfigData = new BehaviorSubject<string>('');
        this.orgconfig = this.orgConfigData.asObservable();
        this.taxGrpData = new BehaviorSubject<string>('');
        this.isTaxGrp = this.taxGrpData.asObservable();
        this.custSubData = new BehaviorSubject<string>('');
        this.customerSubscriber = this.custSubData.asObservable();
        // sessionStorage.clear();
        let country=window.localStorage.getItem("country");
        let loc = JSON.parse(sessionStorage.getItem("loc"));
        let userLocation = JSON.parse(sessionStorage.getItem("userLocation"));
        sessionStorage.clear();
        localStorage.clear();
        sessionStorage.setItem("loc",JSON.stringify(loc));
        sessionStorage.setItem("userLocation",JSON.stringify(userLocation));
        window.localStorage.setItem("country",country);
        this.stateService.go('page.login', {id:''}, {reload: true});
        this.cookieService.removeAll();
    }

    isLoggedIn() {

        if (sessionStorage.getItem('jwt')) {
            return !status;
        } else {
            return this.loggedIn;
        }

        // return false;
    }
    resetdata() {
        this.invoicedata = [];
    }
    setLoggedUserId(user_id) {
        this.user_id = user_id;
    }

    getLoggedUserData(cb) {
        this.getToken(function (status, res) {
            cb(status, res);
        });
        return cb;
    }

    setLoggedUserData(user) {
        this.loginUser = user;
        this.loggedIn = true;
    }
    setTokenData(data) {
        this.tokenData = data;
    }
    getLoggedUserId() {
        return this.user_id;
    }
    clearOrgName() {
        this.orgNameData.next('')
    }
    clearGSTIN() {
        this.gstinData.next('')
    }

    getLoggedUserRecords() {
        return this.loginUser;
    }
    getTokenData() {
        // if token data is null or undefined or '' then fetch data from session and set token data
        // if (!isNullOrUndefined(this.tokenData) && this.tokenData !== '') {
        //     return this.tokenData;
        // } else {
            const token = sessionStorage.getItem('jwt');
            this.tokenData = this.JwtHelperService.decodeToken(token);
            return this.tokenData;
        // }
    }


    resetPasword(email) {
        let body = JSON.stringify({ 'email': email });
        return this.http.post(this.URI + '/password/reset', body, { headers: this.getHeader() }).pipe(map(res => res.valueOf()));
    }

    validateResetPasswordCode(code) {
        let body = JSON.stringify({ 'code': code });
        return this.http.post(this.URI + '/password/reset/validatecode', body, { headers: this.getHeader() }).pipe(map(res => res.valueOf()));
    }

    changePassword(user_id, email, password, code) {
        let body = JSON.stringify({ 'user_id': user_id, 'email': email, 'password': password, 'code': code });
        return this.http.post(this.URI + '/password/update', body, { headers: this.getHeader() }).pipe(map(res => res.valueOf()));
    }

    refreshdata() {
        this.passdata = [];
    }

    setGSTIN(gstin) {
        this.selectedGSTIN = gstin;
        sessionStorage.setItem('gstin',gstin);
    }
    setTaxAgency(taxAgency) {
        this.taxAgency = taxAgency;
    }

    setOrgId(orgid) {
        this.selectedOrgId = orgid;
        sessionStorage.setItem('orgId',orgid);
        localStorage.setItem('orgId',orgid);
    }
    getTaxAgency() {
        if (isNullOrUndefined(this.taxAgency) || this.taxAgency === '') {
            this.taxAgency = sessionStorage.getItem('taxAgency');
        }
        return this.taxAgency;
    }

    getGSTIN() {
        let gstIn;
        this.gstin.subscribe(item => {
            gstIn = item;
        });
        this.selectedGSTIN = gstIn;
        return this.selectedGSTIN;
    }

    getOrgId() {
        if (isNullOrUndefined(this.selectedOrgId) || this.selectedOrgId === '') {
            this.orgId.subscribe(item => {
                this.selectedOrgId = item;
            });
        }
        return this.selectedOrgId;
    }

    setOrgName(orgName) {
        this.selectedOrgName = orgName;
    }

    getOrgName() {
        this.orgName.subscribe(item => {
            this.selectedOrgName = item;
        });
        return this.selectedOrgName;
    }

    setTaxGroup(isTaxGroup) {
        this.selectedTaxGroup = isTaxGroup;
        if (isTaxGroup) {
            this.generateTaxGroup('true');
        } else {
            this.generateTaxGroup('false');
        }
    }

    getTaxGroup() {
        let txGrp;
        this.isTaxGrp.subscribe(item => {
            txGrp = item;
        });
        if (txGrp == 'true') {
            this.selectedTaxGroup = true;
            return this.selectedTaxGroup;
        }
        this.selectedTaxGroup = false;
        return this.selectedTaxGroup;

    }

    setOrgConfig(config) {
        this.orgConfig = config;
    }

    getOrgConfig() {
        this.orgconfig.subscribe(item => {
            this.currentOrgConfig = item;
        });
        return this.currentOrgConfig;
    }

    getOrgGstin() {
        let isrpaAllow = sessionStorage.getItem('isRpaAllow');
        // let orgName = this.getOrgName();
        // let orgGstin = this.getGSTIN();
        let orgName = !isNullOrUndefined(sessionStorage.getItem("orgName")) ? sessionStorage.getItem("orgName").toString() : "";
        let orgGstin = !isNullOrUndefined(sessionStorage.getItem("gstin")) ? sessionStorage.getItem("gstin").toString() : "";
        if (orgName == "") {
            orgName = this.getOrgName();
            orgGstin = this.getGSTIN();
        }
        if (!isNullOrUndefined(orgName) && orgName.trim().length > 0 && orgGstin.trim().length > 0) {
            return orgName + '(' + orgGstin + ')';
        } else if (isNullOrUndefined(orgGstin) || orgGstin.length == 0) {
            return orgName;
        } else {
            if (isrpaAllow) {
                return orgName;
            } else {
                const ret = '';
                return ret;
            }
        }
    }


    setSetUpWizardStatus(setStepStatus) {
        this.setupWizardStatus = setStepStatus;
    }

    getSetUpWizardStatus() {
        return this.setupWizardStatus;
    }

    setPackageID(pa) {
        this.selectedPackage = pa;
    }

    getPackageID() {
        return this.selectedPackage;
    }
    getLoggedUserDetails() {
        let token: any;
        // token = this.cookieService.get('jwt');
        // sessionStorage.setItem('jwt' , token);
        token = sessionStorage.getItem('jwt');
        if (!token) {
            return null;
        } else {
            if (this.JwtHelperService.isTokenExpired(token)) {
                sessionStorage.removeItem('jwt');
                return null;
            } else {
                let user = this.JwtHelperService.decodeToken(token);
                this.setLoggedUserData(user);
                this.getUser(user.userId).subscribe(respData => {
                    let rec: any;
                    rec = respData;
                    if (rec.status) {
                        if (rec.data != null) {
                            this.loginUser = rec.data;
                            this.loggedIn = true;
                            return rec.data;
                        } else {
                            return null;
                        }
                    }
                },
                    err => {
                        // console.log(err);
                        return null;
                    });
                return true;
            }
        }
    }

    setCustomerSubscriberId(subId) {
        this.selectedCustomerSubscriberId = subId;
    }

    getCustomerSubscriberId() {
        let customerSubscriberId;
        this.customerSubscriber.subscribe(item => {
            customerSubscriberId = item;
        });
        if (isNullOrUndefined(this.selectedCustomerSubscriberId) || this.selectedCustomerSubscriberId === '') {
            this.selectedCustomerSubscriberId = customerSubscriberId;
        }
        return this.selectedCustomerSubscriberId;
    }

    /* public googleLogin(profile){
     return this.http.post(this.URI + "/google/sign", JSON.stringify(profile),{headers: this.getHeader()});
     }*/


    getProfilePic() {
        return this.userProfilePic;
    }

    setProfilePic(userProfilePic) {
        this.userProfilePic = userProfilePic;
    }
    changeUserPassword(token, password) {
        let body: any = { "token": token, "password": password };
        return this.http.post(this.MongoURI + 'website/user/newPassword', body, { headers: this.getHeader() }).pipe(map(res => res.valueOf()));
    }

    resetPasswordOld(mobile, hostName, type) {
        let body = JSON.stringify({ 'mobile': mobile, 'hostName': hostName, type });
        return this.http.post(this.MongoURI + 'website/user/resetPassword', body, { headers: this.getHeader() })
            .pipe(map(res => res.valueOf()));
    }

    resetPassword(payload) {
        let body = JSON.stringify(payload);
        return this.http.post(this.MongoURI + 'website/user/resetPassword', body, { headers: this.getHeader() })
            .pipe(map(res => res.valueOf()));
    }

    getUserDetails() {
        let token = sessionStorage.getItem('jwt');
        return this.JwtHelperService.decodeToken(token);
    }
    public setTourCondition(tourdata) {
        this.tourCondition = tourdata;
    }
    public setReviewLevelDetails(level) {
        sessionStorage.setItem('reviewLevel',level);
    }
    public getReviewLevelDetails() {
       return sessionStorage.getItem('reviewLevel');
    }
    public getTourCondition() {
        return this.tourCondition;
    }
    checkSubscriberExists() {
        return this.http.get(this.MongoURI + 'website/subscriber/check', { headers: this.getHeader() })
            .pipe(map(res => res.valueOf()));
    }
    checkLicenseValidity(mobile) {
        const currentUrl = window.location.href;
        const urlArray = currentUrl.split('/');
        const url = urlArray[2];
        // const url = "idp-dev.aiqod.com:453";
        // const url = "msme-dev.gibots.com:453";
        // const url="msme.gibots.com:843"
        let body: any = { 'mobile': mobile, url: url };
        return this.http.post(this.MongoURI + 'website/license/valid', body, { headers: this.getHeader() })
            .pipe(map(res => res.valueOf()));
    }
    validateLicense(license) {
        let body: any = { 'license': license };
        return this.http.post(this.MongoURI + 'website/license/validate', body, { headers: this.getHeader() })
            .pipe(map(res => res.valueOf()));
    }
    registerUserOnLocalDB(response) {
        let body: any = { 'userData': response.userData, 'packageData': response.package };

        return this.http.post(this.MongoURI + 'website/register/localUser', body, { headers: this.getHeader() })
            .pipe(map(res => res.valueOf()));
        // return this._customAuthHttp.post(this.MongoURI+"website/register/localUser", body)
        //     .pipe(map((res:any) => res));
    }
    isTokenExpired() {
        let token = sessionStorage.getItem('jwt');
        return this.JwtHelperService.isTokenExpired(token)
    }
    generateData(row) {
        const self = this;
        if (row > 0) {
            self.selectedData.next(true);
        } else {
            self.selectedData.next(false);
        }
    }
    getBusinessTypes() {
        return this.http.get(this.MongoURI + 'website/business', { headers: this.getHeader() })
            .pipe(map(res => res.valueOf()));
    }
    generateOrgCountry(row) {
        const self = this;
        self.selectedDataorg.next(row);

    }
    generateOrgCurrency(row) {
        this.currecyData.next(row);
    }
    generateLogoPath(row) {
        this.logoData.next(row);
    }
    generateRegistrationBtn(row) {
        this.extReg.next(row);
    }
    generateMobilePattern(row) {
        this.mobilePatternData.next(row);
    }
    generateOrgSubID(row) {
        this.orgsSubIdData.next(row);
    }
    generateWebRequest(row) {
        this.webReqData.next(row);
    }
    generateLangKey(row) {
        this.langKeyData.next(row);
    }
    generateType(row) {
        this.typeData.next(row);
    }
    generateGstin(row) {
        this.gstinData.next(row);
    }
    generateOrgName(row) {
        this.orgNameData.next(row);
    }
    generateOrgId(row) {
        this.orgIdData.next(row);
    }
    generateConsolidated(row) {
        this.consolidateInvData.next(row);
    }
    generateBillPeriod(row) {
        this.billPeriodData.next(row);
    }
    generateOrgList(row) {
        this.orgListData.next(row);
    }
    generateOrgConfig(row) {
        this.orgConfigData.next(row);
    }
    generateTaxGroup(row) {
        this.taxGrpData.next(row);
    }
    generateCustomerSubscriber(row) {
        this.custSubData.next(row);
    }
    generateOrgLogo(row) {
        this.orgLogoData.next(row);
    }
}
