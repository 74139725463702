import {Component, Inject} from '@angular/core';
import {Response} from '@angular/http';
import {isNullOrUndefined} from 'util';
import {JwtHelperService} from '@auth0/angular-jwt';
import {StateService} from '@uirouter/core';

import {APP_CONFIG, IConfig} from '../../../../appConfig/Iconfig';
import {WebsiteService} from '../../service/website.services';
import {Broadcaster} from '../../../../services/sharable.service';
import {AuthService} from '../../../../services/auth.service';
import { map } from 'rxjs/operators';


declare var $: any;
declare var jquery: any;

@Component({
  selector: 'app-layout',
  templateUrl: 'main.component.html',
  styleUrls: ['main.component.css']
})


export class MainComponent {

  loginData:any;
  private hostName: string;
  data: any;
  private JwtHelperService:JwtHelperService;
  private isDimmed:boolean=false;

    constructor(@Inject(APP_CONFIG) private iconfig: IConfig, private webService: WebsiteService,
                private service: Broadcaster, private authService: AuthService, JwtHelperService:JwtHelperService, private stateService: StateService ) {
        this.JwtHelperService = JwtHelperService;
  }
  ngOnInit(){
      this.initValues();
      this.initMethod();
  }

    initMethod(){
        //$('.ui.page.dimmer').dimmer('show');
       // $("#loginModal").modal({backdrop: "static",keyboard:false});
    }



    initValues(){
      this.loginData={};
    }
    onNewUserClick(){
         $('#loginModal').modal('hide');
    }

    onLogin(){
      const currentUrl = window.location.href;
      const urlArray = currentUrl.split('/');
      const url = urlArray[2];
      const self=this;
      let data;
      if(this.loginData.mobileNumber && this.loginData.mobileNumber.trim()!="" && this.loginData.password && this.loginData.password.trim()!="")
      {
          this.authService.authenticate(this.loginData.mobileNumber, this.loginData.password, this.loginData.kli, url)
              .pipe(map((res:any) => res))
              .subscribe(
                  data => {
                      self.data = data;
                      // if(this.data.status==true)
                      if(this.data.status==true)
                      {
                          let token=data.token;
                          if(token) {
                              sessionStorage.setItem("jwt",token);
                              let parseddata = this.JwtHelperService.decodeToken(token);
                              let userid=parseddata.userId;
                              let userType = parseddata.userType;
                              let subscriberId=parseddata.subscriberId;
                              let panNumber=parseddata.panNumber;
                              let userName=parseddata.userName;
                              let permissions=parseddata.permission;
                              let userData = data.userData;
                              // this.app.startLoader();
                              let orgLabelFile;
                              if(isNullOrUndefined(self.data.userData.organisation)){
                                  orgLabelFile="../src/assets/i18n/en.json";
                              }
                              else {
                                  if(isNullOrUndefined(self.data.userData.organisation.orgLabelFile) || self.data.userData.organisation.orgLabelFile == ''){
                                      orgLabelFile="../src/assets/i18n/en.json";
                                  }
                                  else {
                                      orgLabelFile=self.data.userData.organisation.orgLabelFile;
                                  }
                              }
                              sessionStorage.setItem("orgLabelFile",orgLabelFile);

                              if (userid) {
                                  let self = this;
                                  this.authService.setLoggedUserData(userData);
                                  this.authService.setLoggedUserId(userid);
                                  this.authService.setUserType(userType);
                              }

                              self.fetchWebsiteData(self.data);

                          }
                      }
                      else
                      {
                          self.stateService.go('page.login');
                      }
                  },
                  err => {
                      self.stateService.go('page.login');
                  }
              );
      }
    }

    private fetchWebsiteData(pageData) {
        const self = this;
        if(pageData.userData) {
            this.webService.getDynamicDataByUser(pageData.userData._id).subscribe(respData => {
                    if (respData.status) {
                        if (respData.data) {
                            self.setPageContents(respData.data['pageContent'],pageData.userData);
                        }
                    } else {

                    }
                },
                err => {
                });
        }
    }

    setPageContents(pageData, userData) {
        this.service.broadcast('changeAppMode', 'C');
        this.service.broadcast('updateWebsite', true);
        let content: any;
        content = {
            pageData: pageData,
            userData: userData
        }
        this.service.broadcast('changePageContentOnLogin', content);
        $('#loginModal').modal('hide');
    }

}
