import { UIRouterModule } from '@uirouter/angular';

import { AppComponent } from './app.component';

import {routerConfigFn} from "./web/route.config";


export const appState = { name: 'app', redirectTo: 'welcome', component: AppComponent };

export const pageState = { name: 'page.**', url: '/page',
// loadChildren: () => import('./page/page.module').then(m => m.PageModule) ,
// data: {
//   preload: true
// }
};

export const AppStates = UIRouterModule.forRoot(
  {
    otherwise: { state: 'page.login' },
    states: [appState,  pageState  ],
    useHash: false, 
    config: routerConfigFn
  },
)



