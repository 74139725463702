import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, IConfig} from '../../appConfig/Iconfig';
import {AuthService} from '../auth.service';
import {CustomAuthHttp} from '../local-authhttp/local-authhttp';
// import {Response} from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable()
export class UtilityService {

  private MongoURL: string;
  private NewBackendURl : string;
  constructor(private http: CustomAuthHttp,
              @Inject(APP_CONFIG) private _Iconfig: IConfig, private authService: AuthService) {
    this.MongoURL = _Iconfig.mongoUrl;
    this.NewBackendURl = _Iconfig.newBackendUrl;
  }

  restrictChar(e: any) {
    var input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return true;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }

    input = String.fromCharCode(e.which);
    // '^[a-zA-Z0-9._-]+$'
    return !!/[0-9.]/.test(input);
    // return !!/[\d\s]/.test(input);
  }
  public updateMmvTour(data) {
    this.callToUpdateMmvTour(data)
      .subscribe(
        respdata => {
          this.authService.setTourCondition(respdata.data.mmvTour);
        },
        err => {
          console.log(err);
        }
      );
  }
  callToUpdateMmvTour (data) {
    return this.http.post(this.MongoURL + 'user/update/tour', JSON.stringify(data))
      .pipe(map((resp: any) => resp));
  }
  public getTourCondition() {
    // this.callToGetMmvTourConditions()
    //   .subscribe(
    //     respdata => {
    //       this.authService.setTourCondition(respdata.data.mmvTour);
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
  }
  callToGetMmvTourConditions () {
    return this.http.get(this.MongoURL + 'user/getMmvTour')
      .pipe(map((resp: any) => resp));
  }

  public getPackages(userType/*, webUrl*/) {
    let subscriberId;
        this.authService.orgsSubId.subscribe(item => {
          subscriberId = item;
        });
    const body = {
      userType: userType,
      subscriberId: subscriberId
  };
    return this.http.post(this.NewBackendURl + 'website/getPackages/salesEnable/', JSON.stringify(body))
      .pipe(map((resp: any) => resp));
  }

}
