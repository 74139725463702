<div class="modal-body {{displayText}}">
  <p>{{confirmText}}</p>
  <div class="text-left" *ngIf = "inputVisible">
    <label class="place">File Name :</label>
    <app-input-box id="txtDLFileName" (inputChange)="fileName = $event"></app-input-box>
  </div>
  <div class='text-center'>
    <button type="button" class="btn btn-default {{displayYesFlag}}" id="confrimYes" (click)="confirm()" >{{yesMsg}}</button>
    <button type="button" class="btn btn-primary md-space" id="confrimNo" (click)="decline()" >{{noMsg}}</button>
  </div>
</div>
