<section id="section2" [style.background]="pageContents.appTheme" [style.color]="pageContents.textColor" *ngIf="pageContents">
    <div class="container">
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
        <div class="line-title">
            <h3 [ngClass]="{'align': langSetKey === 'arb'}" class="title" style="color: #f47435;">{{title}}</h3>
        </div>
        <div class="row about_me ">

            <div class="col-md-12 col-sm-12 top-content" *ngIf="applicationMode==='C'">

                <div class="col-md-11 col-sm-12 nopadding">
                    <div id="summernoteAbout" class="summernote"></div>
                </div>
                <div class="col-md-1 col-sm-12 edit-btn" >
                    <div><button id="editContent" ngbTooltip="Edit Content" container="body" placement="left" class="more-btn edit-about" (click)="editContent('about')" *ngIf="!contentEdit.editAbout" ><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button></div>
                    <button container="body" placement="left" ngbTooltip="Save Content" class="more-btn" (click)="saveContent('about')" *ngIf="contentEdit.editAbout"><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
                    <button container="body" placement="left" ngbTooltip="Close" class="more-btn" (click)="cancelContent('about')" *ngIf="contentEdit.editAbout"><i class="fa fa-ban" aria-hidden="true"></i></button>
                </div>
            </div>
            <div *ngIf="validateDataAndMode(pageContents.aboutContent)" class="col-md-12 top-content font-clr" [ngClass]="{'position': langSetKey === 'arb'}" [innerHTML]="pageContents.aboutContent | escapeHtml" >
            </div>
            <!--<div slimScroll-->
                 <!--background="#333"-->
                 <!--opacity="0.6"-->
                 <!--position="right"-->
                 <!--height="250px"-->
                 <!--width="7px"-->
                 <!--border-radius="5px"-->
                 <!--size="7px" color="red" position="right" alwaysVisible="true">-->
                <!--ardfsdgfsdgfdsgfd</div>-->
            <!--<div>kuewyturetreutyreiut reytye reytyrety rejhtyret rejtretretjret rehkjtret reuthyreutyre-->
            <!--</div>-->
        </div>


        <!--<div class="exp">
            <div class="line-title">
                <div class="row">
                    <div class="col-sm-11 col-sm-12">
                        <h3 class="title">EXPERIENCE</h3>
                    </div>
                    <div *ngIf="applicationMode==='C'" class="col-sm-1 skill-icon no-right-padding center-align"><button ngbTooltip="Add experience" container="body" placement="left" class="transparent-btn circle-btn add-exp" (click)="addExp()"><i class="fa fa-plus-square-o" aria-hidden="true"></i></button></div>
                </div>
            </div>

            <div class="row experience-row" *ngIf="pageContents.expSet">

                <div class="col-md-6 col-sm-12" *ngFor="let item of pageContents.expSet; let idx = index;">
                    <div class="row experience">
                        <div class="col-sm-2 col-xs-2">
                            <div ngbTooltip="Click to edit experience" container="body" placement="top" *ngIf="applicationMode === 'C'; else loader" class="exp-box " [style.background]="item.color" (click)="editExp(item, idx)" data-toggle="modal" data-target="#expModal"></div>
                            <ng-template #loader>
                                <div class="exp-box" [style.background]="item.color"></div>
                            </ng-template>
                        </div>
                        <div class="mobile-style">
                            <div class="col-sm-4 col-xs-4 exp-web" [style.color]="item.color" style="padding: 0px !important;">
                                <b>
                                    {{item.position}}<br>
                                    {{item.year}}
                                </b>
                            </div>
                            <div class="col-sm-6 col-xs-6">
                                <p>
                                    <b>{{item.company}}</b><br>
                                    {{item.web}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>

</section>

<section id="section3" class="margintop" [style.background]="pageContents.appTheme" [style.color]="pageContents.textColor" *ngIf="pageContents">
    <div class="container">
            <div class="line-title" >
                <div class="row">
                    <div class="col-sm-12 col-sm-12">
                        <h3 *ngIf="langSetKey != 'arb'" class="title" style="color: #f47435;">Our Services</h3>
                        <h3 *ngIf="langSetKey === 'arb'" [ngClass]="{'align': langSetKey === 'arb'}" class="title" style="color: #f47435;">خدماتنا</h3>
                    </div>
                    <div *ngIf="applicationMode==='C'" class="col-sm-1 skill-icon no-right-padding center-align"><button data-toggle="tooltip" data-placement="top" title="Add skill" class="transparent-btn circle-btn add-skill"  (click)="addSkill()"><i class="fa fa-plus-square-o" aria-hidden="true"></i></button></div>            </div>
            </div>

            <div [ngClass]="{'align': langSetKey === 'arb'}" class="row" id="skills" *ngIf="pageContents">
                <div class="col-sm-4 page col-sm-12" *ngFor="let item of pageContents.skillSet; let idx = index;">
                    <div class="row">
                        <div class="col-sm-12 col-sm-12"> <h4 [ngClass]="{'align': langSetKey === 'arb'}" [style.color]="item.color">{{item.title}}</h4> </div>
                        <div *ngIf="applicationMode==='C'" class="col-sm-2 skill-icon" >
                            <button class="transparent-btn circle-btn edit-skill" data-toggle="modal"
                                    data-target="#skillModal" ngbTooltip="Edit skill" container="body" placement="left" (click)="editSkills(item, idx)">
                                <i class="fa fa-pencil-square" aria-hidden="true">
                                </i></button>
                        </div>
                    </div>
                    <!--<div background="#333"-->
                    <!--opacity="0.6"-->
                    <!--position="right"-->
                    <!--height="250px"-->
                    <!--width="7px" alwaysVisible="true"-->
                    <!--border-radius="5px">-->
                    <div slimScroll
                         background="#333"
                         opacity="0.6"
                         transition=".8"
                         position="right"
                         height="200px"
                         width="auto"
                         border-radius="5px"
                         size="7px" color="black" allowPageScroll="true" railVisible="true" >
                        <p [ngClass]="{'position': langSetKey =='arb'}" [innerHTML]="item.desc | escapeHtml">
                        </p>
                    </div>
                    <div *ngIf="item.show" class="skillbar clearfix" [attr.data-percent] = "valToPer(item.val)">
                        <div class="skillbar-bar skillbar-bar-color" [style.background]="item.color"></div>
                        <div class="skill-bar-percent">{{valToPer(item.val)}}</div>
                    </div>
                </div>
            </div>
    </div>
</section>

<div *ngIf="skillData" class = "modal fade" id="skillModal" tabindex="-1" role="dialog" aria-labelledby="skillModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="skillModalLabel">{{modalHeader}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textcolor">
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Title : <sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input class="full-width bottomborder "  #modalTitle="ngModel" [(ngModel)]="skillData.title" [onBlurText]="skillData.title" />
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Description:<sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <textarea  class="full-width" #modalDesc="ngModel" [(ngModel)]="skillData.desc"  rows="4" [onBlurText]="skillData.desc"></textarea>
                    </div>
                    <!--<div class="col-md-2 col-sm-12 col-xs-12">-->
                    <!--Remaining:{{displayMaxCharacter}}-->
                    <!--</div>-->
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="col-sm-6 col-sm-12">Color :  <sup style="color: red">*</sup></div>
                        <div class="col-sm-6 col-sm-12">
                            <input class="full-width" [(colorPicker)]="skillData.color"  [cpPosition]="'bottom'"
                                   [style.background]="skillData.color"  [cpPositionOffset]="'50%'" [cpSaveClickOutside]="true"
                                   [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"/>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="col-sm-6 col-sm-12">Value</div>
                        <div class="col-sm-6 col-sm-12">
                            <input type="number" class="full-width" #modalTitle="ngModel" [(ngModel)]="skillData.val" (blur)="restrictMaxVal('val',100)"/>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="col-sm-8 col-sm-12">Show Value</div>
                        <div class="col-sm-4 col-sm-12">
                            <input type="checkbox" #modalShow="ngModel" [(ngModel)]="skillData.show"/>
                        </div>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteSkillModalData(editSkillIndex)">Delete</button>
                <button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="saveSkillModalData(skillData,editSkillIndex)">Save changes</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="expData" class="modal fade" id="expModal" tabindex="-1" role="dialog" aria-labelledby="expModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="expModalLabel">{{modalHeader}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textcolor">
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Position : <sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input maxlength="20" class="full-width bottomborder" #modalposition="ngModel" [(ngModel)]="expData.position" [onBlurText]="expData.position"/>
                    </div>
                </div>
                <br/>

                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Company:<sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input maxlength="20" class="full-width bottomborder"  #modalcompany="ngModel" [(ngModel)]="expData.company" [onBlurText]="expData.company"/>
                        <!--<input class="full-width bottomborder" #modalcompany="ngModel" [(ngModel)]="expData.company" />-->
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Website
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input maxlength="20" class="full-width bottomborder" #modalWeb="ngModel" [(ngModel)]="expData.web" [onBlurText]="expData.web" />
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="col-sm-4">Color</div>
                        <div class="col-sm-8">
                            <input [(colorPicker)]="expData.color"
                                   [style.background]="expData.color"  [cpPositionOffset]="'50%'" [cpSaveClickOutside]="true"
                                   [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"/>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="col-sm-4">Year : <sup style="color: red">*</sup></div>
                        <div class="col-sm-8">
                            <input  class="full-width bottomborder" #modalYear="ngModel" [(ngModel)]="expData.year"  onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 45 ' />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteExpModalData(editSkillIndex)">Delete</button>
                <button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="saveExpModalData(expData,editExpIndex)">Save changes</button>
            </div>
        </div>
    </div>
</div>