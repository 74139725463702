<footer [style.background]="appColor">
<!--
  <popover-content class="popover-position"  #lnkPopover title="Set Link" placement="top" [closeOnClickOutside]="true">

    <div class="row">
      <div class="col-md-2">
        Link:
      </div>
      <div class="col-md-8">
        <input class="full-width bottomborder"  #linkURL="ngModel" [(ngModel)]="currentLinkObject.linkURL" />
      </div>
      <div class="col-md-2">
        <button class="more-btn" (click)="lnkPopover.hide();saveLink();toggleFlip()"><i class="fa fa-floppy-o" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-7">Disable Link </div>
      <div class="col-md-4">
        <input type="checkbox" #modalShow="ngModel" [(ngModel)]="currentLinkObject.disabled"/>
      </div>
    </div>
  </popover-content>
  <popover-content #createWebsite placement="top" title="Create Website" style="color: black" [closeOnClickOutside]="true">

    <div class="row" style="color: black">
      <div class="col-md-12">
      Do you want to Create Dynamic Website ?
        <span (click)="createWebsite.hide();onCreateDynamicWebsite()" style="border-bottom: 1px solid;color: blue;cursor: pointer">If Yes Click Here.</span>
      </div>
      <div class="col-md-12">
      <span (click)="createWebsite.hide();OnNoDynamicWebsite()" style="border-bottom: 1px solid;color: blue;cursor: pointer">No Thanks !</span>
      </div>
    </div>
  </popover-content>
-->

  <div class="row">
  <div class="container">

    <div class="social-icons">
      <div class="col-md-1 hidden-sm"></div>
      <div class="col-md-1 hidden-sm"></div>
      <div class="col-md-1 hidden-sm"></div>
      <div *ngIf="applicationMode === 'C'">
          <!--<div style="border: red solid;">-->
           <a *ngFor="let link of pageContents.socialLinks; let idx = index;" class="col-md-1 col-sm-2 col-xs-4" (click)="setCurrentLinkObject(link.linkName)" [popover]="lnkPopover">
            <div class="tp-wrapper">
              <div [id]="link.linkName" class="tp-box" [@flipState]="link.flip">
                <div class="si tp-box__side tp-box__front remove-border ">
                  <i [ngClass]="link.iconClass" class="effect"  [ngStyle]="{'color':  link.color}" aria-hidden="true"></i>
                </div>
                <div class="si tp-box__side tp-box__back remove-border" (click)="flipData(link);">
                  <i [ngClass]="link.iconClass"  [ngStyle]="{'color':  link.color}" aria-hidden="true"></i>
                  <i class="fa fa-eye-slash set-position" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </a>
        <!--</div>-->
      </div>
      <div *ngIf="applicationMode==='L'">
        <div *ngFor="let link of pageContents.socialLinks; let idx = index;">
          <a *ngIf="!link.disabled" class="col-md-1 col-sm-2 col-xs-2" (click)="setCurrentLink(link.linkURL)">
            <div class="si">
              <i [ngClass]="link.iconClass" class="effect" [ngStyle]="{'color':  link.color}" aria-hidden="true"></i>
            </div>
          </a>
        </div>
      </div>
      <!--<button [popover]="lnkPopover">-->
        <!--<i class="add icon"></i>-->
      <!--</button>-->
    </div>
  <div class="to_top" [ngx-scroll-to]="'header'">
    <img src="assets/img/totop.png" alt="go to top">
  </div>
  </div>
  </div>
  <div class="copyright" style="margin-top: 150px">
    <div class="col-md-12 nopadding " *ngIf="applicationMode==='C'">

      <!--<div class="col-md-12 nopadding">-->
        <div id="summernoteFooter" class="summernote" style="color: #000000 !important;">
          {{pageContents.footerContent}}
        </div>
      <!--</div>-->
    </div>

    <div style="color: #000000 !important;" class="col-md-12 top-content footer-data" [innerHTML]="pageContents.footerContent | escapeHtml" *ngIf="validateData(pageContents.footerContent) && applicationMode ==='L'">
    </div>
  </div>
</footer>
<!-- end of footer -->
