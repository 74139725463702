import {Component, OnInit, TemplateRef, ViewChild, Input} from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: 'confirm.modal.component.html',
    styleUrls: ['confirm.modal.component.css']
})

export class ConfirmModalComponent implements OnInit {
   
    confirmText: string;
    inputVisible: boolean=false;
    modalRef: BsModalRef;
    message: boolean;
    fileName: string;
    public onClose: Subject<boolean>;
    yesMsg: string = 'Yes';
    noMsg: string = 'No';
    displayYesFlag: string = 'block';
    displayText: string = 'text-center';
    
   // @ViewChild(ModalDirective) modal: ModalDirective;
    constructor(private modalService: BsModalService, public bsModalRef: BsModalRef) {}
    ngOnInit() {
        this.onClose = new Subject();
        
    }
    confirm(): void {
        this.message = true;
        //this.fileName = document.getElementById("txtDLFileName").innerText;
        this.onClose.next(true);
        this.bsModalRef.hide();
    }
    decline(): void {
        this.message = false;
        this.onClose.next(false);
        this.bsModalRef.hide();
    }
}
