import {NgModule} from '@angular/core';
import {ModuleWithProviders} from '@angular/core';
import {UIRouterModule, UIRouter, Ng2StateDeclaration} from '@uirouter/angular';
import {LoginDashBoardComponent} from '../page/logindashboard/logindashboard.component';
import {PageComponent} from './layout/page.component';
import {PageLaunchComponent} from './launch/launch.component';
import {PageLoginComponent} from './login/login.component';
import { ThankYouPageComponent } from './thankyouPage/thankyouPage.component';
import {ResetPasswordPageComponent} from "./resetPasswordPage/resetPasswordPage.component";
import {TokenExpModelComponent} from './tokenExipred/tokenExpModel.component';


export const pageRoutes: Ng2StateDeclaration = {
    parent: 'app',
    name: 'page',
    url: '',
    component: PageComponent
};

export const defaultRoute: Ng2StateDeclaration = {
    name: 'default',
    url: '/',
    redirectTo: 'web.dashboard',
    data: { requiresAuth: true }
};

export const launchRoutes: Ng2StateDeclaration = { name: 'page.launch',
    url: '/launch',
    component: PageLaunchComponent,
    params: {userData: {}}
};
export const loginRoutes: Ng2StateDeclaration = { name: 'page.login',
    url: '/?registrationId=""&verification=""&emailVerification=""',
    component: PageLoginComponent,
    params: {pathname: '', href: ''}
};

export const newDash: Ng2StateDeclaration = { name: 'page.dashboard',
    url: '/dashboard',
    component: LoginDashBoardComponent
};

export const thankyouPageRoutes: Ng2StateDeclaration = { name: 'page.thankyou',
    url: '/thankyou/:token',
    component: ThankYouPageComponent
};
export const resetPasswordPageRoutes: Ng2StateDeclaration = { name: 'page.resetPassword',
    url: '/resetpassword/:token',
    component: ResetPasswordPageComponent
};
export const tokenExpRoute: Ng2StateDeclaration = { name: 'page.tokenExp',
    url: '/tokenExp',
    component: TokenExpModelComponent,
};
// export const shareRoute = { name: 'share.**', url: '/share', loadChildren: () => import('./../share/share.module').then(m => m.ShareModule),data: {
//     preload: true
//   }
// };
export const webRouteState = { name: 'web.**', url: '/web', loadChildren: () => import('./../web/web.module').then(m => m.WebModule),
data:{ requiresAuth: true, preload: true}
};
export const PageRoute = UIRouterModule.forChild({ states: [pageRoutes,newDash, launchRoutes, loginRoutes,thankyouPageRoutes,resetPasswordPageRoutes,tokenExpRoute,webRouteState, defaultRoute] });


export class CustomPreloadingStrategy {
    constructor() {
    }  
    preloadModule(moduleLoader: () => Promise<any>): void {
       
      moduleLoader().then((module) => {
        console.log('Web Module preloading done');
      });
    }
  }
  const customPreloadingStrategy = new CustomPreloadingStrategy();
customPreloadingStrategy.preloadModule(webRouteState.loadChildren);