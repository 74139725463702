import {Injectable} from '@angular/core';

@Injectable()

export class UtilsService {
    restrictNumeric(e): any {
        let input;
        if (e.metaKey || e.ctrlKey) {
            return true;
        }
        if (e.which === 32) {
            return false;
        }
        if (e.which === 0) {
            return true;
        }
        if (e.which < 33) {
            return true;
        }
        input = String.fromCharCode(e.which);
        return !!/[\d\s]/.test(input);
    }

    restrictNumericAndPlus(e): any {
        let input;
        if (e.metaKey || e.ctrlKey) {
            return true;
        }
        if (e.which === 32) {
            return false;
        }
        if (e.which === 0) {
            return true;
        }
        if (e.which < 33) {
            return true;
        }
        if (e.which === 43)
            return true;
        input = String.fromCharCode(e.which);
        return /^[\d\s]$/.test(input);
    }
  restrictAlphaNumeric(e) {
    if (!(e.which > 47 && e.which < 58) && // numeric (0-9)
      !(e.which > 64 && e.which < 91) && // upper alpha (A-Z) //!(e.which > 96 && e.which < 123) &&
      (e.keyCode !== 8 )) {
      return false;
    }  else {
      return true;
    }
  }

    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
}
