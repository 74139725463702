
import {Injectable, Inject} from "@angular/core";
import {Http, Headers, Response} from "@angular/http";
import {CustomAuthHttp} from '../../../../../services/local-authhttp/local-authhttp';
import {APP_CONFIG} from "../../../../../appConfig/Iconfig";
import {IConfig} from "../../../../../appConfig/Iconfig";
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

const moment = require("moment");

@Injectable()
export class UsersService {
    private URI: string;
    private MongoURI: string;
  private BackendURI: string;
  private backendReportUri: string;
    constructor(private http: CustomAuthHttp,
                @Inject(APP_CONFIG) private _Iconfig: IConfig){
        this.MongoURI = _Iconfig.mongoUrl;
      this.BackendURI = _Iconfig.newBackendUrl;
      this.backendReportUri= _Iconfig.backendReportUri;
    }
    public getUserList(){
        return this.http.get(this.MongoURI+"user/allusers")
            .pipe(map((res:any) => res));
    }
    public getCustomerList(){
        return this.http.get(this.MongoURI+"user/all/customerlist")
            .pipe(map((res:any) => res));
    }
    public getCurrencyList() {
        return this.http.get(this.MongoURI + 'currency/all')
            .pipe(map((resp: any) => resp));
    }
    public getUser(){
        return this.http.get(this.MongoURI+"user")
            .pipe(map((res:any) => res));
    }
    public getRegUser(id){
      return this.http.get(this.MongoURI+`website/user/${id}`)
      .pipe(map((res:any) => res));
    }

    public getProfileData() {
        return this.http.get(this.MongoURI + 'user/profileData')
            .pipe(map((res:any) => res));
    }
    public updateProfile(data) {
        return this.http.post(this.MongoURI + 'user/editProfile/userProfile', JSON.stringify(data))
            .pipe(map((resp: any) => resp));
    }

    public updateLocationPreference(data) {
      return this.http.post(this.MongoURI + 'user/update/locationPreference', JSON.stringify(data))
          .pipe(map((resp: any) => resp));
    }


    public expiryDate(){
      return this.http.get(this.MongoURI + 'user/expiryData')
      .pipe(map((resp: any) => resp))
  }

    public getOrgList(){
        return this.http.get(this.MongoURI+"organisation/orglist")
            .pipe(map((res:any) => res));
    }
    public saveUser(data){
        let body = JSON.stringify({
            "data": data
        });
        return this.http.post(this.MongoURI + "user", body)
            .pipe(map((res:any) => res));
    }
    public updateUser(data){
        let body = JSON.stringify({
            "data": data
        });
        return this.http.post(this.MongoURI + "user/update/" + data._id, body)
            .pipe(map((res:any) => res));
    }
    public deleteUser(data){
        return this.http.get(this.MongoURI+"user/delete/"+data)
            .pipe(map((res:any) => res));
    }
    getStateList(){
        return this.http.get(this.MongoURI+'website/state')
            .pipe(map((res:any)=> res));
    }
    public getDepartment(){
        return this.http.get(this.MongoURI+"department")
            .pipe(map((res:any) => res));
    }
    public getRoles(){
        return this.http.get(this.MongoURI+"role")
            .pipe(map((res:any) => res));
    }
    public resetPassword(data){
        return this.http.post(this.MongoURI+"user/changepassword",data)
            .pipe(map((res:any) => res));
    }
    public updateUserProfile(data){
        let body = JSON.stringify({
            "data": data
        });
        return this.http.post(this.MongoURI+"user/profile/update/"+data._id,body)
            .pipe(map((res:any) => res));
    }

    public getUserHierarchy(userId){
        return this.http.get(this.MongoURI+"user/hierarchy/"+userId)
            .pipe(map((res:any) => res));
    }

    public getSubscriber() {
        return this.http.get(this.MongoURI + "subscriber")
            .pipe(map((res:any) => res));
    }

    public getSubscriberById(subscriberId) {
        return this.http.get(this.MongoURI + "website/subscriber/"+subscriberId)
            .pipe(map((res:any) => res));
    }
    profileCompletion() {
        return this.http.get(this.MongoURI+"user/profile/completion")
            .pipe(map((res:any) => res));
    }

    userLimit() {
        return this.http.get(this.MongoURI+"user/limit")
            .pipe(map((res:any) => res));
    }

    gstinLimit() {
        return this.http.get(this.MongoURI + "user/gstinlimit")
            .pipe(map((resp: any) => resp));
    }

    isMobileDuplicate(mobileNo, type) {
        return this.http.get(this.MongoURI + "user/checkduplicate/" + mobileNo + "/" + type)
            .pipe(map((resp: any) => resp));
    }
    public getUserProfile(userId) {
        return this.http.get(this.MongoURI+"user/list/"+userId)
            .pipe(map((res:any) => res));
    }
    public insertOrUpdate(data){
        let body = JSON.stringify({
            "data": data
        });
        return this.http.post(this.MongoURI + "user/insertOrUpdate", body)
            .pipe(map((res:any) => res));
    }
    public getuploadedTemplates() {
     return this.http.get(this.MongoURI + 'mapping/excelfile/')
      .pipe(map((resp: any) => resp));
    }

    public getUploaderHistory(tableName)
    {
       tableName = 'Scan';
      return this.http.get(this.MongoURI + 'mapper/upload/history/' + tableName)
      .pipe(map((resp: any) => resp));
      
    }

    public getUploaderHistoryByType(tableName)
    {
        return this.http.post(this.MongoURI + 'mapper/historybyType', JSON.stringify(tableName))
        .pipe(map((resp: any) => resp));
    }


    public getDZones(body) {
      return this.http.post(this.MongoURI + 'dZone/getStateZones', JSON.stringify(body))
        .pipe(map((resp: any) => resp));
    }

  public getMapping(tableName){
    return this.http.get(this.MongoURI + 'mapping/'+tableName)
      .pipe(map((resp: any) => resp));
  }

  getCustomerProfileData(id) {
    let body = JSON.stringify({
      "custSubscriberId": id
    });
    return this.http.post(this.BackendURI + 'user/profile',body)
      .pipe(map((resp: any) => resp));
  }

  public getExcelUploadData(first, rows, uploadType, refnum, isUpload) {
    const obj = {
      first: first,
      rows: rows,
      isUpload: isUpload,
      fileRefNum: refnum,
      type: uploadType
    };
    const body = JSON.stringify(obj);
    return this.http.post(this.MongoURI + 'invoice/uploadStatus', body)
      .pipe(map((resp: any) => resp));
  }

  public getContactSuccessList(first, rows, uploadType, refnum, isUpload) {
    const obj = {
      first: first,
      rows: rows,
      isUpload: isUpload,
      fileRefNum: refnum,
      type: uploadType
    };
    const body = JSON.stringify(obj);
    return this.http.post(this.MongoURI + 'user/successContact', body)
      .pipe(map((resp: any) => resp));
  }

  public getErrorInvList(first, rows, uploadType, refnum, isUpload) {
    const obj = {
      first: first,
      rows: rows,
      isUpload: isUpload,
      fileRefNum: refnum,
      type: uploadType
    };
    const body = JSON.stringify(obj);
    return this.http.post(this.MongoURI + 'errorInv/paginated', body)
      .pipe(map((resp: any) => resp));
  }

  public getErrorContactList(first, rows, uploadType, refnum, isUpload) {
    const obj = {
      first: first,
      rows: rows,
      isUpload: isUpload,
      fileRefNum: refnum,
      type: uploadType
    };
    const body = JSON.stringify(obj);
    return this.http.post(this.MongoURI + 'errorCon/paginated', body)
      .pipe(map((resp: any) => resp));
  }

  public getInvoiceDetails(data) {
    const body = JSON.stringify(event);
    return this.http.post(this.MongoURI + 'invoice/uploadStatus', body)
      .pipe(map((resp: any) => resp));
  }
  public editInvoiceItem(invId) {
    return this.http.get(this.MongoURI + 'errorInv/' + invId)
      .pipe(map((resp: any) => resp));
  }
  public acceptRejectInv(data) {
    return this.http.post(this.MongoURI + 'invoice/accept/warning', data)
      .pipe(map((resp: any) => resp));
  }
  public deleteInvItems(data) {
    return this.http.get(this.MongoURI + 'errorInv/delete/' + data.invId + '/' + data.itemId)
      .pipe(map((resp: any) => resp));
  }
    public getUploadFileSummary(fileId) {
       return this.http.get(this.MongoURI + 'errorInv/summary/' + fileId)
          .pipe(map((resp: any) => resp));
  }

  public deleteFile(data) {
    return this.http.get(this.MongoURI + 'mapper/delete/' + data)
      .pipe(map((resp: any) => resp));
  }

  public saveInvoiceDetails(data) {
    data.invDt  = moment(data.invDt).format("DD-MM-YYYY");
    data.invoicePlaceOfSupplyDate  = moment(data.invoicePlaceOfSupplyDate).format("DD-MM-YYYY");
    return this.http.post(this.MongoURI + 'errorInv/save', data)
      .pipe(map((resp: any) => resp));
  }
  
  public getAllUserList() {
    return this.http.get(this.BackendURI + 'user/get/allUser')
      .pipe(map((res: any) => res));
  }

  public emailVerification(body){
    return this.http.post(this.BackendURI + 'user/emailVerification',body)
    .pipe(map((resp: any) => resp));
  }
  public saveBatch(batchData) {
    return this.http.post(this.BackendURI + 'batch/save', batchData)
      .pipe(map((resp: any) => resp));
  }

  public getAllBatches(type) {
    return this.http.get(this.BackendURI + 'batch/' + type)
      .pipe(map((resp: any) => resp));
  }

  public updateBatch(batchData) {
    return this.http.post(this.BackendURI + 'batch/update', batchData)
      .pipe(map((resp: any) => resp));
  }

  public deleteBatch(id) {
    return this.http.get(this.BackendURI + 'batch/delete/' + id)
      .pipe(map((resp: any) => resp));
  }

  public getUserReports(reportCategory) {
      return this.http.get(this.backendReportUri+ 'dr/present/' + reportCategory)
        .pipe(map((resp: any) => resp));
  }
  public usedReportCategories(){
    return this.http.get(this.backendReportUri + 'dr/usedReportCategories')
    .pipe(map((resp: any) => resp));
  }
  public getReports(reportCategory) {
    return this.http.get(this.backendReportUri+ 'dr/allReports')
      .pipe(map((resp: any) => resp));
  }
  public processCompletionCheck() {
    return this.http.get(this.backendReportUri+ 'dr/present/get/processCompletionCheck')
      .pipe(map((resp: any) => resp));
}
  getAllRoles() {
    return this.http.get(this.BackendURI + 'role')
      .pipe(map((resp: any) => resp));
  }

}
