<div class="modal fade" id="processModal" tabindex="-1" role="dialog" aria-labelledby="processModalLabel" aria-hidden="true" *ngIf="processData">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="processModalLabel">{{modalHeader}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textcolor">
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Title:<sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input class="full-width bottomborder" #modalServiceTitle="ngModel" [(ngModel)]="processData.title" [onBlurText]="processData.title" />
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Description:<sup style="color:red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <textarea class="full-width" #modalServiceDesc="ngModel" [(ngModel)]="processData.desc" rows="4" [onBlurText]="processData.desc"></textarea>
                    </div>
                    <!--<div class="col-md-2 set-position col-sm-12">-->
                        <!--Remaining:{{displayMaxCharacter}}-->
                    <!--</div>-->
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="col-sm-6 col-sm-12">Color</div>
                        <div class="col-sm-6 col-sm-12">
                            <input class="full-width" [(colorPicker)]="processData.color"
                                   [style.background]="processData.color"  [cpPositionOffset]="'50%'" [cpSaveClickOutside]="true"
                                   [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"/>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="col-sm-6 col-sm-12">Icon:<sup style="color: red">*</sup> </div>
                        <div class="col-sm-6 col-sm-12">
                            <div class="input-group">
                                <span class="input-group-addon"><i [ngClass]="processData.icon"></i></span>
                                <input type="text" name="iconCss" class="form-control"
                                       [iconPicker]="processData.icon"
                                       [ipPosition]="'bottom'"
                                       [ipWidth]="'250px'"
                                       [ipPlaceHolder]="'Choose an icon'"
                                       [ipFallbackIcon]="fallbackIcon"
                                       (iconPickerSelect)="onIconPickerProcessSelect($event)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteProcessModalData(editProcessIndex)">Delete</button>
                <button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="saveProcessModalData(processData,editProcessIndex)">Save changes</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="servicesModal" tabindex="-1" role="dialog" aria-labelledby="servicesModalLabel" aria-hidden="true" *ngIf="serviceData">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="servicesModalLabel">{{modalHeader}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textcolor">
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Title:<sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input class="full-width bottomborder" #modalServiceTitle="ngModel" [(ngModel)]="serviceData.title" [onBlurText]="serviceData.title" />
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-2 col-sm-12">
                        Description:<sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <textarea class="full-width" #modalServiceDesc="ngModel" [(ngModel)]="serviceData.desc" rows="4" [onBlurText]="serviceData.desc"></textarea>
                    </div>
                    <!--<div class="col-md-2 set-position col-sm-12">-->
                        <!--Remaining:{{displayMaxCharacter}}-->
                    <!--</div>-->
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="col-sm-6 col-sm-12">Color</div>
                        <div class="col-sm-6 col-sm-12">
                            <input class="full-width" [(colorPicker)]="serviceData.color"  [cpPosition]="'bottom'"
                                   [style.background]="serviceData.color"  [cpPositionOffset]="'50%'" [cpSaveClickOutside]="true"
                                   [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"/>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="col-sm-6 col-sm-12">Icon:<sup style="color:red">*</sup> </div>
                        <div class="col-sm-6 col-sm-12">
                            <div class="input-group">
                                <span class="input-group-addon"><i [ngClass]="serviceData.icon"></i></span>
                                <input type="text" name="iconCss" class="form-control"
                                       [iconPicker]="serviceData.icon"
                                       [ipPosition]="'bottom'"
                                       [ipWidth]="'250px'"
                                       [ipPlaceHolder]="'Choose an icon'"
                                       [ipFallbackIcon]="fallbackIcon"
                                       (iconPickerSelect)="onIconPickerServiceSelect($event)"/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteServiceModalData(editServiceIndex)">Delete</button>
                <button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="saveServiceModalData(serviceData,editServiceIndex)">Save changes</button>
            </div>
        </div>
    </div>
</div>

<section id="section4" [style.background]="pageContents.appTheme" [style.color]="pageContents.textColor">
    <div class="container">
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
      <!--  <div class="line-title">
            <div class="row">
                <div class="col-sm-11">
                    <h3 class="title">{{title}}</h3>
                </div>
                <div *ngIf="applicationMode === 'C'" class="col-sm-1 skill-icon no-right-padding center-align"><button container="body" placement="left" ngbTooltip="Add service" class="transparent-btn circle-btn add-service" (click)="addServices()"><i class="fa fa-plus-square-o" aria-hidden="true"></i></button></div>
            </div>
            <div *ngIf="applicationMode === 'C'" class="col-md-12 top-content">

                <div class="col-md-11 nopadding col-sm-12">
                    <div id="summernoteServices" class="summernote"></div>
                </div>
                <div class="col-md-1 edit-btn col-sm-12 ">
                    <button container="body" placement="left" ngbTooltip="Edit Content" class="more-btn edit-service-text" (click)="editContent('services')" *ngIf="!contentEdit.editServices" style="float: right"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                    <button container="body" placement="left" ngbTooltip="Save Content" class="more-btn edit-service-text" (click)="saveContent('services')" *ngIf="contentEdit.editServices" style="float: right"><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
                    <button container="body" placement="left" ngbTooltip="Close" class="more-btn edit-service-text" (click)="cancelContent('services')" *ngIf="contentEdit.editServices" style="float: right"><i class="fa fa-ban" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="col-md-12 top-content col-sm-12" [innerHTML]="pageContents.processContent | escapeHtml" *ngIf="validateDataAndMode(pageContents.processContent)">
            </div>
        </div>
        <div class="row services-boxes" *ngIf="pageContents.serviceSet && pageContents.serviceSet.length>0" style="padding: 10px">
            <div class="col-sm-6 col-xs-12"  *ngFor="let item of pageContents.serviceSet; let idx = index;">
                <div class="web-dev">
                    <div *ngIf="applicationMode === 'C'; else loader" container="body" placement="top" ngbTooltip="Click to edit service" class="service-icon" [style.background]="item.color" (click)="editService(item, idx)">
                        <i class="{{item.icon}} fa-5x"></i>
                    </div>
                    <ng-template #loader>
                        <div class="service-icon" [style.background]="item.color">
                            <i class="{{item.icon}} fa-5x"></i>
                        </div>
                    </ng-template>
                    <div class="services-desc" style="padding-right: 0px !important;">
                        <h5 [style.color]="item.color" (click)="contactData(item.title)">{{item.title}}</h5>
                      <div  slimScroll
                        background="#333"
                        opacity="0.6"
                        transition=".8"
                        position="right"
                        height="90px"
                        width="auto"
                        border-radius="5px"
                        size="7px" color="black" allowPageScroll="true" railVisible="true">
                        <p class="set-desc">
                            {{item.desc}}
                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="line-title">
            <div class="row">
                <div class="col-sm-12">
                    <h3 *ngIf="langSetKey != 'arb'"  class="title" style="color: #f47435;">How It Works</h3>
                    <h3 *ngIf="langSetKey === 'arb'" [ngClass]="{'align': langSetKey === 'arb'}" class="title" style="color: #f47435;">كيف تعمل</h3>
                </div>
                <div *ngIf="applicationMode === 'C'" class="col-sm-1 skill-icon no-right-padding center-align"><button container="body" placement="left" ngbTooltip="Add process" class="transparent-btn circle-btn add-process"  (click)="addProcess()"><i class="fa fa-plus-square-o" aria-hidden="true"></i></button></div>
            </div>
            <div class="col-md-12 top-content col-sm-12" *ngIf="applicationMode === 'C'">

                <div class="col-md-11 nopadding col-sm-12">
                    <div id="summernoteProcess" class="summernote"></div>
                </div>
                <div class="col-md-1 edit-btn col-sm-12 ">
                    <button ngbTooltip="Edit Content" container="body" placement="left" class="more-btn" (click)="editContent('process')" *ngIf="!contentEdit.editProcess" style="float: right"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                    <button container="body" placement="left" ngbTooltip="Save Content" class="more-btn" (click)="saveContent('process')" *ngIf="contentEdit.editProcess" style="float: right"><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
                    <button container="body" placement="left" ngbTooltip="Close" class="more-btn" (click)="cancelContent('process')" *ngIf="contentEdit.editProcess" style="float: right"><i class="fa fa-ban" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
        <div class="row works">
            <div class="col-md-3 col-sm-6 col-xs-6 research" *ngFor="let item of pageContents.processSet; let idx = index;">
                <div ngbTooltip="Click to edit process" container="body" placement="top" *ngIf="applicationMode==='C'; else loader2" class="div-process" [style.background]="item.color" (click)="editProcess(item, idx)">
                    <i class="{{item.icon}} fa-3x"></i>
                </div>
                <ng-template #loader2>
                    <div class="div-process" [style.background]="item.color">
                        <i class="{{item.icon}} fa-3x"></i>
                    </div>
                </ng-template>
                <h5 [style.color]="item.color">{{item.title}}</h5>
                <div slimScroll
                background="#333"
                opacity="0.6"
                transition=".8"
                position="right"
                height="200px"
                width="auto"
                border-radius="5px"
                size="7px" color="black" allowPageScroll="true" railVisible="true">
                <p [ngClass]="{'position arbPara': langSetKey === 'arb'}" [innerHTML]="item.desc | escapeHtml" class="set-desc">{{item.desc}}</p>
                </div>
            </div>
        </div>
    </div>
</section>