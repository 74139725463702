import {Component, Injectable, Inject, NgZone, forwardRef} from '@angular/core';
import {Headers, Http, HttpModule, Response} from '@angular/http';
/* Import App Config */
import {APP_CONFIG, IConfig} from '../appConfig/Iconfig';

import {isNullOrUndefined} from 'util';
import {CustomAuthHttp} from './local-authhttp/local-authhttp';
import {JwtHelperService} from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';

let aws = require('aws-sdk');
var moment = require('moment');
var messages = require('../../src/appConfig/messages.json');

@Injectable()
export class DocumentService {
    private MongoURI: string;
    public serverImgUrl: string;
    private JwtHelperService;
    public s3Message = messages.s3; // This variable is used to get s3 error & success messages
    // from json
    public regularMessage = messages.regular; // This variable is used to get regular error &
    // success
    // messages from json
    public mongoMessage = messages.mongo; // This field will be used for getting mongo errors

    constructor(zone: NgZone, private http: CustomAuthHttp,
                @Inject(APP_CONFIG) private _Iconfig: IConfig, JwtHelperService: JwtHelperService
    ) {
        this.MongoURI = _Iconfig.mongoUrl;
        this.serverImgUrl = '';
        this.JwtHelperService = JwtHelperService;

    }

    // Client Side Approach

    s3Operation(data: any, action: string) {
        let token = sessionStorage.getItem('jwt');
        let s3Data = this.JwtHelperService.decodeToken(token);

        if (action === 'download') {
            const self = this;
            let fileName = data.fileName;
            aws.config.update({accessKeyId: s3Data.accessKeyId, secretAccessKey: s3Data.secretAccessKey});
            let s3 = new aws.S3({
                endpoint: s3Data.endpoint,
                signatureVersion: s3Data.signatureVersion,
                region: s3Data.region
            });
            let params = {Bucket: s3Data.Bucket, Key: fileName};
            s3.getObject(params, function (s3Error, s3Resp) {
                if (!s3Error) {
                    let s3res = {
                        fileContent: s3Resp.Body.toString('base64'),
                        contentType: s3Resp.ContentType
                    };
                    if (s3res.fileContent) {
                        self.downloadURI(s3res, fileName);
                    }
                }
            });
        } else if (action === 'load') {
            const self = this;
            let fileName = data.fileName;
            aws.config.update({accessKeyId: s3Data.accessKeyId, secretAccessKey: s3Data.secretAccessKey});
            let s3 = new aws.S3({
                endpoint: s3Data.endpoint,
                signatureVersion: s3Data.signatureVersion,
                region: s3Data.region
            });
            let params = {Bucket: s3Data.Bucket, Key: fileName};
            let extension = fileName.split('.').pop().toLowerCase();
            s3.getObject(params, function (s3Error, s3Resp) {
                if (!s3Error) {
                    let s3res = {
                        fileContent: s3Resp.Body.toString('base64'),
                        contentType: s3Resp.ContentType
                    };
                    let contentType = {gif: 'gif', png: 'png', jpeg: 'jpeg', jpg: 'jpeg'};
                    let mimeType = contentType[extension];
                    self.serverImgUrl = 'data:image/' + mimeType + ';base64,' + s3res.fileContent;
                } else {
                    self.serverImgUrl = '';
                }
            });
        }

    }

    downloadImageFroms3(data: any, action: string, callback) {
        const self = this;
        let fileName = data.fileName;
        let token = sessionStorage.getItem('jwt');
        let s3Data = self.JwtHelperService.decodeToken(token);
        aws.config.update({accessKeyId: s3Data.accessKeyId, secretAccessKey: s3Data.secretAccessKey});
        let s3 = new aws.S3({
            endpoint: s3Data.endpoint,
            signatureVersion: s3Data.signatureVersion,
            region: s3Data.region
        });
        let params = {Bucket: s3Data.Bucket, Key: fileName};
        let extension = fileName.split('.').pop().toLowerCase();
        s3.getObject(params, function (s3Error, s3Resp) {
            if (!s3Error) {
                let s3res = {
                    fileContent: s3Resp.Body.toString('base64'),
                    contentType: s3Resp.ContentType
                };
                var contentType = {gif: 'gif', png: 'png', jpeg: 'jpeg', jpg: 'jpeg'};
                var mimeType = contentType[extension];
                self.serverImgUrl = 'data:image/' + mimeType + ';base64,' + s3res.fileContent;
                callback(true, '', self.serverImgUrl);
            } else {
                callback(false, s3Error, '');
            }
        });
    }

    downloadURI(content: any, name: any) {
        if (!isNullOrUndefined(name) && name !== '') {
            let link = document.createElement('a');
            link.download = name;
            link.href = 'data:' + content.contentType + ';base64,' + content.fileContent;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            delete link.href;
            delete link.download;
        } else {

        }
    }

    public uploadFileContent(fileRespData, fileName, userId, subId, gstin, fileType, s3Config, callback) {

        /*
         Description : This function is used to upload files to S3.
         fileRespData is a buffer data that comes out from file
         */
        var self = this;
        if (!isNullOrUndefined(subId) && subId !== '') {
            if (!isNullOrUndefined(fileType) && fileType !== '') {
                let currentDate = new Date();
                var year = currentDate.getFullYear();
                var day = currentDate.getDate();
                var month = currentDate.getMonth();
                var time = moment().format('HH:mm:ss');
                var key = 'managemyvat/' + subId + '/' + fileType + '/' + year + '/' + month + '/' + day + '/' + time + '-' + fileName;
                var data = fileRespData.split('base64,').pop();
                var base64data = new Buffer(data, 'base64');
                fileName = fileName;
                aws.config.update({accessKeyId: s3Config.accessKeyId, secretAccessKey: s3Config.secretAccessKey});
                var s3 = new aws.S3({
                    endpoint: s3Config.endpoint,
                    signatureVersion: s3Config.signatureVersion,
                    region: s3Config.region
                });
                s3.putObject({
                    Bucket: s3Config.Bucket,
                    Key: key,
                    Body: base64data,
                    ACL: s3Config.ACL
                }, function (awsError, awsResp) {
                    if (!awsError) {
                        var awsFilePath = key;
                        var bodyData = {
                            'fileName': fileName,
                            'awsFilePath': awsFilePath,
                        };
                        self.http.post(self.MongoURI + 'aws/save', JSON.stringify(bodyData)).pipe(map((resp: any) => resp)).subscribe(
                            data => {
                                if (data) {
                                    callback(true, '', awsFilePath);
                                }
                            },
                            err => {
                                callback(false, err, '');
                            },
                            () => {
                                callback(false, awsError, []);
                            }
                        );
                    } else {
                        callback(false, awsError, []);
                    }
                });
            } else {
                callback(false, self.s3Message.fileTypeError, "");
            }
        } else {
            callback(false, self.regularMessage.subId, []);
        }
    }


    convertAndUploadToS3(file, callback) {

        const self = this;
        let reader = new FileReader();
        let token = sessionStorage.getItem('jwt');
        let user = self.JwtHelperService.decodeToken(token);
        let userId = user.userId;
        let subscriberId = user.subscriberId;
        let gstIn = user.gstIn;
        let accessKeyId = user.accessKeyId;
        let secretAccessKey = user.secretAccessKey;


        reader.readAsDataURL(file);
        reader.onload = function () {
            var base64 = reader.result;
            let fileType = 'banner';
            var s3Config = {
                'accessKeyId': user.accessKeyId,
                'secretAccessKey': user.secretAccessKey,
                'endpoint': user.endpoint,
                'signatureVersion': user.signatureVersion,
                'region': user.region,
                'Bucket': user.Bucket,
                'ACL': user.ACL,
            };

            if (!isNullOrUndefined(userId) && userId !== '') {
                if (!isNullOrUndefined(accessKeyId) && accessKeyId !== '' && !
                        isNullOrUndefined(secretAccessKey) && secretAccessKey !== '') {


                    self.uploadFileContent(base64, file.name, user.userId, user.subscriberId, user.gstIn, fileType, s3Config,
                        function (status, error, awsFilePath) {
                            if (error) {
                                callback(false, error, '');
                            }else
                            if (status) {
                                callback(true, '', awsFilePath);

                            }else {
                                callback(false, error, '');
                            }

                        });
                } else {
                    callback(false, 'S3 Access Error...Please upload file later', '');
                }
            } else {
                callback(false, 'No user found', '');
            }
        };

        reader.onerror = function (error) {
            callback(false, 'Failed to upload a file', '');
        };
    }

}

