import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgbModule, NgbTooltipModule, NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AlertModule} from "ngx-bootstrap";
import {LoginDashBoardComponent} from '../page/logindashboard/logindashboard.component';
import {DynamicModule} from '../dynamic/dynamic.module';
import {PageLaunchComponent} from './launch/launch.component';
import {Broadcaster} from '../../services/sharable.service';
import {PageRoute} from './page.routes';
import {PageComponent} from './layout/page.component';
import {PageLoginComponent} from './login/login.component';
import { ThankYouPageComponent } from './thankyouPage/thankyouPage.component';
import {ResetPasswordPageComponent} from "./resetPasswordPage/resetPasswordPage.component";
import {TokenExpModelComponent} from './tokenExipred/tokenExpModel.component';
import { ConfirmationService } from 'primeng/api';
import { ShareModule } from '../share/share.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  imports:
    [
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.rotatingPlane,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // WebModule,
        DynamicModule,
        NgbModule,
        PageRoute,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // NgbTooltipModule.forRoot(),
        NgbTooltipModule,
        ShareModule,
        HttpClientModule,
        AlertModule
    ],
  declarations:
    [
        LoginDashBoardComponent,
        PageComponent,
        PageLaunchComponent,
        PageLoginComponent,
        ThankYouPageComponent,
        ResetPasswordPageComponent,
        TokenExpModelComponent
    ],
  providers:
    [
        Broadcaster,
        NgbTooltipConfig,
        ConfirmationService
    ],
  exports:
    [
      PageLoginComponent,
      ResetPasswordPageComponent,
    ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PageModule {
  constructor() {}
}
