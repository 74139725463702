import { Injectable, Inject } from '@angular/core';
import { isNullOrUndefined } from 'util';


@Injectable()
export class AppConstants {
  private toasterMessage: any;
  public validOrgForm = {
    mobile: false,
    owner: false,
    gstin: false,
    area: false,
    street: false,
    email: false,
    countryName: false,
    name: false,
    state: false,
    currency: false
  };

  public ETaxCode: string = 'T';
  public standardTaxCode: string = 'SR';
  public exemptTaxCode: string = 'EX';
  public zeroTaxCode: string = 'ZR';
  public reverseChargeTaxCode: string = 'RC';
  public notApplicableTaxCode: string = 'NA';
  public adMainComment = ['facebook', 'linkedIn'];
  public replyToMain = ['facebook', 'linkedIn', 'playstore', 'twitter'];
  public replyToReply = ['facebook', 'twitter'];
  public crawlTableHeader = ['Source', 'Summary', 'Item Name', 'Quantity', 'Tender Id', 'Organisation Name', 'Department Name', 'Department Address', 'Start Date', 'End Date', 'Published Date'];
  public crawlTableColumns = ['source', 'Summary', 'ItemName', 'Quantityrequired', 'Tender_Id', 'OrganisationName', 'DepartmentName', 'DepartmentAddress', 'StartDate', 'EndDate', 'e_publishedDate'];
  public newsTableHeader = ['News', 'Published', 'Source'];
  public ocrTrueHead = ['Bounding_Text'];
  public ocrHead = ['Model', 'leftX', 'rightX', 'topY', 'bottomY'];
  public newsTableColumns = ['news', 'publishDate', 'source'];
  public events = [
    'blur',
    'focus',
    'focusin',
    'focusout',
    'resize',
    'scroll',
    'click',
    'dblclick',
    'mousedown',
    'mouseup',
    'mousemove',
    'mouseover',
    'mouseout',
    'mouseenter',
    'mouseleave',
    'change',
    'select',
    'submit',
    'keydown',
    'keypress',
    'keyup',
    'error'
  ];

  public mobilePattern: any = [/^0?(?:50|52|55|56|54|58)\d{7}$/,
    /^[0-9]{2}\d{8}$/,
    /^0?(?:83|90|50|55|52|56|58)\d{7}$/, /^(\\+\\d{1,3}[- ]?)?\\d{8}$/];
  public productsContents = {
    "productFilteList": [
      {
        'id': 'all',
        'name': 'All'
      },
      {
        'id': 'products',
        'name': 'Products'
      },
      {
        'id': 'service',
        'name': 'Services'
      },
      {
        'id': 'Excise',
        'name': 'Excise'
      }
    ]
  };
  public customData = {
    const: '',
    fValue: [],
    rValue: [],
    cnt: 1,
    items: [],
    exp: '',
    ruleName: '',
    truthTable: '',
    tempConst: '',
    tempFValue: [],
    tempRValue: [],
    tempCnt: 1,
    tempItems: [],
    tempExp: '',
    tempTruthTable: [],
    tempRuleName: '',
    tt: false,
    dateFormat: '',
    tempDateFormat: '',
    tempMyObj: '',
    tableName:[],
  };

  public filterList = [
    { id: 'SelectDoc', name: 'Select Document' }
  ]
  
  public departmentList = {
    'departmentData': [
      {
        'id': 'all',
        'name': 'All'
      },
      {
        'id': 'it',
        'name': 'IT'
      }
    ]
  };

  public languages = [
    {label:"Punjabi",value:"pa"},
    {label:"Bengali",value:"bn"},
    {label:"English",value:"en"},
    {label:"Tamil",value:"ta"},
    {label:"Malayalam",value:"ml"},
    {label:"Telugu",value:"te"},
    {label:"Hindi",value:"hi"},
    {label:"Kannada",value:"kn"},
    {label:"Marathi",value:"mr"},
    {label:"Gujarati",value:"gu"},
    {label:"Odia",value:"or"},
    {label:"Assamese",value:"as"},
    {label:"Konkani",value:"kok"},
    {label:"Sanskrit",value:"sa"},
    {label:"Kashmiri",value:"ks"},
    {label:"Nepali",value:"ne"},
    {label:"Urdu",value:"ur"},
    {label:"Sindhi",value:"sd"},
    {label:"Sinhala",value:"si"},
    {label:"Dogri",value:"doi"},
    {label:"Maithili",value:"mai"},
    {label:"Manipuri",value:"mni"},
    {label:"Bodo",value:"brx"},
    {label:"Santali",value:"sat"},
    {label:"Lushai",value:"lus"},
    {label:"Ngungwel",value:"njz"},
    {label:"Panim",value:"pnr"},
    {label:"Khasi",value:"kha"},
    {label:"Garo",value:"grt"},
    {value: 'fr-BE', label: 'French'},
    {value: 'es-AR', label: 'Spanish'},
    {value: 'ru-RU', label: 'Russian'},
    {value: 'de-AT', label: 'German'},
    {value: 'pt-BR', label: 'Portuguese'},
    {value: 'ja-JP', label: 'Japanese'},
    {value: 'tr-TR', label: 'Turkish'},
    {value: 'vi-VN', label: 'Vietnamese'},
    {value: 'ko-KR', label: 'Korean'},
    {value: 'ar-DZ', label: 'Arabic'},
    {value: 'it-IT', label: 'Italian'}


  ];

  public customerData = {
    'customerFindOption': [
      {
        'id': 'name',
        'name': 'Name'
      },
      {
        'id': 'customerCode',
        'name': 'Customer Code'
      }
    ]
  };

  public invoiceFilters = [
    {
      label: 'Invoice Number',
      value: 'Invoice Number'
    },
    {
      label: 'Counterparty Name',
      value: 'Counterparty Name'
    },
    {
      label: 'Counterparty Id',
      value: 'Counterparty Id'
    },
    {
      label: 'Invoice Date',
      value: 'Invoice Date'
    },
    {
      label: 'Supply Date',
      value: 'Supply Date'
    }
  ];

  public uploadInvoiceStatus = [{
    'invType': 'Sale',
    'isConsolateInv': false,
    'invoiceStatus': 'valid'
  },
  {
    'invType': 'Purchase',
    'isConsolateInv': false,
    'invoiceStatus': 'valid'
  },
  {
    'invType': 'Import',
    'isConsolateInv': false,
    'invoiceStatus': 'valid'
  },
  {
    'invType': 'Export',
    'isConsolateInv': false,
    'invoiceStatus': 'valid'
  },
  {
    'invType': 'Credit',
    'isConsolateInv': false,
    'invoiceStatus': 'valid'
  },
  {
    'invType': 'Delivery Challan',
    'isConsolateInv': false,
    'invoiceStatus': 'valid'
  }
  ];

  public customInvformats = [
    { name: "Text: eg. INV", key: "customText", "value": "" },
    { name: "Serial No.: 001", key: "serialNumber", "value": "" }];


  public inviceMonths = [
    { name: "Number Format: 1", key: "monthNumber", "value": "1" },
    { name: "Word Format: Jan", key: "monthText", "value": "Jan" }];

  public invoiceYears = [
    { name: "Financial year: 201819", key: "financialYear", "value": "2018-19" },
    { name: "Calendar year: 2018", key: "calenderYear", "value": "2018" }];

  public transactionTypes = [
    { label: 'All', value: 'ALL' },
    { label: 'Sale', value: 'SALE' },
    { label: 'Purchase', value: 'PURCHASE' },
    { label: 'Income', value: 'INCOME' },
    { label: 'Expense', value: 'EXPENSE' },
    { label: 'Receipt', value: 'RECEIPT' },
    { label: 'Payment', value: 'PAYMENT' },
    { label: 'JV', value: 'JV' },
  ];
  public validForm = {
    'process-name': false, 'description': false, 'process-type': false,
    'department': false, 'lifecycle': false, 'user-type': false
  };

  public validBotForm = { 'bot-name': false, 'bot-category': false, 'bot-description': false };

  public dataTypeBots = [
    { 'label': 'Number', 'value': 'Number' },
    { 'label': 'Email', 'value': 'Email' },
    { 'label': 'Text', 'value': 'Text' },
    { 'label': 'Password', 'value': 'Password' },
    { 'label': 'Password1', 'value': 'Password1' },
    { 'label': 'URL', 'value': 'URL' },
    { 'label': 'Dropdown', 'value': 'Dropdown' },
    { 'label': 'JSON', 'value': 'JSON' },
    { 'label': 'HTML', 'value': 'HTML' },
    { 'label': 'Any', 'value': 'Any' },
  ];

  public variablesLevelType = [
    { 'label': 'Process Level', 'value': 'process' },
    { 'label': 'Project Level', 'value': 'project' },
    { 'label': 'Task Level', 'value': 'task' },
    { 'label': 'System Level', 'value': 'system' },
    { 'label': 'Org Level', 'value': 'org' }
  ];


  public constUserRoleList = [{ 'label': 'Task Creator', 'value': 'Task Creator' }, {
    'label': 'Process Owner',
    'value': 'Process Owner'
  }, { 'label': 'Direct Supervisor', 'value': 'Direct Supervisor' }];


  public processCategoryList = [
    { key: 'All', value: 'Select Category' },
    { key: 'Social media', value: 'Social media' },
    { key: 'Machine learning', value: 'Machine learning' },
    { key: 'File & Image Handling', value: 'File & Image Handling' },
    { key: 'Third Party Integration', value: 'Third Party Integration' },
    { key: 'Assisted Bots', value: 'Assisted Bots' },
    { key: 'Manual', value: 'Manual' }
  ];

  public validProcessForm = {
    'process-name': false,'display-name':false, 'process-descr': false, 'processType': false,
    'processDept': false, 'lifecycleId': false, 'processUserType': false, 'processOwner': false
  };

  public validTaskForm = { 'task-name': false };


  public botsCategory = [
    { 'label': 'Manual', 'value': 'Manual' },
    { 'label': 'Automatic', 'value': 'Automatic' },
    { 'label': 'Assisted', 'value': 'Assisted' }
  ];
  public statusType = [
    { label: 'OPEN', value: 'OPEN' },
    { label: 'CLOSE', value: 'CLOSE' }
  ];

  public statusKey = [
    { label: 'New', value: 'New' },
    { label: 'Complete', value: 'Complete' },
    { label: 'Review', value: 'Review' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected', value: 'Rejected' },
  ];

  public statusForm = { 'statusKey': false, 'statusValue': false, 'businessType': false };

  public OPROCOLIST = [
    { field: 'branchName', header: 'Plant Name' },
    { field: 'ownerName', header: 'Part Owner' },
    { field: 'description', header: 'Part Description' },
  ];
  public CLOSEPROCOLIST = [
    { field: 'branchName', header: 'Plant Name' },
    { field: 'ownerName', header: 'Part Owner' },
    { field: 'description', header: 'Part Description' },
  ];

  public invoicePatterndata: any = [
    {
      invType: 'Sale',
      preview: 'Purchase0001',
    },
    {
      invType: 'Purchase',
      preview: 'REVSDINV_0001',
    },
    {
      invType: 'Import',
      preview: 'DEBTNOTE_0002',
    },
    {
      invType: 'Export',
      preview: 'CRDT_0003',
    },
    {
      invType: 'Credit',
      preview: 'RECPT_0004',
    },
    {
      invType: 'Payment Voucher',
      preview: 'PAYTVCHR_0005',
    },
    {
      invType: 'DC',
      preview: 'REFNDVCH_0006',
    }
  ];

  public taskDurationMandatory = true;
  public OPENPROJLIST = "OPENPROJLIST";
  public CLOSEPROJLIST = "CLOSEPROJLIST";
  public WARNING: string = 'warning';
  public TITLE_WARNING: string = 'Warning';
  public SUCCESS: string = 'success';
  public REQUIRED: string = 'Required';
  public ERROR: string = 'error';
  public CONFIRM_DELETE_UNIT: string = 'Do you want to delete unit?';
  public COMPOUND_UNIT: string = 'Compound';
  public OPENING_STOCK: string = 'openingstock';
  public OPENING_STOCK_ADDED: string = 'Opening stock added';
  public ADJUSTMENT: string = 'adjustment';
  public LEAKAGE: string = 'leakage';
  public WASTAGE: string = 'wastage';
  public dd_MM_yyyy: string = 'dd/MM/yyyy';
  public CONFIRM_ACCEPT_INV: string = 'Do you want to accept invoice?';
  public CONFIRM_REJECT_INV: string = 'Do you want to reject invoice?';
  public CONFIRM_TRANSFER_STOCK: string = 'Do you want to transfer stock?';
  public CONFIRM_OPENING_STOCK: string = 'Do you want to add opening Stock?';
  public CONFIRM_ADJUSTMENT: string = 'Do you want stock adjustment?';
  public PROCESS_SAVED: string = 'Process saved successfully.';
  public PROCESS_MESSAGE: string = 'Message';
  public PROCESS_WRONG: string = 'somethingWrong';
  public PROCESS_WARNNING: string = 'warning';
  public PROCESS_UPDATE: string = 'Process Update Successfully';
  public ADD_INFO_DATA_FETCH_ERROR: string = 'Unable to get template details!!';
  public ADDITIONAL_INFO: string = 'Additional Information';
  public PROCESS_SUCCESS: string = 'success';
  public ATTACHMENT_SUCCESS: string = 'success';
  public PROCESS_CONTENT: string = 'Do you want to cancel without saving the Process?';
  public PROCESS_TASK_CONTENT: string = 'Do you want to cancel without updating the Process Task?';
  public PROCESS_TRY_AGAIN: string = 'Something Went Wrong Please Try Again';
  public STATUS_EDIT: string = 'Status Edit Not allowed';
  public PROCESS_NOT_FOUND: string = 'not found';
  public PROCESS_ADD: string = 'Add Process';
  public PROCESS_LIST: string = 'Process List';
  public PROCESS_EDIT: string = 'Edit Process';
  public NAV_DASHBOARD: string = 'web.dashboard';
  public NAV_PROCESS_VIEW: string = 'process.process-view';
  public NAV_PROCESS_ADD: string = 'process.process-add';
  public NAV_PROCESS_EDIT: string = 'process.process-edit';
  public DELETE_CONFIRM_MSG: string = 'Do you want to delete this record?';
  public DELETE_BATCH_ERR: string = 'Unable to delete Batch';
  public UPDATE_BATCH_ERR: string = 'Unable to update Batch';
  public CREATE_BATCH_ERR: string = 'Unable to create Batch';
  public PROCESS_DELETE: string = 'Do you want to Delete Process?';
  public PROJECT_DELETE: string = 'Do you want to Delete Project?';
  public PROCESS_TASK_DELETE: string = 'Do you want to Delete Process Task?';
  public PROCESS_DELETE_SUCCESS: string = 'Process deleted successfully';
  public STATUS_DELETE_SUCCESS: string = 'Status deleted successfully';
  public TASK_SEARCH: string = 'search';
  public GET_FILTER_DEFAULT: string = 'default';
  public GET_FILTER_ALL: string = 'All';
  public GET_OPEN: string = 'open';
  public TASK_CONTENT: string = 'Do you want to cancel without saving the Task?';
  public ADD_TASK: string = 'Task';
  public NAV_TASK_VIEW: string = 'task.taskList';
  public NAV_TASK_ADD: string = 'task.taskAdd';
  public NAV_TASK_EDIT: string = 'task.taskEdit';
  public SUBSCRIBED: string = 'Subscribed';
  public PROCESS_TASK_SAVE: string = 'Process Task Save Successfully';
  public PROCESS_SAVE: string = 'Process Save Successfully';
  public TASK_LIST: string = 'Task List';
  public QueueManage: string = 'Queue Management';
  public TASK_LIST_LABEL: string = 'Task List';
  public SHOW_ERROR: string = 'Something Went Wrong Please Try Again';
  public PROCESS_TASK_UPDATE: string = 'Process Task Update Successfully';
  public PROCESS_TASK_DELETE_ATTACHMENT: string = 'Process Task Attachment Deleted Successfully';
  LIST_OF_TASK: string = "taskList";
  PROJECT_LIST: string = "projectList";
  PROCESS_Task_LIST: string = "processList";
  START_PROJECT: string = "startProject";
  public TASK_ATTACHMENT: string = 'Attachment Added Successfully';
  public PROJECT_EDIT: string = 'Update Project Details';
  public ERROR_MESSAGE: string = 'Password does not match';
  public INVALID_MOBILE_ERROR: string = 'Enter Valid Mobile Number';
  public INVALID_OTP_ERROR: string = 'Enter valid OTP';
  public CONFIRM_DOC_DELETE: string = 'Do you want delete document?';
  public GET_DATA: string = 'data:';
  public GET_BASE64: string = ';base64,';
  public TRY_AGAIN: string = 'Error while downloading an image...Please try again later';
  public DOWNLOADING_FAILED: string = 'Document not found. Downloading failed.';
  public ACCESS_ERROR: string = 'S3 Access Error...Please upload file later';
  public GET_ERROR_BASE64: string = 'base64';
  public GET_RESP_BASE64: string = 'base64,';
  public DOCUMENT_CREATE: string = 'Document created successfully';
  public NO_DDOCUMNET: string = 'No data found after Document creation';
  public DOCUMENT_CREATION: string = 'Document creation failed';
  public ERROR_CREATING: string = 'error while creating Document';
  public DOCUMENT: string = 'Document';
  public DUPLICATE_DOC: string = 'Document With same name already exists';
  public DOC_UPLOADED_CUCCESS: string = 'Document uploaded successfully';
  public urlDmsS3: string = 'dms/';
  public SELECTTABLWARN: string = "Please select a table.";
  public SELECTCOLWARN: string = "Only column or row allowed.";
  public NORELATIONFOUND: string = "There is no relation found on selected column";
  public SAVEREPORTERROR: string = "Something went wrong..Please try after sometime";
  public CONFIRM_ACCEPT_EXCELINV: string = 'Do you want to accept invoice with warnings?';
  public CONFIRM_ACCEPT_SELECTEDINV: string = 'Do you want to accept current selected invoice with warnings?';
  public CONFIRM_ACCEPT_PROCEED: string = 'Do you want to proceed?';
  public CONFIRM_EMAIL_ALL_SEND: string = 'Do you want to email all document?';
  public CONFIRM_EMAIL_SELECTED_SEND: string = 'Do you want to email selected document?';
  public CONFIRM_ACCEPT_ALLPAGE: string = 'Do you want to accept all pages including next Pages?';
  public CONFIRM_DELETE_EXCELINV: string = 'Do you want to delete invoice Item?';
  public CONFIRM_DELETE_UPLOADFILE: string = 'Do you want to delete File?';
  public CONFIRM_UPDATE_EXCELINV: string = 'Do you want to update invoice Item?';
  public CONFIRM_UPDATE_EXCELProd: string = 'Do you want to update product Item?';
  public CONFIRM_UPDATE_EXCELInven: string = 'Do you want to update inventory Item?';
  public STATUS_DELETED_SUCCESSFULLY: string = 'Status deleted successfully';
  public CONFIRM_STATUS_DELETE: string = 'Do you want to delete status?';
  public CONFIRM_lIFECYCLE_DELETE: string = 'Do you want to delete lifecycle?';
  public TAX_RETURN: string = 'Please file all previous return periods.';
  public Manage_Users: string = 'Manage Users';
  public Add_Subscriber: string = 'Add Subscribers';
  public Update_Subscriber: string = 'Update Subscribers';
  public CONFIRM_SAVE_IMPORTINV: string = 'This import will be considered as not going through customs as import permit number is blank. Do you want to continue?';
  public DURATION_LIMIT: number = 1440;
  public DAILY: string = 'daily';
  public INTERVAL_ONE: number = 1;
  public REVIEW: string = 'Review';
  public APPROVED_TASK: string = 'Do you want to Approve this Task?';
  public REJECT_TASK: string = 'Do you want to Reject this Task?';
  public APPROVED_SUCCESS: string = 'Approved successfully!!';
  public APPROVED_REJECT: string = 'REJECT TASK!!';
  public APPROVEDTASK: string = 'APPROVE';
  public CONFIRM_UPDATE_SCANINV: string = 'Do you want to update Scan Copy?';
  public Confirm_Tax_Group: string = 'Are Your Sure You Want Change Tax Group?';
  public Rquired_Tax_Group: string = 'Please Remove Child Tax Group Before Changing Tax Group';
  public Confirm_Parent_Organisation: string = 'Are Your Sure You Want Change Parent Organization?';
  public confirm_unsaved_changes: string = 'Unsaved changes will be lost. Are you sure you want to continue ?';
  public Confirm_Trn_Change: string = 'Are Your Sure You Want Change Organisation TRN This Can Affect Your Sub-Organisations?';
  public PROJECT_MANAGEMENT: string = 'Project List';
  public CONTACT_MANAGEMENT: string = 'Contact Management';
  public CONFIGURE_MANAGEMENT: string = 'Configuration Management';
  public CONFIGURE_Error_Master: string = 'Upload Error Master';
  public Confirm_Delete_Report: string = 'Do you want to Delete Report?';
  public Confirm_Delete_MetaData: string = 'Do you want to Delete MetaData?';
  public Unable_to_download: string = 'Unable to Download Excel!';
  public Error_while_Fetching_org: string = 'Error while fetching organisations / TRN.';
  public Process_Not_Found: string = 'Process Not Found';
  public Bots_Not_Found: string = 'Bots not found';
  public Notification_Not_Found: string = 'Notification Not Found';
  public Additional_Info_Not_Found: string = 'Additional info not found';
  public Output_Parameter_Requited: string = 'Output Parameter Requited!!';
  public Output_DataType_Requited: string = 'Output DataType Requited!!';
  public Output_varDesc_Requited: string = 'Output Variable Description Requited!!';
  public Input_varDesc_Requited: string = 'Input Variable Description Requited!!';
  public Input_Parameter_Requited: string = 'Input Parameter Requited!!';
  public Input_DataType_Requited: string = 'Input DataType Requited!!';
  public Expression_builder_not_allowd: string = 'Expression builder not allowed';
  public DELETE: string = 'Deleted Successfully';
  public AccessControl: string = 'Access Control Already Present for this User!!';
  public FILEREM: string = 'File removed Successfully';
  public INVALID_EMAIL = 'Please Enter Valid Email';
  public lifecycleArray: any;
  public countryUAE: string = 'UNITED ARAB EMIRATES';
  public RestrictAuthLabel = {
    'auth': 'Restrict Authorisation',
    'unAuth': 'unauthorized'
  };
  public Error_Msg_Org: string = 'All fields are required.';
  public Error_Year_Org: string = 'To Year should be greater then From Year';
  public Error_Month_Org: string = 'To Month should be greater then From Month';
  public Error_Tin_Org: string = 'TIN is Required';
  public SUBPROCESS_DELETE: string = 'Do you want to Delete Sub-Process?';
  public ENTER_ALL: string = 'Please Enter All Details';
  public Success_Contact_Upload: string = 'File Uploaded For Processing! Please come back after some time to know the status of uploaded Contacts!';
  public CONFIRM_TRANSACTION_SAVE: string = 'Do You Want To Save Transaction?';
  public UNSAVED_TRANSACTION: string = 'Unsaved Changes Will Be Lost! Do You Want To Continue?';
  public STATUS_DELETE: string = 'Do you want to Delete Status?';
  public WARNING_RECEIPT_COUNT: string = 'Total Amount is Greater than Pending Amount';
  public WARNING_CREDIT: string = 'Credit and Debit Amount must be Equal';
  public CONFIRM_PROCESS_DELETE: string = 'Do You Want to Delete Process?'
  public CONFIRM_BOT_DELETE: string = 'Do You Want to Delete Bot?'
  public CONFIRM_FINALISE: string = 'You Cannot Amend Or Update Further...Are You Sure You Want To Finalize?';
  public CHECK_ERROR_INVOICE: string = 'There are some error invoices for this period. Do you still want to proceed ?';
  public PORTFOLIO_CODE: string = 'Portfolio Code Must Be Different';
  public billPeriods: any;
  public DELETE_PORTFOLIO: string = 'Portfolio Code Already In Use';
  public CONFIRM_DELETE_ALL: string = 'Do You Want Delete Selected Bots?'
  public AWS_PATH: string = 'https://s3.ap-south-1.amazonaws.com/adhigamtesting/';
  public EMPTY_MSG: string = 'You don\'t have any return tax details yet';
  public UPLOAD_DELETE: string = 'Do you want to Delete All Invoices?';
  public CONFIRM_FINALISE_VISIBLE = false;
  public SELECT_ALL_INVOICES: string = 'Do You Want To Select Invoices On All Pages?';
  public FILE_PROCESSING: string = 'Your Request Is Being Processed.Please Wait For Sometime';
  public IMAGE_EXT: string = 'data:image/';
  public PDF_EXT: string = 'data:application/';
  public Content_Type = {
    gif: 'gif',
    png: 'png',
    jpeg: 'jpeg',
    jpg: 'jpeg',
    tiff: 'tiff'
  }
  public authLabel = {
    'label1': 'Restrict Authorisation',
    'label2': 'unauthorized'
  };
  public pageContents = {
    "aboutContent": "<p><span style=\"font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: rgb(255, 255, 255);\"><p style=\"font-weight: bold;font-size: large;\"><b>Manage My VAT </b></p>We help you manage your VAT effortlessly. Our Software helps you to auto prepare your tax returns & file them on Federal government portal. Value Added Tax (or VAT) is an indirect tax imposed on all goods and services that are bought and sold by businesses, with a few exceptions. VAT is applied in more than 160 countries around the world as a reliable source of revenue for state budgets. VAT is imposed at each stage of the supply chain from the production and distribution to the final sale of the good or service. All GCC countries are committed to impose VAT with effect from early 2018. VAT will be introduced at a standard rate of 5%.<br>We let you take care of your business while we take care of your taxes. </span><br></p>",
    "contactData": {
      "contactAddress": "Gulf Towers Suite No 105, B Block Ummm Hurair 2, Oud Mehta, Dubai",
      "contactMobile": "+97142627388 , +971503581633",
      "contactEmail": "info@mitraagcc.com"
    },
    "processSet": [
      {
        "color": "#606062",
        "icon": "fa fa-user-plus",
        "desc": "<p><ul><li>Fill in your registration details to subscribe to ManageMyVAT & proceed to Payment</li></ul></p>",
        "title": "Registration"
      },
      {
        "color": "#606062",
        "icon": "fa fa-cog",
        "desc": "<p><ul><li>Do a Complete Organisation Setup like – Multiple Branches, Bulk Customer & Product Upload, Invoice template customization – You are now ready for a quick start towards VAT Compliance</li></ul></p>",
        "title": "Set-Up"
      },
      {
        "color": "#606062",
        "icon": "fa fa-indent",
        "desc": "<p><ul><li>Easily create Professional VAT Invoices or Integrate ManageMyVAT with your existing ERP/Accounting Systems with our Special Integration Tools</li></ul></p>",
        "title": "Easy Integration & Invoicing"
      },
      {
        "color": "#606062",
        "icon": "fa fa-arrows",
        "desc": "<p><ul><li>Auto-populated Return Form Creation will ensure 0 manual error for filing & enable you to Concentrate on your Business</li></ul></p>",
        "title": "Compliance"
      }
    ],
    "galleryList": [
      {
        "url": "",
        "title": "Angular"
      },
      {
        "title": "AWS",
        "url": ""
      }
    ],
    "serviceSet": [
      {
        "color": "#11d7db",
        "icon": "fa fa-chrome",
        "desc": "Web development is a broad term for the work involved in developing a dynamic site for the Internet or an intranet.",
        "title": "WEB DEVELOPMENT"
      },
      {
        "color": "#11d7db",
        "icon": "fa fa-area-chart",
        "desc": "Graphic design is the process of visual communication and problem-solving using one or more of typography, photography and illustration.",
        "title": "GRAPHIC DESIGN"
      },
      {
        "color": "#11d7db",
        "icon": "fa fa-paint-brush",
        "desc": "Web design encompasses many different skills and disciplines in the production and maintenance of websites.",
        "title": "WEB DESIGN"
      },
      {
        "color": "#11d7db",
        "icon": "fa fa-line-chart",
        "desc": "Search engine optimization is the process of affecting the online visibility of a website or a dynamic page in a dynamic search engine's unpaid results—often referred to as \"natural\", \"organic\", or \"earned\" results. ",
        "title": "SEO"
      }
    ],
    "expSet": [
      {
        "color": "#90d0e4",
        "web": "http://www.gibots.com/",
        "company": "GIBOTS",
        "year": "2007-2013",
        "position": "WEB DESIGNER"
      },
      {
        "color": "#90d0e4",
        "web": "http://www.gibots.com/",
        "company": "Roots Innovation Labs",
        "year": "2013-2017",
        "position": "ANGULAR DEVELOPER"
      }
    ],
    "skillSet": [
      {
        "show": false,
        "color": "#606062",
        "val": 100,
        "desc": "<p><ul><li>Preformatted Easy to Create Professional Invoices now at your fingertips</li><li>Download & Upload Invoices in Bulk</li><li>Manage Invoicing on Mobile/Tab & Desktops</li><li>Email/SMS Invoice to Clients</li><li>Customize Invoices with Logo, T&C & Page Layouts</li></ul></p>",
        "title": "VAT Compliant Invoicing"
      },
      {
        "show": false,
        "color": "#606062",
        "val": 100,
        "desc": "<p><ul><li>Complete Online Process for easy Registration</li><li>End to end Assistance to ensure smooth transition</li><li>2 Steps Guide to provide information</li><li>Status Tracking Option for Registration</li><li>Chat with your Registration Helpdesk Executive</li></ul></p>",
        "title": "VAT Registration"
      },
      {
        "show": false,
        "color": "#606062",
        "val": 100,
        "desc": "<p><ul><li>Complete Auto preparation of Returns Forms</li><li>Reminders for Timely filing</li><li>Post Filing Support</li><li>Validation of data by with comprehensive Reports</li><li>Multiple Businesses Filing – through a single Login</li></ul></p>",
        "title": "VAT Compliance"
      }
    ],
    socialLinks: [
      {
        "linkName": "facebook",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-facebook-square",
        "flip": "inactive",
        "color": "#3b5998"
      },
      {
        "linkName": "googlePlus",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-google-plus-square",
        "flip": "inactive",
        "color": "#d34836"
      },
      {
        "linkName": "google",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-google",
        "flip": "inactive",
        "color": "#3cba54"
      },
      {
        "linkName": "linkedIn",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-linkedin-square",
        "flip": "inactive",
        "color": "#0077B5"
      },
      // {
      //     "linkName":"behance",
      //     "linkURL":"",
      //     "disabled":false,
      //     "iconClass":"fa fa-behance",
      //     "flip":"inactive"
      // },
      {
        "linkName": "youtube",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-youtube",
        "flip": "inactive",
        "color": "#cc181e"
      },
      {
        "linkName": "instagram",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-instagram",
        "flip": "inactive",
        "color": "#fb3958"
      },
      {
        "linkName": "twitter",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-twitter",
        "flip": "inactive",
        "color": "#0084b4"
      }
    ],
    "headerMenu": [
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "header",
        "title": "HOME"
      },
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "section2",
        "title": "ABOUT"
      },
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "section3",
        "title": "OUR SERVICES"
      },
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "section4",
        "title": "HOW IT WORKS",
        "disabled": false
      },
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "section7",
        "title": "CONTACT"
      }
    ],
    "headerImage": "",
    "appTheme": "#FFFFFF",
    "textColor": "#000000",
    "appLogo": "assets/img/logo.png",
    "footerContent": "2017 © GIBots Team. All rights reserved."
  };



  public pageContentsArabic = {
    "aboutContent": "<p><span style=\"font-family: Helvetica, Arial, sans-serif; font-size: 14px; color: rgb(255, 255, 255);\"><p style=\"font-weight: bold;font-size: large;\"><b>إدارة ضريبة القيمة المضافة</b></p>نحن نساعدك على إدارة ضريبة القيمة المضافة الخاصة بك دون عناء. لدينا برنامج يساعدك على السيارات إعداد الإقرارات الضريبية الخاصة بك وملفهم على بوابة الحكومة الاتحادية. ضريبة القيمة المضافة (ضريبة القيمة المضافة) هي ضريبة غير مباشرة تفرض على جميع السلع والخدمات التي يتم شراؤها وبيعها من قبل الشركات، مع بعض الاستثناءات. يتم تطبيق ضريبة القيمة المضافة في أكثر من 160 بلدا في جميع أنحاء العالم كمصدر موثوق للإيرادات لميزانيات الولايات. يتم فرض ضريبة القيمة المضافة في كل مرحلة من مراحل سلسلة التوريد من الإنتاج والتوزيع إلى البيع النهائي للسلعة أو الخدمة. تلتزم جميع دول مجلس التعاون الخليجي بفرض ضريبة القيمة المضافة اعتبارا من أوائل عام 2018. وسيتم تطبيق ضريبة القيمة المضافة بمعدل قياسي قدره 5٪.<br>نحن تمكنك من رعاية عملك بينما نحرص على الضرائب الخاصة بك.</span><br></p>",
    "contactData": {
      "contactAddress": "جناح أبراج الخليج رقم 105، ب بلوك أم أم هرير 2، عود مهتا، دبي",
      "contactMobile": "+97142627388 , +971503581633",
      "contactEmail": "info@mitraagcc.com"
    },
    "processSet": [
      {
        "color": "#606062",
        "icon": "fa fa-user-plus",
        "desc": "<p><ul><li>املأ تفاصيل التسجيل الخاصة بك للاشتراك في ماناجيميفات والمضي قدما في الدفع</li></ul></p>",
        "title": "التسجيل"
      },
      {
        "color": "#606062",
        "icon": "fa fa-cog",
        "desc": "<p><ul><li>هل إعداد منظمة كاملة مثل - فروع متعددة، العملاء بالجملة وتحميل المنتج، وتخصيص قالب الفاتورة - أنت الآن على استعداد للبدء السريع نحو ضريبة القيمة المضافة الامتثال</li></ul></p>",
        "title": "اقامة"
      },
      {
        "color": "#606062",
        "icon": "fa fa-indent",
        "desc": "<p><ul><li>بسهولة إنشاء فات ضريبة القيمة المضافة المهنية أو دمج ماناجيميفات مع القائمة إرب / نظم المحاسبة الخاصة بك مع أدوات التكامل الخاصة لدينا</li></ul></p>",
        "title": "من السهل التكامل والفواتير"
      },
      {
        "color": "#606062",
        "icon": "fa fa-arrows",
        "desc": "<p><ul><li>أوتو-بوبولاتد ريتورن فورم سيضمن الإنشاء 0 خطأ يدوي للإيداع وتمكينك من التركيز على نشاطك التجاري</li></ul></p>",
        "title": "الالتزام"
      }
    ],
    "galleryList": [
      {
        "url": "",
        "title": "Angular"
      },
      {
        "title": "AWS",
        "url": ""
      }
    ],
    "serviceSet": [
      {
        "color": "#11d7db",
        "icon": "fa fa-chrome",
        "desc": "Web development is a broad term for the work involved in developing a dynamic site for the Internet or an intranet.",
        "title": "WEB DEVELOPMENT"
      },
      {
        "color": "#11d7db",
        "icon": "fa fa-area-chart",
        "desc": "Graphic design is the process of visual communication and problem-solving using one or more of typography, photography and illustration.",
        "title": "GRAPHIC DESIGN"
      },
      {
        "color": "#11d7db",
        "icon": "fa fa-paint-brush",
        "desc": "Web design encompasses many different skills and disciplines in the production and maintenance of websites.",
        "title": "WEB DESIGN"
      },
      {
        "color": "#11d7db",
        "icon": "fa fa-line-chart",
        "desc": "Search engine optimization is the process of affecting the online visibility of a website or a dynamic page in a dynamic search engine's unpaid results—often referred to as \"natural\", \"organic\", or \"earned\" results. ",
        "title": "SEO"
      }
    ],
    "expSet": [
      {
        "color": "#90d0e4",
        "web": "http://www.gibots.com/",
        "company": "GIBOTS",
        "year": "2007-2013",
        "position": "WEB DESIGNER"
      },
      {
        "color": "#90d0e4",
        "web": "http://www.gibots.com/",
        "company": "Roots Innovation Labs",
        "year": "2013-2017",
        "position": "ANGULAR DEVELOPER"
      }
    ],
    "skillSet": [
      {
        "show": false,
        "color": "#606062",
        "val": 100,
        "desc": "<p><ul><li>بريفورماتد سهلة لإنشاء الفواتير المهنية الآن في متناول يدك</li><li>تحميل وتحميل الفواتير في السائبة</li><li>إدارة الفواتير على الجوال / تبويب وسطح المكتب</li><li>البريد الإلكتروني / سمز الفاتورة للعملاء</li><li>تخصيص الفواتير مع الشعار، T & C & تخطيطات الصفحة</li></ul></p>",
        "title": "ضريبة القيمة المضافة المتوافقة مع الفواتير"
      },
      {
        "show": false,
        "color": "#606062",
        "val": 100,
        "desc": "<p><ul><li>عملية كاملة على الانترنت لسهولة التسجيل</li><li>النهاية إلى النهاية المساعدة لضمان الانتقال السلس</li><li>2 دليل الخطوات لتوفير المعلومات</li><li>خيار تتبع الحالة للتسجيل</li><li>الدردشة مع مكتب التسجيل الخاص بك التسجيل التنفيذي</li></ul></p>",
        "title": "تسجيل قيمة الضريبة المضافة"
      },
      {
        "show": false,
        "color": "#606062",
        "val": 100,
        "desc": "<p><ul><li>استكمال إعداد السيارات من نماذج الإرجاع</li><li>تذكيرات للإيداع في الوقت المناسب</li><li>دعم ما بعد الإيداع</li><li>التحقق من صحة البيانات من خلال تقارير شاملة</li><li>الشركات متعددة الايداع - من خلال تسجيل الدخول واحد</li></ul></p>",
        "title": "ضريبة القيمة المضافة الامتثال"
      }
    ],
    socialLinks: [
      {
        "linkName": "facebook",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-facebook-square",
        "flip": "inactive",
        "color": "#3b5998"
      },
      {
        "linkName": "googlePlus",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-google-plus-square",
        "flip": "inactive",
        "color": "#d34836"
      },
      {
        "linkName": "google",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-google",
        "flip": "inactive",
        "color": "#3cba54"
      },
      {
        "linkName": "linkedIn",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-linkedin-square",
        "flip": "inactive",
        "color": "#0077B5"
      },
      // {
      //     "linkName":"behance",
      //     "linkURL":"",
      //     "disabled":false,
      //     "iconClass":"fa fa-behance",
      //     "flip":"inactive"
      // },
      {
        "linkName": "youtube",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-youtube",
        "flip": "inactive",
        "color": "#cc181e"
      },
      {
        "linkName": "instagram",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-instagram",
        "flip": "inactive",
        "color": "#fb3958"
      },
      {
        "linkName": "twitter",
        "linkURL": "",
        "disabled": false,
        "iconClass": "fa fa-twitter",
        "flip": "inactive",
        "color": "#0084b4"
      }
    ],
    "headerMenu": [
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "header",
        "title": "الصفحة الرئيسية"
      },
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "section2",
        "title": "حول"
      },
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "section3",
        "title": "خدماتنا"
      },
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "section4",
        "title": "كيف تعمل",
        "disabled": false
      },
      {
        "childElements": [

        ],
        "class": "",
        "sectionId": "section7",
        "title": "اتصل"
      }
    ],
    "headerImage": "",
    "appTheme": "#ffffff",
    "textColor": "#000000",
    "appLogo": "assets/img/logo.png",
    "footerContent": "2017 © GIBots Team. All rights reserved."
  };

  constValue: any;
  constArray: any;
  taxTypes: any;
  monthList: any;
  liabilityList: any;
  supplyType: any;
  customClearance: any;
  exciseList: any;
  exciseMonthList: any;
  exciseroles: any;
  excisedeals: any;
  exciseprodmaster: any;
  botsOfL: any;
  folder: any;
  constructor() {
    this.toasterMessage = {
      fillDetailsMessage: {
        message: 'Please fill all details',
        title: 'Message'
      },

      updatedSuccessfullyMessage: {
        message: ' Updated successfully',
        title: 'Success!'
      },

      savedSuccessfullyMessage: {
        message: 'Saved successfully',
        title: 'Success!'
      },
      logoUpdatedSucessfullyMessage: {
        message: 'Logo updated successfully',
        title: 'Success!'
      },
      urlSavedSucessfullyMessage: {
        message: 'URL saved sucessfully',
        title: 'Success!'
      },

      linkNotGivenMessage: {
        message: 'Link is not mentioned by creator',
        title: 'Message'
      },
      sendSuccessfullyMessage: {
        message: 'Send successfully',
        title: 'Success!'
      },
      bannerSizeMessage: {
        message: 'Image should have at least 1364 X 545 resolution.',
        title: 'Required'
      },
      deletedMessage: {
        message: 'Deleted successfully',
        title: 'Success!'
      },
      mandatoryFieldsMessage: {
        message: 'Please enter values of mandatory fields',
        title: 'Required'
      },
      selectValueMessage: {
        message: 'Please select value',
        title: 'Required'
      },
      enterPhoneMessage: {
        message: 'Please enter phone',
        title: 'error'
      },
      enterValidPhoneMessage: {
        message: 'Please enter valid mobile number',
        title: 'error'
      },
      enterMessage: {
        message: 'Please enter message',
        title: 'error'
      },

      enterValueMessage: {
        message: 'Enter value',
        title: 'Required'
      },
      somethingWrong: {
        message: 'Something went wrong please try again',
        title: 'Failed'
      },
      fileformatError: {
        message: 'Please upload only .csv or .txt formats.',
        title: 'Failed'
      },
      delimiter: {
        message: 'Please provide delimiter.',
        title: 'Failed'
      },
      mappingSave: {
        message: 'Mapping Save Successfully',
        title: 'Success!'
      },
      manadatory: {
        message: 'Please fill all mandatory fields',
        title: 'Failed'
      },
      deleteUnit: {
        message: 'Unit deleted successfully',
        title: 'Success!'
      },
      unitAdd: {
        message: 'Unit added successfully',
        title: 'Success!'
      },
      unitUpdate: {
        message: 'Unit updated successfully',
        title: 'Success!'
      },
      stockNotAvailable: {
        message: 'Stock not available.',
        title: 'Stock'
      },

      productAdded: {
        message: 'Product added successfully',
        title: 'Success!'
      },

      invoiceAccepted: {
        message: 'Invoice Accepted',
        title: 'Success!'
      },

      stockTransfered: {
        message: 'Stock transfered Successfully',
        title: 'Success!'
      },
      taskUpdate: {
        message: 'Task Updated successfully',
        title: 'Success!'
      },
      facebookPost: {
        message: 'Successfully Posted On Facebook',
        title: 'Success!'
      },
      facebookComment: {
        message: 'Successfully Commented On Facebook',
        title: 'Success!'
      },
      commentsFetched: {
        message: 'Comments Fetched successfully',
        title: 'Success!'
      },
      commentsFetchedIssue: {
        message: 'Unable To Fetch Comments',
        title: 'Success!'
      },
      postConfirmed: {
        message: 'Post Confirmed successfully',
        title: 'Success!'
      },
      postNotConfirmed: {
        message: 'Unable To Confirm Post',
        title: 'Warning!'
      },
      postRejected: {
        message: 'Post Rejected',
        title: 'Message!'
      },

      tryAgainLaterMessage: {
        message: 'Please try again later',
        title: 'error!'
      },
      passwordResetSuccessfullyMessage: {
        message: 'Password reset sucessfully',
        title: 'Success!'
      },
      activateAccountMessage: {
        message: 'Please contact Administrator to activate account.',
        title: 'Message'
      },
      activationLinkSendMessage: {
        message: 'Activation link has been sent to your email.',
        title: 'Thanks for register'
      },
      resetPasswordLinkSendMessage: {
        message: 'Link sent to your registered email id for password reset.',
        title: 'Message'
      },
      emailIdNotValidMessage: {
        message: 'E-mail ID is not valid',
        title: 'Required'
      },
      imageUpdatedSucessfullyMessage: {
        message: 'Image updated successfully',
        title: 'Success!'
      },
      bannerImageUpdatedSucessfullyMessage: {
        message: 'Banner image updated successfully',
        title: 'Success!'
      },
      logoSizeMessage: {
        message: 'Logo size can not be greater than 2KB',
        title: 'Required'
      },
      enterCorrectUrlMessage: {
        message: 'Please enter correct URL',
        title: 'Invalid URL'
      },
      successfullyMessage: {
        message: 'Send successfully',
        title: 'Success!'
      },
      enterNameMessage: {
        message: 'Please enter name',
        title: 'error'
      },
      enterEmailMessage: {
        message: 'Please enter email',
        title: 'error'
      },

      urlNotValidMessage: {
        message: 'URL is not correct',
        title: 'Required'
      },
      addedSuccessfullyMessage: {
        message: ' Added sucsessfully',
        title: 'Success!'
      },
      Add: {
        message: 'Unit added successfully',
        title: 'Success!'
      }, NotAvailable: {
        message: 'Products are not available',
        title: 'Success!'
      },
      branchListNotAvailable: {
        message: 'Branch list not available',
        title: 'Failed'
      },
      productStockAdded: {
        message: 'Product stock added successfully',
        title: 'Success!'
      },
      TransactionListNotAvailable: {
        message: 'Transaction list not available',
        title: 'Failed'
      },
      invoiceDeleted: {
        message: 'Invoice item deleted Successfully',
        title: 'Success!'
      },
      invoiceRejected: {
        message: 'Invoice Rejected Successfully',
        title: 'Success!'
      },
      documentDeleted: {
        message: 'Document deleted successfully',
        title: 'Success!'
      },
      docUploadedSuccessfully: {
        message: 'Document uploaded successfully',
        title: 'Success!'
      },
      docAcceptedSuccessfully: {
        message: 'Request approved successfully',
        title: 'Success!'
      },
      docRejectedSuccessfully: {
        message: 'Approval request rejected.',
        title: 'Success!'
      },
      duplicateDocument: {
        message: 'Document With same name already exists',
        title: 'Error!'
      },
      operationSuccess: {
        message: 'Operation successfully',
        title: 'Success!'
      },
      invoiceUpdated: {
        message: 'Invoice updated Successfully',
        title: 'Success!'
      },
      fileDeleted: {
        message: 'File deleted Successfully',
        title: 'Success!'
      },
      selectedinvAccepted: {
        message: 'Selected Invoice Accepted Successfully',
        title: 'Success!'
      },
      trnRejected: {
        message: 'TRN Must be 15 digits',
        title: 'Error!'
      },
      tinRejected: {
        message: 'TIN Must be 15 digits',
        title: 'Error!'
      },
      emailsendsuccess: {
        message: 'Email Sent Successfully',
        title: 'Success!'
      },
      folderCreate: {
        message: 'Folder created Successfully',
        title: 'Success!'
      },
      folderDelete: {
        message: 'Folder deleted Successfully',
        title: 'Success!'
      },
      categoryCreate: {
        message: 'Category created Successfully',
        title: 'Success!'
      },
      categoryDelete: {
        message: 'Category deleted Successfully',
        title: 'Success!'
      },

      fetchfetchsuccess: {
        message: 'Data fetched Successfully',
        title: 'Success!'
      },
      notabletostart: {
        message: 'Not able to start project!!',
        title: 'Start Project'
      },
      notabletoexecute: {
        message: 'Not able to execute project!!',
        title: 'Execute Project',
      },
      manadatoryPattern: {
        message: 'Invoice Number Pattern is Mandatory',
        title: 'Error!'
      },
      manadatoryPatternLength: {
        message: 'Invoice Pattern Length should not be greater than 50',
        title: 'Error!'
      },
      manadatorySerialNo: {
        message: 'Serial Number is Required',
        title: 'Error!'
      },
      manadatoryInvType: {
        message: 'Invoice Type is Mandatory',
        title: 'Error!'
      },
      invoiceNumberPatternGenerated: {
        message: 'Invoice Number Pattern Generated Successfully!...',
        title: 'Success!'
      }

    };
    this.exciseroles = [{
      label: 'Importer',
      value: 'Importer'
    }, {
      label: 'Producer',
      value: 'Producer'
    }, {
      label: 'Warehouse keeper',
      value: 'Warehouse keeper'
    }, {
      label: 'Stock Piler',
      value: 'Stock Piler'
    }];
    this.excisedeals = [{
      label: 'Yes',
      value: 'Yes'
    }, {
      label: 'No',
      value: 'No'
    }];
    this.exciseprodmaster = [{
      label: 'Yes',
      value: 'Yes'
    }, {
      label: 'No',
      value: 'No'
    }];
    this.constValue = [
      {key:'duplicateBranchError',value:'Branch is already available.'},
      {key:'lessStockAgeError',value:'Age should be greater than 0.'},
      {key:'maxUnitPriceError',value:'Unit price should be greater than 0.'},
      {key:'maxStockLimitError',value:'Max Limit should be greater than 0.'},
      {key:'maxStockQuantityError',value:'Max limit should  be greater than quantity.'},
      {key:'error',value:'Error'},
      {key:'saveMessage', value:'Saved successfully'},
      {key:'maxStockQuantityError',value:'Quantity should be less than max limit'},
      {key:'acceptedInvoice',value:'Accepted Invoice'},
      {key:'rejectedInvoice', value:'Rejected Invoice'},
      {key:'quantityMaxError', value:'Quantity is greater than available stock'},
      {key: 'addStock', value: 'Please add the stock in product'},
      {key:'unitDelete', value:'Unit Deleted Successfully'},
      {key:'unitUpdate', value:'Unit Updated Successfully'},
      {key:'unitAdd', value:'Unit Added Successfully'},
      {key:'failed',value:'Failed'},
      {key:'quantityRequire',value:'Please add product quantity'},
      {key:'minQuantityError',value:'Product quantity should be greater than 0.'},
      {key:'availableStockRequire',value:'Please add available stock'},
      {key:'transactionRequire', value:'Please add all transaction items'},
      {key: 'maxSaleQuantity', value: 'Sale quantity is greater than stock quantity'},
      {key: 'dateFormat', value: 'dd-MMM-yyyy'},
      {key: 'datesFormat', value: 'DD-MM-YYYY'},
      {key: 'pageSetupNote', value: 'Provide input in pixel'},
      {key: 'mobilePattern', value: '/06([0-9]{8})/'},
      {key: 'addressTitlePrimary', value: 'Primary Address'},

      { key: 'addressTitleSecondary', value: 'Secondary Address' },
      { key: 'linuxAppUrl', value: '' },
      { key: 'macAppUrl', value: 'http://adhigamtesting.s3.amazonaws.com/Adhigam.app-20170805T151706Z-001.zip' },
      { key: 'winAppUrl', value: 'https://s3.ap-south-1.amazonaws.com/desktop-utility/Adhigam.exe' },
      { key: 'desktopAppName', value: 'ManageMyVAT App' },
      { key: 'imageFileSize', value: 300000 },
      { key: 'imageFileSizeError', value: 'File size should not be greater than 300KB' },
      { key: 'imageFormatError', value: "File type not supported" },
      { key: 'growlMsgTimeout', value: 3000 },
      { key: 'imageFileSizeErrorTitle', value: 'Image File Size' },
      { key: 'imageFormatErrorTitle', value: 'Image Format' },
      //{ key: 'sampleAvatarUrl', value: '../assets/img/sampleavatar.png' },
      { key: 'sampleAvatarUrl', value: '../assets/img/sampleavatar.png' },
      //chat constant

      { key: 'defaultGroupIcon', value: '../assets/img/group-img.png' },
      { key: 'selectAtLeastOneGroupMemberMessage', value: '"Please select at least one member to create group"' },
      { key: 'errWhilecreatingGroup', value: "Error while crating group" },
      { key: 'groupIsCreated', value: 'Group is Created' },
      { key: 'statusInactive', value: 'status inactive' },
      { key: 'userNotFound', value: 'User not found' },
      { key: "groupIsDeletedSuccessfully", value: "Group is deleted successfully" },
      { key: "errorWhileDeletingGroup", value: "Error while deleting group" },
      { key: "noMembersFound", value: "No members found" },
      { key: "memberIsDeleted", value: "Member is deleted" },
      { key: "errorWhileDeletingMember", value: "Error while deleting member" },
      { key: "noGroupsFound", value: "No Groups Found" },
      { key: "membersAreAdded", value: "Members are added" },
      { key: 'image', value: 'image' },
      { key: 'audio', value: 'audio' },
      { key: 'video', value: 'video' },
      { key: 'filePath', value: '"http://i.imgur.com/G9bDaPH.jpg";' },
      { key: 'chatUploadURL', value: '"chat/message/upload"' },
      { key: "errorWhileSendingMessage", value: "Error while sending message" },


      //Invoice table 13
      {key: 'invOutwardSupply', value: 'Invoices for outward supply'},
      {key: 'invInwardSupplyFromUnRegPerson', value: 'Invoices for inward supply from unregistered person'},
      {key: 'salesInvoice', value:'SalesInvoice',actualValue: 'Invoices for outward supply'},
      {key: 'purchaseInvoice',value:'PurchaseInvoice', actualValue: 'Invoices for inward supply from unregistered person'},
      {key: 'revised_Invoice', value: 'Revised Invoice'},
      {key: 'revisedInvoice',value:'RevisedInvoice', actualValue: 'Revised Invoice'},
      {key: 'debitNote',value:'Dr/Cr', actualValue: 'Debit Note'},
      {key: 'creditNote',value:'Dr/Cr', actualValue: 'Credit Note'},
      {key: 'receiptVoucher',value:'OrderAdvance', actualValue: 'Receipt voucher'},
      {key: 'paymentVoucher',value:'PaymentVoucher', actualValue: ' Payment Voucher'},
      {key: 'refundVoucher',value:'RefundVoucher', actualValue: 'Refund voucher'},
      {key: 'deliveryChallan',value:'DeliveryChallan', actualValue: ''},
      {key: 'deliveryChallanForJobWork',value:'Delivery Challan for job work', actualValue: ''},
      {key: 'deliveryChallanForSupplyOnApproval',value:'Delivery Challan for supply on approval', actualValue: ''},
      {key: 'deliveryChallanInCaseOfLiquidGas',value:'Delivery Challan in case of liquid gas', actualValue: ''},
      {key: 'deliveryChallanInCasesOtherThanByWayOfSupply',value:'Delivery Challan in cases other than by way of supply (excluding at S no. 9 to 11)', actualValue: ''},
      {key: 'export',value:'Export', actualValue: 'Export Invoice'},

      {key: 'serialNumberRequired',value:' Serial number is required'},
      {key: 'patternMaxLength16',value:'Pattern length should not greater than 16.'},
      {key: 'enterSerialAndDrag',value:'please enter serial number and then drag'},
      {key: 'enterCustomTextAndDrag',value:'please enter custom text and then drag'},
      {key: 'selectInvoiceType',value:'please select invoice type.'},
      {key: 'productCode',value:'59a436d0bfde7805247d1716'},
      {key: 'taxType',value:'directtax'},
      {key:'pageSetupDC', value:'Delivery Challan Configuration'},
      {key:"pageSetupDCSelect", value:"Choose item to show on 'Preview'"},

      {key:'defaultCountry',value:'UNITED ARAB EMIRATES'},
      {key:'defaultEmirates',value:'ABU DHABI'},

      {key: 'NotAvailable' , value : 'Download resource not available'},
      {key: 'NotFound' , value : 'Not Found'},
      {key: 'Individual' , value : 'individual'},
      {key: 'Yes' , value : 'Yes'},
      {key: 'Customer' , value : 'customer'},
      {key: 'Employee' , value : 'employee'},
      {key: 'Supplier' , value : 'supplier'},
      {key: 'updateTemplate' , value : 'Update Template'},
      {key: 'addTemplate' , value : 'Add Template'},

      //   /*************************************************New Invoice*********************************************************/





      //   /**********************************************************************************************************/
    ];

    this.constArray = {
      modelHeaders: ['Model Type', 'Version number', 'Document Type', 'Status'],
      fieldName: [{ label: 'System', value: 'system' },
      { label: 'Custom', value: 'custom' }],
      dataTypeList: [
        { label: 'Number', value: 'Number' },
        { label: 'String', value: 'String' },
        { label: 'Date', value: 'Date' },
        { label: 'Boolean', value: 'boolean' },
        { label: 'Dropdown', value: 'Dropdown' },
        { label: 'Multi-Select', value: 'Multi-Select' },
        { label: 'Dynamic Dropdown', value: 'Dynamic Dropdown' },
        { label: 'Dynamic Multi-Select', value: 'Dynamic Multi-Select' },
        { label: 'Drop+Add', value: 'Drop+Add' },
        { label: 'Attachment', value: 'Attachment' }
      ],
      Validation: [
        { label: 'Email', value: 'Email' },
        { label: 'Mobile', value: 'Mobile' }
      ],
      tableTypeList: [
        { label: 'custom', value: 'custom' },
        { label: 'system', value: 'system' },
        { label: 'process', value: 'process' }

      ],
      billPeriods: [
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Quarterly', value: 'Quarterly' },
      ],
      operatorList: [
        { label: "=", value: '===' },
        { label: "!=", value: '!==' },
        { label: ">", value: '>' },
        { label: "<", value: '<' },
        { label: ">=", value: '>=' },
        { label: "<=", value: '<=' },
        { label: "contains", value: "contains" },
        { label: "substring", value: "substring" },
        { label: "EOMonth", value: "EOMonth" }
      ],
      conversionTypeList:[
        {label: "toString", value: '$toString'},
        {label: "toInt", value: '$toInt'},

      ],
      operatorForReport: [
        // {label: "=  (case Insensitive)", value: '=== (case Insensitive)'},
        // {label: "=  (case sensitive)", value: '=== (case sensitive)'},

        // {label: "!= (case Insensitive)", value: '!== (case Insensitive)'},
        // {label: "!= (case sensitive)", value: '!== (case sensitive)'},

        // {label: "> (case Insensitive)", value: '> (case Insensitive)'},
        // {label: "> (case sensitive)", value: '> (case sensitive)'},

        // {label: "< (case Insensitive)", value: '< (case Insensitive)'},
        // {label: "< (case sensitive)", value: '< (case sensitive)'},

        // {label: ">= (case Insensitive)", value: '>= (case Insensitive)'},
        // {label: ">= (case sensitive)", value: '>= (case sensitive)'},

        // {label: "<= (case Insensitive)", value: '<= (case Insensitive)'},
        // {label: "<= (case sensitive)", value: '<= (case sensitive)'},

        // {label:"In (case Insensitive)", value: 'In (case Insensitive)'},
        // {label:"In (case sensitive)", value: 'In (case sensitive)'},

        // {label: "contains (case Insensitive)", value: "contains (case Insensitive)"},
        // {label: "contains (case sensitive)", value: "contains (case sensitive)"},

        // { label: 'In-between (case Insensitive)', value: 'In-between (case Insensitive)'},
        // { label: 'In-between (case sensitive)', value: 'In-between (case sensitive)'},

        { label: "=", value: '===' },
        { label: "!=", value: '!==' },
        { label: ">", value: '>' },
        { label: "<", value: '<' },
        { label: ">=", value: '>=' },
        { label: "<=", value: '<=' },
        { label: "contains", value: "contains" },
        { label: "In", value: 'In' },
        { label : "NotIn", value: "NotIn"},
        { label: "Inbetween", value: "Inbetween" },
      ],

      transactionType: [
        { label: "Sale", value: 'S' },
        { label: "Purchase", value: 'P' }],
      locationList: [{
        label: "Select Location",
        value: ""
      }],
      selectProduct: [{
        label: "Select Product",
        value: ""
      }],
      invoiceTermOfPayment: [
        { label: "Due on the Receipt", value: "netReceipt" },
        { label: "Net 15", value: "net15" },
        { label: "Net 30", value: "net30" },
        { label: "Net 45", value: "net45" },
        { label: "Net 60", value: "net60" },
        { label: "Net 90", value: "net90" },
        { label: "Net 120", value: "net120" },
        { label: "Net 180", value: "net180" },
        { label: "Due end of the Month", value: "netEndMonth" },
        { label: "Due end of the Next Month", value: "netNextMonth" },
        { label: "Custom", value: "netCustom" }

      ],
      invoicePaymentOptions: [
        { label: "Cash", value: "payCash" },
        { label: "Online Banking", value: "payNet" },
        { label: "Credit Card", value: "payCredit" },
        { label: "Debit Card", value: "payDebit" },
        { label: "E-Dirhams", value: "payEDirhams" },
        { label: "PDC (Post Dated Cheques)", value: 'pdc' },
        { label: "Others", value: "others" },
        // {label:"UPI",value:"payUpi"},
      ],

      customDuties: [
        { label: '5%', value: 5 },
        { label: '0%', value: 0 }
      ],

      stockSortList: [
        { label: "Please Select", value: "" },
        { label: "Product Name", value: "productName" },
        { label: "Quantity", value: "quantity" },
        { label: "Unit", value: "unit" },
        { label: "Date", value: "date" },],

      statusList: [
        { label: "Select Status", value: "" },
        { label: "Available", value: "Available" },
        { label: "Blocking", value: "Blocking" },
        { label: "Refurbished", value: "Refurb" },
        { label: "To be Reworked", value: "Rework" },
        { label: "Return To Vendor", value: "RTV" },
        { label: "To be Inspected", value: "To be insp" }],

      tableFields: [
        { field: 'invoiceNumber', header: 'Invoice Number' },
        { field: 'salesOrPurchase', header: 'Sale/Purchase' },
        { field: 'inventoryStatus', header: 'Status' },
        { field: 'invoiceCateory', header: 'Invoice Category' },
        { field: 'invoiceTotalAmount', header: 'Total Amount' }

      ],
      pageFormatList: [
        { label: "A3", value: "A3" },
        { label: "A4", value: "A4" },
        { label: "Legal", value: "Legal" },
        { label: "Letter", value: "Letter" },
        { label: "Tabloid", value: "Tabloid" }
      ],
      defaultInvoicePreviewPageFormat: {
        pageSize: "A4",
        headerHeight: 0,
        headerContents: "",
        footerHeight: 0,
        footerContents: "",
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        quality: 100,
        invoicePreviewItem: {
          rate: false,
          totalAmt: false,
          taxRate: false,
          qty: false,
          netGrWt: false,
          isUpdated: false
        }
      },
      printImageQuality: [
        { label: "Low", value: "35" },
        { label: "Medium", value: "70" },
        { label: "High", value: "100" }

      ],
      taxPayerTypeList: [
        { label: "--Select--", value: "" },
        { label: "Normal", value: "Normal" },
        { label: "E-Commerce", value: "E-Commerce" },
        { label: "ISD", value: "ISD" },
        { label: "Composition Scheme", value: "Composition Scheme" },
        { label: "Non-resident Taxable person", value: "Non-resident Taxable person" },
        { label: "Tax Deductor", value: "Tax Deductor" }
      ],
      businessRegionList: [
        { label: "--Select--", value: "" },
        { label: "Center", value: "Center" },
        { label: "State", value: "State" }
      ],
      natureOfBusinessList: [
        { "label": "--Select--", "value": "" },
        { label: "Bonded Warehouse", value: "Bonded Warehouse" },
        { label: "ISD", value: "ISD" },
        { label: "Factory/manufacturing", value: " Factory/manufacturing" },
        { label: "Leasing business", value: "Leasing business" },
        { label: "Office/Sales office", value: "Office/Sales office" },
        { label: "Retail Business", value: "Retail Business" },
        { label: "Service Provision", value: "Service Provision" },
        { label: "Service Recepient", value: "Service Recepient" },
        { label: "Wholesale Business", value: "Wholesale Business" },
        { label: "Warehouse/Depot", value: "Warehouse/Depot" },
        { label: "Works contract", value: "Works contract" },
        { label: "EOU/STP/EHTP", value: "EOU/STP/EHTP" },
        { label: "Others", value: "Others" }
      ],
      notificationUrl: [
        { url: 'productStock', value: true },
        { url: 'user', value: true },
        { url: 'organisation', value: true },
        { url: 'gstin/save', value: true },
        { url: 'gstin/revoke/request', value: true },
        { url: 'gstin/request', value: true }
      ],
      TemplateValue: {
        addTemplate: "Add Template",
        editTemplate: "Edit Template",
      },

      gatewayList: [
        { label: "Select Gateway", value: "" },
        { label: "RazorPay", value: 'RazorPay' },
        { label: "InstaMojo", value: 'InstaMojo' },
      ],
      selectUnit: [{
        label: "Select Unit",
        value: ""
      }],

      productSotckValues: {
        addStock: "Add stock",
        editStock: "Edit stock",
        add: "add",
        action: "Action",
        Pending: "Pending",
        pending: "pending",
        accepted: "Accepted",
        rejected: "Rejected",
        list: "list",
        item: "item",
      },
      unitTypes: {
        normal: "Normal",
        compound: "Compound",
      },
      lazyLoadEvent: {
        first: 0,
        rows: 10
      },


      gateWayTypes: {
        RazorPay: "RazorPay",
        InstaMojo: "InstaMojo",
      },
      reqListMac: [
        { requirementName: 'Intel based mac,2GB of RAM' },
        { requirementName: 'MAC OSX 10.6 OR Later' },
        { requirementName: 'Safari 9.1 or later' },
        { requirementName: 'Minimum video Resolution of 1024*768' },
        { requirementName: 'Internet connection' }
      ],
      reqListLinux: [
        { requirementName: 'Intel core I3,4 GB of RAM' },
        { requirementName: 'Linux OS' },
        { requirementName: 'Google Chrome OR Firefox' },
        { requirementName: 'Minimum video Resolution of 1024*768' },
        { requirementName: 'Internet connection' }
      ],
      reqListWin: [
        { requirementName: 'Intel core I3, 8GB of RAM' },
        { requirementName: 'Windows 7 or later' },
        { requirementName: 'Internet Explorer 11 OR Later' },
        { requirementName: 'Minimum video Resolution of 1024*768' },
        { requirementName: 'Internet connection' }
      ],
      // offreqListMac : [
      //   {requirementName: 'Intel based mac,GB of RAM'},
      //   {requirementName: 'MAC OSX 10.6 OR Later'},
      //   {requirementName: 'Safari 9.1 or later'},
      //   {requirementName: 'Minimum video Resolution of 1024*768'},
      //   {requirementName: 'Internet connection'}
      // ],
      // offreqListLinux: [
      //   {requirementName: 'Intel core I3,4 GB of RAM'},
      //   {requirementName: 'Linux OS'},
      //   {requirementName: 'Mozilla Firefox 52 or later'},
      //   {requirementName: 'Minimum video Resolution of 1024*768'},
      //   {requirementName: 'Internet connection'}
      // ],
      offreqListWin: [
        { requirementName: 'Intel core I3, 8GB of RAM' },
        { requirementName: 'Windows 7 or later' },
        { requirementName: 'Google Chrome OR Firefox' },
        { requirementName: 'Minimum video Resolution of 1024*768' },
        { requirementName: 'Internet connection' }
      ],


      notificationRequestType: [
        { label: 'POST', value: 'POST' },
        { label: 'PUT', value: 'PUT' },
        { label: 'DELETE', value: 'DELETE' }
      ],

      botSchema: [{
        "_id": '',
        "sequenceNum": '',
        "taskDesc": "conditon",
        "category": "Conditional",
        "inputParameters": [],
        "outputParameters": [],
        "isTemplateFixed": false,
        "order": 0,
        "priority": 0,
        "additionalInfo": [],
        "isDeleted": false,
        "startDate": "2018-08-02T06:38:12.562+0000",
        "taskTitle": "conditon",
        "eventSubscribers": [],
        "duration": [],
        "eventReviewers": [],
        "botId": '',
        "successor": [],
        "condition": '',
        "conditionResult": '',
        "successorExecutedAll": true
      }],

      // table13
      invoiceTypes: [
        { value: 'salesInvoice', label: 'Sales Invoice' },
        { value: 'purchaseInvoice', label: 'Purchase Invoice' },
        { value: 'export', label: 'Export' },
        { value: 'debit', label: 'Debit' },
        { value: 'credit', label: 'Credit' },
        { value: 'receiptVoucher', label: 'Receipt Voucher' },
        { value: 'paymentVoucher', label: 'Payment Voucher' },
        { value: 'refundVoucher', label: 'Refund Voucher' },
        { value: 'deliveryChallan', label: 'Delivery Challan' },
        { value: 'deliveryChallanForJobWork', label: 'Delivery Challan for Job work' },
        { value: 'iwdc', label: 'In Ward Delivery Challan' },
      ],

      aggregateKeyNames: [
        { label: 'sum', value: 'sum' },
        { label: 'avg', value: 'avg' },
        { label: 'first', value: 'first' },
        { label: 'last', value: 'last' },
        { label: 'max', value: 'min' },
        { label: 'push', value: 'push' },
        { label: 'addToSet', value: 'addToSet' },
        { label: 'stdDevSamp', value: 'stdDevSamp' },
        { label: 'count', value: 'count' },
      ],
      tds_credit: [
        {
          "ctin": "12DEFPS5555D1Z2",
          "tds_invoices": [
            {
              "chksum": "AflJufPlFStqKBZ",
              "i_num": "2132132131",
              "i_dt": "22-03-2016",
              "i_val": 6775.77,
              "pay_dt": "22-03-2016",
              "tds_val": 4000.8,
              "irt": 0,
              "iamt": 0,
              "crt": 3.5,
              "camt": 3000.5,
              "srt": 3.5,
              "samt": 3200.9,
              "csrt": 0,
              "csamt": 0
            }
          ]
        },
        {
          "ctin": "12DEFPS5555D1Z2",
          "tds_invoices": [
            {
              "chksum": "AflJufPlFStqKBZ",
              "i_num": "2132132131",
              "i_dt": "22-03-2016",
              "i_val": 6775.77,
              "pay_dt": "22-03-2016",
              "tds_val": 4000.8,
              "irt": 0,
              "iamt": 0,
              "crt": 3.5,
              "camt": 3000.5,
              "srt": 3.5,
              "samt": 3200.9,
              "csrt": 0,
              "csamt": 0
            }
          ]
        },
        {
          "ctin": "12DEFPS5555D1Z2",
          "tds_invoices": [
            {
              "chksum": "AflJufPlFStqKBZ",
              "i_num": "2132132131",
              "i_dt": "22-03-2016",
              "i_val": 6775.77,
              "pay_dt": "22-03-2016",
              "tds_val": 4000.8,
              "irt": 0,
              "iamt": 0,
              "crt": 3.5,
              "camt": 3000.5,
              "srt": 3.5,
              "samt": 3200.9,
              "csrt": 0,
              "csamt": 0
            }
          ]
        }
      ],
      tcs_data: [
        {
          "chksum": "AflJufPlFStqKBZ",
          "etin": "12DEFPS5555D1Z2",
          "m_id": "2132132131",
          "sup_val": 123213.87,
          "tx_val": 6775.77,
          "irt": 9.8,
          "iamt": 2000,
          "crt": 3.5,
          "camt": 3000.5,
          "srt": 3.5,
          "samt": 3200.9,
          "csrt": 0,
          "csamt": 0
        }
      ],

      supplyType: [
        { value: "goods", label: "Goods" },
        { value: "service", label: "Service" },
        { value: "both", label: "Both" },
      ],

      customClearance: [
        { value: "Y", label: "Yes" },
        { value: "N", label: "No" },
      ],
      // liabilityList : [
      //   { value:"EX201", label: "imports and release"},
      //   { value:"EX202A", label: "DZ Reporting"},
      //   { value:"EX202B", label: "Production of goods"},
      //   { value:"EX203A", label: "Local Purchases" },
      //   { value:"EX203B", label: "Lost and Damaged Declaration"},
      //   { value:"EX203C", label: "Transfer Ownership within DZ"},
      // ],
      // reasons:[
      //   { value:"export", label: "Export"},
      //   { value:"EX202A", label: "Tax paid on excise goods"},
      //   { value:"EX202B", label: "Amounts paid to the autherity in error"},
      //   { value:"EX203A", label: "Other" },
      // { value:"EX203B", label: "Lost and Damaged Declaration"},
      // { value:"EX203C", label: "Transfer Ownership within DZ"},
      // ],
      //--- month list will used in GSTR to get month name by month
      monthList: [
        { value: "1", label: "January" },
        { value: "2", label: "February" },
        { value: "3", label: "March" },
        { value: "4", label: "April" },
        { value: "5", label: "May" },
        { value: "6", label: "June" },
        { value: "7", label: "July" },
        { value: "8", label: "August" },
        { value: "9", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
      ],


      exciseMonthList: [
        { value: "Jan 2019", label: "January" },
        { value: "Feb 2019", label: "February" },
        { value: "Mar 2019", label: "March" },
        { value: "Apr 2019", label: "April" },
        { value: "May 2019", label: "May" },
        { value: "June 2019", label: "June" },
        { value: "July 2019", label: "July" },
        { value: "Aug 2019", label: "August" },
        { value: "Sept 2019", label: "September" },
        { value: "Oct 2019", label: "October" },
        { value: "Nov 2019", label: "November" },
        { value: "Dec 2019", label: "December" },
      ],
      exciseList: {

        "EX201": [
          { value: "EX201A", label: "Import Of Goods" },
          { value: "EX201B", label: "Release from DZ requiring Custom Clearance" }
        ],
        "EX202A": [
          { value: "EX202A1", label: "Release from DZ Not Requiring Customs Clearance" },
          { value: "EX202A2", label: "Consumption with in DZ" },
          { value: "EX202A3", label: "Enter to DZ" },
          { value: "EX202A4", label: "Transfer Goods to another DZ" },
          { value: "EX202A5", label: "Transfer Goods for export" },
          { value: "EX202A6", label: "Import to DZ" }
        ]
      },

      selectedFilters: [
        { label: "Transaction No", value: "Transaction No" },
        { label: "Product Code", value: "Product Code" },
        { label: "Product Name", value: "Product Name" },
        { label: "FTA Code", value: "FTA Code" },
        { label: "Quantity", value: "Quantity" },
        { label: "FTA Qty", value: "FTA Qty" },
        { label: "Product Category", value: "Product Category" },
        { label: "Excise Rate", value: "Excise Rate" },
        { label: "FTA Price", value: "FTA Price" },
        { label: "Price", value: "Price" },
        { label: "Excise Due", value: "Excise Due" },
        { label: "Not Reconciled Trancations", value: "Not Reconciled Trancations" }
      ],

      selectedStatus: [
        { label: 'All', value: 'all' },
        { label: 'Draft', value: 'draft' },
        { label: 'Saved', value: 'saved' },
        { label: 'Approved', value: 'Approved' },
      ],

      /*************************************************New Invoice*********************************************************/

      totalRowsPerPage: [5, 10, 50, 100],
      threeDecimalCountries: ["kwd", "bhd", "omr", "iqd", "jod", "lyd", "tnd"],
      voucherTypes: [
        { label: "Advance", value: "Advance" },
        { label: "Payment", value: "Payment" },
      ],
      b2bTypeList: [
        { label: "Regular", value: "R" },
        { label: "SEWP", value: "SEWP" },
        { label: "SEWOP", value: "SEWOP" },
        { label: "Deemed", value: "DE" },
      ],
      reasonList: [
        { label: "Sales Return", value: "01-Sales Return" },
        { label: "Post sale discount", value: "02-Post Sale Discount" },
        { label: "Deficiency in Service", value: "03-Deficiency in services" },
        { label: "Correction in invoice", value: "04-Correction in Invoice" },
        { label: "Changes in POS", value: "05-Change in POS" },
        { label: "Finilization of Provisional assessment", value: "06-Finalization of Provisional assessment" },
        { label: "Others", value: "07-Others" },
      ],
      creditDebitNoteTypeList: [
        { label: "Credit", value: 'C' },
        { label: "Debit", value: 'D' },
        { label: "Refund", value: "Refund" }
      ],
      exportTypes: [
        { label: "With Payment Of Tax", value: 'WPAY' },
        { label: "Without Payment Of Tax", value: 'WOPAY' },
        { label: "DEPD", value: "DEPD" }
      ],
      discountTypeList: [
        { label: "Percent", value: "per" },
        { label: "Amount", value: "amt" }
      ],
      invoiceItemTypeList: [
        { label: "Goods", value: "G" },
        { label: "Service", value: "S" },
        /*{label: "Packaging", value: "P"},
         {label: "Freight", value: "F"},
         {label: "Insurance", value: "I"},*/
      ],

      calculationFieldList: [
        { label: "Quantity", value: "Quantity" },
        { label: "GrossWt", value: "GrossWt" },
        { label: "NetWt", value: "NetWt" },

      ],


      taxRateList: [
        { label: 'Standard Rate(5%)', value: 'SR', taxRate: 5 },
        { label: 'Zero Rate (0%)', value: 'ZR', taxRate: 0 },
        { label: 'Exempt', value: 'EX', taxRate: 0 },
        { label: 'OS', value: 'OS', taxRate: 0 }
      ],

      reverseChargeList: [
        {
          value: 'Y',
          label: 'Y'
        },
        {
          value: 'N',
          label: 'N'
        }
      ],

      stateOfSupplyList: [
        {
          value: 'ABU DHABI',
          label: 'ABU DHABI'
        },
        {
          value: 'DUBAI',
          label: 'DUBAI'
        },
        {
          value: 'FUJIRAH',
          label: 'FUJIRAH'
        },
        {
          value: 'RAS AL KHAIMAH',
          label: 'RAS AL KHAIMAH'
        },
        {
          value: 'SHARJAH',
          label: 'SHARJAH'
        },
        {
          value: 'UMM AL QUWAIN',
          label: 'UMM AL QUWAIN'
        },
        {
          value: 'AJMAN',
          label: 'AJMAN'
        }
      ],

      goodsOfServiceList: [{
        value: 'G',
        label: 'G'
      },
      {
        value: 'S',
        label: 'S'
      },
      ],

      exiceRateList: [{
        value: '50',
        label: '50'
      },
      {
        value: '100',
        label: '100'
      },
      ],

      vatRateList: [{
        value: '0',
        label: '0'
      },
      {
        value: '5',
        label: '5'
      },
      ],
      taxCodeList: [{
        value: 'SR',
        label: 'SR'
      },
      {
        value: 'ZR',
        label: 'ZR'
      },
      {
        value: 'EX',
        label: 'EX'
      },
      {
        value: 'OS',
        label: 'OS'
      },
      {
        value: 'RC',
        label: 'RC'
      }
      ],
      invoiceCurrencyCodeList: [{
        value: 'AED',
        label: 'AED'
      },
      {
        value: 'ALL',
        label: 'ALL'
      }
      ],

      uqcList: [{
        value: 'DOZ',
        label: 'DOZ'
      },
      {
        value: 'KGS',
        label: 'KGS'
      },
      {
        value: 'NOS',
        label: 'NOS'
      }
      ],

      itcList: [{
        value: 'NO',
        label: 'NO'
      },
      {
        value: 'IP',
        label: 'IP'
      },
      {
        value: 'CP',
        label: 'CP'
      }
      ],
      itceligibilityList: [{
        value: 'IP',
        label: 'IP'
      },
      {
        value: 'IS',
        label: 'IS'
      },
      {
        value: 'MS',
        label: 'MS'
      },
      {
        value: 'MP',
        label: 'MP'
      },
      {
        value: 'NO',
        label: 'NO'
      }
      ],
      originalTaxCodeList: [{
        value: 'SR',
        label: 'SR'
      },
      {
        value: 'RC',
        label: 'RC'
      }
      ],



      taxModeList: [
        { label: "Inclusive", value: "I" },
        { label: "Exclusive", value: "E" },
      ],
      taxPayerTypes: {
        vatReg: 'vatReg',
        nonVatReg: 'nonVatReg',
        gccVatReg: 'gccVatReg',
        nonGccVatReg: 'nonGccVatReg',
        nonGCC: 'nonGCC'
      },

      gccCountries: {
        uae: 'UNITED ARAB EMIRATES',
        bahrain: 'BAHRAIN',
        kuwait: 'KUWAIT',
        oman: 'OMAN',
        qatar: 'QATAR',
        saudi: 'SAUDI ARABIA',
        abudhabi: 'ABU DHABI',
      },
      entityList: [
        { label: 'Sales', value: 'Sale' },
        { label: 'Purchase', value: 'Purchase' },
        { label: 'Import', value: 'Import' },
        { label: 'Export', value: 'Export' },
        { label: 'Credit', value: 'Credit' },
        { label: 'Debit', value: 'Debit' },
        { label: 'Contacts', value: 'customers' },
        { label: 'Products', value: 'products' },
        { label: 'Voucher', value: 'voucher' },
        { label: 'Trial Balance', value: 'TrialBalance' },
        { label: 'Inventory', value: 'Inventory' },
        { label: 'All In One', value: 'AllInOne' },
      ],
      tableHeaderTemp: ['File name', 'Category'],
      tableColumnsTemp: ['name', 'fileType'],
      UtilityTableHeaderHistory: ['Download File', 'Upload Date', 'File Reference number', 'File Name', 'Total Records', 'Status'],
      tableHeaderHistory: ['File Name', 'Upload Date', 'File Ref. No.', 'Failed', 'Success', 'Success with Warnings', 'Total', 'Status'],
      tableColumnsHistory: ['fileName', 'uploadDate', 'fileRefNum', 'failedRecords', 'totalRecords', 'status'],

      invoiceChallanTypeOptions: [
        { label: "Liquid Gas", value: "liquidGas" },
        { label: "Sale on Approval", value: "saleApproval" },
        { label: "Job Work", value: "jobWork" },
        { label: "Others", value: "others" }
      ],
      tableComplianceTaxReturnHeader: ['SrNo', 'Customer Name', 'TRN No', 'Tax Period', 'Action'],
      complianceColumnsTaxReturnHistory: ['SrNo', 'Customer Name', 'TRN No', 'Tax Period', 'Action'],
      // netVatDueHeader:['Subno','Particulars','Vat Amount(AED)'],
      netVatDueHeader: [{ name: 'Subno', type: 'number', isEditable: false, isMandatory: false },
      { name: 'Particulars', type: 'string', isEditable: false, isMandatory: false },
      { name: 'Recoverable VAT Amount(AED)', type: 'number', isEditable: true, isMandatory: false }],
      // netVatDueColumns:['index','title','vatAmount'],
      netVatDueColumns: ['index', 'title', 'vatAmount'],
      // vatOnExpensesHeader:['Subno','Particulars','Amount(AED)','Recoverable Vat Amount(AED)','Adjustment(AED)'],
      vatOnExpensesHeader: [{ name: 'Subno', type: 'number', isEditable: false, isMandatory: false },
      { name: 'Particulars', type: 'string', isEditable: false, isMandatory: false },
      { name: 'Amount(AED)', type: 'number', isEditable: true, isMandatory: false },
      { name: 'Recoverable VAT Amount(AED)', type: 'number', isEditable: true, isMandatory: false },
      { name: 'Adjustment(AED)', type: 'number', isMandatory: true, isEditable: false }],
      vatOnExpensesColumns: ['index', 'title', 'amount', 'vatAmount', 'adjustment'],
      // vatOnExpensesHistory:['Subno','Particulars','Amount(AED)','Vat Amount(AED)','Adjustment(AED)'],
      // vatOnSalesHeader:['Particulars','Subno','Vat Amount(AED)','Amount(AED)','Adjustment(AED)'],
      vatOnSalesHeader: [{ name: 'Subno', type: 'number', isEditable: false, isMandatory: false },
      { name: 'Particulars', type: 'string', isEditable: false, isMandatory: false },
      { name: 'Amount(AED)', type: 'number', isEditable: true, isMandatory: false },
      { name: 'VAT Amount(AED)', type: 'number', isEditable: true, isMandatory: false },
      { name: 'Adjustment(AED)', type: 'number', isMandatory: true, isEditable: false }],
      // vatOnSalesColumns:['index','title','amount','vatAmount','adjustment'],
      vatOnSalesColumns: ['index', 'title', 'amount', 'vatAmount', 'adjustment'],


      itcTypes: [
        { label: "IP-Input Product", value: "IP" },
        { label: "IS-Input Service", value: "IS" },
        { label: "CP-Capital Good", value: "CP" },
        { label: "NO", value: "NO" }
      ],
      statesList: [
        { "label": "ABU DHABI", "value": "ABU DHABI" },
        { "label": "AJMAN", "value": "AJMAN" },
        { "label": "DUBAI", "value": "DUBAI" },
        { "label": "FUJAIRAH", "value": "FUJAIRAH" },
        { "label": "RAS AL KHAIMAH", "value": "RAS AL KHAIMAH" },
        { "label": "SHARJAH", "value": "SHARJAH" },
        { "label": "UMM AL QUWAIN", "value": "UMM AL QUWAIN" }
      ],
      deliveryTerms: [
        { "label": "Ex Works ", "value": "EXW" },
        { "label": "Free Carrier", "value": "FCA" },
        { "label": "Carriage Paid To", "value": "CPT" },
        { "label": "Carriage and Insurance Paid To", "value": "CIP" },
        { "label": "Delivered At Terminal", "value": "DAT" },
        { "label": "Delivered At Place", "value": "DAP" },
        { "label": "Delivered Duty Paid", "value": "DDP" },
        { "label": "Free Alongside Ship", "value": "FAS" },
        { "label": "Free On Board", "value": "FOB" },
        { "label": "Cost and Freight", "value": "CFR" },
        { "label": "Cost, Insurance and Freight", "value": "CIF" }
      ],
      invEntityList: [
        { label: 'Sales', value: 'sale' },
        { label: 'Purchase', value: 'purchase' },
        { label: 'Import', value: 'import' },
        { label: 'Export', value: 'export' },
        { label: 'Credit', value: 'credit' },
        { label: 'All In One', value: 'AllInOne' },
      ],
      allCategoryList: [
        { label: 'Start With', value: 'startsWith' },
        { label: 'End With', value: 'endsWith' }
      ],
      uaeEmirates: ['ABU DHABI', 'AJMAN', 'DUBAI', 'FUJAIRAH', 'RAS AL KHAIMAH', 'SHARJAH', 'UMM AL QUWAIN'],//  Add & Edit Organization Bussiness Types

      bussinessTypes: [
        { label: 'Retail', value: 'Retail' },
        { label: 'Manufacturing', value: 'Manufacturing' },
        { label: 'Financial', value: 'Financial' },
        { label: 'Food', value: 'Food' },
        { label: 'Hospitality', value: 'Hospitality' },
        { label: 'Others', value: 'Others' }
      ],
      accountTypes: [
        { label: "Income", value: 'INCOME' },
        { label: "Expense", value: 'EXPENSE' },
        { label: "Receipt", value: 'RECEIPT' },
        { label: "Payment", value: 'PAYMENT' },
        { label: "JV", value: 'JV' },
      ],
      reportingHeads: [
        { label: "Asset", value: 'Asset' },
        { label: "Liability", value: 'Liability' },
        { label: "Income", value: 'Income' },
        { label: "Expense", value: 'Expense' }
      ],
      stockRemarkList: [
        { 'label': 'New Stock', 'value': 'newstock' },
        { 'label': 'Leakage', 'value': 'leakage' },
        { 'label': 'Wastage', 'value': 'wastage' },
        { 'label': 'Goods Return', 'value': 'goodsreturn' },
        { 'label': 'Other', 'value': 'other' }
      ],

      stockActionList: [
        { 'label': 'Add', 'value': true },
        { 'label': 'Remove', 'value': false },
      ],
      tourArray: [
        {
          'permission': 'dashboard.invoice',
          'click #cart-invoice': 'For list of invoices or create new invoice',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.compliance',
          'click #cart-compliance': 'To display VAT return status.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.approveRequest',
          'click #cart-approveRequest': 'Approve Request of Tax consultant for linking your account',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.contacts',
          'contact': true,
          'click #cart-contact': 'List of Contacts or add new Contact',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.inventory',
          'click #cart-inventory': 'Opens the inventory.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.accounting',
          'click #cart-accounting': 'Displays account transactions and reports.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.products',
          'product': true,
          'click #cart-product': 'List of existing products or add new Products',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.masterdata',
          'masterData': true,
          'click #cart-masterData': 'Find the list of Currency, Units and tax rates or can add new tax rate also',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.invoice',
          'uploads': true,
          'click #cart-uploads': 'Gives the documents that you have uploaded till now.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.admin',
          'admin': true,
          'click #cart-admin': 'Features that only an admin can access.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 10,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.salesReport',
          'click #sale-report': 'Graphical presentation of monthly Sales Report Based on State',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.purchaseReport',
          'click #purchase-report': 'Graphical presentation of monthly Purchase Report Based on State',
          'showNext': false,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.reportgeneration',
          'click #cart-report-studio': 'To create dashboard and counter reports.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.rule',
          'click #cart-rule': 'To add and edit rules.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'package.all',
          'click #cart-package': 'To Purchase packages.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.downloadsoftware',
          'click #cart-download': 'To download desktop utility software.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.organisation',
          'click #cart-org': 'To see organisation hierarchy.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.groups',
          'click #cart-groups': 'To see permission groups.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.useraccess',
          'click #cart-useraccess': 'Displays the permissions of the current user.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.manageRoles',
          'click #cart-roles': 'Used to manage roles.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.package',
          'click #cart-addpackage': 'Used to add and edit packages.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.additionalinfo',
          'click #cart-additionalinfo': 'Displays the permissions of the current user.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.task',
          'click #cart-mytasks': 'Displays the task list of the current user.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.queue',
          'click #cart-queue': 'To add new queue and display existing queues.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.dms',
          'click #cart-dms': 'Displays the document drive.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.dmstree',
          'click #cart-document': 'Displays the document list.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.lifecycle',
          'click #cart-lifecycle': 'Displays the lifecycle.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.scanning',
          'click #cart-scanning': 'Displays the documents that can be scanned.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.processstudio',
          'click #cart-processstudio': 'Opens the environment to create various processes.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.bots',
          'click #cart-bots': 'Displays the various bots available.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.projectstudio',
          'click #cart-startprocess': 'To execute a process available to the user.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.calendar',
          'click #cart-calendar': 'To view the calendar.',
          'showNext': true,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },
        {
          'permission': 'dashboard.calendar',
          'click #cart-newcalendarsla': 'To manipulate the calendar available to the user.',
          'showNext': false,
          'shape': 'rect',
          'bottom': 5,
          'left': 5,
          'right': 5
        },     

      ],
      statusListInvoice: [
        { label: 'All', value: 'all' },
        { label: 'Draft', value: 'draft' },
        { label: 'Final', value: 'valid' },
        { label: 'Filed', value: 'Filed' },
      ],
      processList: [
        { 'label': 'External', 'value': 'external' },
        { 'label': 'Internal', 'value': 'internal' }
      ],
      userTypes: [
        { 'label': 'Internal', 'value': 'internal' },
        { 'label': 'External', 'value': 'external' },
        { 'label': 'Both', 'value': 'both' }
      ],
      schedularList: [
        { 'label': 'Daily', 'value': 'daily' },
        { 'label': 'Weekly', 'value': 'weekly' },
        { 'label': 'Monthly', 'value': 'monthly' }
      ],
      filterList: [
        { 'id': 'Default', 'name': 'Default' },
        { 'id': 'Subscribed', 'name': 'Subscribed' },
        { 'id': 'All', 'name': 'All' },
        { 'id': 'Review', 'name': 'Review' }
        // {'id' : 'Custome Date', 'name' : 'customeDate'}
      ],
      processTableHeaders: ['Department', 'Name', 'LifeCycle Name', 'Type'],
      processTableColumns: ['deptName', 'processName', 'lifeCycleName', 'processType'],
      upldSmryTableHeaders: ['Invoice Type', 'Month', 'Year', 'Total Success', 'Total Warning', 'Total Error', 'Total Records', 'TRN'],
      upldSmryTableColumns: ['invTyp', 'month', 'year', 'success', 'warning', 'error', 'totalCnt', 'trn'],

      // taskTableHeaders: ['Name', 'Task', 'Due Date', 'Customer', 'Project Name', 'Status',
      // 'Description'],
      userTypesForTask: [
        { 'label': 'Select', 'value': null },
        { 'label': 'Internal', 'value': 'internal' },
        { 'label': 'External', 'value': 'external' },
        { 'label': 'Both', 'value': 'both' }
      ],
      /* taskTableHeaders : [ {name: 'Name', type: 'string', isEditable: false},
         {name: 'Task', type: 'string', isEditable: false},
         {name: 'Due Date', type: 'date', isEditable: true},
         {name: 'Customer', type: 'string', isEditable: false},
         {name: 'Project Name', type: 'string', isEditable: false},
         {name: 'Status', type: 'string',  isEditable: true}],*/
      taskTableHeaders: ['Task', 'Start Date', 'Process', 'Status'],
      taskTableColumns: ['taskName', 'startDate', 'projectName', 'botName'],
      taskViewTableHeaders: ['Task', 'Start Date', 'Process', 'Status'],
      taskViewTableColumns: ['taskName', 'startDate', 'projectName', 'botName'],
      projectTaskHeaders: ['Task', 'Start Date', 'Status', 'Project Name', 'Executor Name'],
      projectTaskColumns: ['taskTitle', 'startDate', 'eventStatus', 'projectName', 'userName'],
      reportTypeList: [
        { label: 'Table Chart', value: 'table' },
        { label: 'Counter', value: 'counter' },
        { label: 'Pie Chart', value: 'pie' },
        { label: 'Bar Chart', value: 'bar' },
        { label: 'Bar and line', value: 'barline' },
        { label: 'HorizontalBar', value: 'horizontalBar' },
        { label: 'ScriptableBar', value: 'scriptableBar' },
        { label: 'Doughnut chart', value: 'doughnut' },
        { label: 'PolarArea', value: 'polarArea' },
        { label: 'Line', value: 'line' },
        { label: 'StackedBar', value: 'stackedBar' },
        { label: 'HorizontalBar', value: 'horizontalBar' },
        { label: 'Radar', value: 'radar' },
        {label: "Counter-Imp",value:"counterimp"}
      ],
      reportCategoryList: [
        { label: 'Banking Report', value: 'bankingreport' },
        { label: 'Counter Report', value: 'counterreport' },
        { label: 'Dashboard', value: 'dashboard' },
        { label: 'Compliance', value: 'compliance' },
        { label: 'Project', value: 'project' },
        { label: 'Organisation', value: 'organisation' },
        { label: 'CA Dashboard', value: 'cadashboard' },
        { label: 'Account', value: 'account' },
        { label: 'HR Report', value: 'hrreport' },
        { label: 'Finance Report', value: 'financereport' },
        { label: 'Delivery Report', value: 'deliveryreport' },
        { label: 'IT Report', value: 'itreport' },
        { label: 'Innovation Report', value: 'innovationreport' },
        { label: 'Sales Report', value: 'salesreport' },
        { label: 'Marketing Report', value: 'marketingreport' },
        { label: 'Daily Status Report', value: 'dailystatus' },
        { label: 'Testing Report', value: 'testingreport' },
        { label: 'Operations Report', value: 'operationsreport' },
        { label: 'Support Report', value: 'supportreport' },
        { label: 'Legal Report', value: 'legalreport' }
      ],
      reportOperationType: [
        { label: 'SUM', value: 'SUM', operation: 'sum' },
        { label: 'MAX', value: 'MAX', operation: 'max' },
        { label: 'MIN', value: 'MIN', operation: 'min' },
        { label: 'AVG', value: 'AVG', operation: 'avg' },
        { label: 'COUNT', value: 'COUNT', operation: 'count' }
      ],
      typeOfOpertation: [
        { label: '=', value: '=', type: 'Number' },
        { label: '!=', value: '!=', type: 'Number' },
        { label: '>', value: '>', type: 'Number' },
        { label: '<', value: '>', type: 'Number' },
        { label: '>=', value: '>=', type: 'Number' },
        { label: '<=', value: '<=', type: 'Number' },
        { label: 'In', value: 'in', type: 'Number' },
        { label: 'NIn', value: 'nin', type: 'Number' },
        { label: '=', value: '=', type: 'String' },
        { label: '!=', value: '!=', type: 'String' },
        { label: 'In', value: 'in', type: 'String' },
        { label: 'NIn', value: 'nin', type: 'String' },
        { label: '>', value: '>', type: 'Date' },
        { label: '<', value: '<', type: 'Date' },
        { label: '>=', value: '>=', type: 'Date' },
        { label: '<=', value: '<=', type: 'Date' },
        { label: 'In-between', value: 'In-between', type: 'Date' },

      ],
      typeOfOperationSign: [
        { label: '=', value: 'eq' },
        { label: '!=', value: 'ne' },
        { label: '>', value: 'gt' },
        { label: '<', value: 'lt' },
        { label: '>=', value: 'gte' },
        { label: '<=', value: 'lte' }
      ],

      table: [
        {
          tableName: 'A1',
          tableData: [
            { description: 'champi', gwt: '6.400', netWet: '6.000', pqty: '2', qty: '', rate: '8.75', tUnit: '.20', taxableAmt: '52.50', productCode: '' },
            { description: 'champi', gwt: '6.400', netWet: '6.000', pqty: '2', qty: '', rate: '8.75', tUnit: '.20', taxableAmt: '52.50', productCode: '' },
            { description: 'champi', gwt: '6.400', netWet: '6.000', pqty: '2', qty: '', rate: '8.75', tUnit: '.20', taxableAmt: '52.50', productCode: '' }
          ],
          tableHeader: [
            'Product Code', 'Description', 'Quantity Price', 'Quantity Per Case', 'Taxable Amount', 'Gross Weight', 'Tare Unit', 'Net Weight'
          ]
        },
        {
          tableName: 'A2',
          tableData: [
            { description: 'champi', gwt: '6.400', netWet: '6.000', pqty: '2', qty: '', rate: '8.75', tUnit: '.20', taxableAmt: '52.50', productCode: '' },
            { description: 'champi', gwt: '6.400', netWet: '6.000', pqty: '2', qty: '', rate: '8.75', tUnit: '.20', taxableAmt: '52.50', productCode: '' },
            { description: 'champi', gwt: '6.400', netWet: '6.000', pqty: '2', qty: '', rate: '8.75', tUnit: '.20', taxableAmt: '52.50', productCode: '' }
          ],
          tableHeader: [
            'Product Code', 'Description', 'Quantity Price', 'Quantity Per Case', 'Taxable Amount', 'Gross Weight', 'Tare Unit', 'Net Weight'
          ]
        }
      ],

      /**********************************************************************************************************/
      tableHeadersForSubscriber: ['Name', 'Task', 'Due Date', 'Customer', 'Project Name', 'Description'],
      tableColumnsForSubscriber: ['userName', 'taskTitle', 'dueDate', 'customerName', 'projectName', 'taskDesc'],
      tableColumnsSearchForSubscribers: [{ key: 'Task', value: 'eventInfo.taskTitle' }, { key: 'Start Date', value: 'startDate' }, { key: 'Status', value: 'orchestratorStatus' }, { key: 'Project Name', value: 'projectInfo.name' }, { key: 'Bot Name', value: 'statusName' }],

      projectStudioSearch: [{ key: 'Task', value: 'taskTitle' }, { key: 'Executor Name', value: 'userInfo.personalInfo.name' }, { key: 'Status', value: 'eventStatus' }, { key: 'taskDesc', value: 'taskDesc' }, { key: 'Project Name', value: 'projectInfo.name' }, { key: 'Start Date', value: 'startDate' }],
      fetchfetchsuccess: {
        message: 'Data fetched Successfully',
        title: 'Success!'
      },
      notabletostart: {
        message: 'Not able to start project!!',
        title: 'Start Project'
      },
      notabletoexecute: {
        message: 'Not able to execute project!!',
        title: 'Execute Project',
      },

      processExceeded: {
        message: 'You will not be allowed to SAVE the Process due to license restrictions. Please contact your Administrator!!!',
        title: 'Warning'
      },

      processLimit: {
        message: 'Due to license restrictions you can create only ',
        title: 'Warning'
      },

      somethingWrong: {
        message: 'Something Went Wrong. Please Try Again.',
        title: 'Failed'
      }
    };

    //tableColumnsSearchForSubscribers: [{key:'Name',value:'userName'},{key:'Task',value:'taskTitle'},{key:'Due Date',value:'dueDate'},{key:'Customer',value:'customerName'},{key:'Project Name',value:'projectName'},{key:'Description',value:'taskDesc'},{key:'Status',value:'statusValue'}]


    this.taxTypes = [{ label: "Direct Tax", value: "directtax" }, { label: "InDirect Tax", value: "indirecttax" }, { label: "Multiple Tax", value: "multipletax" }];

    this.lifecycleArray = {
      viewStatusColumn: ['statusKey', 'statusType', 'statusValue'],
      viewStatusHeader: ['Status Key', 'Status Type', 'Status Value'],
      addStatusColumn: ['statusOrder', 'statusKey', 'statusValue'],
      addStatusHeader: ['Status Order', 'Status Key', 'Status Value'],
    };

  }
  getToastrMessageByKey(keyVal: any) {
    return this.toasterMessage[keyVal];
  }

  getValueByKey(keyVal: any) {
    return this.findWithAttr(this.constValue, 'key', keyVal);
  }

  findWithAttr(array, attr, value) {
    if (!isNullOrUndefined(array) && array.length > 0) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) { return array[i]['value']; }
      } return -1;
    } else {
      return -1;
    }
  }

  getArrayByKey(keyVal: any) {
    return this.constArray[keyVal];
  }

  findNotificationAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (value == array[i][attr]) { return array[i]['value']; }
    } return -1;
  }
  getValidTIN(isChecked, value) {
    if (isChecked) {
      if (isNullOrUndefined(value) || value === '') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  getOrgFormValidate() {
    if (!this.validOrgForm.name) {
      return 'name';
    }
    if (!this.validOrgForm.gstin) {
      return 'gstin';
    }
    if (!this.validOrgForm.area) {
      return 'area';
    }
    if (!this.validOrgForm.street) {
      return 'street';
    }
    if (!this.validOrgForm.mobile) {
      return 'mobile';
    }
    if (!this.validOrgForm.owner) {
      return 'owner';
    }
    if (!this.validOrgForm.countryName) {
      return 'countryName';
    }
    if (!this.validOrgForm.state) {
      return 'state';
    }
    if (!this.validOrgForm.currency) {
      return 'currency';
    }
  }
  getNotifyByUrl(url: any) {
    //adhigam-api/

    let urlData = url.split('adhigam-api/')[1]; //url.split('adhigam-api/')[1].split('/')[0];
    return this.findNotificationAttr(this.constArray.notificationUrl, 'url', urlData);
  }
  invoiceStatusTypes() {
    let StatusList =
    {
      PENDING: "GSTR1-PENDING",
      SAVE: "GSTR1-SAVE",
      ERROR: "GSTR1-FILE-ERROR",
      FILE_PENDING: "GSTR1-FILE-PENDING",
      VALID: "VALID",
      INVALID: "invalid",
      FILED: "GSTR1-FILED",
      GSTR2PENDING: "GSTR2-PENDING",
      GSTR2FILED: "GSTR2-FILED",

    };
    return StatusList;
  }
  isEmpty(value) {
    for (let key in value) {
      if (value.hasOwnProperty(key)) return false;
    }
    return true;
  }

  getSuggestionFieldHeader() {
    let suggestionHead = [{ "label": "Suggested Fields", "key": "head", "value": "head", "headName": true },
    { "label": "All Fields", "key": "head", "value": "head", "headName": true }];
    return suggestionHead;
  }

  getSuggestionField() {
    let suggestionField = [
      {
        "key": "Reference Doc Number",
        "value": [
          { "label": "invoice number", "key": "invoice number", "value": "invoice number" },
          { "label": "Invoice Num", "key": "Invoice Num", "value": "Invoice Num" },
          { "label": "Invoice No", "key": "Invoice No", "value": "Invoice No" },
          { "label": "Inv Num", "key": "Inv Num", "value": "Inv Num" },
          { "label": "Inv Number", "key": "Inv Number", "value": "Inv Number" },
          { "label": "Inv No", "key": "Inv No", "value": "Inv No" },
          { "label": "Reference Doc Num", "key": "Reference Doc Number", "value": "Reference Doc Number" }
        ]
      },
      {
        "key": "Reference Doc Date",
        "value": [
          { "label": "Invoice Date", "key": "Invoice Date", "value": "Invoice Date" },
          { "label": "Inv Date", "key": "Inv Date", "value": "Inv Date" }
        ]
      },
      {
        "key": "Supplier Name",
        "value": [
          { "label": "Name of Supplier", "key": "Name of Supplier", "value": "Name of Supplier" },
          { "label": "Customer Name", "key": "Customer Name", "value": "Customer Name" },
          { "label": "Party Name", "key": "Party Name", "value": "Party Name" },
        ]
      },
      {
        "key": "Receiver Name",
        "value": [
          { "label": "Name of Receiver", "key": "Name of Receiver", "value": "Name of Receiver" }
        ]
      },
      {
        "key": "Place Of Supply",
        "value": [
          { "label": "State", "key": "State", "value": "State" }
        ]
      },
      {
        "key": "Doc Type",
        "value": [
          { "label": "Invoice Type", "key": "Invoice Type", "value": "Invoice Type" },
          { "label": "Inv Type", "key": "Inv Type", "value": "Inv Type" }
        ]
      }, {
        "key": "Product-Service Name",
        "value": [
          { "label": "item", "key": "item", "value": "item" },
          { "label": "product", "key": "product", "value": "product" },
          { "label": "product name", "key": "product name", "value": "product name" }
        ]
      }, {
        "key": "Quantity",
        "value": [
          { "label": "qty", "key": "qty", "value": "qty" },
          { "label": "Quantity", "key": "Quantity", "value": "Quantity" }
        ]
      }, {
        "key": "Taxable Amount",
        "value": [
          { "label": "Invoice Value", "key": "Invoice Value", "value": "Invoice Value" },
          { "label": "Inv Value", "key": "Inv Value", "value": "Inv Value" },
          { "label": "Invoice Vall", "key": "Invoice Vall", "value": "Invoice Vall" },
          { "label": "Inv Val", "key": "Inv Val", "value": "Inv Val" }
        ]
      }, {
        "key": "GST Tax Rate",
        "value": [
          { "label": "Tax Rate", "key": "Tax Rate", "value": "Tax Rate" },
          { "label": "VAT Rate", "key": "VAT Rate", "value": "VAT Rate" }
        ]
      }
    ];
    return suggestionField;
  }

}
