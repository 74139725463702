<div class="bannner-image" [ngClass]="{'header' : currentTransition == 'main.home' , 'scrolling' :  highlightMenu }"  [ngStyle]="dynamicStyles()"  id="header" >
  <header>
    <div>
      <div class="lang-align" dropdown>
        <span class="clr">{{ 'LOGIN.CHANGELANG' | translate }}</span>
        <select #langSelect (change)="changeToArabic(langSelect.value)" style="color: black;">
          <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{ lang }}</option>
        </select>


      </div>
    </div>
    <div class="container">
      <!--<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>-->
      <div class="row top-nav-collapse">
        <div class="logo-menu">
          <div class="col-sm-2 logo-img">
            <div class="col-sm-6 "><a uiSref="main.home"><img class="logo-image" height="70px" width="110px" style="max-height:70px;max-width: 110px " src="{{globalData['appLogo']}}" alt="logo"></a></div>
            <div *ngIf="applicationMode === 'C'" class="col-sm-6 paddingTop" (click)="onEditLogoClick()">
              <button container="body" placement="right" ngbTooltip="Update logo"  class="more-btn edit-icon transparent-btn" ><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
            </div>
            <!--<app-image style="padding-left: 38px" data-toggle="tooltip" data-placement="right" title="Select image" [dropConfig]="dropConfig" [disableControl]="disabledImageUpload" [iconPos]="'right'" [message]="''" [section]="'logo'" (Close)="retriveImage($event)">-->
            <!--</app-image>-->

          </div>
          <div class="col-sm-10">
            <!-- menu -->
            <div class="navbar navbar-custom">
              <!-- icon bars shows only in responsive -->
              <div class="navbar-header">
                <button class="navbar-toggle collapsed" data-target="#navbar-menu-collapse" data-toggle="collapse" type="button">
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
              </div>

              <div class="collapse navbar-collapse no-padding" id="navbar-menu-collapse">
                <div id="sidebar">
                  <div id="checkdiv"></div>
                  <nav class="">
                    <ul class="menu nav navbar-nav">
                      <li class="hover-show" *ngFor="let item of headerMenu; let idx = index;" [ngClass]="{'active' : globalData['selectedHeader'] == item.sectionId , 'no-display' : item.disabled, 'position': langSetKey === 'arb'}">
                        <a class="pointer-cursor" style="color: #f47435 !important;" [ngClass]="{'position': langSetKey === 'arb'}" [ngx-scroll-to]="getItemId(item)" [ngx-scroll-to-offset]="-60" (click)="checkPageState(item)">{{item.title}}</a>
                        <ul class="bl" *ngFor="let subItem of item.childElements">
                          <li><a (click)="getPageLink(subItem)">{{subItem.title}}</a></li>
                        </ul>
                      </li>
                      <li class="hover-show">
                        <!--<a [uiSref]="'page.login'"><span class="loginbtn-clr">LOGIN</span></a>
                       --> <a *ngIf="langSetKey != 'arb'" [uiSref]="'page.login'" class="loginbtn-clr">LOGIN</a>
                        <a *ngIf="langSetKey === 'arb'" [uiSref]="'page.login'" class="loginbtn-clr">تسجيل الدخول</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div><!-- end of menu -->
          </div>
        </div>
      </div>
    </div>
  </header>
</div>

<div class="modal fade" id="logoModal" tabindex="-1" role="dialog" aria-labelledby="galleryModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="galleryModalLabel">{{modalHeader}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body textcolor galleryModal">
        <div class="col-sm-6">
          <div class="clear-image" (click)="clearImage()"><i class="fa fa-refresh" aria-hidden="true"></i></div>
          <app-image [dropConfig]="dropConfig" [disableControl]="disabledGalleryUpload" [message]="message" [section]="'gallery'" [showImageIcon]="false" (Close)="retriveImage($event)"></app-image>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="(logoImage == null || logoImage == undefined)" (click)="saveGalleryModalData(galleryData)" >Save changes</button>
      </div>
    </div>
  </div>
</div>
