import { AuthService } from '../services/auth.service';

export function appInitializer(authenticationService: AuthService) {
    return () => new Promise(resolve => {
        // attempt to refresh token on app start up to auto authenticate
        console.log("Initializing App...")
        authenticationService.checkLoggedIn()
            .subscribe()
            .add(resolve);
    });
}