import {
    Component, OnInit, HostListener, Inject, ViewChild, ViewEncapsulation, AfterViewInit, ViewContainerRef,
    OnDestroy
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {StateService} from '@uirouter/core';
// import { DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import {NgbCarouselConfig, NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
// import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { ToastrService } from 'ngx-toastr';
import {Transition} from '@uirouter/angular';

import {Broadcaster} from '../../../services/sharable.service';
import {WebsiteService} from '../service/website.services';
import {AppConstants} from '../../../services/constant';
import {APP_CONFIG, IConfig} from '../../../appConfig/Iconfig';
import {AuthService} from '../../../services/auth.service';
import {isNullOrUndefined} from 'util';
import {JwtHelperService} from '@auth0/angular-jwt';
import {TranslateService} from "@ngx-translate/core";

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['home.component.css']
})
export class HomeComponent implements OnInit , AfterViewInit, OnDestroy {
  editSkillIndex: number;
  editExpIndex: number;
  editServiceIndex: number;
  editProcessIndex: number;
  loadScripts: boolean;
  private contentEdit: any;
  public pageContents: any;
  disabledImageUpload: boolean;
  disabledGalleryUpload: boolean;
  closeResult: string;
  skillData: any;
  expData: any;
  serviceData: any;
  processData: any;
  galleryData: any;
    bannerImage: any;
    modalHeader: string;
    myFormGroup: FormGroup;
    iconCss = new FormControl();
    fallbackIcon = 'fa fa-book';
    icon: string;

    private headerMenu: any;
    public applicationMode: any;
    message: string;

  public dropConfig:any;
 /* public dropConfig: DropzoneConfigInterface = {
    url: 'https://httpbin.org/post',
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null
  };*/

   /* public swiperConfig: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        centeredSlides: true,
        autoplay: true
    };*/
   swiperIndex: number;
    private hostName: string;
    launchWeb: boolean;
    loginedUser: any;
    params: any;
    private JwtHelperService: JwtHelperService;
    autoSaveData: any;
    public loading: boolean;
    private currentLang: string;

  constructor(@Inject(DOCUMENT) private document: Document, private service: Broadcaster,
              config: NgbCarouselConfig, private modalService: NgbModal,@Inject(APP_CONFIG) private iconfig: IConfig,
              private scrollToService: ScrollToService, private webService: WebsiteService, private stateService: StateService,
              private constant: AppConstants, public toastr: ToastrService, vcr: ViewContainerRef, public translate: TranslateService,
              private authService: AuthService, private transition: Transition, JwtHelperService: JwtHelperService) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
      //this.toastr.setRootViewContainerRef(vcr);
      this.params = transition.params();
      this.JwtHelperService = JwtHelperService;
  }

  ngOnInit() {
    this.initValues();
    this.initMethods();
  }

    ngAfterViewInit() {

    }

    ngOnDestroy() {

        if(this.autoSaveData){
            clearInterval(this.autoSaveData);
        }
    }

  setDivData(elemType, pagePos, pageMaxSize) {
    let element = document.getElementById(elemType);
    if (element) {
        let elemTop = element.offsetTop - 150;
        let elemBottom = element.clientHeight;
        if ((elemBottom <= pageMaxSize) && ((elemTop + elemBottom) >= pagePos )) {
            return true;
        } else {
            return false;
        }
    }
  }

    retriveImage(event) {
            if (event) {
                if (event.length > 0) {
                    if (event[0].height > 545 && event[0].width > 1349) {
                        this.bannerImage = event[0].dataURL;
                    }
                    else {
                        this.toastr.error('Image should have at least 1364 X 545 resolution.','Required');
                        this.service.broadcast('clearGalleryImage', 'clear');
                    }
                }
            }
    }

    saveGalleryModalData(data: any) {
        this.service.broadcast('setBannerImage', this.bannerImage);
        this.pageContents['headerImage'] = this.bannerImage;
        $('#bannerModal').modal('hide');
        this.bannerImage = null;
        this.service.broadcast('clearGalleryImage', 'clear');
    }



  getData(winPos, winMax) {
    if (this.setDivData('header', winPos, winMax)) {
      this.service.broadcast('changeHeader', `header`);
    }else if (this.setDivData('section2', winPos, winMax)) {
      this.service.broadcast('changeHeader', `section2`);
    }else if (this.setDivData('section3', winPos, winMax)) {
      this.service.broadcast('changeHeader', `section3`);
    }else if (this.setDivData('section4', winPos, winMax)) {
      this.service.broadcast('changeHeader', `section4`);
    }else if (this.setDivData('section5', winPos, winMax)) {
      this.service.broadcast('changeHeader', `section5`);
    }else if (this.setDivData('section6', winPos, winMax)) {
      this.service.broadcast('changeHeader', `section6`);
    }else if (this.setDivData('section7', winPos, winMax)) {
      this.service.broadcast('changeHeader', `section7`);
    }else {
      // this.service.broadcast('changeHeader', ``);
    }
  }

  @HostListener('window:scroll', [])
  public onWindowScroll() {
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    let scrollPos = (document.documentElement.scrollTop || document.body.scrollTop);
    if ( pos === max ) {
      // code when scroll reached end
    }
    if ( scrollPos > 100 ) {
            this.service.broadcast('HighLightMenu', true);
      }else {
        this.service.broadcast('HighLightMenu', false);
      }
    this.getData(scrollPos, max);
  }

  private initValues() {
    this.launchWeb = false;
    this.currentLang = 'en';
    this.loadScripts = true;
    this.pageContents = {};
    this.applicationMode = this.iconfig.applicationMode;

    this.message = '';
    this.modalHeader = '';
    this.loading = false;
  }

  initWebsiteData() {
        this.contentEdit = {
            editHeader: false,
            editAbout: false,
            editGallery: false,
            editServices: false,
            editBlog: false,
            editClients: false,
            editContact: false,
            editProcess: false
        };
        this.pageContents = this.constant.pageContents;
        this.setPageContentDataForServices();
        this.skillData = {
            color: '#fff500'
        };
        this.expData = {
            color: '#fff500'
        };
        this.serviceData = {};
        this.processData = {};
        this.galleryData = {};
        this.animateSkill();
    }
  private initMethods() {
      this.checkWebLogin();
      $('[data-toggle="tooltip"]').tooltip();
      this.getBroadcastMessage();
      this.initWebsiteData();
      this.checkSessionData();
  }

    getBroadcastMessage() {
        const self = this;

        this.service.on<string>('reg')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents['contactData'] = rec.contactData;
                self.registerWebsite(rec.userData, self.pageContents);
            });

        this.service.on<string>('contactUpdate')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents['contactData'][rec.controlName] = rec.value;
            });

        this.service.on<string>('logoUpdate')
            .subscribe(message => {
                self.pageContents['appLogo'] = message;
            });

        this.service.on<string>('homePageColor')
            .subscribe(message => {
                self.pageContents.appTheme = message;
            });
        this.service.on<string>('homeTextColor')
            .subscribe(message => {
                self.pageContents.textColor = message;
            });

        this.service.on<string>('changeHeaderData')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.headerMenu = rec.headerMenu;
            });
        this.service.on<string>('changeHeaderDataOnSidebar')
            .subscribe(message => {
                let rec: any;
                rec = message;
                let headerMenu = rec;
                self.pageContents.headerMenu = headerMenu;
                setTimeout(function(){
                    self.service.broadcast('changeHeaderData', self.pageContents);
                    self.service.broadcast('changeSectionData', self.pageContents);
                }, 200 );
            });
        this.service.on<string>('setLinkURL')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents.socialLinks = rec;
            });
        this.service.on<string>('updateFooterContent')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents.footerContent = rec;
            });
        this.service.on<string>('setLogoImage')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents['appLogo'] = rec;
            });

        this.service.on<string>('changePageContentOnLogin')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents = rec.pageData;
                self.loginedUser = rec.userData;
                self.setPageContentDataForServices();
            });

        this.service.on<string>('changeAppMode')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.applicationMode = rec;
            });
        this.service.on<string>('updateWebsite')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.launchWeb = rec;
            });

        this.service.on<string>('updateWebsiteContent')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.updateWebsiteByUser();
            });

        this.service.on<string>('logout')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.logoutUser();
            });
        this.service.on<string>('changelang')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.changelang(rec);
            });
    }

  private loadSummerNote() {
    $(document).ready(function() {
      $('#summernote').summernote({
        height: 120,
        minHeight: null,
        maxHeight: null,
        focus: true
      });

      $('#summernoteHeader').summernote({

      });
    });
  }

  private setPageContentDataForServices() {
      const self = this;
      setTimeout(function(){
          self.service.broadcast('changeHeaderData', self.pageContents);
          self.service.broadcast('changeSectionData', self.pageContents);
          self.service.broadcast('appThemeSetting', self.pageContents.appTheme);
          self.service.broadcast('appTextColorSetting', self.pageContents.textColor);
          self.service.broadcast('footerPageColor', self.pageContents.appTheme);
          self.service.broadcast('setApplicationMode', self.applicationMode);
          self.service.broadcast('launchWeb', self.launchWeb);
          self.service.broadcast('changeLanguages', self.currentLang);
      }, 200 );
  }

  editContent(type: String) {
    switch ( type) {
      case 'header':
        $('#summernoteHeader').summernote({

        });
        this.contentEdit.editHeader = true;
        break;
    }
  }

  saveContent(type: String) {
      switch ( type) {
        case 'header':
          this.pageContents.headerContent = $('#summernoteHeader').summernote('code');
          $('#summernoteHeader').summernote('destroy');
          this.contentEdit.editHeader = false;
          break;
      }
  }

  cancelContent(type: String) {
    switch ( type) {
      case 'header':
        $('#summernoteHeader').summernote('destroy');
        this.contentEdit.editHeader = false;
        break;
    }
  }

  resetDropzoneUploads() {

  }

  onUploadError(args: any) {
    console.log('onUploadError:', args);
  }

  onUploadSuccess(args: any) {
    this.resetDropzoneUploads();
  }



  private assignImage(section, image) {
    switch ( section) {
      case 'header':
        this.pageContents.headerImage = image;
        this.service.broadcast('changeHeaderImg', image);
        break;
      case 'about':
        break;
      case 'gallery':
          this.galleryData.url = image;
        break;
      case 'services':
        break;
      case 'process':
        break;
      case 'blog':
        break;
      case 'clients':
        break;
      case 'contact':
        break;
    }
  }

  private animateSkill() {

    setTimeout(function () {
      const skillbar = $('.skillbar');
      skillbar.each(function(){
        $(this).find('.skillbar-bar').animate({
          width: $(this).attr('data-percent')
        }, 6000);
      });
    }, 1000);
  }

  saveSkillModalData(data: any, index ) {
    if (this.editSkillIndex != null && this.editSkillIndex !== undefined) {
        this.pageContents.skillSet[this.editSkillIndex] = Object.assign(this.pageContents.skillSet[this.editSkillIndex], data);
        this.animateSkill();
    }else {
      if (this.checkFields(data, 'skill')) {
          let rec = Object.assign({}, data);
          this.pageContents.skillSet.push(rec);
          this.animateSkill();
      }
    }
  }
  deleteSkillModalData (index) {
      this.pageContents.skillSet.splice(index, 1);
  }

  editSkills(rec: any, idx: number) {
    this.modalHeader = 'Edit Skill';
    this.skillData = Object.assign(this.skillData, rec);
    this.editSkillIndex = idx;
  }

  valToPer(val: any) {
    return val + '%';
  }

    addSkill() {
        this.modalHeader = 'Add Skill';
        this.skillData = {};
        this.editSkillIndex = null;
    }


   checkFields(data: any, type: any) {
    if (type === 'skill') {
        if (data.title && data.title.trim() !== '' && data.desc && data.desc.trim() !== '' && data.color && data.color!='') {
            data.title = data.title.trim();
            data.desc = data.desc.trim();
            if (data.show === true ) {
                if (data.val && data.val !== '') {
                    return true;
                } else {
                    this.toastr.error('Please select value', 'Required');
                    return false;
                }
            } else {
                return true;
            }
        } else {
            this.toastr.error('Please enter values of mandatory fields', 'Required');
            return false;
        }
    }else {
      if (type === 'exp') {
          if (data.position && data.position.trim() !== '' && data.year && data.year !== '' && data.company && data.company.trim() !== '') {
              data.position = data.position.trim();
              data.company = data.company.trim();

              if (data.web && data.web.trim() !== '') {
                  data.web = data.web.trim();
                  if (this.isUrl(data.web)) {
                      return true;
                  } else {
                      this.toastr.error('URL is not correct', 'Required');
                      return false;
                  }
              }else {
                  return true;
              }
          }  else {
              this.toastr.error('Please enter values of mandatory fields', 'Required');
              return false;
          }
      } else {
          if (type === 'service') {
              if (data.title && data.title.trim() !== '' && data.desc && data.desc.trim() !== '' && data.icon && data.icon !== '') {
                  data.title = data.title.trim();
                  data.desc = data.desc.trim()
                  return true;
              } else {
                  this.toastr.error('Please enter values of mandatory fields',  'Required');
                  return false;
              }
          }else {
              if (type === 'process') {
                  if (data.title && data.title.trim() !== '' && data.desc && data.desc.trim() !== '' && data.icon && data.icon !== '') {
                      data.title = data.title.trim();
                      data.desc = data.desc.trim()
                      return true;
                  } else {
                      this.toastr.error('Please enter values of mandatory fields', 'Required');
                      return false;
                  }
              }else {
                  if (type === 'gallery') {
                      if (data.title && data.title.trim() !== '' && data.url && data.url !== '') {
                          data.title = data.title.trim();
                          return true;
                      } else {
                          this.toastr.error('Please enter values of mandatory fields', 'Required');
                          return false;
                      }
                  }
              }
          }
      }
    }
  }

    onEditBannerClick() {
        this.message = 'Click or drag images here to upload (Image should have at least 1364 X 545 resolution).';
        this.modalHeader = 'Update banner image';
        $('#bannerModal').modal('show');
    }

    restrictMaxVal(model, val) {
        if (this.skillData[model] > val) {
          this.skillData[model] = 0;
        }
    }

    addExp() {
        this.modalHeader = 'Add Experience';
        this.expData = {};
        this.editExpIndex = null;
    }

    editExp(rec: any, idx: number) {
        this.modalHeader = 'Edit Experience';
        this.expData = Object.assign(this.expData, rec);
        this.editExpIndex = idx;
    }

    deleteExpModalData (index) {
        this.pageContents.expSet.splice(index, 1);
    }

    saveExpModalData( data: any, index) {
        if (this.editExpIndex != null && this.editExpIndex !== undefined) {
            this.pageContents.expSet[this.editExpIndex] = Object.assign(this.pageContents.expSet[this.editExpIndex], data);
        }else {
            if (this.checkFields(data, 'exp')) {
                let rec = Object.assign({}, data);
                this.pageContents.expSet.push(rec);
            }
        }
    }

    onIconPickerServiceSelect(icon: string): void {
        this.serviceData.icon = icon;
    }

    addServices() {
        this.modalHeader = 'Add Service';
        this.serviceData = {};
        this.editServiceIndex = null;
    }

    deleteServiceModalData (index){
        this.pageContents.serviceSet.splice(index, 1);
    }

    saveServiceModalData( data: any, index) {
        if (this.editServiceIndex != null && this.editServiceIndex !== undefined) {
            this.pageContents.serviceSet[this.editServiceIndex] = Object.assign(this.pageContents.serviceSet[this.editServiceIndex], data);

        }else {
            if (this.checkFields(data, 'service')) {
                let rec = Object.assign({}, data);
                this.pageContents.serviceSet.push(rec);
            }
        }
    }

    editService(rec: any, idx: number) {
        this.modalHeader = 'Edit Service';
        this.serviceData = Object.assign(this.serviceData, rec);
        this.editServiceIndex = idx;
    }

    onIconPickerProcessSelect(icon: string): void {
        this.processData.icon = icon;
    }

    addProcess() {
        this.modalHeader = 'Add Process';
        this.processData = {};
        this.editProcessIndex = null;
    }

    deleteProcessModalData (index) {
        this.pageContents.processSet.splice(index, 1);
    }


    editProcess(rec: any, idx: number) {
        this.modalHeader = 'Edit Process';
        this.processData = Object.assign(this.processData, rec);
        this.editProcessIndex = idx;
    }

    contactData (type: any) {
        this.triggerScrollToContact();
    }

    public triggerScrollToContact() {

        const config: ScrollToConfigOptions = {
            target: 'section7',
            offset: 180
        };
        this.scrollToService.scrollTo(config);
    }

    registerWebsite(userData, pageContent) {
        const self = this;
        let record: any;
        record =  this.authService.getLoggedUserRecords();
        record.domain = userData.domain;
        this.loading = false;
        this.webService.launchWebsite(record, pageContent).subscribe(respData => {
                if (respData.status) {
                    sessionStorage.removeItem('jwt');
                    self.stateService.go('page.launch', {userData: respData.data});

                }else {
                    self.service.broadcast('showErrorMsg', respData.data);
                }
                self.loading = false;
            },
            err => {
                self.loading = false;
            });
    }

    deleteSlide(idx) {
        this.pageContents.galleryList.splice(idx, 1);
    }

    addGallery() {
        this.modalHeader = 'Add Image';
        this.galleryData = {};
    }

    clearImage() {
        this.service.broadcast('clearGalleryImage', 'clear');
    }

    private fetchWebsiteData() {
        this.loading = true;
        const self = this;
        this.hostName = 'testdw16.gibots.com';
        // this.hostName = location.hostname;
        const pageData = {
            domain: this.hostName
        }

        this.webService.getDynamicData(pageData).subscribe(respData => {
                if (respData.status) {
                    this.loading = false;
                    if (respData.data) {
                        self.setPageContents(respData.data);
                    }
                }else {
                    this.loading = false;
                }
            },
            err => {
            this.loading = false;
            });
    }

    setPageContents(data) {
        this.pageContents = data['pageContent'];
        this.setPageContentDataForServices();
    }

    isUrl(url) {
        const regexp = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        return regexp.test(url);
    }

    changelang(rec) {
        if(!isNullOrUndefined(rec) || rec != ''){
            this.currentLang = rec;
            if( rec == 'arb'){
                this.pageContents = this.constant.pageContentsArabic;
                this.setPageContentDataForServices();
            }else if(rec == 'en'){
                this.pageContents = this.constant.pageContents;
                this.setPageContentDataForServices();
            }
        }else {
            this.pageContents = this.constant.pageContents;
            this.setPageContentDataForServices();
        }

    }

    logoutUser() {
        const self = this;
        this.webService.onLogout().subscribe(
            respData => {
                if (respData['status']) {
                    sessionStorage.clear();
                    localStorage.clear();
                    this.authService.logout();
                    self.stateService.go('page.login', {}, {reload: true});
                }else {
                    console.log('Enable to Logout');
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    updateWebsiteByUser() {
        const self = this;
        this.loading = true;
        this.webService.updateDynamicData(self.loginedUser, self.pageContents).subscribe(respData => {
                if (respData.status) {
                    self.loading = false;
                    self.stateService.go('main.home', {}, {reload: true});
                }else {
                    self.loading = false;
                }
            },
            err => {
                self.loading = false;
            });
    }

    private fetchUserWebsiteData(pageData) {
        const self = this;
        if (pageData._id) {
            this.loading = false;
            this.webService.getDynamicDataByUser(pageData._id).subscribe(respData => {
                    if (respData.status) {
                        if (respData.data) {
                            self.setPageData(respData.data['pageContent'], respData.data['domain'], pageData);
                        }else {
                            self.autoSaveContent();
                        }
                        self.autoSaveData = setInterval(() => {
                            self.autoSaveContent();
                        }, (60000 * 5));
                        self.loading = false;
                    } else {
                        self.loading = false;
                    }
                },
                err => {
                    self.loading = false;
                });
        }
    }

    setPageData(pageData, domain, userData) {
        this.service.broadcast('changeAppMode', 'C');
        if(!isNullOrUndefined(domain) && domain !== ''){
            this.service.broadcast('updateWebsite', true);
        }else{
            this.service.broadcast('updateWebsite', false);
        }

        let content: any;
        content = {
            pageData: pageData,
            userData: userData
        }
        this.service.broadcast('changePageContentOnLogin', content);

    }

    private checkWebLogin() {
        if (this.iconfig.applicationMode === 'L') {
           // this.fetchWebsiteData();
        }else {
            if (isNullOrUndefined(this.params.userData._id)) {
                this.checkLoginDuration();
            }else {
               // this.fetchUserWebsiteData(this.params.userData);
            }
        }

    }

    private checkLoginDuration() {
        const self = this;
        const jwtToken = sessionStorage.getItem('jwt');
        this.authService.getLoggedUserData(function (status, response) {
           if(status) {
               if (response) {
                   //self.fetchUserWebsiteData(response);
               }else{
                   self.stateService.go('page.login');
               }
           } else{
               self.stateService.go('page.login');
           }
        });
    }

    autoSaveContent() {
        const self = this;
        let userData = this.authService.getLoggedUserRecords();
        if ( userData) {
            this.webService.saveWebsiteContent(userData, this.pageContents).subscribe(respData => {
                    if (respData.status) {

                    } else {
                        self.service.broadcast('showErrorMsg', respData.data);
                    }
                },
                err => {
                });
        }
    }

  private checkSessionData() {
    let sessionData;
    this.authService.sentWebReqData.subscribe(item => {
        sessionData = item;
    });
    if (sessionData === 'Y') {
      this.toastr.success('Send successfully', 'Request');
      sessionStorage.removeItem('sentWebRequest');
    }
  }
}
