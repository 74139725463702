import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment-timezone';


@Injectable()
export class DateInterceptor implements HttpInterceptor {

  private userTimeZone:string;
  public blacklistApis:Array<string>;
  // private dateTimeFormat:string;vfv

  constructor(){
    this.userTimeZone = moment.tz.guess();
    this.blacklistApis = [
      "/gibots-orch/process/",
      "/gibots-reports-api/dr/queryData"
    ]
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          if(!this.blacklistApis.find(a=>event.url.includes(a)))
            return this.convertDates(event);
        }
        return event;
      })
    );
  }

  private convertDates(response: HttpResponse<any>): HttpResponse<any> {
    const body = this.convertDatesInObject(response.body);
    return response.clone({ body });
}

private convertDatesInObject(obj: any): any {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return obj;
  }

  for (const key of Object.keys(obj)) {
    if (this.isIsoDateString(obj[key])) {
      obj[key] = this.convertToUserTimezone(obj[key]);
    } else if (typeof obj[key] === 'object') {
      this.convertDatesInObject(obj[key]);
    }
  }
  return obj;
}

private isIsoDateString(value: any): boolean {
  return typeof value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value);
}

private convertToUserTimezone(date: string): string {
  let locale = sessionStorage.getItem("locale");
  let timezone = sessionStorage.getItem("timezone");
  if(this.userTimeZone === "Asia/Calcutta"){
    this.userTimeZone = "Asia/Kolkata";
  }
  let convertedDate = moment.utc(date).tz(timezone ? timezone : this.userTimeZone).locale(locale ? locale : "en").format("L");
  let time = moment.utc(date).tz(timezone ? timezone : this.userTimeZone).locale(locale ? locale : "en").format("LT");
  return `${convertedDate}, ${time}`;
}
}
