import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {isNullOrUndefined} from 'util';

import {UtilsService} from '../../../../utils/common.util';
import {AppConstants} from "../../../../services/constant";
import { AuthService } from '../../../../services/auth.service';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'app-input-box',
    templateUrl: 'input-box.component.html',
    styleUrls: ['input-box.component.css']
})
export class InputBoxComponent implements OnInit, OnChanges {

    showReqValidation: boolean;
    inputData: any;
    @Input() id: any;
    @Input() customClass: any;
    @Input() validPassword: any;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() isDisabled: boolean;
    @Input() showUserValidation: boolean;
    @Input() regValidation: boolean;
    @Input() reqErrorMessage: string;
    @Input() reqLabelStyle: object;
    @Input() customErrLabelStyle: object;
    @Input() errorMessage: string;
    @Input() showSuggestion: boolean;
    @Input() suggestionMessage: string;
    @Input() mandatory: boolean;
    @Input() type: string;
    @Input() rows: number;
    @Input() title: any;
    @Input() isOpration: boolean;
    @Input() isShipping?: boolean;
    @Input() maxlength: number;
    @Input() max: number;
    @Input() min: number;
    @Input() minlength: number;
    @Input() addOrd : boolean;
    @Input() pattern?: any;
    @Input() logIn:boolean;
    @Output() blur = new EventEmitter();
    @Output() inputChange:  EventEmitter<any>;
    @Output() isValid= new EventEmitter();

    @Input()
    get model() {
        return this.inputData;
    }
    @Output() modelChange = new EventEmitter();
    set model(val) {
        this.inputData = val;
        this.modelChange.emit(this.inputData);
        this.inputChange.emit(this.inputData);
    }
    invalidEmail: boolean;
    invalidPassword: boolean;
    invalidMobile: boolean;
    invalidTrn: boolean;
    invalidTin: boolean;
    invalidIdn: boolean;
    constructor(private utilsService: UtilsService, private constant: AppConstants,  private authService: AuthService) {
        this.placeholder = '';
        this.showUserValidation = false;
        this.regValidation = false;
        this.errorMessage = '';
        this.reqErrorMessage = '';
        this.showSuggestion = false;
        this.suggestionMessage = '';
        this.label = '';
        this.validPassword=false;
        this.type = 'text';
        this.showReqValidation = false;
        this.mandatory = false;
        this.inputChange = new EventEmitter();
        this.rows = 2;
        this.reqLabelStyle = {};
        this.customErrLabelStyle = {};
        this.invalidEmail = false;
        this.invalidPassword = false;
        this.invalidMobile = false;
        this.invalidTrn = false;
        this.invalidTin = false;
        this.addOrd =false;
        this.logIn = false;
    }

    ngOnInit() {
      this.showReqValidation = false;
      this.invalidIdn = false;
       if (this.isIOS()) {
           // document.body.style.position = 'fixed';
       }
    }

    ngOnChanges() {
      if (this.regValidation) {
        if (isNullOrUndefined(this.model) || this.model === '') {
          this.showReqValidation = true;
          this.isValid.emit(false);
        }else {
          this.showReqValidation = false;
          this.isValid.emit(true);
        }
      }
    }

    onBlur($event, type) {
        if (this.regValidation) {
            if (isNullOrUndefined(this.model) || this.model === '') {
                this.showReqValidation = true;
              this.isValid.emit(false);
            }else {
                this.showReqValidation = false;
                this.isValid.emit(true);
            }
        }
        switch(type){
          case 'email':
            if (this.model) {
              const emailSubString = this.model.split('@');
              const emailRgx = /^[a-z|A-Z|0-9|]*([_][a-z|A-Z|0-9]+)*([.][a-z|A-Z|0-9]+([_][a-z|A-Z|0-9]+)*)?@[a-z|A-Z][a-z|A-Z|0-9|]*\.([a-z|A-Z][a-z|A-Z|0-9]*(\.[a-z|A-Z][a-z|A-Z|0-9]*)?)$/;
              if (!this.model.match(emailRgx)) {
                this.invalidEmail = true;
                this.isValid.emit(false);
              } else if (emailSubString[0].length === 0) {
                this.invalidEmail = true;
                this.isValid.emit(false);
              } else {
                this.invalidEmail = false;
                  this.isValid.emit(true);
                }
              }
            break;
          case 'password':
            if (this.model) {
              const passwordRgx = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\$%]).{8,20})$/;
              if (!this.model.match(passwordRgx) && (!this.validPassword)) {
                this.invalidPassword = true;
                this.isValid.emit(false);
              } else {
                this.invalidPassword = false;
                this.isValid.emit(true);
              }
            }
            break;
          case 'mobile-number':
            let patternMob;
            this.authService.mobPattern.subscribe(item => {
              patternMob = item;
            });
            if(this.model) {
              let BreakException = {};
              const pattern: any = (!isNullOrUndefined(patternMob) && patternMob !== '') ? patternMob : this.constant.mobilePattern;
              const mobileRgx: any = new RegExp(pattern);
              try {
                mobileRgx.forEach(mobPattern => {
                  if (mobPattern.test(this.model)) {
                    this.invalidMobile = false;
                    this.isValid.emit(true);
                    throw BreakException;
                  } else {
                    this.invalidMobile = true;
                    this.isValid.emit(false);
                  }
                });
              } catch (err) {
                console.log('error');
              }
            }
            break;

          case 'Idn' :
            if (this.model) {
              const importDNRgx = /^([a-zA-Z0-9])$/;
              if (this.model.match(importDNRgx)) {
                this.invalidIdn = true;
              } else {
                this.invalidIdn = false;
              }
                }

            break;

          case 'pattern' :
            if (this.label === 'TIN') {
              if (this.model.length === 15 || this.model.length === 0) {
                this.invalidTin = false;
              } else {
                this.invalidTin = true;
              }
            } else {
              if (!isNullOrUndefined(this.model) && this.model.length === 15) {
                this.invalidTrn = false;
              } else {
                this.invalidTrn = true;
              }
            }

            break;
          default: break;
        }

      $event.validPassword = this.invalidPassword;
       this.blur.emit($event);
    }



  onInput(type){

    let validMail;
    let validPassword;

    switch(type) {
      case 'email':
        if (type === 'email') {
          if (this.model) {
            const emailRgx = /^[a-z|A-Z|0-9|]*([_][a-z|A-Z|0-9]+)*([.][a-z|A-Z|0-9]+([_][a-z|A-Z|0-9]+)*)?@[a-z|A-Z][a-z|A-Z|0-9|]*\.([a-z|A-Z][a-z|A-Z|0-9]*(\.[a-z|A-Z][a-z|A-Z|0-9]*)?)$/;
            if (this.model.match(emailRgx)) {
              this.invalidEmail = false;
              validMail = true;
            } else {
              validMail = false;
            }
          }
          this.inputChange.emit({event: event, validMail: validMail});

        }
        break;

        case 'textarea':
        break;

      default:break;
      // case 'password' :
      //
      //   if (type === 'password') {
      //     if (this.model) {
      //       const passwordRgx = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#*/\$%]).{8,20})$/;
      //       if (this.model.match(passwordRgx)) {
      //         this.invalidEmail = false;
      //         validPassword=true;
      //       }else {
      //         validPassword=false;
      //       }
      //     }
      //     this.inputChange.emit({event: event, validPassword: validPassword});
      //
      //   }
      //   break;
    }

    // if(this.max >= 0){
    //
    //   if(this.model>this.max){
    //     this.model=this.max;
    //   }
    //
    // }
  }
   isIOS() {

    let iDevices = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ];

    if (!!navigator.platform) {
      while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) { return true; }
      }
    }

    return false;
  }

  allowDecimal(e) {
    if ((e.keyCode == 8)) {
      return true;
    }
    let model=this.model.toString();
    if((isNaN(e.key )&& e.keyCode!=46) || e.keyCode == 32){

      return false;

    }else if(model.includes('.') && e.keyCode == 46){
        e.preventDefault();
        return false;
      }else if (model.includes('.')) {

        let pointIndex = model.indexOf('.');
        let res = model.substring(pointIndex+1, model.length);
        if(res.length == 2){
          e.preventDefault();
          return false;
        }
      }
  }



  onPaste(event: any) {
    let data = parseInt(event.clipboardData.getData('Text'));
    if (isNaN(data)) {
      event.preventDefault();
    }
}

  /*restrictAlphaNumeric(e) {
      if (!(e.which > 47 && e.which < 58) && // numeric (0-9)
        !(e.which > 64 && e.which < 91) && // upper alpha (A-Z)
        !(e.which > 96 && e.which < 123) &&
        (e.keyCode !== 8)) {
        return false;
      }  else {
        return true;
  }
     }*/
}
