import {
    Component, Inject, OnInit, AfterViewInit, ViewContainerRef, ElementRef, Renderer2,
    ViewChild
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {animate, state, style, transition, trigger} from "@angular/animations";

import { AppConstants } from '../../../../services/constant';
import { Broadcaster } from '../../../../services/sharable.service';
import { APP_CONFIG, IConfig } from "../../../../appConfig/Iconfig";
import {StateService} from '@uirouter/core';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-layout-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.css'],
    animations:[
        trigger('flipState', [
            state('active', style({
                transform: 'rotateY(179.9deg)'
            })),
            state('inactive', style({
                transform: 'rotateY(0)'
            })),
            transition('active => inactive', animate('500ms ease-out')),
            transition('inactive => active', animate('500ms ease-in'))
        ])
    ]
})

export class HomeFooterComponent implements OnInit {
    appColor: string;
    public pageContents: any;
    private currentLinkObject:any;
    isURLCorrect:boolean;
    contentEdit:boolean=false;
    flip: string = 'inactive';
    public applicationMode:string;
    private linkLength: number = 0;
    private color:any;
    // private isOpenLink:boolean;

    @ViewChild('lnkPopover',{static:false}) el:ElementRef;
    private currentLink: string;
  constructor(@Inject(DOCUMENT) private document: any, private service: Broadcaster , private constant: AppConstants,
              public toastr: ToastrService,vcr: ViewContainerRef, private rd: Renderer2,@Inject(APP_CONFIG) private iconfig: IConfig,
              private stateService: StateService) {
      //this.toastr.setRootViewContainerRef(vcr);
      this.applicationMode = iconfig.applicationMode;
  }

  ngOnInit() {
    this.initMethods();
    this.initValues();
  }

    initValues(){
      this.currentLinkObject={};
      this.isURLCorrect=false;
      this.pageContents= {};
      this.color="red";
      // this.isOpenLink=false;
      this.currentLink="";
      // this.applicationMode="";
    }

  gotoTop() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

    private initMethods() {
        this.getBroadCastMessages();
    }

    private getBroadCastMessages() {
        const self = this;

        this.service.on<string>('footerPageColor')
            .subscribe(message => {
               self.appColor = message;
            });

        this.service.on<string>('changeSectionData')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents = rec;
                self.linkLength = self.pageContents.socialLinks.length;
            });
        this.service.on<string>('setApplicationMode')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.applicationMode = rec;
            });
    }
    saveLink(){
        const self=this;

            this.isURLCorrect = true;
            this.pageContents.socialLinks.forEach(function (link) {
                if(link.linkName===self.currentLinkObject.linkName){
                    if(self.isUrl()) {
                        link.linkURL = self.currentLinkObject.linkURL;
                        self.toastr.success('URL saved sucessfully', 'Success!');
                    }
                    else{
                        self.isURLCorrect = false;
                        if(self.currentLinkObject.disabled===false)
                        self.toastr.error('Please enter correct URL', 'Invalid URL');
                    }
                    if(self.currentLinkObject.disabled){
                       link.flip = 'active';
                    }
                    else{
                        link.flip = 'inactive';
                    }
                    link.disabled=self.currentLinkObject.disabled;
                }
            });
            this.service.broadcast('setLinkURL', this.pageContents.socialLinks);
        this.currentLinkObject={};
    }
    setCurrentLinkObject(linkName){
        const self=this;
        // this.el.nativeElement.show();
        this.pageContents.socialLinks.forEach(function (link) {
            if(link.linkName===linkName){
                self.currentLinkObject.linkName=linkName;
                self.currentLinkObject.linkURL=link.linkURL;
                self.currentLinkObject.disabled=link.disabled;
            }
        });
    }

    isUrl() {
        var regexp = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
        return regexp.test(this.currentLinkObject.linkURL);
    }

    editFooterContent(){
        $('#summernoteFooter').summernote({
            height: 120,
            minHeight: null,
            maxHeight: null,
            focus: true
        });
       this.contentEdit = true;
    }

    saveFooterContent(){
        this.pageContents.footerContent = $('#summernoteFooter').summernote('code');
        $('#summernoteFooter').summernote('destroy');
        this.pageContents.footerContent.trim();
        this.service.broadcast('updateFooterContent', this.pageContents.footerContent);
        this.contentEdit = false;
    }

    cancelFotterContent(){
        $('#summernoteFooter').summernote('destroy');
        this.contentEdit = false;
    }

    toggleFlip() {
        this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
    }
    closePopover(popup){
        setTimeout(function () {
            popup.hide();
        },1);

    }

    flipData(link){
        link.flip = 'inactive';
        link.disabled=false;
        this.currentLinkObject.disabled=false;
    }

    setCurrentLink(linkURL){
        this.currentLink=linkURL;
    }

    validateData(data){
        if(data){
            return true;
        }else{
            return false;
        }
    }

    OnNoDynamicWebsite(){
        // this.isOpenLink=true;
        // if(this.currentLink!=""){
            window.open(this.currentLink,'_blank');
        //}
    }

    onCreateDynamicWebsite(){
        this.stateService.go('web.home');
        // this.service.broadcast('changeAppMode', 'C');
    }
}
