import {Component, Inject, OnInit, ViewContainerRef} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import {Broadcaster} from '../../../../services/sharable.service';
import {WebsiteService} from '../../service/website.services';
import {UtilsService} from '../../../../utils/common.util';
import {APP_CONFIG, IConfig} from '../../../../appConfig/Iconfig';
import { AuthService } from '../../../../services/auth.service';

declare var jquery: any;
declare var $: any;
declare var goog_report_conversion: any;

@Component({
  selector: 'app-layout-sidebar',
  templateUrl: 'sidemenu.component.html',
  styleUrls: ['sidemenu.component.css']
})
export class HomeSidebarComponent implements OnInit {
   opened: boolean;
   modeNum: number;
   positionNum: number;
   dock: boolean;
   closeOnClickOutside: boolean;
   closeOnClickBackdrop: boolean;
   showBackdrop: boolean;
   animate: boolean;
   trapFocus: boolean;
   autoFocus: boolean;
   keyClose: boolean;
   autoCollapseHeight: number = null;
   autoCollapseWidth: number = null;

   MODES: Array<string> = ['over', 'push', 'slide'];
   POSITIONS: Array<string> = ['left', 'right', 'top', 'bottom'];

   userData: any = {
    name: '',
    email: '',
    password: '',
    domain: '',
    mobile: ''
  };
   contactData: any = {};
   errorMessage: string;
   req: any = {};
  showReqVal: boolean;
  invalidReqMobile: boolean;
  invalidEmail: boolean;
  loading: boolean;
  senderEmail: string;
  showReqName: boolean;
  showReqEmail: boolean;
  showReqPhone: boolean;
  showReqMessage: boolean;
  controlCalled: any;
   showForm: boolean;
  constructor( private service: Broadcaster, private webService: WebsiteService, private authService: AuthService,
               public toastr: ToastrService, vcr: ViewContainerRef, public utilsService: UtilsService,
               @Inject(APP_CONFIG) private iconfig: IConfig) {
      //this.toastr.setRootViewContainerRef(vcr);
      this.opened = false;
      this.modeNum = 2;
      this.positionNum = 1;
      this.dock = false;
      this.closeOnClickOutside = false;
      this.closeOnClickBackdrop = false;
      this.showBackdrop = false;
      this.animate = true;
      this.trapFocus = true;
      this.autoFocus = true;
      this.keyClose = false;
  }

  ngOnInit() {
    this.initValues();
    this.initMethod();
  }

   toggleOpened(): void {
    this.opened = !this.opened;
  }

   toggleMode(): void {
    this.modeNum++;

    if (this.modeNum === this.MODES.length) {
      this.modeNum = 0;
    }
  }

   toggleAutoCollapseHeight(): void {
    this.autoCollapseHeight = this.autoCollapseHeight ? null : 500;
  }

   toggleAutoCollapseWidth(): void {
    this.autoCollapseWidth = this.autoCollapseWidth ? null : 500;
  }

   togglePosition(): void {
    this.positionNum++;

    if (this.positionNum === this.POSITIONS.length) {
      this.positionNum = 0;
    }
  }

   toggleDock(): void {
    this.dock = !this.dock;
  }

   toggleCloseOnClickOutside(): void {
    this.closeOnClickOutside = !this.closeOnClickOutside;
  }

   toggleCloseOnClickBackdrop(): void {
    this.closeOnClickBackdrop = !this.closeOnClickBackdrop;
  }

   toggleShowBackdrop(): void {
    this.showBackdrop = !this.showBackdrop;
  }

   toggleAnimate(): void {
    this.animate = !this.animate;
  }

   toggleTrapFocus(): void {
    this.trapFocus = !this.trapFocus;
  }

   toggleAutoFocus(): void {
    this.autoFocus = !this.autoFocus;
  }

   toggleKeyClose(): void {
    this.keyClose = !this.keyClose;
  }

   onOpenStart(): void {
  }

   onOpened(): void {
  }

   onCloseStart(): void {
  }

   onClosed(): void {
  }

   initValues() {
    this.showReqVal = false;
    this.invalidReqMobile = false;
    this.invalidEmail = false;
    this.loading = false;
    this.showReqName = false;
    this.showReqEmail = false;
    this.showReqPhone = false;
    this.showReqMessage = false;
    this.controlCalled = {
            reqname: false,
            reqmobile: false,
            reqemail: false,
            reqmessage: false
        };
     this.showForm = true;
  }

   initMethod() {
    this.getBroadcastMessage();
  }

  getBroadcastMessage() {
    const self = this;
    this.service.on<string>('showErrorMsg')
      .subscribe(message => {
        self.errorMessage = message;
      });
    this.service.on<string>('getContact')
      .subscribe(message => {
          let res: any;
          res = message;
          self.senderEmail = isNullOrUndefined(res.contactEmail) || res.contactEmail === '' ? self.senderEmail : res.contactEmail;
      });

  }

  checkModelValue (from) {
    if (isNullOrUndefined(this.req[from]) || this.req[from].trim() === '') {
      this.req[from] = '';
    }
    this.checkValidData(this.req);
  }
  createRequest(req) {
    const self = this;
    this.showReqVal = true;
    if (this.checkValidData(req)) {
      const reqData = {
        fromEmail: this.req.email.toLowerCase(),
        toEmail:  self.senderEmail,
        message: this.req.message,
        mobile: this.req.mobile,
        name: this.req.name
      };
      self.loading = true;
      this.webService.sendEmailRequest(reqData).subscribe(respData => {
          if (respData.status) {
            // this.toastr.success('Sent successfully', 'Request');
            this.req = {};
            this.showReqVal = false;
            // self.showForm = false;
            this.authService.generateWebRequest('Y');
            goog_report_conversion(self.iconfig.Application_URL + 'main/home');
          }else {
            this.toastr.error('Please try again later', '');
          }
          self.loading = false;
        },
        err => {
          this.toastr.error('Please try again later', '');
          self.loading = false;
        });
    }
  }

  checkValidData(req: any) {
      this.showReqName = false;
      this.showReqEmail = false;
      this.showReqPhone = false;
      this.showReqMessage = false;
    if (isNullOrUndefined(req.name) || req.name === '') {
        this.showReqName = true;
      return false;
    } else if (isNullOrUndefined(req.mobile) || req.mobile === '') {
        this.showReqPhone = true;
      return false;
    } else {
      const phoneno = /^\d{10}$/;
      if (!req.mobile.match(phoneno)) {
        this.invalidReqMobile = true;
        return false;
      }else {
        this.invalidReqMobile = false;
      }
    }
    if (isNullOrUndefined(req.email) || req.email === '') {
        this.showReqEmail = true;
      return false;
    } else {
      const emailRgx = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (!req.email.match(emailRgx)) {
        this.invalidEmail = true;
        return false;
      } else {
        this.invalidEmail = false;
      }
    }
    if (isNullOrUndefined(req.message) || req.message === '') {
        this.showReqMessage = true;
      return false;
    } else {return true; }
  }

    onFocus(type) {
      const self = this;
      if (this.controlCalled[type] === false) {
          $('#' + type).blur();
          setTimeout(function () {
              $('#' + type).focus();
          });
          self.controlCalled[type] = true;
          self.setOtherType(type);
      }
    }

    setOtherType(type) {
      const self = this;
      const key = Object.keys(this.controlCalled);
      key.forEach(function (item) {
         if (item !== type) {
             self.controlCalled[item] = false;
         }
      });
    }
  closeSideMenu() {
    this.showForm = true;
  }

}
