<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>

<div class="container"  *ngIf="applicationMode === 'C'">
  <div class="section1-summernote">
    <!--<div class="col-md-1 edit-btn nopadding">-->
      <!--<button container="body" placement="left" ngbTooltip="Edit Content" class="more-btn" (click)="editContent('header')" *ngIf="!contentEdit.editHeader">-->
        <!--<i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>-->
      <!--<button container="body" placement="left" ngbTooltip="Save Content" class="more-btn" (click)="saveContent('header')" *ngIf="contentEdit.editHeader"><i class="fa fa-floppy-o" aria-hidden="true"></i></button>-->
      <!--<button container="body" placement="left" ngbTooltip="Close" class="more-btn" (click)="cancelContent('header')" *ngIf="contentEdit.editHeader"><i class="fa fa-ban" aria-hidden="true"></i></button>-->
    <!--</div>-->
    <div class="col-md-11 nopadding">
      <div id="summernoteHeader" class="summernote"></div>

    </div>
  </div>
  <div class="edit-banner" >
    <button class="more-btn transparent-btn" container="body" placement="left" ngbTooltip="Update banner image" (click)="onEditBannerClick()"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
  </div>

</div>
<app-sections *ngFor="let headerMenu of pageContents.headerMenu" [currentComponent]="headerMenu">
</app-sections>

<div class="modal fade" id="bannerModal" tabindex="-1" role="dialog" aria-labelledby="galleryModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="galleryModalLabel">{{modalHeader}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body textcolor galleryModal">


        <div class="col-sm-6">
          <div class="clear-image" (click)="clearImage()"><i class="fa fa-refresh" aria-hidden="true"></i></div>
          <app-image [dropConfig]="dropConfig" [disableControl]="disabledGalleryUpload" [message]="message" [section]="'gallery'" [showImageIcon]="false" (Close)="retriveImage($event)"></app-image>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="(bannerImage == null || bannerImage == undefined)" (click)="saveGalleryModalData(galleryData)" >Save changes</button>
      </div>
    </div>
  </div>
</div>

