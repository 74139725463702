import {NgModule} from '@angular/core';
import {ModuleWithProviders} from '@angular/core';
import {UIRouterModule, UIRouter, Ng2StateDeclaration} from '@uirouter/angular';

import {HomeComponent} from './home/home.component';
import {MainComponent} from './layouts/main/main.component';

export const mainRoutes: Ng2StateDeclaration = {
    parent: 'app',
    name: 'main',
    url: '/main',
    component: MainComponent
  };

export const homeRoutes: Ng2StateDeclaration = { name: 'main.home',
  url: '/home',
  component: HomeComponent,
  params: {userData: {}}
};

export const DynamicRoute = UIRouterModule.forChild({ states: [mainRoutes, homeRoutes] });
