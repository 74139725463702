<div *ngIf ="!addOrd">
<div class="form-group" [ngClass]="customClass" *ngIf="type == 'text'">
  <label class="form-control-placeholder" for="controlinp-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger" *ngIf="mandatory" style="padding-left: 3px">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <input [ngClass]="{'textAlign':isOpration }" type="text" id="controlinp-{{id}}" class="form-control input-style" [placeholder]="placeholder"
    [attr.maxlength]="maxlength" [attr.minlength]="minleng" [(ngModel)]="model" (blur)="onBlur($event,'text')"
    [readonly]="isDisabled" [ngClass]="{'defaultClass': logIn, 'text-style': !logIn}" required>
</div>

<div class="form-group row" *ngIf="type == 'textBot'">
  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
    <label class="form-control-placeholder" for="controlinp-{{id}}">
      <span class="label-title short-text">{{label | titlecase}}{{':'}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  </div>
  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
    <span tooltip="{{title}}">
      <input type="text" id="controlinp-{{id}}" class="form-control" [placeholder]="placeholder"
      [attr.maxlength]="maxlength" [attr.minlength]="minleng" [(ngModel)]="model" (blur)="onBlur($event,'text')"
      [readonly]="isDisabled" required>
    </span>
  </div>
</div>

<div class="form-group" *ngIf="type == 'password'">
  <label class="form-control-placeholder" for="controlinp-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <label *ngIf="invalidPassword && !showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">Password must contain one upper case letter,special character and
    alphanumeric,length must be between 8 and 20.</label>
  <input type="password" id="controlPassword-{{id}}" class="form-control" [placeholder]="placeholder"
    minlength="8" maxlength="20" [attr.maxlength]="maxlength" (blur)="onBlur($event,'password')" [(ngModel)]="model"
    [readonly]="isDisabled" autocomplete="off" [ngClass]="{'defaultClass': logIn, 'text-style': !logIn}"

    required>
</div>

<div class="form-group" *ngIf="type == 'pass'">
  <label class="form-control-placeholder" for="controlinp-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <label *ngIf="invalidPassword && !showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">Password must contain one upper case letter,special character and
    alphanumeric,length must be between 8 and 20.</label>
  <input type="password" id="controlPassword-{{id}}" class="form-control input-style" [placeholder]="placeholder"
    minlength="6" maxlength="20" [attr.maxlength]="maxlength" [(ngModel)]="model" [readonly]="isDisabled"
    autocomplete="off" required>
</div>

<div class="form-group" *ngIf="type == 'email'">
  <label class="form-control-placeholder" for="emailcontrolinp-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <label *ngIf="invalidEmail && !showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">Invalid email id</label>
  <input type="email" id="emailcontrolinp-{{id}}" class="form-control input-style" [placeholder]="placeholder"
    [attr.maxlength]="maxlength" [(ngModel)]="model" (blur)="onBlur($event,'email')" (input)="onInput('email')"
    [readonly]="isDisabled" required>
</div>

<div class="form-group" *ngIf="type == 'number'" [ngClass]="customClass">
  <!--<input *ngIf="!isShipping" type="text" id="formcontrolinp-{{id}}" class="form-control input-style" [attr.maxlength]="maxlength" [placeholder]="placeholder" [(ngModel)]="model" (blur)="onBlur($event)" (keypress)="this.utilsService.restrictNumeric($event)" [readonly]="isDisabled"  required>-->

  <label class="form-control-placeholder" for="controlinp-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <input type="text" pattern="{{pattern ? pattern: '.*'}}"
    [ngClass]="{'right-text': isShipping, 'not-allowed': isDisabled}" id="formcontrolinp-{{id}}"
    [attr.maxlength]="maxlength" class="form-control input-style" [placeholder]="placeholder" [(ngModel)]="model"
    (blur)="onBlur($event,'number')" (keypress)="this.utilsService.restrictNumeric($event)" [readonly]="isDisabled"
    required>
</div>

<div class="form-group" *ngIf="type === 'textarea'">
  <label class="form-control-placeholder" for="controltextarea-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <textarea rows="{{rows}}" id="controltextarea-{{id}}" class="form-control textarea-style" [attr.maxlength]="maxlength"
    [placeholder]="placeholder" [(ngModel)]="model" (blur)="onBlur($event,'textarea')" [readonly]="isDisabled"
    required></textarea>
</div>

<div class="form-group" *ngIf="type == 'amount'">

  <label class="form-control-placeholder" for="controlinp-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <input type="text" style="text-align: right;" [ngClass]="{'right-text': isShipping, 'not-allowed': isDisabled}"
    id="form-controlinp-{{id}}" [attr.maxlength]="maxlength" class="form-control input-style"
    [placeholder]="placeholder" [(ngModel)]="model" (blur)="onBlur($event,'number')" (keypress)="allowDecimal($event)"
    (input)="onInput(max)" [readonly]="isDisabled" required>
</div>

<div class="form-group" *ngIf="type == 'pattern'">
  <label class="form-control-placeholder" for="controlinp-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <label *ngIf="invalidTrn && !showReqValidation" class="text-danger label-error" [ngStyle]="customErrLabelStyle">TRN
    Number Must Be 15 Digits </label>
  <label *ngIf="invalidTin && !showReqValidation" class="text-danger label-error" [ngStyle]="customErrLabelStyle">TIN
    Number Must Be 15 Digits </label>
  <input type="text" id="pattern-{{id}}" class="form-control input-style" [placeholder]="placeholder"
    [attr.maxlength]="maxlength" [attr.minlength]="minlength" (keypress)="utilsService.restrictNumeric($event)"
    (paste)="onPaste($event)" [(ngModel)]="model" (blur)="onBlur($event,'pattern')" [readonly]="isDisabled" required>
</div>

<div class="form-group" *ngIf="type == 'Idn'">
  <label class="form-control-placeholder" for="controlinp-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <label *ngIf="invalidIdn && !showReqValidation" class="text-danger label-error" [ngStyle]="customErrLabelStyle">No
    Special Characters</label>
  <input type="text" id="Idn-{{id}}" class="form-control input-style" [placeholder]="placeholder"
    (keypress)="utilsService.restrictAlphaNumeric($event)" [(ngModel)]="model" (blur)="onBlur($event,'Idn')"
    [readonly]="isDisabled" required>
</div>

<div class="form-group" *ngIf="type == 'mobile-number'">
  <!--<input *ngIf="!isShipping" type="text" id="formcontrolinp-{{id}}" class="form-control input-style" [attr.maxlength]="maxlength" [placeholder]="placeholder" [(ngModel)]="model" (blur)="onBlur($event)" (keypress)="this.utilsService.restrictNumeric($event)" [readonly]="isDisabled"  required>-->
  <label class="form-control-placeholder" for="controlmobile-{{id}}">
    <span class="label-title">{{label}}</span>
    <span class="text-danger mandatory" *ngIf="mandatory">*</span>
  </label>
  <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
  <label *ngIf="showUserValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
  <label *ngIf="invalidMobile && !showReqValidation" class="text-danger label-error"
    [ngStyle]="customErrLabelStyle">Please Enter valid Mobile Number</label>
  <input type="text" pattern="{{pattern ? pattern: '.*'}}"
    onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 0' minlength="7"
    maxlength="10" [ngClass]="{'right-text': isShipping, 'not-allowed': isDisabled}" id="controlmobile-{{id}}"
    [attr.maxlength]="maxlength" class="form-control input-style" [placeholder]="placeholder" [(ngModel)]="model"
    (blur)="onBlur($event,'mobile-number')" (keypress)="utilsService.restrictNumeric($event)" [readonly]="isDisabled"
    required>
</div>
</div>

<div *ngIf ="addOrd">
  <div class=" row form-group orgName" [ngClass]="customClass" *ngIf="type == 'text'">
    <label class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 form-control-placeholder" for="controlinp-{{id}}">
      <span class="label-title-addOrg label-title">{{label}}</span>
      <span class="text-danger" *ngIf="mandatory" style="padding-left: 3px">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <input [ngClass]="{'textAlign':isOpration }" type="text" id="controlinp-{{id}}" 
    class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 form-control input-style-addOrg" [placeholder]="placeholder"
      [attr.maxlength]="maxlength" [attr.minlength]="minleng" [(ngModel)]="model" (blur)="onBlur($event,'text')"
      [readonly]="isDisabled" required>
  </div>
  
  <div class="form-group row" *ngIf="type == 'textBot'">
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <label class="form-control-placeholder" for="controlinp-{{id}}">
        <span class="label-title short-text">{{label | titlecase}}{{':'}}</span>
        <span class="text-danger mandatory" *ngIf="mandatory">*</span>
      </label>
      <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
      <label *ngIf="showUserValidation" class="text-danger label-error"
        [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
      <span tooltip="{{title}}">
        <input type="text" id="controlinp-{{id}}" class="form-control" [placeholder]="placeholder"
        [attr.maxlength]="maxlength" [attr.minlength]="minleng" [(ngModel)]="model" (blur)="onBlur($event,'text')"
        [readonly]="isDisabled" required>
      </span>
    </div>
  </div>
  
  <div class="form-group" *ngIf="type == 'password'">
    <label class="form-control-placeholder" for="controlinp-{{id}}">
      <span class="label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <label *ngIf="invalidPassword && !showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">Password must contain one upper case letter,special character and
      alphanumeric,length must be between 8 and 20.</label>
    <input type="password" id="controlPassword-{{id}}" class="form-control input-style" [placeholder]="placeholder"
      minlength="8" maxlength="20" [attr.maxlength]="maxlength" (blur)="onBlur($event,'password')" [(ngModel)]="model"
      [readonly]="isDisabled" autocomplete="off" required>
  </div>
  
  <div class="row orgName form-group" *ngIf="type == 'pass'">
    <label class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 form-control-placeholder" for="controlinp-{{id}}">
      <span class="label-title-addOrg label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <label *ngIf="invalidPassword && !showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">Password must contain one upper case letter,special character and
      alphanumeric,length must be between 8 and 20.</label>
    <input type="password" id="controlPassword-{{id}}" 
    class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 form-control input-style-addOrg" [placeholder]="placeholder"
      minlength="6" maxlength="20" [attr.maxlength]="maxlength" [(ngModel)]="model" [readonly]="isDisabled"
      autocomplete="off" required>
  </div>
  
  <div class="row orgName form-group" *ngIf="type == 'email'">
    <label class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 form-control-placeholder" for="emailcontrolinp-{{id}}">
      <span class="label-title-addOrg label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <label *ngIf="invalidEmail && !showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">Invalid email id</label>
    <input type="email" id="emailcontrolinp-{{id}}" 
    class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 form-control input-style-addOrg" [placeholder]="placeholder"
      [attr.maxlength]="maxlength" [(ngModel)]="model" (blur)="onBlur($event,'email')" (input)="onInput('email')"
      [readonly]="isDisabled" required>
  </div>
  
  <div class="form-group" *ngIf="type == 'number'">
    <!--<input *ngIf="!isShipping" type="text" id="formcontrolinp-{{id}}" class="form-control input-style" [attr.maxlength]="maxlength" [placeholder]="placeholder" [(ngModel)]="model" (blur)="onBlur($event)" (keypress)="this.utilsService.restrictNumeric($event)" [readonly]="isDisabled"  required>-->
  
    <label class="form-control-placeholder" for="controlinp-{{id}}">
      <span class="label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <input type="text" pattern="{{pattern ? pattern: '.*'}}"
      [ngClass]="{'right-text': isShipping, 'not-allowed': isDisabled}" id="formcontrolinp-{{id}}"
      [attr.maxlength]="maxlength" class="form-control input-style-addOrg" [placeholder]="placeholder" [(ngModel)]="model"
      (blur)="onBlur($event,'number')" (keypress)="this.utilsService.restrictNumeric($event)" [readonly]="isDisabled"
      required>
  </div>
  
  <div class="form-group" *ngIf="type === 'textarea'">
    <label class="form-control-placeholder" for="controltextarea-{{id}}">
      <span class="label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <textarea rows="{{rows}}" id="controltextarea-{{id}}" class="form-control textarea-style" [attr.maxlength]="maxlength"
      [placeholder]="placeholder" [(ngModel)]="model" (blur)="onBlur($event,'textarea')" [readonly]="isDisabled"
      required></textarea>
  </div>
  
  <div class="form-group" *ngIf="type == 'amount'">
  
    <label class="form-control-placeholder" for="controlinp-{{id}}">
      <span class="label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <input type="text" style="text-align: right;" [ngClass]="{'right-text': isShipping, 'not-allowed': isDisabled}"
      id="form-controlinp-{{id}}" [attr.maxlength]="maxlength" class="form-control input-style"
      [placeholder]="placeholder" [(ngModel)]="model" (blur)="onBlur($event,'number')" (keypress)="allowDecimal($event)"
      (input)="onInput(max)" [readonly]="isDisabled" required>
  </div>
  
  <div class="form-group" *ngIf="type == 'pattern'">
    <label class="form-control-placeholder" for="controlinp-{{id}}">
      <span class="label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <label *ngIf="invalidTrn && !showReqValidation" class="text-danger label-error" [ngStyle]="customErrLabelStyle">TRN
      Number Must Be 15 Digits </label>
    <label *ngIf="invalidTin && !showReqValidation" class="text-danger label-error" [ngStyle]="customErrLabelStyle">TIN
      Number Must Be 15 Digits </label>
    <input type="text" id="pattern-{{id}}" class="form-control input-style" [placeholder]="placeholder"
      [attr.maxlength]="maxlength" [attr.minlength]="minlength" (keypress)="utilsService.restrictNumeric($event)"
      (paste)="onPaste($event)" [(ngModel)]="model" (blur)="onBlur($event,'pattern')" [readonly]="isDisabled" required>
  </div>
  
  <div class="form-group" *ngIf="type == 'Idn'">
    <label class="form-control-placeholder" for="controlinp-{{id}}">
      <span class="label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <label *ngIf="invalidIdn && !showReqValidation" class="text-danger label-error" [ngStyle]="customErrLabelStyle">No
      Special Characters</label>
    <input type="text" id="Idn-{{id}}" class="form-control input-style" [placeholder]="placeholder"
      (keypress)="utilsService.restrictAlphaNumeric($event)" [(ngModel)]="model" (blur)="onBlur($event,'Idn')"
      [readonly]="isDisabled" required>
  </div>
  
  <div class=" row orgName form-group" *ngIf="type == 'mobile-number'">
    <!--<input *ngIf="!isShipping" type="text" id="formcontrolinp-{{id}}" class="form-control input-style" [attr.maxlength]="maxlength" [placeholder]="placeholder" [(ngModel)]="model" (blur)="onBlur($event)" (keypress)="this.utilsService.restrictNumeric($event)" [readonly]="isDisabled"  required>-->
    <label class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 form-control-placeholder" for="controlmobile-{{id}}">
      <span class="label-title-addOrg label-title">{{label}}</span>
      <span class="text-danger mandatory" *ngIf="mandatory">*</span>
    </label>
    <label *ngIf="showReqValidation" class="text-danger label-error" [ngStyle]="reqLabelStyle">{{reqErrorMessage}}</label>
    <label *ngIf="showUserValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">{{errorMessage}}</label>
    <label *ngIf="invalidMobile && !showReqValidation" class="text-danger label-error"
      [ngStyle]="customErrLabelStyle">Please Enter valid Mobile Number</label>
    <input type="text" pattern="{{pattern ? pattern: '.*'}}"
      onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 0' minlength="7"
      maxlength="10" [ngClass]="{'right-text': isShipping, 'not-allowed': isDisabled}" id="controlmobile-{{id}}"
      [attr.maxlength]="maxlength" 
      class=" col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 form-control input-style-addOrg" [placeholder]="placeholder" [(ngModel)]="model"
      (blur)="onBlur($event,'mobile-number')" (keypress)="utilsService.restrictNumeric($event)" [readonly]="isDisabled"
      required>
  </div>
  </div>