import { Component, Inject, OnInit ,Input} from '@angular/core';

declare var jquery: any;
declare var $: any;


@Component({
    selector: 'app-sections',
    templateUrl: 'section.component.html',
})
export class SectionComponent implements OnInit {

    @Input() currentComponent: any;

    constructor() {
    }

    ngOnInit() {
        console.log("current comppnent",this.currentComponent);
    }
}