import {Component, Inject, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-page',
    template: `
        <ui-view></ui-view>
    `,
    styleUrls: ['page.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class PageComponent {

    constructor() {
    }

}
