import {Component, OnInit , Inject , ViewContainerRef} from '@angular/core';
import {StateService, TransitionService} from '@uirouter/core';
// import {DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {JwtHelperService} from '@auth0/angular-jwt';

import { Broadcaster } from '../../../../services/sharable.service';
import {APP_CONFIG, IConfig} from '../../../../appConfig/Iconfig';
import {isNullOrUndefined} from 'util';
import { AuthService } from '../../../../services/auth.service';

declare var jquery: any;
declare var $: any;


@Component({
  selector: 'app-layout-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.css']
})
export class HomeHeaderComponent implements OnInit {
  globalData: any = {};
  headerMenu: any;
  currentTransition: any;
  disabledImageUpload: false;
    modalHeader: string;
    message: string;
    logoImage: any;
    isDimmed: boolean;
    highlightMenu: boolean;
   public dropConfig: any;
   /* public dropConfig: DropzoneConfigInterface = {
        url: 'https://httpbin.org/post',
        clickable: true,
        maxFiles: 1,
        autoReset: null,
        errorReset: null,
        cancelReset: null
    };*/
    applicationMode: any;
    pageDimmed: boolean;
    private loading: boolean;
    public langSetKey: string;
    public disabledGalleryUpload: any;
    public galleryData: any;
    private translateService: TranslateService;
    private currLang: string;
  constructor(private broadcaster: Broadcaster, private transitionService: TransitionService, private authService: AuthService,
              private stateService: StateService, @Inject(APP_CONFIG) private iconfig: IConfig,
              public toastr: ToastrService, vcr: ViewContainerRef, public translate: TranslateService) {
      this.translateService = this.translate;
      //this.toastr.setRootViewContainerRef(vcr);
      this.applicationMode = iconfig.applicationMode;
      translate.addLangs(['en', 'arb']);
      let language;
        this.authService.langKey.subscribe(item => {
        language = item;
        });
      this.currLang = isNullOrUndefined(language) ||
      language === '' ? 'en' : language;
      translate.setDefaultLang(this.currLang);

      let browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|arb/) ? this.currLang : browserLang);
      translate.currentLang = this.currLang;
  }

  ngOnInit() {
    this.globalData['selectedHeader'] = 'header';
    this.getBroadcastMessage();
    this.initValues();
    this.initMethod();
  }

  getBroadcastMessage() {
    const self = this;
    this.broadcaster.on<string>('changeHeader')
      .subscribe(message => {
          self.loading = false;
        self.globalData['selectedHeader'] = message;
      });

    this.broadcaster.on<string>('changeHeaderImg')
      .subscribe(message => {
        self.globalData['selectedImage'] = message;
        this.toastr.success('Image updated successfully', 'Success!');
      });

      this.broadcaster.on<string>('changeHeaderData')
          .subscribe(message => {
              let rec: any;
              rec = message;
              self.globalData['selectedImage'] = rec.headerImage;
              self.headerMenu = rec.headerMenu;
              self.globalData['appLogo'] = rec.appLogo;
          });
      this.broadcaster.on<string>('setApplicationMode')
          .subscribe(message => {
              let rec: any;
              rec = message;
              self.applicationMode = rec;
          });

      this.broadcaster.on<string>('setLogoImage')
          .subscribe(message => {
              let rec: any;
              rec = message;
              self.globalData['appLogo'] = rec;
              this.toastr.success('Logo updated successfully', 'Success!');
          });

      this.broadcaster.on<string>('setBannerImage')
          .subscribe(message => {
              let rec: any;
              rec = message;
              self.globalData['selectedImage'] = rec;
              this.toastr.success('Banner image updated successfully', 'Success!');
          });

      this.broadcaster.on<string>('HighLightMenu')
          .subscribe(message => {
              let rec: any;
              rec = message;
              self.highlightMenu = rec;
          });
  }

  private initValues() {
   this.currentTransition = this.stateService.current.name;
   this.isDimmed = false;
   this.highlightMenu = false;
   this.pageDimmed = false;
   this.loading = false;
  }

  getItemId(item: any): string {
    return item.sectionId;
  }

  private initMethod() {
      $('[data-toggle="tooltip"]').tooltip();
    const self = this;
    this.transitionService.onSuccess({}, function(transition) {
      self.currentTransition = transition.to().name;
    });
      /*this.pageContents = this.constant.pageContentsArabics;
      this.setPageContentDataForServices();*/
      this.changeLanguage(this.translate.currentLang);
  }
  getPageLink(item: any) {
    this.stateService.go(item.link);
  }

    onEditLogoClick() {
        this.message = 'Click or drag images here to upload (Logo size can not be greater than 2KB).';
        this.modalHeader = 'Update logo'
        $('#logoModal').modal('show');
    }

  checkPageState(item) {
    if (this.stateService.current.name !== 'main.home') {
      this.globalData['selectedHeader'] = 'header';
      this.stateService.go('main.home');
    }
      this.globalData['selectedHeader'] = item.sectionId;
  }

    clearImage() {
        this.broadcaster.broadcast('clearGalleryImage', 'clear');
    }

  dynamicStyles(): any {
    /*return {
        'background': 'url(\'' + this.globalData['selectedImage'] + '\') center 0 no-repeat ',
        'background-size': 'cover',
         };*/
  }

    retriveImage(event) {
        if (event) {
            if (event.length > 0) {
                if (event[0].size < 204800) {
                    this.logoImage = event[0].dataURL;
                } else {
                    this.toastr.error('Logo size can not be greater than 2KB', 'Required');
                    this.broadcaster.broadcast('clearGalleryImage', 'clear');
                }
            }
        }
    }

    saveGalleryModalData(data) {
        this.broadcaster.broadcast('setLogoImage', this.logoImage);
        this.globalData['appLogo'] = this.logoImage;
        $('#logoModal').modal('hide');
        this.logoImage = null;
        this.broadcaster.broadcast('clearGalleryImage', 'clear');
    }

    setPageDim() {
        this.isDimmed = true;
    }
    changeToArabic(lang){
        let token = lang;
        if (token) {
            this.authService.generateLangKey(token);
        }
        this.authService.langKey.subscribe(item => {
            this.langSetKey = item;
        });
        this.broadcaster.broadcast('changelang', lang);
    }

    private changeLanguage(lang) {
        const self = this;
        setTimeout(function(){
            self.changeToArabic(lang);
        },2000);
        let browserLang = this.translateService.getBrowserLang();
        this.translateService.use(browserLang.match(/en|arb/) ? this.currLang : browserLang);
       /* translate.currentLang = currLang;*/
    }
}
