import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[onBlurText]'
})
export class BlurDirective {

    constructor(private el: ElementRef) { }

    @Input('onBlurText') textInput: string;

    @HostListener('blur') onMouseEnter() {
         this.el.nativeElement.value=this.textInput.trim();
    }
}