/**
 * Created by rootsinnovation on 19/12/17.
 */
import {
  Compiler, Component, AfterViewInit, ElementRef, Renderer2, ViewChild, OnInit, Inject,
  forwardRef, ViewContainerRef
} from '@angular/core';

import {ConfirmationService} from "primeng/api";
// import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {Observable} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {AppConstants} from "../../services/constant";
import {APP_CONFIG, IConfig} from "../../appConfig/Iconfig";
import {Broadcaster} from "../../services/sharable.service";
import {StateService} from '@uirouter/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {LoaderComponent} from "../share/components/loaderComponent/loader.component";
import { ToastrService } from 'ngx-toastr';
import {AppComponent} from "../app.component";
import {TranslateService} from "ng2-translate";
import {isNullOrUndefined} from "util";

enum MenuOrientation {
    STATIC,
    OVERLAY,
    HORIZONTAL
};

// export var appProvider = {
//     provide: WebAppComponent,
//     useExisting: forwardRef(function () { return WebAppComponent; })
// };

declare var $: any;
declare var jquery: any;

@Component({
    selector: 'webapp-root',
    templateUrl: 'web.component.html',
    providers: [ Broadcaster ]
    // styleUrls: ['../app.component.scss', './app.component.css']
})
export class WebAppComponent implements OnInit {
    // @BlockUI() blockUI: NgBlockUI;
    public message:string;
    public displayError:boolean=false;
    public displaySuccess:boolean=false;
    public displayMessage:boolean=false;
    public showChatWin:boolean=false;
    private show:boolean=false;
    private APP_ID:string;
    private SUB_DOMAIN:string;
    private requestedOrg:Array<any>=[];
    isConnected: boolean = navigator.onLine;//Observable<boolean>;
    public currentOrgName:string;
    public currentOrg:string;
    public changeGstinFlag:boolean=true;
    public gstrOrg:string;
    public bussinessType: string;
    modalRef: BsModalRef;
    constructor(public renderer: Renderer2, public _authservice: AuthService,
                public broadcaster: Broadcaster,
                private _compiler:Compiler,
                public stateService: StateService,
                public confirmationService:ConfirmationService,
                public constant: AppConstants,
                @Inject(APP_CONFIG) public _config: IConfig,
                private modalService: BsModalService, private toastr: ToastrService, private vcr: ViewContainerRef,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent, private translate:TranslateService
    ) {
      //this.toastr.setRootViewContainerRef(vcr);
      this.APP_ID = _config.APP_ID;
        this.SUB_DOMAIN = _config.SUB_DOMAIN;
        this._compiler.clearCache();

        window.addEventListener('online', () => {this.isConnected = true});
        window.addEventListener('offline', () => {this.isConnected = false});
        // this.isConnected = Observable.merge(
        //     Observable.of(navigator.onLine),
        //     Observable.fromEvent(window, 'online').map(() => true),
        //     Observable.fromEvent(window, 'offline').map(() => false));
      let labelFilePath = this.app.translateLanguage();
      translate.setDefaultLang("../" + labelFilePath.substring(labelFilePath.lastIndexOf("assets"), labelFilePath.length));
    }

    ngOnInit() {
        /* this.router.events.subscribe((evt) => {
         if (!(evt instanceof NavigationEnd)) {
         return;
         }
         window.scrollTo(0, 0);
         });*/

    }

    ngOnDestroy() {}

    ngAfterViewInit() {

    }

    public showError(msg){
        this.message=msg;
        this.displayError=true;
    }
    public showSuccess(msg){
        this.message=msg;
        this.displaySuccess=true;
    }
    public showMessage(msg){
        this.message=msg;
        this.displayMessage=true;
    }
    public hideDailog(){
        this.message="";
        this.displayError=false;
        this.displaySuccess=false;
        this.displayMessage=false;
    }

    public startLoader() {
      if(!document.querySelector('#cssId')){
        let style = document.createElement('style');
          style.type = 'text/css';
          style.id  = 'cssId'
          style.innerHTML = '.cssClass { position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1040;background-color: #ffffff70;text-align: center;display: flex;justify-content: center;align-items: center; }';
          document.getElementsByTagName('head')[0].appendChild(style);

          document.body.addEventListener('keyup', function(e) {
            if (e.key == "Escape") {
              if(document.querySelector('.cssClass')){
                document.body.removeChild(document.querySelector('.cssClass'))
              }
            }
          });
      }

      let loaderImg = document.createElement('img');
          loaderImg.alt = 'Loader';
          loaderImg.src = 'assets/img/loaderRed.gif';
          loaderImg.style.height = '8%';

      let templateEle = document.createElement('div');
          templateEle.classList.add('cssClass');
          templateEle.appendChild(loaderImg);
          document.body.appendChild(templateEle);
          
        // this.modalRef = this.modalService.show(LoaderComponent, {class: 'modal-sm loader-modal', animated: false, backdrop: true, ignoreBackdropClick: true});
        //  this.blockUI.start();
    }

// Function to Unblock UI
    public stopLoader() {
      if(document.querySelector('.cssClass')){
        document.body.removeChild(document.querySelector('.cssClass'))
      }
        // const self = this;
        // if (!isNullOrUndefined( self.modalRef)) {
        //   setTimeout(function () {
        //     self.modalRef.hide();

        //   }, 1000);
        // }

        //   this.blockUI.stop();
    }

    public goBack(){
        //this.location.back();
    }

    openChatWindow(){
        this.showChatWin=true;
        this.show=true;
    }
    closeChatWindow(){
        this.showChatWin = false;
        this.show=false;
    }
     showMsg(type, title, msg) {
       //this.toastr.setRootViewContainerRef(this.vcr);
       let self = this;
      if (type === 'warning') {
        self.toastr.warning(msg, title);

      }else if (type === 'success') {

        self.toastr.success(msg, title);


      }else if (type === 'error') {

        self.toastr.error(msg, title);
      }else if (type === 'info') {

        self.toastr.error(msg, title);
      }
     }

}
