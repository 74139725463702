/**
 * Created by root on 7/3/17.
 */
export class _AddressModel{
    state?:string;
    city?:string;
    pinCode?: string;
    area?:string;
    street?:string;
    stateCode?:string;
    code
?:
    string;
    name
?:
    string;
    //_id?:string;

    constructor(){
        this.state="";
        this.city="";
        this.pinCode = "0";
        this.area="";
        this.street="";
        this.stateCode="";
        this.code = "";
        this.name = "";
        //this._id="";
    }
}
export class _PersonalInfoModel{
    _id?:string;
    name?:string;
    email?:string;
    mobile?:string;
    landline?:string;
    pan?:string;
    fax?:string;
    dob?:string;
    password?:string;
    cpassword?:string;
    panNumber?: string;
    profilePic?: string;

    constructor(){
        this.name="";
        this.email="";
        this.mobile="";
        this.landline="";
        this.dob="";
        this.panNumber = "";
        this.profilePic = "";
    }
}
export class _OrganisationModel{
    _id?:string;
    name?:string;
    email?:string;
    owner?:number;
    mobile?:number;
    gstin?:string;
    professionType?:string;
    profession?:string;
    department?:string;
    website?:string;
    panNumber?: string;
   /* constructor(){
        this._id="";
        this.name="";
        this.email="";
        this.owner=0;
        this.mobile=0;
        this.gstin="";
        this.professionType="";
        this.profession="";
        this.department="";
        this.website="";
    }*/
}

export class Role{
    _id:string;
    roleName: string;
    roleDesc: string;
}

export class department{
    _id:string;
    deptName: string;
    deptDesc: string;
}
export class Address{
    stateCode?:string;
    street?:string;
    area?:string;
    pinCode?: string;
    city?:string;
    state?:string;
    code
?:
    string;
    name
?:
    string;
    constructor(){
        this.stateCode="";
        this.street="";
        this.area="";
        this.pinCode = "0";
        this.city="";
        this.state="";
        this.code = "";
        this.name = "";
    }
}
export class notificationSettings{
    pushEnable?:boolean;
    smsNotificationEnable?:boolean;
    emailNotificationEnable?:boolean;
    notificationPreference1?:any;
    notificationPreference2?:any;
    notificationPreference3?:any;
    isEnable?:boolean;
    disableNotification?:boolean;
    constructor(){
        this.pushEnable=false;
        this.smsNotificationEnable=false;
        this.emailNotificationEnable=false;
        this.notificationPreference1="";
        this.notificationPreference2="";
        this.notificationPreference3="";
        this.isEnable=false;
        this.disableNotification=false;

    }

}
export class User
{
    notificationSettings:notificationSettings=new notificationSettings();
    personalInfo:_PersonalInfoModel=new _PersonalInfoModel();
    address:_AddressModel=new _AddressModel();
    organisation:_OrganisationModel=new _OrganisationModel();
    _id:string;
    total_price:number;
    paymentId:string;
    paymentStatus:boolean;
    role:Role=new Role();
    //department:department=new department();
    supervisor:string;
    orgId:string;
    isActive:boolean;
    cartList:Array<Object>;
    userType:string;
    accessParams:string[]=[];
    branchList: string[] = [];
    type:string;
    gstin:string;  //this control field only
    customerGstin: string; // this for customer
    /*paan:string; // this for customer*/
    constructor(){
        this.address=new _AddressModel();
    }

}
