<div class="modal" tabindex="-1" role="dialog" id="passwordExpiry" data-backdrop="static">
    <div class="modal-dialog" role="document">
      <div class="modal-content pwd-expiry">
        <div class="modal-header">
          <span class="modal-title" style="font-size: 16px;">{{headerName}}</span>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="!isForm">
            <div class="otp-msg">OTP is sent to Your Mobile Number</div>
            <div style="text-align: center;">
              <input type="number" class="otp-input" placeholder="Enter OTP" [(ngModel)]="otp" (keyup.enter)="verifyOTPFn()">
            </div>
            <div style="text-align: center;">
              <button class="verify-btn" (click)="verifyOTPFn()">Verify</button>
            </div>
            <div class="resend-otp-box">
              <button [ngClass]="{'disabled-resend-otp':resendOtpTimer > 0}">Resend OTP <br><span *ngIf="resendOtpTimer > 0">{{timerDisplay}}</span></button>
            </div>
          </div>
          <div *ngIf="isForm">
            <div class="msg">{{msg}}</div>
            <form class="box" [formGroup]="pwdResetForm" (ngSubmit)="resetPassword()">
              <div>
                  <label for="current-pwd">Current Password</label>
                  <div>
                      <input [type]="showPwdInText === 'currentPwd' ? 'text':'password'" formControlName="currentPwd">
                      <img src="assets/img/eye.png" (click)="showInText('currentPwd')" class="eye">
                  </div>
              </div>
              <div>
                  <label for="new-pwd" >New Password</label>
                  <div>
                      <input [type]="showPwdInText === 'pwd' ? 'text':'password'" formControlName="pwd">
                      <img src="assets/img/eye.png" (click)="showInText('pwd')" class="eye">
                  </div>
              </div>
              <div>
                  <p *ngIf="pRF.pwd.touched && pRF.pwd.invalid" class="error-message">At least
                      12 length password must contain one upper case letter,special character and
                      alphanumeric <strong>& White space is also not allowed</strong></p>
              </div>
              <div>
                  <label for="confirm-new-pwd" >Confirm New Password</label>
                  <div>
                      <input [type]="showPwdInText === 'confirmPwd' ? 'text':'password'" formControlName="confirmPwd">
                      <img src="assets/img/eye.png" (click)="showInText('confirmPwd')" class="eye">
                  </div>
              </div>
              <div>
                  <p *ngIf="pRF.confirmPwd.touched && pRF.pwd.value !== pRF.confirmPwd.value" class="error-message">Passwords do not match</p>
              </div>
              <div style="justify-content: center;margin-top:30px;">
                  <button class="reset-btn">{{btnName}}</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
</div>