import {Component, Inject , OnInit , ElementRef , ViewContainerRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Broadcaster } from '../../../../../services/sharable.service';
import { HomeComponent } from '../../home.component';
import {APP_CONFIG, IConfig} from "../../../../../appConfig/Iconfig";
import { AuthService } from '../../../../../services/auth.service';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'app-services-section',
    templateUrl: 'services.component.html',
    styleUrls: ['services.component.css']
})
export class ServicesComponent implements OnInit {

    public pageContents: any;
    private contentEdit: any;

    modalHeader: string;
    serviceData: any;
    editServiceIndex: number;
    editProcessIndex: number;
    processData: any;
    currentSectionId: string;
    title: string;
    applicationMode:string;
    maxDescCharacters:number;
    displayMaxCharacter:number;
    public loading:boolean=true;
    public langSetKey: string;

    constructor(private service: Broadcaster, private homeComponent: HomeComponent, private elem: ElementRef
    ,  private authService: AuthService, public toastr: ToastrService,vcr: ViewContainerRef,@Inject(APP_CONFIG) private iconfig: IConfig) {
        //this.toastr.setRootViewContainerRef(vcr);
        this.applicationMode = iconfig.applicationMode;
    }

    ngOnInit() {
        this.initValues();
        this.initMethod();
        this.maxDescCharacters=210;
        this.displayMaxCharacter=this.maxDescCharacters;
    }

    initValues() {
        this.pageContents = {};
        this.contentEdit = {
            editServices: false,
            editProcess: false
        };
        this.processData = {};
        this.title="";
        this.currentSectionId  = 'section4';
        // this.applicationMode="";
        this.serviceData={};

    }
    initMethod() {
        $('[data-toggle="tooltip"]').tooltip();
        this.getBroadcastMessage();
    }

    getBroadcastMessage() {
      const self = this;
        this.service.on<string>('changeSectionData')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents = rec;
                self.loading=false;
                self.setCurrentTitle();
            });
        // this.service.on<string>('changeHeaderDataOnSidebar')
        //     .subscribe(message => {
        //         let rec: any;
        //         rec = message;
        //         let headerMenu = rec;
        //         self.setTitle(headerMenu);
        //     });
    }

    setCurrentTitle(){
        const self = this;
        this.pageContents.headerMenu.forEach(function (menu) {
            if(menu.sectionId === self.currentSectionId){
                self.title = menu.title;
            }
        });
        this.service.on<string>('setApplicationMode')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.applicationMode = rec;
            });
        this.service.on<string>('changeLanguages')
            .subscribe(message => {
                let rec: any;
                rec = message;
                let language;
                this.authService.langKey.subscribe(item => {
                    language = item;
                });
                if(language == 'arb' || language == 'en'){
                    self.langSetKey = language;
                }else {
                    self.langSetKey = 'en';
                }

            });
    }

    // onKeyUpOfProcessDesc(event){
    //     if(event.keyCode===8 || event.keyCode=== 46){
    //         if(this.processData.desc.length===0){
    //             this.displayMaxCharacter=this.maxDescCharacters;
    //         }
    //     }
    //
    // }

    // onKeyPressOfSProcessDesc(event){
    //     if(this.processData.desc) {
    //         if ((this.processData.desc).trim().length > this.maxDescCharacters
    //             && event.keyCode != 46 // delete
    //             && event.keyCode != 8  // backspace
    //         ) {
    //             event.preventDefault();
    //         }else{
    //             if(event.keyCode === 46 || event.keyCode === 8 ){
    //                 if(this.processData){
    //                     this.displayMaxCharacter=this.maxDescCharacters-((this.processData.desc).trim().length);
    //                 }
    //                 else{
    //                     this.displayMaxCharacter=this.maxDescCharacters;
    //                 }
    //
    //             }
    //             else{
    //                 if(event.keyCode!=13){
    //                     this.displayMaxCharacter=this.maxDescCharacters-((this.processData.desc).trim().length);
    //                 }
    //             }
    //         }
    //     }else{
    //         this.displayMaxCharacter=this.maxDescCharacters;
    //     }
    // }
    //
    // onKeyUpOfServiceDesc(event){
    //     if(event.keyCode===8 || event.keyCode=== 46){
    //         if(this.serviceData.desc.length===0){
    //             this.displayMaxCharacter=this.maxDescCharacters;
    //         }
    //     }
    // }

    // onKeyPressOfServiceDesc(event){
    //
    //     if(this.serviceData.desc) {
    //         if ((this.serviceData.desc).trim().length > this.maxDescCharacters
    //             && event.keyCode != 46 // delete
    //             && event.keyCode != 8  // backspace
    //         ) {
    //             event.preventDefault();
    //         }else{
    //             if(event.keyCode === 46 || event.keyCode === 8 ){
    //                 if(this.serviceData){
    //                     this.displayMaxCharacter=this.maxDescCharacters-((this.serviceData.desc).trim().length);
    //                 }
    //                 else{
    //                     this.displayMaxCharacter=this.maxDescCharacters;
    //                 }
    //
    //             }
    //             else{
    //                 if(event.keyCode!=13){
    //                     this.displayMaxCharacter=this.maxDescCharacters-((this.serviceData.desc).trim().length);
    //                 }
    //             }
    //         }
    //     }else{
    //         this.displayMaxCharacter=this.maxDescCharacters;
    //     }
    // }


    addServices() {
        this.displayMaxCharacter = this.maxDescCharacters;
        this.serviceData = {};
        $('#servicesModal').modal('show');
        this.modalHeader = 'Add Service';
        this.homeComponent.addServices();
        this.editServiceIndex = null;
    }

    setSelectorName() {
        const self = this;
        const tagName = this.elem.nativeElement.tagName.toLowerCase();
        const serviceMenuSelector: any = {
            'selector': tagName,
            'sectionId': this.currentSectionId,
        }
        setTimeout(function(){
            self.service.broadcast('serviceSelector', serviceMenuSelector);
        }, 1200 );
    }


    editContent(type: string) {
        this.homeComponent.editContent(type);
        if (type === 'process') {
            $('#summernoteProcess').summernote({
                height: 120,
                minHeight: null,
                maxHeight: null,
                focus: true
            });
            this.contentEdit.editProcess = true;
        }

        if (type === 'services') {
            $('#summernoteServices').summernote({
                height: 120,
                minHeight: null,
                maxHeight: null,
                focus: true
            });
            this.contentEdit.editServices = true;
        }
    }

    saveContent(type: string) {
        this.homeComponent.saveContent(type);
         if (type === 'process') {
             this.pageContents.processContent = $('#summernoteProcess').summernote('code');
             $('#summernoteProcess').summernote('destroy');
             this.contentEdit.editProcess = false;
         }

         if (type === 'services') {
             this.pageContents.servicesContent = $('#summernoteServices').summernote('code');
             $('#summernoteServices').summernote('destroy');
             this.contentEdit.editServices = false;
         }
    }

    cancelContent(type: string) {
        this.homeComponent.cancelContent(type);
         if (type === 'process') {
             $('#summernoteProcess').summernote('destroy');
             this.contentEdit.editProcess = false;
         }

         if (type === 'services') {
             $('#summernoteServices').summernote('destroy');
             this.contentEdit.editServices = false;
         }
    }

    editService(rec: any, idx: number) {
        this.displayMaxCharacter=this.maxDescCharacters-rec.desc.length;
        $('#servicesModal').modal('show');
        this.modalHeader = 'Edit Service';
        this.serviceData = Object.assign(this.serviceData, rec);
        this.editServiceIndex = idx;
    }

    contactData(type: any) {
        this.homeComponent.contactData(type);
    }

    editProcess(rec: any, idx: number) {
        this.displayMaxCharacter = this.maxDescCharacters - rec.desc.length
        $('#processModal').modal('show');
        this.modalHeader = 'Edit Process';
        this.processData = Object.assign(this.processData, rec);
        this.editProcessIndex = idx;
    }

    addProcess() {
        this.displayMaxCharacter = this.maxDescCharacters;
        $('#processModal').modal('show');
        this.modalHeader = 'Add Process';
        this.processData = {};
        this.editProcessIndex = null;
    }

    onIconPickerProcessSelect(icon: string): void {
        this.processData.icon = icon;
    }

    deleteProcessModalData (index) {
        this.pageContents.processSet.splice(index, 1);
    }

    onIconPickerServiceSelect(icon: string): void {
        this.serviceData.icon = icon;
    }

    deleteServiceModalData (index){
        this.pageContents.serviceSet.splice(index, 1);
    }

    saveServiceModalData( data: any, index) {
        if (this.editServiceIndex != null && this.editServiceIndex !== undefined) {
            if (this.homeComponent.checkFields(data, 'skill')) {
                $('#servicesModal').modal('hide');
                this.toastr.success('Updated sucessfully', 'Success!');
                this.pageContents.serviceSet[this.editServiceIndex] = Object.assign(this.pageContents.serviceSet[this.editServiceIndex], data);
            }else{

            }
        }else {
            if (this.homeComponent.checkFields(data, 'service')) {
                $('#servicesModal').modal('hide');
                this.toastr.success('Saved successfully', 'Success!');
                let rec = Object.assign({}, data);
                this.pageContents.serviceSet.push(rec);
            }
        }
    }
    saveProcessModalData( data: any, index) {
        if (this.editProcessIndex != null && this.editProcessIndex !== undefined) {
            if (this.homeComponent.checkFields(data, 'skill')) {
                $('#processModal').modal('hide');
                this.toastr.success('Updated successfully', 'Success!');
                this.pageContents.processSet[this.editProcessIndex] = Object.assign(this.pageContents.processSet[this.editProcessIndex], data);
            }
            else {
              //  this.toastr.error('Unable to Update Process','error');
            }
        }else {
            if (this.homeComponent.checkFields(data, 'process')) {
                $('#processModal').modal('hide');
                this.toastr.success('Saved successfully', 'Success!');
                let rec = Object.assign({}, data);
                this.pageContents.processSet.push(rec);
            }else {
             //   this.toastr.error('Unable to Add Process','error');
            }
        }
    }
    validateDataAndMode(data){
        if (data && this.applicationMode === 'L') {
            return true;
        }else {
            return false;
        }
    }
}
