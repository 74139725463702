import {Component, Inject , OnInit, ElementRef , ViewContainerRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Broadcaster } from '../../../../../services/sharable.service';
import { HomeComponent } from '../../home.component';
import {APP_CONFIG, IConfig} from "../../../../../appConfig/Iconfig";
import { AuthService } from '../../../../../services/auth.service';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'app-about-section',
    templateUrl: 'about.component.html',
    styleUrls: ['about.component.css']
})

export  class AboutComponent implements OnInit {

    public contentEdit: any;
    public pageContents: any;
    modalHeader: string;
    skillData: any;
    editSkillIndex: number;
    editExpIndex: number;
    expData: any;
    currentSectionId: string;
    title: string;
    headerMenu:any;
    applicationMode:any;
    maxDescCharacters:number;
    displayMaxCharacter:number;
    public loading: boolean=true;
    public langSetKey: string;

    constructor(private service: Broadcaster, private homeComponent: HomeComponent, private elem: ElementRef, private authService: AuthService,
                public toastr: ToastrService,vcr: ViewContainerRef, @Inject(APP_CONFIG) private iconfig: IConfig) {
       // //this.toastr.setRootViewContainerRef(vcr);
        this.applicationMode = iconfig.applicationMode;
    }

    ngOnInit() {
        this.initValues();
        this.initMethod();
        this.maxDescCharacters=140;
        this.displayMaxCharacter=this.maxDescCharacters;
        // $("#editContent")
        // {
        //     'data-toggle':'tooltip',
        //         'data-placement':'left',
        //         'title':'Save Content'
        // }
    }

    initValues() {
        this.pageContents = {};
        this.skillData = {};
        this.expData = {};
        this.contentEdit = {
            editAbout: false,
        };
        this.currentSectionId  = 'section2';
        this.headerMenu=[];
        this.title="";
        this.langSetKey = '';
        // this.applicationMode="";
    }

    initMethod() {
        $('[data-toggle="tooltip"]').tooltip({ container: 'body' });
        //$('your element').tooltip({ container: 'body' })
        this.getBroadcastMessage();
        this.setSelectorName();
    }

    getBroadcastMessage() {
        const self = this;
        this.service.on<string>('changeSectionData')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.pageContents = rec;
                self.loading = false;
                self.setCurrentTitle();
                this.animateSkill();
            });
        this.service.on<string>('setApplicationMode')
            .subscribe(message => {
                let rec: any;
                rec = message;
                self.applicationMode = rec;
            });
        this.service.on<string>('changeLanguages')
            .subscribe(message => {
                let rec: any;
                rec = message;
                let language;
                this.authService.langKey.subscribe(item => {
                    language = item;
                });
                if(language == 'arb' || language == 'en'){
                    self.langSetKey = language;
                }else {
                    self.langSetKey = 'en';
                }

            });
    }

    setCurrentTitle(){
        const self = this;
        this.pageContents.headerMenu.forEach(function (menu) {
            if(menu.sectionId === self.currentSectionId){
                self.title=menu.title;
            }
        });
    }

    setSelectorName() {
        const self = this;
        const tagName = this.elem.nativeElement.tagName.toLowerCase();
        const aboutMenuSelector: any = {
            'selector': tagName,
            'sectionId': this.currentSectionId,
        }
        setTimeout(function(){
            self.service.broadcast('aboutSelector', aboutMenuSelector);
        }, 1200 );
    }

    editContent(type: string) {
        // $("[data-toggle='tooltip']").tooltip('hide');
        $('#summernoteAbout').summernote({
            height: 120,
            minHeight: null,
            maxHeight: null,
            focus: true
        });
        this.contentEdit.editAbout = true;
    }

    saveContent(type: string) {
        // $('[data-toggle="tooltip"]').tooltip({ container: 'body' });
        this.pageContents.aboutContent = $('#summernoteAbout').summernote('code');
        $('#summernoteAbout').summernote('destroy');
        this.contentEdit.editAbout = false;
    }

    cancelContent(type: string) {
        // $('[data-toggle="tooltip"]').tooltip({ container: 'body' });
        $('#summernoteAbout').summernote('destroy');
        this.contentEdit.editAbout = false;
    }

    editSkills(rec: any, idx: number) {
        this.displayMaxCharacter=(this.maxDescCharacters-rec.desc.length)+1;
        this.modalHeader = 'Edit Skill';
        this.skillData = Object.assign(this.skillData, rec);
        this.editSkillIndex = idx;
    }

    valToPer(val: any) {
        return val + '%';
    }

    //     onKeyPressOfSkillDesc(event){
    //     if(this.skillData.desc) {
    //         if ((this.skillData.desc).trim().length > this.maxDescCharacters
    //             && event.keyCode != 46 // delete
    //             && event.keyCode != 8  // backspace
    //         ) {
    //             event.preventDefault();
    //         }else{
    //             if(event.keyCode === 46 || event.keyCode === 8 ){
    //                 if(this.skillData){
    //                     this.displayMaxCharacter=this.maxDescCharacters-((this.skillData.desc).trim().length);
    //                 }
    //                 else{
    //                     this.displayMaxCharacter=this.maxDescCharacters;
    //                 }
    //
    //             }
    //             else{
    //                 if(event.keyCode!=13){
    //                     this.displayMaxCharacter=this.maxDescCharacters-((this.skillData.desc).trim().length);
    //                 }
    //             }
    //         }
    //      }else{
    //         this.displayMaxCharacter=this.maxDescCharacters;
    //     }
    // }
    //
    // onKeyUpOfSkillDesc(event){
    //   if(event.keyCode===8 || event.keyCode=== 46){
    //          if(this.skillData.desc.length===0){
    //              this.displayMaxCharacter=this.maxDescCharacters;
    //          }
    //          else{
    //              this.displayMaxCharacter=this.maxDescCharacters-((this.skillData.desc).trim().length+1);
    //          }
    //    }
    //    if(event.ctrlKey && event.keyCode == 86){
    //         this.displayMaxCharacter=this.maxDescCharacters-((this.skillData.desc).trim().length);
    //             if(this.displayMaxCharacter<0){
    //                 event.preventDefault();
    //                 this.skillData.desc="";
    //                 this.displayMaxCharacter=this.maxDescCharacters;
    //                 this.toastr.error('Enter minimum characters','Required');
    //             }
    //         }
    // }

    addExp() {
        $('#expModal').modal('show');
        this.modalHeader = 'Add Experience';
        this.expData = {};
        this.editExpIndex = null;
    }

    editExp(rec: any, idx: number) {
        this.modalHeader = 'Edit Experience';
        this.expData = Object.assign(this.expData, rec);
        this.editExpIndex = idx;
    }
    deleteExpModalData (index) {
        this.pageContents.expSet.splice(index, 1);
    }

    saveExpModalData( data: any, index) {
        if (this.editExpIndex != null && this.editExpIndex !== undefined) {
            if (this.homeComponent.checkFields(data, 'exp')) {
                $('#expModal').modal('hide');
                this.toastr.success('Updated successfully', 'Success!');
                this.pageContents.expSet[this.editExpIndex] = Object.assign(this.pageContents.expSet[this.editExpIndex], data);
            }
            else{
                // this.toastr.error('Unable to Update Experience','error');
            }
        }else {
            if (this.homeComponent.checkFields(data, 'exp')) {
                $('#expModal').modal('hide');
                if(!data.color){
                    data.color="#90d0e4";
                }
                this.toastr.success('Saved successfully', 'Success!');
                let rec = Object.assign({}, data);
                this.pageContents.expSet.push(rec);
            }
            else{
                //this.toastr.error('Unable to Add Experience','error');
            }
        }
    }
    restrictMaxVal(model, val) {
        if (this.skillData[model] > val) {
            this.skillData[model] = 0;
        }
    }

    deleteSkillModalData (index) {
        this.pageContents.skillSet.splice(index, 1);
    }

    private animateSkill() {

        setTimeout(function () {
            let skillbar = $('.skillbar');
            skillbar.each(function(){
                $(this).find('.skillbar-bar').animate({
                    width: $(this).attr('data-percent')
                }, 6000);
            });
        }, 1000);
    }

    saveSkillModalData(data: any, index ) {
        if (this.editSkillIndex != null && this.editSkillIndex !== undefined) {
            if (this.homeComponent.checkFields(data, 'skill')) {
                $('#skillModal').modal('hide');
                this.toastr.success('Updated successfully', 'Success!');
                this.pageContents.skillSet[this.editSkillIndex] = Object.assign(this.pageContents.skillSet[this.editSkillIndex], data);
                this.animateSkill();
            }
            else{
                //this.toastr.error('Unable to Update Skill','error');
            }
        }else {
            if (this.homeComponent.checkFields(data, 'skill')) {
                $('#skillModal').modal('hide');
                this.toastr.success('Saved successfully', 'Success!');
                let rec = Object.assign({}, data);
                this.pageContents.skillSet.push(rec);
                this.animateSkill();
            }
            else{
               // this.toastr.error('Unable to Add Skill','error');
            }
        }
    }

    addSkill() {
        this.displayMaxCharacter=this.maxDescCharacters;
        $('#skillModal').modal('show');
        this.modalHeader = 'Add Skill';
        this.skillData = {};
        this.editSkillIndex = null;
    }

    validateDataAndMode(data){
        if(data && this.applicationMode == 'L'){
            return true;
        }else{
            return false;
        }
    }

}
