import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; 
import { Http, HttpModule, RequestOptions } from '@angular/http'; 
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxViewerModule } from 'ngx-viewer';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieModule, CookieService } from 'ngx-cookie';

import { AppComponent } from './app.component';
import { AppStates } from './app.routes';
import { WelcomeComponent } from './welcome.component';
import { CustomAuthHttp } from '../services/local-authhttp/local-authhttp';
import { IConfig, APP_CONFIG } from '../appConfig/Iconfig';
import { DocumentService } from '../services/Document.service';
import { AppConstants } from '../services/constant';
//import { CustomOption } from '../appConfig/custom-toastr-option';
import { AuthService } from '../services/auth.service';
import { UtilsService } from '../utils/common.util';
import { TranslateModule, TranslateLoader, TranslateStaticLoader, TranslateService } from "ng2-translate";
import { UIRouterModule } from '@uirouter/angular';
import { AuthHttp, AuthConfig } from 'angular2-jwt/angular2-jwt';
import { DateInterceptor } from './share/date.interceptor';


import { appInitializer } from './app.initializer';

import { PageModule } from './page/page.module';

export function appConfig() {
  return new IConfig('');
}
export function test() {
  return new JwtHelperService();
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function authHttpServiceFactory(http: Http, options: RequestOptions) {
  return new AuthHttp(new AuthConfig({
    tokenName: 'token',
          tokenGetter: (() => localStorage.getItem('token')),
          globalHeaders: [{'Content-Type':'application/json'}],
     }), http, options);
}

export function createTranslateLoader(http: Http) {
  return new TranslateStaticLoader(http, '', '');
}

// export function test2(http: Http) {
//     return new AuthHttp(new AuthConfig({
//         tokenName: 'jwt',
//         tokenGetter: (() => sessionStorage.getItem('jwt')),
//         noJwtError: true,
//         globalHeaders: [{'Content-Type': 'application/json'}]
//     }), http);
// }

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
  ],
  imports: [
    HttpClientModule,
    RouterModule,
    NgbModule,
    UIRouterModule,
    NgxDocViewerModule,
    NgxViewerModule,
    AmazingTimePickerModule,
    CookieModule.forRoot(),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    PageModule,
    // SuiModule,
    HttpModule,
    AppStates,
    ToastrModule.forRoot({
      timeOut: 5000
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    // DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [Http]
    })
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },

    // CustomAuthHttp,
    {
      provide: APP_CONFIG, useFactory: (appConfig),
    },
    {
      provide: JwtHelperService,
      useFactory: (test),
      deps: []
    },
    // {
    //   provide:HTTP_INTERCEPTORS,
    //   useClass:TokenInterceptor,
    //   multi:true
    // },

    // { provide: AuthHttp,
    //     useFactory: (test2),
    //     deps: [Http]
    // },
    // {
    //     provide: ToastOptions,
    //     useClass: CustomOption
    // },
    {
      provide: AuthHttp,
      useFactory: authHttpServiceFactory,
      deps: [Http, RequestOptions]
    },

    // AuthService,
    DocumentService,
    AppConstants,
    CookieService,
    UtilsService,
    TranslateService,
    BsModalRef,
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
