import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TreeModule as customTreeMoodule } from 'angular-tree-component';
import { AngularDraggableModule } from 'angular2-draggable';
import { ChipsModule } from 'primeng/chips';

import { AccordionModule, 
         AlertModule,
         BsDatepickerModule,
         ModalModule, 
         PaginationModule,
         PopoverModule,
         ProgressbarModule,
         TabsModule,
         TimepickerModule,
         TooltipModule } from 'ngx-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
// import { TagInputModule } from 'ngx-chips';

import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

import { NgDragDropModule } from 'ng-drag-drop';
import { DndModule } from 'ng2-dnd';
import { NgChartsModule } from "ng2-charts";
// import { TreeTableModule } from 'ng-treetable';
import { DynamicFormsCoreModule } from '@ng-dynamic-forms/core';
import { DynamicFormsNGBootstrapUIModule } from '@ng-dynamic-forms/ui-ng-bootstrap';
import { DynamicFormsBootstrapUIModule } from '@ng-dynamic-forms/ui-bootstrap';
import { FullCalendarModule } from 'ng-fullcalendar';

import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

import { ReportsViewComponent_V1 } from '../web/report-generation/reports-view-V1/reports-view-V1.component'
import { RuleEngineComponent_V1 } from '../web/rule-engine-V1/rule-engine-V1.component';
import { PopOverComponent_V1 } from './components/pop-over-V1/pop-over-V1.component';
import { TruthTableComponent_V1 } from './components/truth-table-V1/truth-table-V1.component';
import { ReportsViewComponent } from '../web/report-generation/reports-view/reports-view.component'
import { ReportHeaderComponent } from '../web/report-generation/reports-header/reports-header.component'
import { BlurDirective } from '../../services/blur.directive';
import { ValidationOnBlurDirective } from '../../directives/FormControlEvents.directive';
import { EscapeHtmlPipe, FilterPipe, ExactFilterPipe } from '../../services/pipe.services';
import { SafeUrlPipe } from '../../services/safeUrlpipe.service'
import { CardComponent } from './components/cardcomponent/card.component';
import { ActionCardComponent } from './components/actioncomponent/actioncard.component';
import { DetailCardComponent } from './components/detailcomponent/detailcard.component';
import { ProfileCardComponent } from './components/profilecardcomponent/profilecard.component';
import { LineCardComponent } from './components/linecardcomponent/linecard.component';
import { TileComponent } from './components/tileComponent/tile.component';
import {SmartTabsComponent} from "./components/smart-tabs/smart-tabs.component";
import { ConfirmModalComponent } from './components/confirm-modal/confirm.modal.component';
import { EsignModalComponent } from './components/esign-modal/esign.modal.component';
import { LoaderComponent } from './components/loaderComponent/loader.component';
import { NumberToWordsPipe, RoundPipe } from './service/roundoff.directives';
import { FileUploadComponent } from './components/uploadfilecomponent/upload-file.component';
import { TableComponent } from './components/tableComponent/table.component';
import { AccordionExciseComponent } from '../web/excise/accodion/excise-accordion.component';
import { ExciseTaxReturn } from '../web/excise/excise-tax-return/excise-tax-return.component'
import { ExciseTableComponent } from '../web/excise/table/excise-table.component';
import { InputBoxComponent } from './components/inputboxcomponent/input-box.component';
import { DownloadSoftwareComponent } from '../web/download-software/download.component';
import { ActionButtonComponent } from './components/actionButtonComponent/button.component';
import { FilterSearchComponent } from './components/filterSearchComponent/filterSearch.component';
import { DropdownComponent } from './components/dropdowncomponent/dropdown.component';
import { ShortinfoCardComponent } from './components/shortinfocardcomponent/shortinfo.card.component';
import { MultidropdownComponent } from './components/multidropdowncomponent/multidropdown.component';
import { UserComponent } from '../web/user-management/user.register/component/user.component';
import { HandbookComponent} from "./components/handbook/handbook.component";
import { LinecardwithactionComponent } from './components/linecardwithactioncomponent/linecardwithaction.component';
import { AddEditContactComponent } from '../web/user-management/contact/add-edit-contact/add-edit-contact.component';
import { AddRecordModalComponent } from './components/addrecordmodal/addrecordmodal.component';
import { InlineEditTableComponent } from './components/inlineEditTableComponent/inlineEditTable.component';
import { UploadExcelComponent } from './components/uploadExcelComponent/uploadExcel.component';
import { ColorCodedComponent } from './components/colorCodedComponent/colorCoded.component';
import { ComplianceDeclarationComponent } from './components/complianceDeclarationComponent/compliance-declaration.component';
import { GroupDropdownComponent } from './components/groupdropdowncomponent/groupdropdown.component';
import { TreeViewComponent } from './components/treeViewComponent/tree-view.component';
import { ExcelDetailsComponent } from './components/excelitemdetailscomponent/excelitemdetails.component';
import { EditInvoiceItemComponent } from './components/excelitemdetailscomponent/editInvoiceItem.component';
import { FileRecordComponent } from './components/fileRecordComponent/fileRecord.component';
import { DropdownCheckboxComponent } from './components/dropdownCheckbox/dropdown-checkbox.component';
import { AddSchedulerComponent } from './components/scheduler/add-scheduler/add-scheduler.component';
import { WizardComponent } from './components/wizardComponent/wizard.component';
import { InlineEditTreeTableComponent } from './components/inlineEditTreeTableComponent/inlineEditTreeTable.component';
import { ChatWindowComponent } from './chat/component/chatWindowComponent';
import { ChatService } from './chat/service/chat.service';
import { CacheService } from './service/cache.service';
import { MapperTableComponent } from './components/mapperTableComponent/mapperTable.component';
import { TreeViewIconComponent } from './components/treeViewIconComponent/tree-view-icon.component';
import { AddInfoTableComponent } from './components/wizardComponent/additionalInfoTableComponent/addInfoTable.component';
import { AddInfoViewComponent } from './components/additionalInfoView/additionalInfo.View';
import { AssignmentHistoryComponent } from './components/assignmentHistoryComponent/assignment-history.component';
import { DocumentTreeViewComponent } from './components/document-tree/document-tree.component';
import { ListViewDmsComponent } from '../web/dms-management/list-view/list-view.component';
import { DmsListViewComponent } from '../web/dms-management/dms-list-view/dms-list-view.component';
import { UploadDmsComponent } from '../web/dms-management/upload-dms/upload-dms.component';
import { CalendarContainerComponent } from './components/calendar-container/component/calendar-container.component';
import { ActionLoaderComponent } from './components/actionLoaderComponent/action-loader.component';
import { BarChartComponent } from './components/charts/barChart/barChart.component';
import { ScriptableBarChartComponent } from './components/charts/scriptablebarChart/scriptablebarChart.component';
import{ BarlineChartComponent } from './components/charts/barlineChart/barlineChart.component'
import { BarHorizontalChartComponent } from './components/charts/barhorizontalChart/barhorizontalChart.component';
import { RadarChartComponent } from './components/charts/radarChart/radarChart.component';
import { CardswithdropdownComponentDynamic } from './components/cardswithdropdowndynamic/cardswithdropdowndynamic.component';
import { StackedBarChartComponent } from './components/charts/stackedbarChart/stackedbarChart.component';
import { PieChartComponent } from './components/charts/pieChart/pieChart.component';
import { PolarAreaChartComponent } from './components/charts/polarChart/polarChart.component';
import { LineChartComponent } from './components/charts/lineChart/lineChart.component';
import { SimpleTableChartComponent } from './components/charts/tableChart/tableChart.component';
import { CardswithdropdownComponent } from './components/cardswithdropdown/cardswithdropdown.component';
import { CardwithactionsComponent } from './components/cardwithactions/cardwithactions.component';
import { AccordionActionComponent } from './components/accordionActionComponent/accordionAction.component';
import { PopOverComponent } from './components/pop-over/pop-over.component';
import { CounterTileComponent } from './components/counterTileComponent/counter-tile.component';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AccessControlComponent } from '../web/dynamic-component/process-studio/access-control/access-control.component'
import { UploadSummaryComponent } from './components/upload-invoice-summary/upload-summary.component';
import { DetailPreviewComponent } from './components/upload-invoice-summary/detail-preview/detail-preview.component';
import { TruthTableComponent } from './components/truth-table/truth-table.component';
import { TruthTableForReportsComponent } from './components/truth-table-for-reports/truth-table-for-reports.component';
import { AdDirective } from "./ad.directive";
import { ExpressionBuilderComponent } from './components/expressionBuilderComponent/expressionBuilder.component';
import { ToggleButtonComponent } from './components/ToggleButton/ToggleButton';
import { DynamicFormBuilderComponent } from './components/dynamic-form-builder/dynamic-form-builder.component';
import { AdditionalInfoComponent } from './components/additional-info/additional-info.component';
import { RuleEngineComponent } from '../web/rule-engine/rule-engine.component';
import { NgxDocViewerComponent } from './components/doc-viewer-component/doc-viewer-component.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { DocumentTreeForBotViewComponent } from './components/document-tree-for-bot-view/document-tree-for-bot-view.component';
import { TruthTableDateFilterComponent } from './components/truth-table-date-filter/truth-table-date-filter.component';
import { NewLoaderComponent } from './components/newloadercomponent/new-loader.component';
import {NgxDocumentScannerModule} from  './documentScanner/ngx-document-scanner.module'
import {OpenCVConfig} from './documentScanner/PublicModels';
import { NgxDocScannerComponent } from './documentScanner/components/image-editor/ngx-doc-scanner.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AddTableMetaDataComponent } from '../web/report-generation/add-table-metadata/add-table-metaData.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
// import { DmsFolderComponent } from '../web/document-view/dmsfolder-hierarchy/dms-folder.component'
import { MultiLevelDropdownComponent } from './components/multiLevelDropdownComponent/multileveldropdown.component';
import { BotSchedulerComponent } from '../web/dynamic-component/process-studio/bot-scheduler/bot-scheduler.component';
import {PasswordResetComponent} from "./components/passwordReset/password-reset.component";
import { RestAPIComponent } from './components/rest-api-component/rest-api.component';

const openCvConfig: OpenCVConfig = {
    openCVDirPath: '../../../assets/opencv'
    
  };
@NgModule({
    imports: [
        DragDropModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AccordionModule.forRoot(),
        ProgressbarModule.forRoot(),
        ModalModule.forRoot(),
        NgDragDropModule.forRoot(),
        DndModule.forRoot(),
        TooltipModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.wanderingCubes,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        FileUploadModule,
        DropdownModule,
        InputTextModule,
        BsDatepickerModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        PaginationModule.forRoot(),
        // TreeTableModule,
        customTreeMoodule.forRoot(),
        AngularDraggableModule,
        // TagInputModule,
        DynamicFormsCoreModule.forRoot(),
        DynamicFormsNGBootstrapUIModule,
        DynamicFormsBootstrapUIModule,
        FullCalendarModule,
        NgChartsModule,
        AlertModule.forRoot(),
        NgxDatatableModule,
        PopoverModule.forRoot(),
        ChipsModule,
        NgxDocumentScannerModule.forRoot(openCvConfig),
        InfiniteScrollModule,
        MatDatepickerModule,
        MatInputModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatButtonModule,
        NgxMatMomentModule,
        NgxDocViewerModule
    ],
    declarations: [
        NewLoaderComponent,
        ToggleButtonComponent,
        BlurDirective,
        ValidationOnBlurDirective,
        CardswithdropdownComponentDynamic,
        EscapeHtmlPipe,
        SafeUrlPipe,
        FilterPipe,
        CardComponent,
        ActionCardComponent,
        DetailCardComponent,
        ProfileCardComponent,
        LineCardComponent,
        TileComponent,
        SmartTabsComponent,
        ConfirmModalComponent,
        TruthTableComponent_V1,
        PopOverComponent_V1,
        EsignModalComponent,
        LoaderComponent,
        NumberToWordsPipe,
        RoundPipe,
        ExactFilterPipe,
        FileUploadComponent,
        TableComponent,
        AccordionExciseComponent,
        ExciseTaxReturn,
        ExciseTableComponent,
        DownloadSoftwareComponent,
        InputBoxComponent,
        DropdownComponent,
        GroupDropdownComponent,
        ActionButtonComponent,
        FilterSearchComponent,
        ShortinfoCardComponent,
        MultidropdownComponent,
        UserComponent,
        HandbookComponent,
        LinecardwithactionComponent,
        AddEditContactComponent,
        AddRecordModalComponent,
        InlineEditTableComponent,
        RuleEngineComponent_V1,
        AddInfoTableComponent,
        ComplianceDeclarationComponent,
        InlineEditTableComponent,
        UploadExcelComponent,
        ExcelDetailsComponent,
        ColorCodedComponent,
        DropdownCheckboxComponent,
        AddSchedulerComponent,
        WizardComponent,
        InlineEditTreeTableComponent,
        ChatWindowComponent,
        TreeViewComponent,
        TreeViewIconComponent,
        AssignmentHistoryComponent,
        EditInvoiceItemComponent,
        FileRecordComponent,
        MapperTableComponent,
        AddInfoViewComponent,
        DocumentTreeViewComponent,
        DocumentTreeForBotViewComponent,
        ListViewDmsComponent,
        DmsListViewComponent,
        // DmsFolderComponent,
        UploadDmsComponent,
        CalendarContainerComponent,
        ActionLoaderComponent,
        BarChartComponent,
        ScriptableBarChartComponent,
        BarlineChartComponent,
        PieChartComponent,
        PolarAreaChartComponent,
        BarHorizontalChartComponent,
        RadarChartComponent,
        StackedBarChartComponent,
        LineChartComponent,
        SimpleTableChartComponent,
        CardswithdropdownComponent,
        CardwithactionsComponent,
        AccordionActionComponent,
        ReportsViewComponent_V1,
        PopOverComponent,
        CounterTileComponent,
        UploadSummaryComponent,
        DetailPreviewComponent,
        TruthTableComponent,
        AdDirective,
        ExpressionBuilderComponent,
        AccessControlComponent,
        ReportsViewComponent,
        ReportHeaderComponent,
        DynamicFormBuilderComponent,
        AdditionalInfoComponent,
        RuleEngineComponent,
        NgxDocViewerComponent,
        TruthTableDateFilterComponent,
        TruthTableForReportsComponent,
        AddTableMetaDataComponent,
        MultiLevelDropdownComponent,
        BotSchedulerComponent,
        PasswordResetComponent,
        RestAPIComponent
    ],
    providers: [
        ChatService,
        CacheService
    ],
    exports: [
        NewLoaderComponent,
        ToggleButtonComponent,
        BlurDirective,
        EscapeHtmlPipe,
        SafeUrlPipe,
        FilterPipe,
        ExactFilterPipe,
        DndModule,
        ValidationOnBlurDirective,
        CardComponent,
        CardswithdropdownComponentDynamic,
        ActionCardComponent,
        DetailCardComponent,
        ProfileCardComponent,
        LineCardComponent,
        TruthTableComponent_V1,
        TileComponent,
        SmartTabsComponent,
        PopOverComponent_V1,
        NumberToWordsPipe,
        RoundPipe,
        FileUploadComponent,
        TableComponent,
        AccordionExciseComponent,
        ExciseTaxReturn,
        ExciseTableComponent,
        DownloadSoftwareComponent,
        InputBoxComponent,
        ActionButtonComponent,
        FilterSearchComponent,
        DropdownComponent,
        GroupDropdownComponent,
        ShortinfoCardComponent,
        ReportsViewComponent_V1,
        MultidropdownComponent,
        UserComponent,
        HandbookComponent,
        LinecardwithactionComponent,
        AddEditContactComponent,
        AddRecordModalComponent,
        InlineEditTableComponent,
        AddInfoTableComponent,
        BsDatepickerModule,
        BsDatepickerModule,
        UploadExcelComponent,
        ExcelDetailsComponent,
        ColorCodedComponent,
        ComplianceDeclarationComponent,
        RuleEngineComponent_V1,
        DropdownCheckboxComponent,
        AddSchedulerComponent,
        TimepickerModule,
        WizardComponent,
        InlineEditTreeTableComponent,
        ChatWindowComponent,
        TreeViewComponent,
        EditInvoiceItemComponent,
        TreeViewIconComponent,
        AssignmentHistoryComponent,
        FileRecordComponent,
        MapperTableComponent,
        AngularDraggableModule,
        AddInfoViewComponent,
        DocumentTreeViewComponent,
        DocumentTreeForBotViewComponent,
        ListViewDmsComponent,
        DmsListViewComponent,
        // DmsFolderComponent,
        UploadDmsComponent,
        CalendarContainerComponent,
        ActionLoaderComponent,
        BarChartComponent,
        ScriptableBarChartComponent,
        BarlineChartComponent,
        PieChartComponent,
        PolarAreaChartComponent,
        BarHorizontalChartComponent,
        RadarChartComponent,
        StackedBarChartComponent,
        LineChartComponent,
        SimpleTableChartComponent,
        CardswithdropdownComponent,
        CardwithactionsComponent,
        AccordionActionComponent,
        AlertModule,
        PopOverComponent,
        CounterTileComponent,
        UploadSummaryComponent,
        DetailPreviewComponent,
        ExpressionBuilderComponent,
        AccessControlComponent,
        TruthTableComponent,
        ReportsViewComponent,
        ReportHeaderComponent,
        DynamicFormBuilderComponent,
        AdditionalInfoComponent,
        RuleEngineComponent,
        NgxDocViewerComponent,
        TruthTableDateFilterComponent,
        TruthTableForReportsComponent,
        NgxDocScannerComponent,
        AddTableMetaDataComponent,
        MultiLevelDropdownComponent,
        BotSchedulerComponent,
        PasswordResetComponent,
        RestAPIComponent
        // NgxShapeOutlineComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ShareModule {
  constructor() { }
}
