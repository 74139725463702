<section id="section7" [style.background]="pageContents.appTheme" [style.color]="pageContents.textColor" *ngIf="pageContents">
    <div class="container" style="margin-top: -87px !important;">
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
        <div class="line-title">
            <h3 *ngIf="langSetKey != 'arb'" [ngClass]="{'align': langSetKey != 'arb'}" class="title" style="color: #f47435;">{{title}}</h3>
            <h3 *ngIf="langSetKey === 'arb'" [ngClass]="{'align': langSetKey === 'arb'}" class="title title-position" style="color: #f47435;">{{title}}</h3>
            <div class="col-md-12 top-content" *ngIf="applicationMode === 'C'">

                <div class="col-md-11 nopadding">
                    <div id="summernoteContact" class="summernote"></div>
                </div>
                <div class="col-md-1 edit-btn ">
                    <button class="more-btn" ngbTooltip="Edit Content" container="body" placement="left" (click)="editContent('contact')" *ngIf="!contentEdit.editContact" style="float: right"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                    <button container="body" placement="left" ngbTooltip="Save Content" class="more-btn" (click)="saveContent('contact')" *ngIf="contentEdit.editContact" style="float: right"><i class="fa fa-floppy-o" aria-hidden="true"></i></button>
                    <button container="body" placement="left" ngbTooltip="Close" class="more-btn" (click)="cancelContent('contact')" *ngIf="contentEdit.editContact" style="float: right"><i class="fa fa-ban" aria-hidden="true"></i></button>
                </div>
            </div>
            <div class="col-md-12 top-content" *ngIf="applicationMode==='L'">
                <div *ngIf="validateDataAndMode(pageContents.contactContent)" [innerHTML]="pageContents.contactContent | escapeHtml"></div>
            </div>
        </div>

                <div class="row contact-boxes fullbtn">
                    <div class="col-sm-4 col-xs-12">
                        <div class="contact-info" ontouchstart="">
                            <div>
                                <h5 *ngIf="langSetKey != 'arb'" class="fontclr">E-MAIL</h5>
                                <h5 *ngIf="langSetKey === 'arb'" class="fontclr">البريد الإلكتروني</h5>
                                <p>{{pageContents.contactData == null || pageContents.contactData == undefined ? '' : pageContents.contactData.contactEmail}}</p>
                            </div>
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-12">
                        <div class="contact-info" ontouchstart="">
                            <div>
                                <h5 *ngIf="langSetKey != 'arb'" class="fontclr">PHONE</h5>
                                <h5 *ngIf="langSetKey === 'arb'" class="fontclr">هاتف</h5>
                                <p>{{pageContents.contactData == null || pageContents.contactData == undefined ? '' : pageContents.contactData.contactMobile}}</p>
                            </div>
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-sm-4 col-xs-12">
                        <div class="contact-info" ontouchstart="">
                            <div>
                                <h5 *ngIf="langSetKey != 'arb'" class="fontclr">ADDRESS</h5>
                                <h5 *ngIf="langSetKey === 'arb'" class="fontclr">عنوان</h5>
                                <p [ngClass]="{'position': langSetKey === 'arb'}">{{pageContents.contactData == null || pageContents.contactData == undefined ? '' : pageContents.contactData.contactAddress}}</p>
                            </div>
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <div class="row fullbtn">
                    <form class="contact-form" >
                        <div class="col-sm-6" >
                            <div class="form-group">
                                <input type="text" name="name" class="form-control" #txtMsg="ngModel" [(ngModel)]="requestData.name" [ngModelOptions]="{standalone: true}"  required [placeholder]="'CONTACT.NAME' | translate" [onBlurText]="requestData.name">
                            </div>
                            <div class="form-group">
                                <input type="text" name="email" class="form-control" #txtMsg="ngModel" [(ngModel)]="requestData.email" [ngModelOptions]="{standalone: true}"  required [placeholder]="'CONTACT.E-MAIL' | translate" [onBlurText]="requestData.email">
                            </div>
                            <div class="form-group" >
                                <input type="text" name="phone" class="form-control" #txtMsg="ngModel" minlength="10" maxlength="10" [(ngModel)]="requestData.phone" [ngModelOptions]="{standalone: true}"  required (keypress)="restrictNumeric($event)" [placeholder]="'CONTACT.PHONE' | translate" [onBlurText]="requestData.phone">
                            </div>
                        </div>
                        <div class="col-sm-6" >
                            <div class="form-group textarea">
                                <textarea rows="5" [placeholder]="'CONTACT.MESSAGE' | translate" #txtMsg="ngModel" [(ngModel)]="requestData.message" [ngModelOptions]="{standalone: true}" required  [onBlurText]="requestData.message"></textarea>
                            </div>
                            <button class="colorbtn" (click)="createRequest(requestData)" >{{'CONTACT.SEND' | translate}}</button>
                        </div>
                    </form>
                </div>
        </div>
</section>
