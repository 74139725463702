<app-layout-sidebar ></app-layout-sidebar>
<app-layout-header></app-layout-header>
<ui-view></ui-view>
<app-layout-footer></app-layout-footer>


<!--
<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="expModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="expModalLabel">Login To Your Account</h5>

            </div>
            <div class="modal-body textcolor">
                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        Mobile Number : <sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input maxlength="10" class="full-width bottomborder" #modalShow="ngModel" [(ngModel)]="loginData.mobileNumber" onkeypress='return event.charCode >= 48 && event.charCode <= 57 ' />
                    </div>
                </div>
                <br/>

                <div class="row">
                    <div class="col-md-3 col-sm-12">
                        Password : <sup style="color: red">*</sup>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <input type="password" maxlength="10" class="full-width bottomborder"  #modalShow="ngModel" [(ngModel)]="loginData.password"/>
                        &lt;!&ndash;<input class="full-width bottomborder" #modalcompany="ngModel" [(ngModel)]="expData.company" />&ndash;&gt;
                    </div>
                </div>
                <br/>
            </div>
            <div class="modal-footer" style="text-align: center">
                <button type="button" class="btn btn-primary set-position" (click)="onLogin()">Log In</button>
               <div >
                <a class="new-user" (click)="onNewUserClick()">New User?</a>
            </div>
            </div>
        </div>
    </div>
</div>
-->

<link>
