import {Component, Inject, OnInit} from '@angular/core';
import { StateService } from '@uirouter/core';

import {IConfig, APP_CONFIG} from '../appConfig/Iconfig';
import {OrgService} from './web/organization/services/org.services';
import {isNullOrUndefined} from 'util';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-root',
  template: `
    <ui-view></ui-view>
  `,
  styleUrls: ['./app.component.css'],
  providers: [OrgService]
})
export class AppComponent implements OnInit {
  public changeGstinFlag:boolean=true;
  public currentOrg:string;
  public currentOrgName : string;
  public gstrOrg : string ;
  private message: string;
  private displayError: boolean = false;
  c: number;

  constructor(@Inject(APP_CONFIG) private iconfig: IConfig, private stateService: StateService,
              private organisationService: OrgService, private authService: AuthService) {
                this.authService.getClickEvent().subscribe(() => {
                  this.detectURL();
                })
  }

  ngOnInit() {
    this.changeGstinFlag = true;
    sessionStorage.setItem('orgLabelFile', '../assets/i18n/en.json');
    this.detectURL();
    this.c = 0;
    if(isNullOrUndefined(sessionStorage.getItem('oauth-verifer'))){
      if(window.location.href.indexOf('oauth_verifier=')!==-1){
        let rArray = window.location.href.split('oauth_verifier=');
        let verifier = rArray[1];
        sessionStorage.setItem('oauth-verifer',verifier)
      }
    }
  }
    public showError(msg) {
        this.message = msg;
        this.displayError = true;
    }

    public showMessage(msg) {
        console.log();
    }

  public translateLanguage(){
    return sessionStorage.getItem("orgLabelFile");
  }

  detectURL() {
    // const currentUrl = window.location.href;
    // const urlArray = currentUrl.split('/');
    // const url = urlArray[2];
    const url = this.iconfig.domainName;
    const self = this;
    // const url = "savex.gibots.com:443";
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link['type'] = 'image/x-icon';
    link['rel'] = 'shortcut icon';
    link['href'] = '../assets/img/aiqod-logo.png'
    const data = {
      websiteUrl: url
    };
    if(!isNullOrUndefined(sessionStorage.getItem('orgData'))){
      let org = sessionStorage.getItem('orgData');
      let respData = JSON.parse(org);
      if (respData.status) {
        this.authService.generateLogoPath(respData.data.logoURL);
        this.authService.generateRegistrationBtn(respData.data.extRegAllow);
        this.authService.generateMobilePattern(JSON.stringify(respData.data.mobileNoPattern));
        this.authService.generateOrgSubID(respData.data.subscriberId);
        // link['href'] = respData.data.iconURL;
        document.getElementsByTagName('head')[0].appendChild(link);
      } else {
        console.log();
      }
    }else{
    self.organisationService.detectURL(data)
      .subscribe(
        respData => {
          console.log("VK---",this.c++)
          if (respData.status) {
            this.authService.generateLogoPath(respData.data.logoURL);
            this.authService.generateRegistrationBtn(respData.data.extRegAllow);
            this.authService.generateMobilePattern(JSON.stringify(respData.data.mobileNoPattern));
            this.authService.generateOrgSubID(respData.data.subscriberId);
            localStorage.setItem('orgData',JSON.stringify(respData));
            sessionStorage.setItem('orgData',JSON.stringify(respData));
            sessionStorage.setItem('loginBgPath', respData.data.loginBackground);
            sessionStorage.setItem('urlRequired', respData.data.urlRequired);
            sessionStorage.setItem('iconPath', respData.data.iconURL);
            sessionStorage.setItem('orgsSubId', respData.data.subscriberId);
            sessionStorage.setItem('isRpaAllow', respData.data.isRpaAllow);
            sessionStorage.setItem('conditionalTermsUrl', respData.data.conditionalTermsUrl);
            sessionStorage.setItem('loginImageUrl', respData.data.loginImageUrl);
            sessionStorage.setItem('loginBtnColor', respData.data.loginBtnColor);
            sessionStorage.setItem('loginBtnText', respData.data.loginBtnText);
            sessionStorage.setItem('logoPath', respData.data.logoURL);
            sessionStorage.setItem('showSideImage', respData.data.showSideImage);
            sessionStorage.setItem('cardHeaderContent', respData.data.cardHeaderContent);
            sessionStorage.setItem('userName', respData.data.userName);
            sessionStorage.setItem('passWord', respData.data.passWord);
            sessionStorage.setItem('cardFooterArray', JSON.stringify(respData.data.cardFooterArray));
            sessionStorage.setItem('footerContent', respData.data.footerContent);
            sessionStorage.setItem('privacy', respData.data.privacy);
            sessionStorage.setItem('privacyhref', respData.data.privacyhref);
            // link['href'] = respData.data.iconURL;
            document.getElementsByTagName('head')[0].appendChild(link);


            // Required if website type is set in configuration(eg:for sports)
            /*if(!this.websiteParam) {
              if (respData.data.websiteType === 'none') {
                this.router.navigate(['/login']);
              }else if (respData.data.websiteType === 'dynamic'){
                //window.location.assign(url);
                this.router.navigate(['/'],  {queryParams: {website: true}});
                //window.open(url);
              }else if (respData.data.websiteType === 'custom'){
                this.router.navigate(['/sport']);
              }
            }else {
              console.log();
            }*/
            // Required for load dynamic theme
            /*if(!isNullOrUndefined(respData.data.businessType) && respData.data.businessType != '') {
              if(respData.data.businessType.toLowerCase() == 'automobile'){
                let themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
                let layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');
                themeLink.href = 'assets/theme/theme-' + 'dark-blue' +'.css';
                layoutLink.href = 'assets/layout/css/layout-' + 'dark-blue' +'.css';
              } else{
                console.log();
              }
            }else {
              console.log();
            }*/
          } else {
            console.log();
          }
        },
        err => {
          console.log();
        });
      }
  }
}
