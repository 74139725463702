<div class="modal fade text-color" id="forgotPasswordModal" tabindex="-1" role="dialog"
    aria-labelledby="servicesModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" style="top:15%">
    <div class="modal-dialog" role="document" style="width: 450px">
        <div class="modal-content">
            <div class="modal-header" style="border: none;text-align: center;">
                <strong class="modal-title" id="skillModalLabel" style="font-size: 20px;">Forgot Password ?</strong>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textcolor" style="margin-top: 0px;">
                <div class="row">
                    <div class="text-center lableStyle" *ngIf="clickMobile && !passing">
                        <label class="title-header">Enter your mobile number.You will receive an OTP to reset your password.</label>
                    </div>
                    <!-- <div class="col-md-3 col-sm-3 col-xs-3"></div> -->
                    <div *ngIf="!passing" style="width: 400px;padding-left: 15%;padding-right: 10%;" class="widthStyle">
                        <img src="assets/img/phoneF.png" style="position: relative; left: 4%; top: 2.5em; transform: translateY(-50%); height: 20px;" />
                        <input id="contactData" class="form-control text-style" style="padding-left: 13%;"
                            [(ngModel)]="mobileNumber" (focus)="onMobileNumberFocus()" (keyup)="checkLenght()" />
                    </div>

                    <!--
                    <div class="col-md-3 col-sm-3 col-xs-3">
                        <button class="btn btn-primary mar-right" type="button"
                            (click)="onSearchMobileNumberClick()">Submit</button>
                    </div>
                    -->

                    <div *ngIf="!passing" class="col-sm-12 col-md-12 col-lg-12" style="text-align: center;">
                        <button class="upSignUpF" [disabled]="showOtpElement===true" (click)="sendOtpFn('forget') ; sendOtp()" >SEND OTP</button>
                    </div>
                    <div *ngIf="showOtpElement" style="color: black;">
                        <input class="otp" maxlength='7' type="number" id="verifySentOtp" #verifySentOtp name="verifySentOtp" (keyup)="verifyOTPFn($event.target.value,'forgot')" autocompleteOff/>
                        <div class="verifyOtp">
                            <a (click)="sendOtpFn('forget')" [ngClass]="{'disabled-resend-otp':resendOtpTimer > 0}">Resend OTP <span *ngIf="resendOtpTimer > 0">{{timerDisplay}}</span></a>&nbsp;&nbsp;
                            <a (click)="changeMobileNo()">Change Mobile No.</a>
                        </div>
                    </div>

                    <div *ngIf="passing">
                        <!-- <h4 class="modal-title pull-left">Change Password1</h4> -->
                        <div class="modal-body">
                          <!-- <div class="row" style="position: relative; display: flex; flex-direction: column; align-items: center">
                            <div class="bottom-space bottomSpace">
                              <app-input-box [type]="showPw ? 'text' : 'password'" id="user-password" [(model)]="password" label="New Password" (blur)="validatePassword($event)"[logIn] = "'true'"></app-input-box>
                              <img src="assets/img/lock.png" (click)="showPassword()" class="eyeLock">
                              <img src="assets/img/eye.png" (click)="showPassword()" class="eyeF">
                            </div>
                            <div class="bottom-space bottomSpace">
                              <app-input-box [type]="showPw ? 'text' : 'password'" id="user-Cpassword" [(model)]="cpassword" label="Confirm New Password" (input)="comparePassword()" [logIn] = "'true'"></app-input-box>
                              <img src="assets/img/lock.png" (click)="showPassword()" class="eyeLock">

                              <img src="assets/img/eye.png" (click)="showPassword()" class="eyeF">
                              <p *ngIf="isMatchPass" style="color: red;font-size: small">Password does not match</p>
                            </div>
                          </div>
                          <div class="text-center">
                            <button type="button" class="btn btn-primary" [disabled]="!isMatch" (click)="resetPasswordWithOtp()">Reset Password</button>
                          </div> -->
                          <form [formGroup]="changePwdForm" class="change-pwd">
                            <div>
                                <label for="password">New Password</label>
                                <div style="position: relative;">
                                    <input [type]="showPwdInText === 'pwd' ? 'text' : 'password'" formControlName="pwd">
                                    <img src="assets/img/lock.png" (click)="showPwdInText=''" class="eyeLock">
                                    <img src="assets/img/eye.png" (click)="showInText('pwd')" class="eyeF">
                                    <p *ngIf="pCF.pwd.touched && pCF.pwd.invalid" class="error-message">At least
                                        12 length password must contain one upper case letter,special character and
                                        alphanumeric <strong>& White space is also not allowed</strong></p>
                                </div>
                            </div>
                            <div>
                                <label for="password" >Confirm Password</label>
                                <div style="position: relative;">
                                    <input [type]="showPwdInText === 'confirmPwd' ? 'text' : 'password'"  formControlName="confirmPwd">
                                    <img src="assets/img/lock.png" (click)="showPwdInText=''" class="eyeLock">
                                    <img src="assets/img/eye.png" (click)="showInText('confirmPwd')" class="eyeF">
                                    <p *ngIf="pCF.confirmPwd.touched && pCF.pwd.value !== pCF.confirmPwd.value" class="error-message">Passwords do not match</p>
                                </div>
                            </div>
                            <div style="text-align: center;">
                                <button (click)="resetPasswordWithOtp()">Reset Password</button>
                            </div>
                            <div>

                            </div>
                          </form>
                        </div>
                      </div>


                      

                    <div class="col-md-3 col-sm-3 col-xs-3"></div>
                    <div class="col-md-6 col-sm-6 col-xs-6">
                            <input id="contactData" class="form-control text-style"
                            [(ngModel)]="validOTP"
                            *ngIf="checkOTP"/>
                </div>
                <button type="button" class="btn btn-primary" *ngIf="checkOTP" (click)="showOTP('checkOTP')">OTP</button>
            <br/>
            <div class="modal-header" *ngIf="pass">
                    <h4 class="modal-title pull-left">Change Password</h4>

                  </div>

                  <div class="modal-body" *ngIf="pass">

                        <div class="row">
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 bottom-space">
                            <app-input-box [type]="showPw ? 'text' : 'password'" [id]="'user-password'" [(model)]="password" (inputChange)="password = $event" [label]="'Password'" (blur)="validatePassword($event)" [regValidation]="true" [reqErrorMessage]="'Password is Required'" [mandatory]="true" ></app-input-box>
                            <img src="assets/img/eye.png" (click)="showPassword()" class="eyeF">
                            
                          </div>

                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 bottom-space">
                            <app-input-box [type]="showPw ? 'text' : 'password'" [id]="'user-Cpassword'" [(model)]="cpassword" (inputChange)="cpassword = $event" [label]="'Confirm Password'" (input)="comparePassword();" [regValidation]="true" [reqErrorMessage]="'Confirm Password is Required'" [mandatory]="true" ></app-input-box>
                            <img src="assets/img/eye.png" (click)="showPassword()" class="eyeF">
                            <p *ngIf="isMatchPass" style="color: red;font-size: small">Password does not match</p>
                          </div>

                        </div>
                      </div>
                      <div class="text-center">
              <button type="button" class="btn btn-primary" [disabled]="!isMatch" (click)="savePassword('pass');" *ngIf="pass"  data-dismiss="modal">Reset Password</button>
            </div>
            </div>
            <br/>
        </div>

    </div>
</div>
</div>

<div class="modal fade text-color" id="mobileVerification" tabindex="-1" role="dialog"
    aria-labelledby="servicesModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <strong class="modal-title" id="skillModalLabel">Mobile Verification</strong>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="mobileNumber = ''">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body textcolor">
                <div class="row">
                        <label>Mobile Number</label>
                        <input id="contactData" class="form-control text-style" [value]="mobileNumber" disabled/>
                    <div *ngIf="showOtpElement" style="color: black;">
                        <input class="otp" maxlength='7' type="number" id="verifySentOtp" #verifySentOtp name="verifySentOtp" (keyup)="verifyOTPFn($event.target.value,'mobileVerification')" autocompleteOff/>
                        <div class="verifyOtp">
                            <a (click)="sendOtpFn()" [ngClass]="{'disabled-resend-otp':resendOtpTimer > 0}" >Resend OTP <span *ngIf="resendOtpTimer > 0">{{timerDisplay}}</span></a>&nbsp;&nbsp;
                        </div>
                    </div>
            </div>
            </div>
            <br/>
        </div>

    </div>
</div>


<div *ngIf="active">
    <app-password-reset [pRUserInfo]="pRUserInfo" [headerName]="'Reset Password'" [btnName]="'Reset Password'" [msg]="'Your password has expired, please change it'" (setActive)="active = $event" (resetUserInfo)="pRUserInfo = $event"></app-password-reset>
</div>

<div  id="forScroll" [ngClass]="{'centerPos':(!extRegisterAllow && !isMobile)}" class="row">
    <div class="col-lg-6 col-md-6 col-sm-6 zindex" *ngIf="!showSignUpForm" style="height: 100vh;">
        <div class="login-box">
            <div class="logo-position logo-top">
                <img [ngClass]="{'wdth':isMobile}" class="sizeOf" src="{{logoUrlDynamic}}">
            </div>          
            <!-- <div>
                <h1 class="txt">Welcome !</h1>
            </div> -->
            <div class="input-section">
                <div class="input-box">
                    <input type="text" placeholder="Mobile No"  
                    [ngClass]="{'has-error': (userData.mobile == '' || invalidMobile) && formSubmit }"
                    name="txtMobile" #txtMobile="ngModel" [(ngModel)]="userData.mobile"
                    (ngModelChange)="validMobileLength(userData.mobile)" id="userId"
                    (blur)="customMobilePattern(userData.mobile);userData.mobile = userData.mobile.trim()" class="input-style mobile-no">
                    <img src="assets/img/phone.png" alt="" class="left-icon">
                </div>

                 
                <div class="input-box">
                    <input [type]="showPw ? 'text' : 'password'" placeholder="Password" class="input-style password"
                    [ngClass]="{'has-error': userData.pwd == '' && formSubmit }" name="txtPassword"
                    #txtPassword="ngModel" minlength="8" maxlength="20" [(ngModel)]="userData.pwd"
                    (keyup.enter)="loginUser()" id="password">
                    <img src="assets/img/password.png" alt="" class="left-icon">
                    <img src="assets/img/eye.png" (click)="showPassword()" class="eye">
                </div>
        
                <p *ngIf="invalidCred"  class="danger">Invalid Credentials</p> 
                
                <p class="forgotPassword" (click)="onForgotPasswordClick()">Forgot Password</p>
               

                <!-- <label class="with" style="margin-right: 15%;" [ngClass]="{'forMob':isMobile}">
                    <span>Keep me signed in</span>
                    <input type="checkbox" [ngModel]="userData.kliselected == true" (ngModelChange)="userData.kliselected=$event?true:false"> 
                    <span class="checkmark"></span>
                </label> -->

                <div class="signed-in-checkbox">
                    <input type="checkbox" [ngModel]="userData.kliselected == true" (ngModelChange)="userData.kliselected=$event?true:false"> 
                    <span>Keep me signed in</span>
                </div>

                <div class="signin-btn">
                    <button (click)="loginUser()" id="signIn">Sign in</button>
                    <!-- <button (click)="openMobilePopup()">Enter Mobile Number</button>                    -->
    <div class="popup" *ngIf="showPopup">
        <div class="popup-content">
          <h2></h2>
          <form [formGroup]="mobileForm" (ngSubmit)="submitMobileNo()">
            <div>
              <label for="mobileNo">Mobile Number</label>
              <input id="mobileNo" formControlName="mobileNo" type="tel" placeholder="Enter your mobile number">
              <div *ngIf="mobileForm.get('mobileNo')?.invalid && (mobileForm.get('mobileNo')?.dirty || mobileForm.get('mobileNo')?.touched)">
                <small *ngIf="mobileForm.get('mobileNo')?.errors?.['required']">Mobile number is required.</small>
                <small *ngIf="mobileForm.get('mobileNo')?.errors?.['pattern']">Enter a valid mobile number.</small>
              </div>
            </div>
            <div>
                <label for="ipAddress">Enter IP Address</label>
                <input id="ipAddress" formControlName="ipAddress" type="tel" placeholder="Enter your IP address">
                <!-- <div *ngIf="mobileForm.get('mobileNo')?.invalid && (mobileForm.get('mobileNo')?.dirty || mobileForm.get('mobileNo')?.touched)">
                  <small *ngIf="mobileForm.get('mobileNo')?.errors?.['required']">Mobile number is required.</small>
                  <small *ngIf="mobileForm.get('mobileNo')?.errors?.['pattern']">Enter a valid mobile number.</small>
                </div> -->
              </div>
            <div class="popup-actions">
              <button type="button" (click)="closeMobilePopup()">Cancel</button>
              <button type="submit" [disabled]="mobileForm.invalid">Submit</button>
            </div>
          </form>
        </div>
      </div>                     
                </div>
               
            </div>
        </div>  

    </div>
    <div *ngIf="extRegisterAllow && !showSignUpForm" class="col-lg-6 col-md-6 col-sm-6" style="position: relative;height: 100vh;background-size: cover;" [style.background-image]="'url('+loginImageUrl+')'" >
    <div class="give-sdw" >
        <div>
            <img src="assets/img/signUp.png" alt="" style="height: 95%;width:95%;top: 15%;position: relative;" class="left-icon">
        </div>
        
        <div class="content">
            <h1>NEW HERE ?</h1>
            <h4>Sign up and step into the future</h4> 
            <button class="sign-up" (click)="changeType('taxpayer')" id="signUp">Sign up</button>
        </div>
    </div>
    <!-- <img *ngIf="!showSignUpForm" class="loginImageUrl" src={{loginImageUrl}}  style="height: 100vh; width: 100%;"> -->
    </div>
   
  
    <!-- <img *ngIf="isSignUp" src={{loginWoText}} class="sign-off" style="height: 100vh;"> -->
    <!-- <img src="../../../assets/img/LogIn-Page-image.jpg" *ngIf="isSignUp" class="sign-off"> -->
    <!-- <div class="over" *ngIf="isSignUp">
                        <div class="btn-group wbdisp" data-toggle="buttons" *ngIf="!isRpa">
                            <label *ngIf="!isRpa" class="btn btn-info width55 label-size"
                                [ngClass]="{'selected-user': selectedUserType === 'taxpayer'}"
                                (click)="changeType('taxpayer')">
                                <input type="radio" name="options" id="option1" autocomplete="off">TAX PAYER
                            </label>
                            <label *ngIf="!isRpa" class="btn btn-info width55 label-size"
                                [ngClass]="{'selected-user': selectedUserType === 'agent'}"
                                (click)="changeType('agent')">
                                <input type="radio" name="options" id="option2" autocomplete="off">TAX CONSULTANTS
                            </label>
                        </div>
                        <div  class="ght">
                        <form id="signForm" novalidate (ngSubmit)="onSignUpSubmit()" [formGroup]="regform">
                            <div class="dist">
                                <label class="title-up">SIGN UP</label>
                                </div>
                                <div class="offSize">
                                       <span class="name fnt">Name</span>
                                       <input class="form-control  border-name" type="text" name="txtName" formControlName="name"
                                         [onBlurText]="name.value" (focus)="onNameFocus()" />
                                <p *ngIf="isNameErrorMessage && name.invalid" style="color: red;font-size: small">Name
                                    is required</p>
                            </div>
                            <div class="offSize">
                                <span class="name fnt">Mobile</span>
                                <input class="form-control border-name" type="text" maxlength="10"
                               (keypress)="utilsService.restrictNumeric($event)" name="txtUserMobile"
                                    formControlName="mobile" (blur)="customSignupMobilePattern()" />
                                <p *ngIf="isMobileErrorMessage" style="color: red;font-size: small">Enter valid mobile
                                    number</p>
                                <p *ngIf="invalidSignupMobile" style="color: red;font-size: small">Invalid Mobile Number
                                </p>
                            </div>
                            <div class="offSize">
                                <span class="name fnt">Email</span>
                                <input class="form-control border-name" type="text" name="txtEmail"  formControlName="cemail" (focus)="onEmailFocus()"
                                        (input)="isEmailValid($event)" />
                                    <p *ngIf="isEmailErrorMessage && (cemail.invalid || invalidEmail)"
                                        style="color: red;font-size: small">Enter valid email</p>

                                
                            </div>
                            <div class="offSize">
                                <span class="name fnt">Password</span>
                                <input class="form-control border-name" type="password" name="txtUserPwd"
                                     formControlName="pwd" (focus)="onPasswordFocus()"
                                        (input)="checkConfirmPass()" />
                                    <p *ngIf="isShowMessage && pwd.invalid" style="color: red;font-size: small">At least
                                        8 length password must contain one upper case letter,special character and
                                        alphanumeric</p>
                                </div>
                                <div class="offSize">
                                    <span class="name fnt">Confirm Password</span>
                                    <input class="form-control border-name" type="password" formControlName="cpwd" (input)="checkConfirmPass()" />
                                    <p *ngIf="isCPasswordMessage" style="color: red;font-size: small">Password is not
                                        matched</p>
                                </div>
                                <div class="boxes">
                            <div class="row">
                                <label class="new-with register fnt">I agree to <span class="name fnt">Terms & Conditions</span>
                                    <input type="checkbox" name="trmcond" formControlName="trmcond" (input)="selectTerms($event)">
                                    <span class="checkmarked"></span>        
                                </label>
                                    </div>

                                </div>
                            </form>
                            </div>
                            <div class="opac-sign">
                                <button type="submit" class="btn btn-success width100 margin-bottom1"
                                    [disabled]="regform.invalid">{{ 'LOGIN.SIGNUP' | translate }}</button>
                                   <button type="button" class="btn btn-info width100 margin-bottom1"
                                    (click)="backToUserType();">{{ 'LOGIN.ALREADYACCOUNT' | translate }}</button>

                </div>
            </div> -->
            <!-- <form id="signForm" novalidate (ngSubmit)="onKycSignUpSubmit()" [formGroup]="regform"></form> -->
            <form id="signForm" novalidate  (ngSubmit)="onSignUpSubmit()"  [formGroup]="regform">
    <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="showSignUpForm">
        <!-- <img src="../../../assets/img/LogIn-Page-image1.png"> -->
    <div [ngStyle]="{'background-image': 'url(' + loginWoText + ')'}" class="hero-image" >
    <div class="col-sm-4 col-md-4 col-lg-4 hero-text" >
        <h4 class="registration-title">Registration Form</h4>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="radioButtonsStyle" *ngIf="showIndAndEnt">
                <!-- <label class="radio-inline" >
                    <input type="radio" formControlName="registrationType" value="Individual" (change)="changeOption('Individual')" [checked]="regform.get('registrationType').value == 'Individual'"> Individual
                  </label> -->
                  <label class="radio-inline">
                    <input type="radio" formControlName="registrationType" value="Enterprise" (change)="changeOption('Enterprise')" [checked]="regform.get('registrationType').value == 'Enterprise'"> Enterprise
                  </label>
              </div>
        <input placeholder="Name" class="form-control place leave-bt" type="text" name="txtName" formControlName="name"
        [onBlurText]="name.value" (focus)="onNameFocus()" id="name">
        <p *ngIf="isNameErrorMessage && name.invalid" class="selectError error-message">Name
            is required</p>
<!-- 
        <div class="styled-select">
        <select id="select-category" class="drpSelect" formControlName="outputParams">
        <option *ngFor="let outputParams of genderList" [ngValue]="outputParams">{{outputParams.label}}</option>            
        </select>
        </div> -->

        <!-- <div class="styled-select">
        <select id="select-category" class="drpSelect" formControlName="marital">
        <option *ngFor="let marital of maritalList" [ngValue]="marital">{{marital.label}}</option> 
        </select>
          </div> -->

        <!-- <div class="styled-select">
        <select id="select-category" class="drpSelect" formControlName="residential">
        <option *ngFor="let residential of residentialList" [ngValue]="residential">{{residential.label}}</option> 
        </select>
        </div> -->

        <!-- <input placeholder="State" class="form-control place leave-bt" type="text" name="txtState" formControlName="state"
        [onBlurText]="state.value" (focus)="onStateFocus()">
        <p *ngIf="isStateErrorMessage && state.invalid" style="color: red;font-size: small" class="selectError">Please Enter State</p> -->
      <div class="countryCodes" id="countryCodes">
        <select class="selectedCountrycode form-control leave-bt place" >
            <option *ngFor="let code of countryCodes" [value]="code">{{ code }}</option>
          </select>
        <input placeholder="Mobile (Login Id)" class="form-control place leave-bt" type="text" maxlength="10" id="mobileNo"
        (keypress)="utilsService.restrictNumeric($event)" name="txtUserMobile" formControlName="mobile" (blur)="customSignupMobilePattern()">
        <p *ngIf="isMobileErrorMessage"  class="selectError  error-message">Enter valid mobile
            number</p>
        <p *ngIf="invalidSignupMobile"  class="selectError  error-message">Invalid Mobile Number
        </p>
      </div>

        <input placeholder="Email ID" class="form-control place leave-bt" type="text" name="txtEmail"  formControlName="cemail" (focus)="onEmailFocus()" id="emailId"
        (input)="isEmailValid($event)" [ngStyle]="{'pointer-events':registeredUserEmail ? 'none' : ''}" >
        <p *ngIf="isEmailErrorMessage && (cemail.invalid || invalidEmail)"
        style="color: red;font-size: small" class="selectError  error-message">Enter valid email</p>

        <div style="position: relative;">
            <input [type]="showPw ? 'text' : 'password'" placeholder="Password" class="form-control place leave-bt" name="txtUserPwd" id="loginPass"
            formControlName="pwd" (focus)="onPasswordFocus()" (input)="checkConfirmPass()"  [pattern]="noSpacesRegex" >
            <img class="ece login-logo eyeStyle ece-signForm" src="{{eyeImage}}" (click)="showPassword()">
        </div>
            
            <p *ngIf="isShowMessage && pwd.invalid" class="error-message">At least
                8 length password must contain one upper case letter,special character and
                alphanumeric <strong>& White space is also not allowed</strong></p>
    
          
            <div style="position: relative;">
                <input [type]="showConfirmPw ? 'text' : 'password'" placeholder="Confirm Password" class="form-control place leave-bt" formControlName="cpwd"
                (input)="checkConfirmPass()" id="cpassword" />
                <img class="ece login-logo eyeStyle ece-signForm" src="{{eyeImage}}" (click)="showConfirmPassword()">
            </div>
            <p *ngIf="isCPasswordMessage" style="color: red;font-size: small">Password is not
                matched</p>
            </div>
      

        <!-- <button type="button" class="btn btn-info width100 margin-bottom1"
        (click)="backToUserType();">{{ 'LOGIN.ALREADYACCOUNT' | translate }}</button> -->

        <!-- <div class="row hidden_div">
            <div class="farm"
            [ngClass]="{'has-error': offline.invalid && (offline.dirty || offline.touched), 'has-success': offline.valid && (offline.dirty || offline.touched)}">
            <div style="text-align: left;">
                <div class="pretty p-icon p-curve p-tada">
                    <input type="checkbox" name="offline" formControlName="offline">
                    <div class="state p-primary-o">
                        <i class="icon fa fa-check"></i>
                        <label></label>
                    </div>
                </div>
            </div>
            <div class="registerUti hidden_div" style="text-align: left;margin-top: -20px; margin-left: 10%;">
                <label>Register for Offline Utility
                </label>
            </div>
        </div>
        </div>
        <div *ngIf="regform.value.offline === true" class="row">
            <div class="farm"  style="text-align: left; color: #000;"
                [ngClass]="{'has-error': machineId.invalid && (machineId.dirty || machineId.touched), 'has-success': machineId.valid && (machineId.dirty || machineId.touched)}">
                <label>Enter Machine Id</label>
                <input type="text" class="form-control place" formControlName="machineId" />
               
            </div>
        </div> -->
    
    
        <div class="row">
        <div class="farm"
        [ngClass]="{'has-error': trmcond.invalid && (trmcond.dirty || trmcond.touched), 'has-success': trmcond.valid && (trmcond.dirty || trmcond.touched)}">
        <div style="text-align: left;">
            <div class="pretty p-icon p-curve p-tada">
                <input type="checkbox" name="trmcond" formControlName="trmcond"
                    (input)="selectTerms($event)" id="TermsandConditions">
                <div class="state p-primary-o">
                    <i class="icon fa fa-check"></i>
                    <label></label>
                </div>
            </div>
        </div>
        <div style="text-align: left;margin-top: -20px; margin-left: 10%;">
            <label class="registerUti"> I agree to
                <a href="https://aiqod.com/terms-of-service/" target="_blank">
                    <b data-toggle="modal" data-target="#myModal"
                        style="cursor: pointer;">Terms & Conditions</b>
                </a>
                and 
                <a href="https://aiqod.com/privacy-policy-2/" target="_blank">
                    <b data-toggle="modal" data-target="#myModal"
                        style="cursor: pointer;">Privacy Policy</b>
                </a>
                and 
                <a href="https://aiqod.com/terms-of-use/" target="_blank">
                    <b data-toggle="modal" data-target="#myModal"
                        style="cursor: pointer;">Terms of Use</b>
                </a>
            </label>
        </div>
    </div>
    </div>

    <div  style="text-align: center;" class="col-sm-12 col-md-12 col-lg-12">
        <!-- <button class="upSignUp">{{ 'LOGIN.SIGNUP' | translate }}</button> -->
        <!-- -->
       
        
        <button class="upSignUp"  [disabled]="showOtpElement===true || !regform.valid || isCPasswordMessage || !isSelected"  [ngClass]="{'disabled-sign-up':!regform.valid || isCPasswordMessage || !isSelected}" id="sendOtp">SEND OTP</button>
    </div>
       
        <div *ngIf="showOtpElement" style="color: black;">
            <input class="otp" maxlength='7' type="number" id="verifySentOtp" #verifySentOtp name="verifySentOtp" (keyup)="verifyOTPFn($event.target.value)" autocompleteOff/>
            <div class="verifyOtp">
                <a (click)="sendOtpFn()" [ngClass]="{'disabled-resend-otp':resendOtpTimer > 0}">Resend OTP <span *ngIf="resendOtpTimer > 0">{{timerDisplay}}</span></a>&nbsp;&nbsp;
                <a (click)="changeMobileNo()">Change Mobile No.</a>
              </div>
          </div>

          <!-- <div *ngIf="showOtpElement"  style="color: black;">
            <input class="otp" maxlength='7' type="number" id="verifySentOtp" #verifySentOtp name="verifySentOtp" (keyup)="verifyOTPEm($event.target.value)" autocompleteOff/>
            <div class="verifyOtp">
                <span >Enter email otp </span>
                <a (click)="resendOTPEm()">Resend OTP</a>&nbsp;&nbsp;
              </div>


          </div> -->
      
        <button type="button" class="btn btn-info width100 margin-bottom1"
        (click)="backToUserType();">Already have an account? Log In</button>

        <!-- <button type="button" (click)="showToaster()">Toaster</button> -->
    </div>
    </div>
    </div>
    </form>


        <!-- </div> -->
</div>

 <!-- <input placeholder="Gender" class="col-sm-12 col-md-12 col-lg-12 place leave-bt"> -->
        <!-- <input placeholder="Marital Status" class="col-sm-12 col-md-12 col-lg-12 place leave-bt"> -->
        <!-- <input placeholder="Residential Status" class="col-sm-12 col-md-12 col-lg-12 place leave-bt"> -->