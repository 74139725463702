import {Component, Inject, OnInit} from '@angular/core';
import {Transition} from '@uirouter/angular';
import {StateService} from '@uirouter/core';

@Component({
    selector: 'app-launch',
    templateUrl: 'launch.component.html',
    styleUrls: ['launch.component.css']
})
export class PageLaunchComponent implements OnInit {
    params: any;
    loading: boolean;

    constructor(private transition: Transition, private stateService: StateService) {
        this.params = transition.params();
    }

    ngOnInit() {
        this.openWebsite(this.params.userData.domain);
        if (this.params.userData.domain === null || this.params.userData.domain === undefined) {
            this.stateService.go('main.home');
        }

    }

    openWebsite(web) {
        let self = this;
        self.loading = true;
        setTimeout(function () {
            self.loading = false;
            window.location.assign('https://' + web);
        }, 30000);
    }
}
