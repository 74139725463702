import { Injectable, Inject } from '@angular/core';
import { Response } from '@angular/http';
import { APP_CONFIG, IConfig } from '../../../appConfig/Iconfig';
import { CustomAuthHttp } from '../../../services/local-authhttp/local-authhttp'
import { map } from 'rxjs/operators';

@Injectable()
export class PackageService {
  private MongoURI: string;
  public AllRules: any = [];
  private BackendURI:any;

  constructor(private http: CustomAuthHttp,
              @Inject(APP_CONFIG) private iconfig: IConfig, private _customAuthHttp: CustomAuthHttp) {
    this.MongoURI = iconfig.mongoUrl;
    this.BackendURI = iconfig.newBackendUrl;
  }

    // public getHeaders(){
    //     let headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     return headers;
    // }
    public getCartData(){
        return this.http.get(this.BackendURI+"user/get/cart/List")
        .map((resp: any) => resp);
    }
    
    public addToCart(data){
        return this.http.post(this.BackendURI+"user/cart",data)
        .pipe(map((resp: any) => resp));
    }

    public removeFromCart(data){
        return this.http.post(this.BackendURI+"user/cartRemove",data)
            .map((resp:any)=>resp);
    }
    
    public removeFromCartAfterBuy(data){
        return this.http.post(this.BackendURI+"user/cartRemove/afterBuy/success",data)
            .map((resp:any)=>resp);
    }
    public doPayment(list){
        return this.http.post(this.BackendURI+"subs/addPackage",list)
            .map((resp:any)=>resp);
    }
    public getProfileData() {
        return this.http.get(this.MongoURI + 'user/profileData')
            .pipe(map((res:any) => res));
    }
    public getprocessListId(list){
        return this.http.post(this.BackendURI+"product/getProcessListID",list)
            .map((resp:any)=>resp);
    }
    public savepayment(paymentData){
        return this.http.post(this.BackendURI+"payment/save",paymentData)
        .map((resp:any)=>resp);
    }
    public processAccess(list){
        return this.http.post(this.BackendURI+"process/multiProcessProject",list)
            .map((resp:any)=>resp);
    }
    
 
    public getSubscriberPackageList(){
        return this.http.get(this.BackendURI+"subs/getPackage")
            .map((resp:any)=>resp);
    }
    // public getPackage(data){
    //     return this.http.post(this.MongoURI+'website/pricingpkg',data)
    //         .map((resp:any)=>resp)
    // }

    public getAddOnPackages(data){
        return this.http.post(this.BackendURI+"product/getAddOnPackages/",data)
            .map((resp:any) => resp)
    }

    public getPackages(){
        return this.http.get(this.BackendURI+"product/getPackages")
          .map((resp:any) => resp);
      }
    public getCustomPackage(data){
        return this.http.post(this.MongoURI+'website/custompkg/',data)
            .map((resp:any)=>resp)
    }

    public updateUserPackage(data){
        return this.http.post(this.MongoURI+'user/update/userpackage',data)
            .map((resp:any)=>resp)
    }
    public createOrder(data) {
        return this.http.post(this.MongoURI + "website/createOrder", data)
            .map((resp: any) =>resp);


    }

}