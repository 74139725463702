import {Component, Inject, OnInit, ViewContainerRef} from '@angular/core';
import {Transition} from '@uirouter/angular';
import {Response} from "@angular/http";
import {StateService} from '@uirouter/core';
import { ToastrService } from 'ngx-toastr';

import {AuthService} from "../../../services/auth.service";
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-thankyou-page',
    templateUrl: 'thankyouPage.component.html',
    styleUrls: ['thankyouPage.component.css']
})
export class ThankYouPageComponent implements OnInit{

    params: any;
    constructor(private transition: Transition, private stateService: StateService, private authService: AuthService, public toastr: ToastrService, vcr: ViewContainerRef) {
        //this.toastr.setRootViewContainerRef(vcr);
        this.params = transition.params();
    }

    ngOnInit() {
        this.initValues();
        this.initMethods();
    }

    public openRegistration(){
        this.stateService.go('page.login');
    }

    private initValues() {

    }

    private initMethods() {
        this.validateToken();
    }

    private validateToken() {
        let self = this;
        this.authService.validateUserToken(this.params.token)
            .pipe(map((res:any) => res))
            .subscribe(
                response => {
                    if(response.status)
                    {

                    }
                    else
                    {
                        self.toastr.error(response.data,'Message');
                    }
                },
                err => {

                }
            );
    }
}
