import {Component, Inject , OnInit ,  ElementRef , ViewContainerRef,ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {StateService, TransitionService} from '@uirouter/core';

@Component({
    selector: 'token-exp',
    templateUrl: 'tokenExpModel.component.html',
    styleUrls: [],
    providers: []
   
})
export class TokenExpModelComponent implements OnInit {
   
    @ViewChild('template',{static:false}) tempModal: ElementRef;
    modalRef: BsModalRef;

    constructor(private stateService: StateService,private modalService: BsModalService) {
                   
    }

    ngOnInit() {
        this.initValues();
        this.initMethods();
    }

    private initValues() {
    }

    private initMethods() {
        let self = this;
        this.modalRef = this.modalService.show(this.tempModal,{backdrop: 'static',keyboard:false,});
         setTimeout(function () {
            self.modalRef.hide()
            self.stateService.go('page.login');
        }, 4000);
    }
}
