import {Inject, Injectable} from '@angular/core';
import {CustomAuthHttp} from '../../../../services/local-authhttp/local-authhttp';
import {AuthService} from '../../../../services/auth.service';
import {APP_CONFIG, IConfig} from '../../../../appConfig/Iconfig';
import {Response} from '@angular/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import * as io from 'socket.io-client';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ChatService {
  private URI: string;
  private MongoURI: string;
  private NewBackendUrl: string;
  private smartAIUri:string;
  private smartDocChatUrl:string;
  private socket;
  private ChatURL;
  private videoSocket;
  constructor(private http: CustomAuthHttp, private normalHttp: HttpClient, private authService: AuthService,
              @Inject(APP_CONFIG) private _Iconfig: IConfig) {
    this.MongoURI = _Iconfig.mongoUrl;
    this.NewBackendUrl = _Iconfig.newBackendUrl;
    this.smartAIUri = _Iconfig.smartAIUri;
    this.smartDocChatUrl = _Iconfig.smartDocChatUrl;
    this.ChatURL = _Iconfig.chatWindow;
    this.videoSocket = _Iconfig.videoWindow;
   // this.socket = io.connect(this.ChatURL, {secure: true});
   this.socket = authService.returnSocket();

   // this.socket.emit('join', {ID: "123"});
  }

  public videoConnect(){
   this.videoSocket = this.authService.returnVideoSocket();
  }

  public joinRoom(data) {
    this.socket.emit('join', data);
  }

  public Adduser(){
    this.videoSocket.emit('Add-user');
  }

  public makeoffer(data){
    this.videoSocket.emit('make-offer', data);
  }

  public makeAnswer(data){
    this.videoSocket.emit('make-answer', data);
  }
  public sendToSmartChat(msgdata,module) {
    if (module == 'process') {
      return this.http.post(this.smartAIUri + 'chatbot/chat', JSON.stringify(msgdata))
        .pipe(map((resp: any) => resp));
    } else if (module == 'document') {
      return this.http.post(this.smartDocChatUrl + 'chatbot/chat', JSON.stringify(msgdata))
      .pipe(map((resp: any) => resp));
    }
  }


  public sendComment(msgdata) {
    return this.http.post(this.NewBackendUrl + 'chat/save', JSON.stringify(msgdata))
      .pipe(map((resp: any) => resp));
  }

  public getMsgs(taskId) {
    return this.http.post(this.NewBackendUrl + 'chat/get', JSON.stringify(taskId))
      .pipe(map((resp: any) => resp));
  }
  public deleteMessage(data) {
    return this.http.post(this.NewBackendUrl + 'chat/delete', JSON.stringify(data))
      .pipe(map((resp: any) => resp));
  }
  public msgdata(msg) {
    this.socket.emit('new_msg', msg);
  }
  public removeMsg(msg) {
    this.socket.emit('rm_msg', msg);
  }
  getRemoveMsg() {
    const observable = new Observable(observer => {
      this.socket.on('remove', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }
  getComments() {
    const observable = new Observable(observer => {
      this.socket.on('rec_msg', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }

  public sendTyping(data) {
    this.socket.emit('is_Typing', data);
  }
  public joinVideoChat(data) {
    this.videoSocket.emit('join', data);
  }

  public addUser(){
    const observable = new Observable(observer => {
      this.videoSocket.on('add-users', (data) => {
        observer.next(data);
      });
    });
      return observable;
  }

  public disconnectVideo(){
    this.videoSocket.disconnect();
  }
  // removeUser(){
  //   const observable = new Observable(observer => {
  //     this.videoSocket.on('add-users', (id) => {
  //       observer.next(id);
  //     });
  //   });
  //     return observable;
  // }

  public answerMade(){
    const observable = new Observable(observer => {
      this.videoSocket.on('answer-made', (data) => {
        observer.next(data);
      });
    });
      return observable;
  }

  public offerMade(){
    const observable = new Observable(observer => {
      this.videoSocket.on('offer-made', (data) => {
        observer.next(data);
      });
    });
      return observable;
  }


  public getTyping() {
    let observable = new Observable(observer => {
      this.socket.on('Typing', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }

  public async handleTranslate(data) {
    // return this.http.post(this.NewBackendUrl + 'copilot/translate', JSON.stringify(data))
    //   .pipe(map((resp: any) => resp));

    
    const response = await fetch(this.NewBackendUrl + 'copilot/translate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const value = await response.json();

    return value;    
  }

  public handleSyn(data) {
    return this.http.post(this.NewBackendUrl + 'copilot/syn', JSON.stringify(data))
      .pipe(map((resp: any) => resp));
  }

  public handleUpload(data) {
    return this.normalHttp.post(this.NewBackendUrl + 'copilot/upload', data)
      .pipe(map((resp: any) => resp));
  }

  public async handleDetectLang(data) {
    // return this.http.post(this.NewBackendUrl + 'copilot/detect_lang', JSON.stringify(data))
    //   .pipe(map((resp: any) => resp));
      
      const response = await fetch(this.NewBackendUrl + 'copilot/detect_lang', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      const value = await response.json();
  
      return value;
  }

}
