import {Component} from '@angular/core';

@Component({
  selector: 'app-login-dashboard',
  templateUrl: 'logindashboard.component.html',
  styleUrls: ['logindashboard.component.css']
})


export class LoginDashBoardComponent {
  public showdash:any;
  ngOnInit(){
    this.initValues();
}
initValues(){

}
 



}
