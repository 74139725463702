<ng-sidebar-container>
  <ng-sidebar
    [(opened)]="opened"
    [mode]="MODES[modeNum]"
    [keyClose]="keyClose"
    [position]="POSITIONS[positionNum]"
    [dock]="dock"
    [dockedSize]="'50px'"
    [autoCollapseHeight]="autoCollapseHeight"
    [autoCollapseWidth]="autoCollapseWidth"
    [closeOnClickOutside]="closeOnClickOutside"
    [closeOnClickBackdrop]="closeOnClickBackdrop"
    [showBackdrop]="showBackdrop"
    [animate]="animate"
    [trapFocus]="trapFocus"
    [autoFocus]="autoFocus"
    [sidebarClass]="'sidebar-style'"
    [ariaLabel]="'My sidebar'"
    (onOpenStart)="onOpenStart()"
    (onOpened)="onOpened()"
    (onCloseStart)="onCloseStart()"
    (onClosed)="onClosed()">

    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
    <div class="setting-div">
      <button class="sidebar-btn bg-transparent" closeSidebar (click)="closeSideMenu()"><a><i class="fa fa-times" aria-hidden="true"></i></a></button>
    </div>

    <div class="req-width" *ngIf="showForm">
        <div class="req-control">

            <div class="form-group input-box">
                <input type="text" id="reqname" class="form-control input-style" [(ngModel)]="req.name" (focus)="onFocus('reqname')" (blur)="checkModelValue('name')"  required>
                <label class="form-control-placeholder" for="reqname">
                    <span class="label-title">Your name</span>
                    <span class="text-danger mandatory">*</span>
                </label>
                <label *ngIf="showReqName && showReqVal"  class="text-danger" style="padding-left: 13px">Name is required</label>
            </div>

        </div>
        <div class="req-control">

            <div class="form-group input-box">
                <input type="text" id="reqmobile" class="form-control input-style" [(ngModel)]="req.mobile" (focus)="onFocus('reqmobile')" (blur)="checkModelValue('mobile')" maxlength="10" (keypress)="this.utilsService.restrictNumeric($event)" required>
                <label class="form-control-placeholder" for="reqmobile">
                    <span class="label-title">Mobile</span>
                    <span class="text-danger mandatory">*</span>
                </label>
                <label *ngIf="showReqPhone && showReqVal"  class="text-danger" style="padding-left: 13px">Mobile is required</label>
                <label *ngIf="invalidReqMobile && showReqVal" class="text-danger" style="padding-left: 13px">Invalid mobile number</label>
            </div>

        </div>
        <div class="req-control">
            <div class="form-group input-box">
                <input type="text" id="reqemail" class="form-control input-style" [(ngModel)]="req.email" (focus)="onFocus('reqemail')" (blur)="checkModelValue('email')"  required>
                <label class="form-control-placeholder" for="reqemail">
                    <span class="label-title">Your email</span>
                    <span class="text-danger mandatory">*</span>
                </label>
                <label *ngIf="showReqEmail && showReqVal" class="text-danger" style="padding-left: 13px">Email is required</label>
                <label *ngIf="invalidEmail && showReqVal" class="text-danger" style="padding-left: 13px">Invalid email number</label>
            </div>

        </div>
        <div class="req-control">
           <div class="form-group input-box">
                <textarea  id="reqmessage" class="form-control input-style" rows="2" [(ngModel)]="req.message" (focus)="onFocus('reqmessage')" (blur)="checkModelValue('message')" required></textarea>
                <label class="form-control-placeholder" for="reqmessage">
                    <span class="label-title">Your message</span>
                    <span class="text-danger mandatory">*</span>
                </label>
                <label *ngIf="showReqMessage && showReqVal" class="text-danger" style="padding-left: 13px">Message is required</label>
            </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary btn-lg" (click)="createRequest(req)">Send</button>
        </div>
        <div class="text-center">
          &nbsp;
        </div>
    </div>
    <div class="req-width" *ngIf="!showForm">

      <p>Dear Customer,<p>
      <p>Welcome to the world of ManageMyVAT!</p>
      <p>Your registration on ManageMyVAT.com was completed successfully.</p>
      <p>Thank you for Choosing us as your compliance partner! You can now explore our services & make your business VAT Compliant. We look forward to serve you . Have a great Day</p>
    </div>
  </ng-sidebar>

  <div ng-sidebar-content>
   <!-- <div class="sidebar-icon">
      <span (click)="toggleOpened()">Reach Us</span>
    </div>-->

  </div>
</ng-sidebar-container>
<div class="sidebar-icon">
    <span (click)="toggleOpened()">Reach Us</span>
</div>
