import { TransitionService } from '@uirouter/core';
import { AuthService } from '../../services/auth.service';


/**
 * This file contains a Transition Hook which protects a
 * route that requires authentication.
 *
 * This hook redirects to /login when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
export function requiresAuthHook(transitionService: TransitionService) {
  let nextStatedata: any = {};
  // Matches if the destination state's data property has a truthy 'requiresAuth' property
  const requiresAuthCriteria = {
    to: (state) => state.data && state.data.requiresAuth
  };

  const roleAuthCriteria = {
    to: (state) => { nextStatedata = state.data; return state.data && state.data.permission }
  };
  // Function that returns a redirect for the current transition to the login state
  // if the user is not currently authenticated (according to the AuthService)

  const redirectToLogin = (transition) => {
    const authService: AuthService = transition.injector().get(AuthService);
    const $state = transition.router.stateService;
    const currentUrl = window.location.href;
    if (!authService.checkLoginDataPresent()) {
      if(currentUrl.includes("/web/dashboard") && currentUrl.includes("?emailVerification")){
        let queryParam = currentUrl.split("?")[1];
        return $state.target('page.login', {emailVerification:queryParam.split("=")[1]}, { location: true });
      } else {
        return $state.target('page.login', undefined, { location: true });
      }
    }
  };

  const cancelRedirect = (transition) => {
    const authService: AuthService = transition.injector().get(AuthService);
    const $state = transition.router.stateService;
    if (!authService.checkPermission(nextStatedata)) {
      if(window.location.pathname != '/web/task/new-my-task'){
        return $state.target('web.restrictAuth', { location: true });
      }
      else{
      sessionStorage.setItem('myNewTaskUrl', window.location.href);
      sessionStorage.setItem('myNewTaskPath', window.location.pathname);
      return $state.target('page.login', {pathname: window.location.pathname, href: window.location.href}, { location: true });
    }
   }   else {
      return true;
    }
  };

  // Register the "requires auth" hook with the TransitionsService
  transitionService.onBefore(requiresAuthCriteria, redirectToLogin, { priority: 10 });
  transitionService.onBefore(roleAuthCriteria, cancelRedirect, { priority: 11 });
}
